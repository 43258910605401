import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import CallerSelect from "../customerRelated/customerDetail/Call Log/helper/CallerSelect";
import CustomTextField from "../formikInputs/CustomTextField";
import GroupSelect from "../formikInputs/GroupSelect";
import UserSelectByGroup from "../formikInputs/UserSelectByGroup";
import CallLogHistorySearchTable from "./CallLogHistorySearchTable";
import CallFlagSelect from "./helper/CallFlagSelect";
import CallGroupSelect from "./helper/CallGroupSelect";
import ClearIcon from "@mui/icons-material/Clear";
import LoginContext from "../../context/LoginContext";

export default function CallLogHistorySearch() {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const [groupId, setGroupId] = useState(0);
  const [ticketRows, setTicketRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const initialValues = {
    groupId: 0,
    startTime: "",
    startTimeEnd: "",
    registerUserId: "",
    caller: "",
    callLogGroup: {
      id: 0,
    },
    callFlag: {
      id: 0,
    },
  };
  useEffect(() => {
    context.setPageTitle("Call Log History Search");
  }, []);
  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      setFieldValue("registerUserId", "");
      setGroupId(values.groupId);
    }, [values.groupId]);
    return null;
  };
  const handleSubmit = async (values) => {
    //console.log(values, "values");
    setTableLoading(true);
    var APIvalues = { ...values };
    delete APIvalues.groupId;
    console.log(APIvalues, "APIvalues");
    try {
      const response = await axiosFetch.post("/ticketSearch", APIvalues, {
        params: {
          groupId: values.groupId,
        },
      });
      console.log(response, "response");
      setTicketRows(response.data);
    } catch (error) {
      console.log(error, "error");
      alert(error);
      // context.setGlobalAlert({
      //   open: true,
      //   severity: "error",
      //   title: "error",
      //   message: `${error.response.data.errorMessage}`,
      // });
    } finally {
      setTableLoading(false);
    }
    return null;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <FormObserver />
          <Box
            sx={{
              alignItems: "center",
              px: "5%",
              py: "1%",
            }}
          >
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <CardContent>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={4}>
                        <GroupSelect name="groupId" />
                      </Grid>
                      <Grid item xs={4}>
                        {/* <CustomTextField
                          data={{ name: "userId", label: `${t("User ID")}` }}
                        /> */}
                        <UserSelectByGroup
                          name="registerUserId"
                          label={t("Register User Name")}
                          groupId={groupId}
                          typeFlag="search"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <CustomTextField
                          data={{
                            name: "telephone",
                            label: `${t("Telephone")}`,
                          }}
                          disabled
                          //type="number"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CallGroupSelect name="callLogGroup.id" />
                      </Grid>
                      <Grid item xs={4}>
                        <CallFlagSelect name="callFlag.id" />
                      </Grid>
                      <Grid item xs={4}>
                        <CallerSelect name="caller" />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            style: {
                              height: "55px",
                            },
                          }}
                          data={{
                            name: "startTime",
                            label: `${t("Start Time")}`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            style: {
                              height: "55px",
                            },
                          }}
                          data={{
                            name: "startTimeEnd",
                            label: `${t("Start Time End")}`,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3.5}
                        alignContent="stretch"
                        display={"flex"}
                      >
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          fullWidth
                        >
                          {t("Search")}
                        </Button>
                      </Grid>
                      <Grid item xs={0.5}>
                        <IconButton
                          color="error"
                          variant="outlined"
                          type="reset"
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <LinearProgress
                    sx={{ visibility: `${!tableLoading ? "hidden" : ""}` }}
                  />
                  <CardContent>
                    <CallLogHistorySearchTable rows={ticketRows} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    </>
  );
}
