import React, { useState, useEffect, memo } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  tabsClasses,
} from "@mui/material";
import CategoryData from "./CategoryData";
import { AxiosFetch } from "../../AxiosFetch";
import { useMemo } from "react";

const CategoryForm2 = (props) => {
  const axiosFetch = AxiosFetch();

  const customerId = props.customerId;

  const mode = props.mode || "";
  let groupId = props.groupId;
  // groupId=2;

  const [tabValue, setValue] = useState(1); /////make it zero
  const [isLoading, setisLoading] = useState(true);
  const [categoryArray, setCategoryArray] = useState([]);

  //This will work like componentDidMount
  useEffect(() => {
    setisLoading(true);
    async function getData() {
      if (props.groupId && props.groupId > 0) {
        const response = await axiosFetch.get(`/category/${groupId}`);

        if (response.status === 200) {
          var arr = [];
          //console.log(response, "category by group id");
          response.data.map((data, index) => {
            if (data.visible) {
              arr.push(data);
            }
          });
          setCategoryArray(arr);
          setisLoading(false);
        }
      }
    }
    getData();
  }, [props.groupId]);

  function allProps(index) {
    return categoryArray[index];
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    console.log("tab panel", value, index);
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CategoryData
            {...allProps(index)}
            mode={mode}
            customerId={customerId}
          />
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabContent = () => {
    return categoryArray.map((category, index) => (
      <div key={index}>
        <TabPanel value={tabValue} index={index}>
          {category.categoryName}
        </TabPanel>
      </div>
    ));
  };
  const tabHead = categoryArray.map((category, index) => (
    <Tab
      label={category.categoryName}
      wrapped
      index={index}
      key={index}
      sx={{ minWidth: "fit-content", flex: 1 }}
    />
  ));

  return isLoading ? (
    <div> Loading </div>
  ) : (
    <Card sx={{ minWidth: 275 }} elevation={4}>
      <CardContent>
        <div>
          <AppBar
            position="static"
            sx={{ backgroundColor: "primary.light" }}
            color="primary"
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.MuiTabScrollButton-root": { opacity: 1, color: "black" },
                  "&.Mui-disabled": { opacity: 0.5, color: "white" },
                },
              }}
              //centered
            >
              {tabHead}
            </Tabs>
          </AppBar>
          {/* <br /> */}
          <TabContent />
        </div>
      </CardContent>
    </Card>
  );
};
const CategoryForm = memo(CategoryForm2);
export default CategoryForm;
