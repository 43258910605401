import {
  Button,
  Grid,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {  Form, Formik, FormikContext } from "formik";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import CustomTextField from "../formikInputs/CustomTextField";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //p: 4,
};

export const NewBookmarkFolder = ({ refreshList }) => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const initialValues = {
    name: "",
    bookmarkList: [
      {
        name: "",
        url: "",
      },
    ],
  };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlListCount, setUrlListCount] = useState(1);

  const handleSubmit = async (values) => {
    console.log(values, "vvvvvv");
    setLoading(true);
    try {
      const response = await axiosFetch.post(
        "/bookmarkFolder",
        JSON.stringify(values)
      );
      console.log(response, "URL Folder API response");
    } catch (error) {
      console.log(error, "URL Folder API Error");
    } finally {
      setLoading(false);
      setOpen(false);
      refreshList();
    }
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        size="large"
        fullWidth
        startIcon={<CreateNewFolderIcon />}
        sx={{ borderRadius: "30px" }}
      >
        {t("Add New Folder")}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <LinearProgress
            sx={{
              visibility: `${!loading ? "hidden" : ""}`,
            }}
          />
          <div style={{ padding: "2.5%" }}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTextField
                      data={{ name: "name", label: t("Folder Name") }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <FormikContext.Consumer>
                        {(formikContext) => {
                          // console.log(formikContext.values,'live valll');
                          return (
                            <>
                              <Grid item xs={6}>
                                <Button
                                  onClick={() => {
                                    setUrlListCount((p) => p + 1);
                                  }}
                                  variant="outlined"
                                  fullWidth
                                >
                                  {t("Add Another URL")}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                onClick={() => {
                                  setUrlListCount((p) => (p > 0 ? p - 1 : 0));
                                  const mm = [
                                    ...formikContext.values.bookmarkList,
                                  ];
                                  mm.pop();
                                  formikContext.setFieldValue(
                                    "bookmarkList",
                                    mm
                                  );
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  fullWidth
                                >
                                  {t("Delete")}
                                </Button>
                              </Grid>
                            </>
                          );
                        }}
                      </FormikContext.Consumer>
                      {Array.from({ length: urlListCount }, (_, indexJ) => (
                        <>
                          <Grid
                            item
                            xs={1}
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Typography variant="h6">{indexJ + 1}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              data={{
                                name: `bookmarkList.${indexJ}.name`,
                                label: t("URL Name"),
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <CustomTextField
                              data={{
                                name: `bookmarkList.${indexJ}.url`,
                                label: t("URL"),
                              }}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpen(false)}
                      variant="contained"
                      size="large"
                      fullWidth
                      color="error"
                      startIcon={<ArrowBackIcon />}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
