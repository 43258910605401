export const images = {
    customerSearch: require('./images/icon.png'),
    customerDetail: require('./images/icon2.png'),
    customerAdd: require('./images/icon3.png'),
    calendar: require('./images/icon4.png'),
    inquiry: require('./images/icon5.png'),
    list: require('./images/icon6.png'),
    settings: require('./images/icon7.png'),

    phoneForward: require('./images/Phone_Forwarded.png'),
    build: require('./images/Build.png'),
    localCafe: require('./images/Local_Cafe.png'),
    meetingRoom: require('./images/Meeting_Room.png'),
    reportProblem: require('./images/Report_Problem.png'),
    transferStation: require('./images/Transfer_Within_A_Station.png'),
    
    newFile: require('./images/icon8.png'),
}
