import { Tab, IconButton, Tabs, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { memo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FormikContext, useFormikContext } from "formik";
import TabPanelMultiple from "./TabPanelMultiple";
import { useTranslation } from "react-i18next";

const TabPanelMemo = ({
  id,
  categoryId,
  itemArray,
  tabCountExisting,
  multipleTabFlag,
  mode,
}) => {
  //console.log("TabPanelCategory Render",tabCountExisting);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [tabCount, setTabCount] = useState(tabCountExisting || 1);

  const handleTabDelete = (props) => {
    //console.log(props, "formik props");
    var categoryDataForm = { ...props.values.categoryDataNew };
    itemArray.map((data, index) => {
      categoryDataForm[data.itemId].pop();
      props.setFieldValue(
        `categoryDataNew.${data.itemId}.${tabCount - 1}`,
        undefined
      );
    });
    if (tabCount - 1 === tabValue) {
      setTabValue((p) => p - 1);
    }
    setTabCount((prev) => prev - 1);
  };

  const handleTabAdd = (props) => {
    var categoryDataForm = { ...props.values.categoryDataNew };
    //console.log(props, "propss");
    itemArray.map((data, index) => {
      data.itemType === "multipleselect"
        ? props.setFieldValue(`categoryDataNew.${data.itemId}.${tabCount}`, [])
        : data.itemType === "filterselect"
        ? props.setFieldValue(`categoryDataNew.${data.itemId}.${tabCount}`, {})
        : props.setFieldValue(`categoryDataNew.${data.itemId}.${tabCount}`, "");
      //   Array.isArray(categoryDataForm[data.itemId][0])
      //     ? props.setFieldValue(`categoryDataNew.${data.itemId}.${tabCount}`, [])
      //     : props.setFieldValue(`categoryDataNew.${data.itemId}.${tabCount}`, "");
    });

    setTabCount((prev) => prev + 1);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={(e, n) => {
              if (e.target.id) {
                setTabValue(n);
              }
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {Array.from({ length: tabCount }, (_, index) =>
              index === tabCount - 1 && index !== 0 ? (
                <Tab
                  label={
                    //id given for onChange tabs
                    <div id="justTab">
                      {t("ItemTab")} {index + 1}
                      {/* <IconButton color={"error"} onClick={handleTabDelete}>
                        <DeleteIcon fontSize="small" onClick={handleTabDelete} />
                      </IconButton> */}
                      <FormikContext.Consumer>
                        {(props) => (
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => handleTabDelete(props)}
                            sx={{
                              "&:hover": {
                                color: "red",
                              },
                              color: "grey",
                              display: mode === "read" ? "none" : "inline",
                            }}
                          />
                        )}
                      </FormikContext.Consumer>
                    </div>
                  }
                  id={index}
                  key={index}
                />
              ) : (
                <Tab
                  label={`${t("ItemTab")} ${index + 1}`}
                  id={index}
                  key={index}
                />
              )
            )}
          </Tabs>
          <FormikContext.Consumer>
            {(props) => (
              <Button
                variant="contained"
                size="medium"
                color={"complementary"}
                startIcon={<AddIcon />}
                onClick={() => handleTabAdd(props)}
                disabled={mode === "read" || !multipleTabFlag}
              >
                {t("ADD")}
              </Button>
            )}
          </FormikContext.Consumer>
        </div>

        {Array.from({ length: tabCount }, (_, index) => (
          <div hidden={tabValue !== index} key={index}>
            <TabPanelMultiple index={index} itemArray={itemArray} mode={mode} />
          </div>
        ))}
      </div>
    </>
  );
};
const TabPanelCategory = memo(TabPanelMemo);
export default TabPanelCategory;
