import * as React from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import { FormikContext } from "formik";
import { useMemo } from "react";
import { useEffect } from "react";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import { WarningAmber } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "10",
    fontSize: 11,
  },
}));

export const DuplicateTelephoneSelectForCustDetail = ({
  customerDetails,
  duplicateTelephoneCustArr,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const groupId = customerDetails.customerGroup.groupId;
  const [loading, setLoading] = useState(true);
  const [telList, setTelList] = useState([]);
  const [age, setAge] = useState(customerDetails.telephone[0] && customerDetails.telephone[0].telNum);
  const [telMap, setTelMap] = useState({});

  useEffect(() => {
    const telMap = new Map();
    const list = customerDetails.telephone.map((telObj, index) => {
      telMap.set(telObj.telNum, []);
      return {
        name: telObj.telNum,
        value: telObj.telNum,
      };
    });
    duplicateTelephoneCustArr.forEach((custData, index) => {
      custData.telephone.forEach(({ telNum, id }, indexx) => {
        if (telMap.has(telNum)) {
          telMap.set(telNum, [...telMap.get(telNum), custData]);
        }
      });
    });
    //console.log(telMap, "TEL MAP");
    setTelList(list);
    setTelMap(telMap);
    setLoading(false);
  }, []);

  const ToolTipTitle = ({ data }) => (
    <div
      style={{
        padding: "2%",
        boxShadow: "0px 3px 10px #212121",
        borderRadius: "10px",
        width: "150%",
        backgroundColor: "white",
        display: "flex",
        paddingLeft: "4%",
      }}
    >
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center" noWrap>
            {t("Duplicate Telephone")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold" align="center">
                {t("Group Name")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold" align="center">
                {t(`Customer Name`)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold" align="center">
                {t("SPT ID")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {telMap
            .get(data.value)
            .map(
              (
                {
                  customerId,
                  customerGroup,
                  customerFirstName,
                  customerLastName,
                },
                i
              ) => {
                return (
                  <Grid container rowSpacing={4} columnSpacing={2} key={i}>
                    <Grid item xs={4}>
                      <Typography variant="body1" align="center">
                        {customerGroup.groupDisplayName}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" noWrap align="center">
                        {`${customerLastName} ${customerFirstName}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} justifyContent="center" display={"flex"}>
                      <Link
                        component="button"
                        variant="body1"
                        underline="hover"
                        onClick={() => {
                          navigate("/customerDetail", {
                            state: {
                              customerId: customerId,
                            },
                          });
                        }}
                        disabled={!(groupId === customerGroup.groupId)}
                        color={
                          groupId === customerGroup.groupId ? "primary" : "rgb(172 172 172)"
                        }
                      >
                        {customerId}
                      </Link>
                    </Grid>
                  </Grid>
                );
              }
            )}
        </Grid>
      </Grid>
    </div>
  );

  return loading ? (
    <></>
  ) : (
    <div>
      <FormikContext.Consumer>
        {(context) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Telephone")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Telephone"
              onChange={(event) => {
                //console.log(event.target, "vvvvvvv");
                setAge(event.target.value);
                context.setFieldValue("telephoneNew", event.target.value);
              }}
              renderValue={(selected) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {selected}
                    {telMap.get(selected).length === 0 ? (
                      <></>
                    ) : (
                      <Tooltip title={t("Duplicate Telephone")}>
                        <WarningAmberOutlinedIcon
                          sx={{ color: "rgb(237, 108, 2)" }}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              }}
            >
              {telList.map((data, index) => (
                <MenuItem value={data.value} key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body">{data.name}</Typography>
                    <LightTooltip
                      title={
                        telMap.get(data.value).length === 0 ? (
                          <></>
                        ) : (
                          <ToolTipTitle data={data} />
                        )
                      }
                      placement="right"
                    >
                      <span>
                        {telMap.get(data.value).length === 0 ? (
                          <></>
                        ) : (
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            //color="error"
                            sx={{ color: "rgb(237, 108, 2)" }}
                          >
                            <InfoIcon />
                          </IconButton>
                        )}
                      </span>
                    </LightTooltip>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </FormikContext.Consumer>
    </div>
  );
};
