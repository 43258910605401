import React from 'react'

const list={
    "==Login==": "====Comment Login page=====",
    "CTI Log In": "CTI ログイン",
    "Login": "ログイン",
    "User ID": "ユーザーID",
    "Password": "パスワード",
    "Select role": "ログインモード",
    "Log In": "ログイン",
    "Forgot password?": "パスワードをお忘れですか？",
    "==UserSearch==": "Comment User Search",
    "User search": "ユーザー検索",
    "extension number": "内線番号",
    "Last Name": "姓",
    "First Name": "名",
    "Search": "検索",
    "Output": "ユーザー出力",
    "Create New": "新規ユーザー作成",
    "Select Group": "グループを選択",
    "Role": "役割",
    "Edit / Details": "詳細を編集する",
    "Delete": "消去",
    "Last Kana Name": "カナ姓",
    "First Kana Name": "カナ名",
    "Save": "保存",
    "Save and New": "保存して新規作成",
    "Cancel": "キャンセル",
    "Create New User": "新しいユーザーを作成",
    "Edit": "編集",
    "User role": "ユーザーの役割",
    "User Detail": "ユーザー詳細",
    "User Edit": "ユーザー編集",
    "Create New Customer": "新規お客様の作成",
    "Birthday": "誕生日",
    "Last Ruby": "カナ姓",
    "First Ruby": "カナ名",
    "Age": "年齢",
    "Email": "Eメール",
    "Sex": "性別",
    "Telephone": "電話番号",
    "ZipCode": "郵便番号",
    "Address 1": "住所（1",
    "Address 2": "住所（2",
    "Address 3": "住所（3",
    "Address 4": "住所（4",
    "Profession": "職業",
    "First User Name": "1st担当",
    "Second User Name": "2nd担当",
    "Author": "作成者",
    "Last Updated by": "最終更新者",
    "Memo 1": "メモ1",
    "Memo 2": "メモ2",
    "Customer Information": "お客様情報",
    "Customer Details": "お客様詳細情報",
    "Customer Edit": "お客様編集",
    "Next Call Date": "次回電話日",
    "   ": "最終更新日",
    "Group Name": "グループ名",
    "Project Group": "プロジェクトグループ",
    "Ticket Search": "トピック検索",
    "Ticket Creation Date": "トピック作成日",
    "Ticket Last Update Date": "トピックの最終更新日",
    "Call Log Search": "通話履歴検索",
    "Last Call Date": "最終電話日",
    "Final Situation": "最新通話フラグ",
    "Call Flag Group": "コール フラグ グループ",
    "Include Title Space": "全てのトピックを含める",
    "Start Date": "通話開始日",
    "End Date": "通話終了日",
    "Ticket Title": "タイトル",
    "Ticket Phase": "フェーズ",
    "Caller": "発信者",
    "Phone Type": "発信/受信",
    "Customer Assignment": "お客様を割当",
    "Customer Project Assignment": "プロジェクトに割当",
    "Customer Export": "お客様情報の出力",
    "Selected Call Flags": "選択されたコール フラグ",
    "Available Call Flags": "使用可能なコール フラグ",
    "Select Project": "プロジェクトを選択",
    "Group ID": "グループ ID",
    "Group Display Name": "グループ表示名",
    "Parent Group": "親グループ",
    "Create New Group": "新しいグループを作成",
    "Group Table": "グループテーブル",
    "New Customer Group": "新しい顧客グループ",
    "Status": "状態",
    "Select Parent Group": "親グループを選択",
    "Register User ID": "ユーザーID登録",
    "Project ID": "プロジェクト ID",
    "Project Name": "プロジェクト名",
    "Flag": "フラグ",
    "Project Table": "プロジェクト",
    "Create New Project": "新規プロジェクトを作成",
    "New Customer Project": "新規顧客プロジェクト",
    "Customer Project Edit": "顧客プロジェクトの編集",
    "Category Name": "カテゴリ名",
    "Create New Category": "新規カテゴリを作成",
    "Visible": "状態",
    "Customize Category Table": "カテゴリ テーブルのカスタマイズ",
    "New Category": "新たなカテゴリー",
    "Visibility": "状態",
    "Multiple Value": "複数作成",
    "Customize Category Detail": "カテゴリの詳細をカスタマイズ",
    "Category ID": "カテゴリーID",
    "Customize Category Edit": "カテゴリー編集のカスタマイズ",
    "Customer Output": "お客様情報出力",
    "------": "------",
    "-----above translation checked-----": "-----------",
    "Category Preview": "カテゴリプレビュー",
    "Create New Category Item": "新しいカテゴリ アイテムの作成",
    "Item ID": "項目ID",
    "Item Name": "項目名",
    "Item Type": "項目の種類",
    "Mandatory": "必須",
    "Item List": "項目一覧",
    "ADD": "追加",
    "New Category Item": "新しいカテゴリ項目",
    "Preview": "プレビュー",
    "Category Item Edit/Detail": "カテゴリ項目編集・詳細",
    "yes": "はい",
    "no": "いいえ",
    "hidden": "非表示",
    "Customer Search": "顧客検索",
    "Home": "HOME",
    "This field is required": "この項目は必須です",
    "-----above translation checked-----(2)": "-----above translation checked-----",
    "Topic Search": "トピック検索",
    "Topic Creation Date": "トピック作成日",
    "Topic Last Update Date": "トピックの最終更新日",
    "Topic Title": "トピックタイトル",
    "Topic Phase": "トピックフェーズ",
    "-----above translation checked-----(3)": "-----above translation checked-----",
    "Customer Search Screen":"顧客検索画面",
    "User Search":"ユーザー管理",
    "Customer Group Table":"グループ管理",
    "Customize CategoryTable":"カスタマイズカテゴリ",
    "Customer Project Table":"プロジェクト管理",
    "Call Log Settings":"コール関連設定",
    "Import Management":"データインポート管理",
    "Login Page":"ログインページ",
    "Test Page":"テストページ",
    "-----above translation checked-----(4)": "-----above translation checked-----",
    "Settings":"設定",
    "All":"全て",
    "Checked only":"チェックのみ",
    "Numerical input":"数値入力",
    "Allocate":"割り当てる",
    "select":"選択する",
    "Select User One":"ユーザー 1 を選択",
    "Select User Two":"ユーザー 2 を選択",
    "User 1 ID":"ユーザー 1 ID",
    "User 2 ID":"ユーザー 2 ID",
    "Export":"書き出す",
    "Save As Format":"形式で保存",
    "Select From Formats":"フォーマットから選択",
    "Saved Formats":"保存されたフォーマット",
    "Customer Columns":"顧客コラム",
    "Category Columns":"カテゴリ列",
    "Last Updated":"最終更新"
}

export default function TestForTranslation() {

    const tt=Object.keys(list);
    let st='';
    tt.map((data,index)=>{
        st=st.concat(`"${data}":"${data}",`)
    })
    console.log(st);

  return (
    <div>TestForTranslation</div>
  )
}
