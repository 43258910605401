import React from "react";
import { useEffect, useState } from "react";
import CustomMultiSelectCheck from "./CustomMultiSelectCheck";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

export default function GroupMultiSelect(props) {
  const { t } = useTranslation();

  const axiosFetch = AxiosFetch();
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let group = [];

  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/group/user`);
      if (response.status === 200) {
        response.data.map((data, index) => {
          group[index] = { name: data.groupDisplayName, value: data.groupId };
        });
        setGroupList(group);
        setIsLoading(false);
      }
    }
    getData();
  }, []);
  return isLoading ? (
    <div><Skeleton variant="rounded" width={"100%"} height={56} /></div>
  ) : (
    <div>
      <CustomMultiSelectCheck
        data={{
          name: props.name,
          label: `${t("Select Group")}`,
          list: groupList,
        }}
        mode={props.mode}
      />
    </div>
  );
}
