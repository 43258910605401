import { Alert, AlertTitle, Box, IconButton, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoginContext from "../../context/LoginContext";
import FlexSideBar from "../sideBar/FlexSideBar";
import TopBar from "../topBar/TopBar";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";

export const drawerWidth = isMobile ? 250 : 180;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? "0px" : `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layout({ children }) {
  //const [sideBarToggle, setSideBarToggle] = useState(false);
  //const context = useContext(LoginContext);
  //console.log(isMobile, "IS Mobile FLag");
  const { globalAlert, setGlobalAlert, pageTitle } = useContext(LoginContext);
  const { t } = useTranslation();
  const handletoggle = () => {
    //setSideBarToggle(!sideBarToggle);
    setOpen(true);
    sessionStorage.setItem("sideBarOpen", true);
  };

  const theme = useTheme();
  const [open, setOpen] = useState(
    JSON.parse(sessionStorage.getItem("sideBarOpen")) || false
  );
  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "CTI-CRM";
  }, []);
  useEffect(() => {
    if (
      JSON.parse(sessionStorage.getItem("sideBarOpen")) === true &&
      pageTitle === "Customer Search"
    ) {
      setOpen(true);
    }
  }, [pageTitle]);

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem("sideBarOpen", false);
  };

  return (
    <>
      {/* <SideBar handletoggle={handletoggle} sideBarToggle={sideBarToggle} />
      <TopBar  handletoggle={handletoggle} sideBarToggle={sideBarToggle}/>
      {context.pageTitle ==='Customer Details' ? <></> : <br/>}
      <div>{children}</div> */}

      <Box sx={{ display: "flex" }}>
        <TopBar
          handletoggle={handletoggle}
          sidebaropen={open}
          drawerWidth={drawerWidth}
        />

        <FlexSideBar
          handleDrawerClose={handleDrawerClose}
          open={open}
          drawerWidth={drawerWidth}
          theme={theme}
        />
        <Main open={open}>
          <DrawerHeader />
          <div>{children}</div>
          <Snackbar
            open={globalAlert.open}
            autoHideDuration={10000}
            //onClose={() => setGlobalAlert({ ...globalAlert, open: false })}
            onClose={(e, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setGlobalAlert({ ...globalAlert, open: false });
            }}
          >
            <Alert
              severity={globalAlert.severity}
              sx={{ width: "100%" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setGlobalAlert({ ...globalAlert, open: false })
                  }
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>{t(`${globalAlert.title}`)}</AlertTitle>
              {t(`${globalAlert.message}`)}
            </Alert>
          </Snackbar>
        </Main>
      </Box>
    </>
  );
}
