import React, { useContext, useState } from "react";
import { Card, CardContent, Button, Grid, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextField from "../formikInputs/CustomTextField";
import { Box } from "@mui/system";
import CustomSelect from "../formikInputs/CustomSelect";
import { useNavigate } from "react-router";
import GroupSelect from "../formikInputs/GroupSelect";
import { AxiosFetch } from "../AxiosFetch";
import LoginContext from "../../context/LoginContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";


export default function CreateNewCategory() {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("New Category");
  }, []);

  var submitAction = "";
  const [loading, setLoading] = useState(false);

  const initialValues = {
    categoryName: "",
    customerGroup: {
      groupId: "",
    },
    visible: "",
    multipleValue: "",
    categoryOrder: "",
    registerUserId: context.userId,
  };

  const formValidation = Yup.object().shape({
    categoryName: Yup.string().required(`${t("This field is required")}`),
    customerGroup: Yup.object().shape({
      groupId: Yup.string()
        .required(`${t("This field is required")}`)
        .test(
          "zero not allowed",
          `${t("This field is required")}`,
          (val) => Number(val) !== 0
        ),
    }),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const Response = await axiosFetch.post(
        "/category",
        JSON.stringify(values)
      );
      console.log(Response, "Response");
      submitAction === "primary" ? navigate(-1) : window.location.reload();
      setLoading(false);
    } catch (error) {
      alert("error");
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div>
      {/* <ButtonAppBar title="New Category" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "20%",
              py: "5%",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item xs={12}>
                    <CustomTextField
                      data={{
                        name: "categoryName",
                        label: `${t("Category Name")}`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupSelect name="customerGroup.groupId" />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      data={{
                        name: "visible",
                        label: `${t("Visibility")}`,
                        list: [
                          { name: `${t("visible")}`, value: true },
                          { name: `${t("hidden")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <CustomSelect
                      data={{
                        name: "multipleValue",
                        label: `${t("Multiple Value")}`,
                        list: [
                          { name: `${t("yes")}`, value: true },
                          { name: `${t("no")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip
                      fontSize="small"
                      title={t(
                        `Create attributes that may be registered multiple times for one customer. (Example: "contract information", "person in charge information", etc.)`
                      )}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      fullWidth
                      onClick={() => {
                        submitAction = "primary";
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      fullWidth
                      onClick={() => {
                        submitAction = "secondary";
                      }}
                    >
                      {t("Save and New")}
                    </Button>
                  </Grid>

                  
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
