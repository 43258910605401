import axios from "axios";

export function AxiosFetch() {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);
  // console.log("here");
  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    // const token = a.jwtToken;
    const token = sessionStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Check if the error is due to unauthorized access (401 status code)
      console.log(error, "axios fetch error");
      if (error.response && error.response.status === 401) {
        window.location = "/";
      }

      return Promise.reject(error);
    }
  );
  return instance;
}
