import React, { useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import Display from "./Display";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import Options from "./Options";
import {
  Formik,
  Form,
  useFormikContext,
  FormikContext,
} from "formik";
import { AxiosFetch } from "../AxiosFetch";
import CustomCheckbox from "../formikInputs/CustomCheckbox";
import CustomTextField from "../formikInputs/CustomTextField";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import { AlertModal } from "./AlertModal";
import SelectWithDelete from "../commonComponents/SelectWithDelete";

const Import = () => {
  const { t } = useTranslation();
  const { userId, ...context } = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Import");
  }, []);

  const [initialValues, setInitialValues] = useState({
    skipLine: false,
    groupId: "",
    projectId: "",
    importType: "noImport",
    importFormat: "",
    newImportFormatName: "",
    Column: [],
  });

  const [categoryItemsArray, setCategoryItemsArray] = useState([]);
  const [validationErrorObject, setValidationErrorObject] = useState(new Map());
  const [alertOpen, setAlertOpen] = useState(false);

  const [importFormatList, setImportFormatList] = useState([]);

  const [importFormatSave, setImportFormatSave] = useState(false);

  const [formatSelected, setFormatSelected] = useState("None");
  async function updateImportFormat() {
    const response = await axiosFetch.get("/importFormat");
    console.log(response, "import format API response");
    if (response.status === 200) {
      var arr = [];
      response.data.forEach((item) => {
        arr.push({
          id: item.id,
          name: item.name,
          value: JSON.parse(item.value),
        });
      });
      setImportFormatList(arr);
    }
  }
  useEffect(() => {
    updateImportFormat();
  }, []);

  const allowedExtensions = ["csv"];

  const [fileData, setFileData] = useState(false);

  const [error, setError] = useState("");

  const [file, setFile] = useState("");

  const axiosFetch = AxiosFetch();

  const [Loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setError("");

    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        //return;
      }
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    if (!file) {
      window.alert("Please enter a supported file type (CSV)");
      return setError("Enter a valid file");
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: false,
        skipEmptyLines: true,
      });
      setFileData(csv.data);
    };
    reader.readAsText(file, "Shift-JIS");
  };

  const handleSubmit = async (values) => {
    //save import format
    if (importFormatSave) {
      if (values.newImportFormatName) {
        const body = {
          name: values.newImportFormatName,
          value: JSON.stringify({
            Column: values.Column,
            groupId: values.groupId,
            projectId: values.projectId,
            skipLine: values.skipLine,
            importType: values.importType,
          }),
        };
        try {
          const response = await axiosFetch.post("/importFormat", body);
          console.log("Success", response.data);
          {
            response &&
              context.setGlobalAlert({
                open: true,
                severity: "success",
                title: "success",
                message: `${response.data}`,
              });
          }
        } catch (error) {
          console.log(error, "Something went wrong");
          {
            error.response.data &&
              error.response.data.errorMessage &&
              context.setGlobalAlert({
                open: true,
                severity: "error",
                title: "error",
                message: `${error.response.data.errorMessage[0]}`,
              });
          }
        }
      } else {
        window.alert("Please select a name for Import format");
      }
      setImportFormatSave(false);
    } else {
      submitData(values);
    }
  };
  const submitData = async (values) => {
    let skipLine = values.skipLine;
    const mappedValues = values.Column;
    var emptyColumnCount = 0;
    var finalList = [];
    fileData.map((fileData1) => {
      if (skipLine) {
        skipLine = false;
      } else {
        var Customer = {
          categoryData: [],
          telephone: [],
          customerRegisterUserId: userId,
        };
        fileData1.map((val, i) => {
          if (val === "") {
            emptyColumnCount++;
          }

          if (mappedValues[i] && mappedValues[i] == "skip") {
          } else if (mappedValues[i] && mappedValues[i].charAt(0) == "c") {
            Customer[mappedValues[i]] = val;
          } else if (mappedValues[i] && mappedValues[i] == "telephone1") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone2") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone3") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone4") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone5") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone6") {
            Customer.telephone.push({ telNum: val });
          } else if (mappedValues[i] && mappedValues[i] == "telephone7") {
            Customer.telephone.push({ telNum: val });
          } else {
            if (mappedValues[i]) {
              var it = mappedValues[i].split("_")[1];
              Customer.categoryData.push({
                itemId: it,
                value: val,
                tabValue: 1,
              });
            }
          }
        });
        Customer.customerGroup = { groupId: values.groupId };
        Customer.project = { id: values.projectId };
        if (emptyColumnCount !== values.Column.length) {
          finalList.push(Customer);
        }
        emptyColumnCount = 0;
      }
    });
    setLoading(true);
    console.log(finalList, "final list");
    const APIvalues = {
      customerList: finalList,
      importType: values.importType,
    };
    const verifyFlag = await verifySubmitData(APIvalues.customerList);
    if (verifyFlag) {
      console.log(APIvalues, "Import  API values");
      try {
        const response = await axiosFetch.post("/customerListSave", {
          customerList: finalList,
          importType: values.importType,
        });

        // window.alert(response.data);
        console.log(response, 'import api response');
        {
          response &&
            context.setGlobalAlert({
              open: true,
              severity: "success",
              title: "success",
              message: `${response.data}`,
            });
        }
      } catch (err) {
        context.setGlobalAlert({
          open: true,
          severity: "error",
          title: "error",
          message: `${err.response.data.errorMessage}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  const verifySubmitData = async (customerList) => {
    const errorDetailMap = new Map();
    console.log(customerList, "final customer list");
    customerList.forEach((object, j) => {
      for (const [key, value] of Object.entries(object)) {
        if (key === "categoryData") {
          value.forEach((itemObj, index) => {
            const isNumberType = itemTypeFromItemId(itemObj.itemId);
            const displayName = itemNameFromItemId(itemObj.itemId);
            if (isNumberType) {
              //number field all digits
              const flag = /^\d+$/.test(itemObj.value);
              if (!flag && itemObj.value !== "") {
                const errorObj = {
                  rowNumber: j,
                  reason: "Not a Number",
                  columnDisplayName: displayName,
                  columnName: key,
                  errorValue: itemObj.value,

                  rowDetails: object,
                };
                if (errorDetailMap.has(j)) {
                  errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
                } else {
                  errorDetailMap.set(j, [errorObj]);
                }

                //outerLoopBreak = true;
                //return;
              }
            } else {
              //1000 char limit
              if (itemObj.value.length >= 1000) {
                const errorObj = {
                  rowNumber: j,
                  reason: "Above character limit of 1000",
                  columnDisplayName: displayName,
                  columnName: key,
                  errorValue: itemObj.value,
                  rowDetails: object,
                };
                if (errorDetailMap.has(j)) {
                  errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
                } else {
                  errorDetailMap.set(j, [errorObj]);
                }

                //outerLoopBreak = true;
                //return;
              }
            }
          });
        } else if (key === "telephone") {
          //all digits
          value.forEach((telephone) => {
            const flag = /^[\d-]+$/.test(telephone.telNum);
            if (!flag && telephone.telNum !== "") {
              const errorObj = {
                rowNumber: j,
                reason: "Not a Number",
                columnDisplayName: `Telephone`,
                columnName: key,
                errorValue: telephone.telNum,
                rowDetails: object,
              };
              if (errorDetailMap.has(j)) {
                errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
              } else {
                errorDetailMap.set(j, [errorObj]);
              }
            }
          });
        } else if (key === "custometZipCode") {
          //all digits
          const flag = /^\d+$/.test(value);
          if (!flag && value !== "") {
            const errorObj = {
              rowNumber: j,
              reason: "Not a Number",
              columnDisplayName: `Customer ZipCode`,
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }

            //outerLoopBreak = true;
            //return;
          }
        } else if (key === "customerEmail") {
          //email format
          const emailPattern =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const emailFlag = emailPattern.test(value);
          if (!emailFlag && value !== "") {
            const errorObj = {
              rowNumber: j,
              reason: "Invalid Email",
              columnDisplayName: `Customer Email`,
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }

            //outerLoopBreak = true;
            //return;
          }
        } else if (key === "customerMemo1") {
          //2000 char limit
          //console.log(value.length, "memo1 length !!!!!!!", value);
          if (value.length >= 2000) {
            const errorObj = {
              rowNumber: j,
              reason: "Above character limit of 2000",
              columnDisplayName: `Customer Memo 1`,
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }

            //outerLoopBreak = true;
            //return;
          }
        } else if (key === "customerMemo2") {
          //2000 char limit
          if (value.length >= 2000) {
            const errorObj = {
              rowNumber: j,
              reason: "Above character limit of 2000",
              columnDisplayName: `Customer Memo 2`,
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }

            //outerLoopBreak = true;
            //return;
          }
        } else if (key === "customerFirstName") {
          if (value.length >= 255 || value === "") {
            const errorObj = {
              rowNumber: j,
              reason:
                value === ""
                  ? "First Name is empty"
                  : "Above character limit of 255",
              columnDisplayName: "Customer First Name",
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }
          }
        } else if (key === "customerLastName") {
          if (value.length >= 255 || value === "") {
            const errorObj = {
              rowNumber: j,
              reason:
                value === ""
                  ? "Last Name is empty"
                  : "Above character limit of 255",
              columnDisplayName: "Customer Last Name",
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }
          }
        } else if (
          key === "customerGroup" ||
          key === "customerRegisterUserId"
        ) {
          //ignore
          continue;
        } else {
          //255 char limit
          if (value.length >= 255) {
            const errorObj = {
              rowNumber: j,
              reason: "Above character limit of 255",
              columnDisplayName: `${key}`,
              columnName: key,
              errorValue: value,
              rowDetails: object,
            };
            if (errorDetailMap.has(j)) {
              errorDetailMap.set(j, [...errorDetailMap.get(j), errorObj]);
            } else {
              errorDetailMap.set(j, [errorObj]);
            }

            //outerLoopBreak = true;
            //return;
          }
        }
      }
    });
    setValidationErrorObject(errorDetailMap);
    if (errorDetailMap.size !== 0) {
      setAlertOpen(true);
    }
    console.log("final errors", errorDetailMap);
    return errorDetailMap.size === 0 ? true : false;
  };
  const itemTypeFromItemId = (id) => {
    const foundObject = categoryItemsArray.find((obj) => obj.itemId === +id); //have converted id from string to number
    if (foundObject && foundObject.itemType === "number") {
      return true;
    } else {
      return false;
    }
  };
  const itemNameFromItemId = (id) => {
    const foundObject = categoryItemsArray.find((obj) => obj.itemId === +id);
    return (
      foundObject &&
      `${foundObject.category.categoryName}->${foundObject.itemName}`
    );
  };

  const handleFormatDelete = async () => {
    console.log("yessss");
    let obj = importFormatList.find((o) => o.name === formatSelected);
    //console.log(obj, "obj");
    try {
      const response = await axiosFetch.delete(`/importFormat/${obj.id}`);
      console.log(response, "response format delete");
      //setImportFormatList((current) => current.filter((e) => e.id !== obj.id));
      setFormatSelected("None");
      updateImportFormat();
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
    } catch (error) {
      //alert(error);
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error);
    }
  };
  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    console.log(values, "live values are", formatSelected);

    useEffect(() => {
      if (importFormatSave) {
        console.log("Don't change format");
      } else {
        {
          values.importFormat &&
            setFieldValue("Column", values.importFormat.Column);
        }
        {
          values.importFormat &&
            setFieldValue("groupId", values.importFormat.groupId);
        }
        {
          values.importFormat &&
            setFieldValue("projectId", values.importFormat.projectId ? values.importFormat.projectId : 0);
        }
        {
          values.importFormat &&
            values.importFormat.skipLine &&
            setFieldValue("skipLine", values.importFormat.skipLine);
        }
        {
          values.importFormat &&
            values.importFormat.importType &&
            setFieldValue("importType", values.importFormat.importType);
        }
      }
    }, [formatSelected]);

    return null;
  };

  return (
    <div>
      {/* <ButtonAppBar title="Import" /> */}
      <Formik
        // enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          handleSubmit(e);
        }}
      >
        <Form>
          {/* <Button onClick={() => setAlertOpen(true)}>Open modal</Button> */}
          <AlertModal
            open={alertOpen}
            handleOpen={(e) => setAlertOpen(e)}
            data={validationErrorObject}
          />
          <FormObserver />
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              px: "5%",
              py: "2%",
              // background: "red",
            }}
          >
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12} md={12}>
                <Card elevation={4}>
                  <CardContent>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                      <Grid item xs={4} md={4}>
                        <input
                          onChange={handleFileChange}
                          id="csvInput"
                          name="file"
                          type="File"
                        />
                        <Button onClick={handleParse} variant="contained">
                          {t("Upload")}
                        </Button>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        {fileData ? (
                          // <CustomSelect
                          //   data={{
                          //     name: "importFormat",
                          //     label: `${t("Import Format")}`,
                          //     list: importFormatList,
                          //   }}
                          // />
                          <FormikContext.Consumer>
                            {(formikContext) => (
                              <SelectWithDelete
                                list={importFormatList}
                                label="Import Format"
                                selected={formatSelected}
                                handleSelected={(e) => {
                                  setFormatSelected(e);
                                  formikContext.setFieldValue(
                                    "importFormat",
                                    importFormatList.find(
                                      (obj) => obj.name === e
                                    ) &&
                                    importFormatList.find(
                                      (obj) => obj.name === e
                                    ).value
                                  );
                                }}
                                handleSelectedDelete={handleFormatDelete}
                              />
                            )}
                          </FormikContext.Consumer>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={3} md={3}>
                        {fileData ? (
                          <CustomTextField
                            data={{
                              name: "newImportFormatName",
                              label: `${t("New Import Format")}`,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={1} md={1}>
                        {fileData ? (
                          <Button
                            onClick={() => setImportFormatSave(true)}
                            variant="contained"
                            type="submit"
                          >
                            {t("Save")}
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <div>
                      <CustomCheckbox
                        data={{
                          name: "skipLine",
                          label: `${t("Skip First Line")}`,
                        }}
                      />
                    </div>
                    <br />
                    {fileData ? (
                      <div>
                        <hr />
                        <br />
                        <Options loading={Loading} />
                      </div>
                    ) : (
                      ""
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              px: "5%",
              py: "2%",
              // background: "red",
            }}
          >
            <div>
              {fileData ? (
                <Display
                  data={fileData}
                  setCategoryItemsArray={(itemsArray) => {
                    setCategoryItemsArray(itemsArray);
                  }}
                />
              ) : (
                `${t("Load a file to see content")}`
              )}
            </div>
          </Box>
        </Form>
      </Formik>
    </div>
  );
};

export default Import;
