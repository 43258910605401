import { Button, Card, CardContent, Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import HideSourceIcon from "@mui/icons-material/HideSource";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "../commonComponents/DeleteDialog";


export default function CustomerGroupTable() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(LoginContext);
  // const cw = 200;
  const axiosFetch = AxiosFetch();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectRow, setSelectRow] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };



  const columns = [
    // { field: 'id', headerName: `${t()}` 'ID', width: 70 },
    {
      field: "groupId",
      headerName: `${t("Group ID")}`,
      flex: 0.5,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "groupDisplayName",
      headerName: `${t("Group Display Name")}`,
      flex: 1.5,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "parentGroup",
      headerName: `${t("Parent Group")}`,
      flex: 0.6,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row, "ppppp");
          navigate("/customerGroupDetail", { state: { data: params.row } });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={<EditIcon />}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    // {
    //   field: "disable",
    //   headerName: `${t("Disable")}`,
    //   sortable: false,
    //   flex:0.8,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       console.log(params.row, "ppppp");
    //     };
    //     return (
    //       <Button
    //         size="small"
    //         variant="outlined"
    //         color="error"
    //         onClick={onClick}
    //         startIcon={<HideSourceIcon />}
    //       >
    //         {t("Disable")}
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "delete",
      headerName: `${t("Delete")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDialogOpen(true);
          setSelectRow(params.row);
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={onClick}
            startIcon={<DeleteIcon />}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    context.setPageTitle("Group Table");
    async function getData() {
      const response = await axiosFetch.get(`/group/user`);
      if (response.status === 200) {
        console.log(response.data);
        setRows(response.data);
        setIsLoading(false);
      }
    }
    getData();
  }, []);
  const handleRowDelete = (e) => {
    console.log(e, "eeee");
    var arr = [...rows];
    arr.splice(
      arr.findIndex((a) => a.groupId === e.groupId),
      1
    );
    //console.log(arr,'arrr');
    setRows(arr);
  };
  const handleDelete = async (e) => {
    setDelLoading(true);
    console.log(selectRow, "ppppp");
    try {
      const response = await axiosFetch.delete(
        `/group/${selectRow.groupId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  }

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      {/* <ButtonAppBar title="Group Table" /> */}
      <Box
        sx={{
          alignItems: "center",
          px: "5%",
          py: "1%",
          // background: "red",
        }}
      >
         <DeleteDialog
              handleDialogOpen={handleDialogOpen}
              handleDelete={handleDelete}
              dialogOpen={dialogOpen}
              delLoading={delLoading}
            />
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/newCustomerGroup");
              }}
            >
              {t("Create New Group")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.groupId}
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
