import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CustomTextField from "../../formikInputs/CustomTextField";
import { Form, Formik } from "formik";
import { AxiosFetch } from "../../AxiosFetch";
import CallGroupSelect from "../helper/CallGroupSelect";
import { modalCustomStyle } from "../../commonComponents/ModalCusotmStyle";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function EditCallFlagModal({
  openModal,
  handleModalChange,
  data,
  setAlert,
  refreshList,
}) {
  //initialValues = {id:1, name:"flag", callloggroup.id:""}
  const initialValues = data;
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async (values) => {
    setButtonLoading(true);
    if (values && !values.id) {
      axiosFetch
        .post("/callFlag", {
          name: values.name,
          callLogGroup: { id: values.callLogGroup.id },
        })
        .then((response) => {
          setAlert({ open: true, message: "Call Flag Saved", type: "success" });
        })
        .catch((error) => {
          setAlert({ open: true, message: error.message, type: "error" });
        });
    } else {
      axiosFetch
        .put(`/callFlag/${values.id}`, {
          name: values.name,
          callLogGroup: { id: values.callLogGroup.id },
        })
        .then((response) => {
          setAlert({
            open: true,
            message: "Call Flag updated",
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({ open: true, message: error.message, type: "error" });
        });
    }
    handleModalChange();
    refreshList();
    setButtonLoading(false);
  };

  return (
    <div>
      <Modal
        sx={modalCustomStyle}
        open={openModal}
        onClose={handleModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          // validationSchema={formValidation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("Call Log Flag")}
              </Typography>
              <Grid
                container
                spacing={1}
                justifyContent={"center"}
                id="modal=modal-description"
              >
                <Grid item xs={3}>
                  <CustomTextField
                    mode="read"
                    data={{ name: "id", label: `${t("Flag ID")}` }}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    data={{ name: "name", label: `${t("Flag Name")}` }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CallGroupSelect
                    // mode={readMode}
                    name="callLogGroup.id"
                  />
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    loading={buttonLoading}
                    type="submit"
                    size="large"
                    variant="contained"
                    fullWidth
                  >
                    {t("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}
