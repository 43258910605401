import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import CustomTextField from "../formikInputs/CustomTextField";

export default function UserChangePassword() {
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const { userId, userRole, userFirstName, userLastName, ...context } =
    useContext(LoginContext);
  const [togglePasswordOld, setTogglePasswordOld] = useState(false);
  const [togglePasswordNew, setTogglePasswordNew] = useState(false);
  const [togglePasswordNew2, setTogglePasswordNew2] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    context.setPageTitle("Change Password");
  }, []);

  const initialValues = {
    userId: "",
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  };
  const formValidation = Yup.object().shape({
    oldPassword: Yup.string().required(`${t("This field is required")}`),
    newPassword: Yup.string().required(`${t("This field is required")}`),
    newPassword2: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required(`${t("This field is required")}`),
  });

  const handleSubmit = async (values,{resetForm}) => {
    console.log(values, "values");
    setLoading(true);
    try {
      const response = await axiosFetch.put(
        `/userPassword/${userId}`,
        {},
        {
          params: {
            oldPassword: `${values.oldPassword}`,
            newPassword: `${values.newPassword}`,
          },
        }
      );
      console.log(response, "rr");
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `${response.data}`,
      });
    } catch (error) {
      console.log(error, "err");
       context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      resetForm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      <Form>
        <Box
          sx={{
            alignItems: "center",
            px: "20%",
            py: "5%",

            // pointerEvents: `${loading ? "none" : "auto"}`,
            // filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <Card elevation={4}>
            <LinearProgress
              sx={{ visibility: `${!loading ? "hidden" : ""}` }}
            />
            <CardContent>
              <Typography variant="h3" align="center">
                {userLastName} {userFirstName}
              </Typography>
              <Typography variant="h5" align="center">
                {userId}
              </Typography>
              <Box mb={5} />
              <Divider />
              <Box mb={5} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <CustomTextField
                    data={{
                      name: "oldPassword",
                      label: `${t("Old Password")}`,
                    }}
                    type={togglePasswordOld ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {togglePasswordOld ? (
                            <Visibility
                              onClick={(e) => {
                                setTogglePasswordOld(false);
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={(e) => {
                                setTogglePasswordOld(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    data={{
                      name: "newPassword",
                      label: `${t("New Password")}`,
                    }}
                    type={togglePasswordNew ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {togglePasswordNew ? (
                            <Visibility
                              onClick={(e) => {
                                setTogglePasswordNew(false);
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={(e) => {
                                setTogglePasswordNew(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    data={{
                      name: "newPassword2",
                      label: `${t("Re-enter Password")}`,
                    }}
                    type={togglePasswordNew2 ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {togglePasswordNew2 ? (
                            <Visibility
                              onClick={(e) => {
                                setTogglePasswordNew2(false);
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={(e) => {
                                setTogglePasswordNew2(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Form>
    </Formik>
  );
}
