import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";

export default function CategoryMultiSelect(props) {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [categoryColumn, setCategoryColumn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function getDataCategory() {
      try {
        const response = await axiosFetch.get(
          `/categoryItemsByGroupId/${props.groupId}`
        );
        console.log(response, "Response category Items by GroupID");
        var test = [];
        response.data.map((data, index) => {
          test[index] = {
            value: `CAT${data.itemId}`,
            name: `${data.category.categoryName} => ${data.itemName}`,
          };
        });
        //console.log(test, "test");
        setIsLoading(false);
        setCategoryColumn(test);
        if (
          !test.find(
            (obj) =>
              obj.value === props.formValuesLive.current.values.itemIdList[0]
          )
        ) {
          props.formValuesLive.current.setFieldValue("itemIdList", []);
        }

        props.setList(test);
      } catch (err) {
        console.log(err.response.data, "err");
        alert(JSON.stringify(err.response.data, null, 4));
      }
    }
    if (props.groupId !== 0) {
      setIsLoading(true);
      getDataCategory();
    }
  }, [props.groupId]);
  return isLoading ? (
    // <Skeleton variant="rounded" width={"100%"} animation="wave">
    //   <CustomMultiSelectCheck
    //     data={{
    //       name: props.name,
    //       label: "Category Columns",
    //       list: categoryColumn,
    //     }}
    //   />
    // </Skeleton>
    <LinearProgress />
  ) : (
    <>
      <CustomMultiSelectCheck
        data={{
          name: props.name,
          label: `${t("Category Columns")}`,
          list: categoryColumn,
        }}
        selectAllFlag={true}
      />
    </>
  );
}
