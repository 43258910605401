import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useState } from "react";
import TopicTable from "./TopicTable";
import TicketTable from "./TicketTable";
import CreateTopicModal from "./CreateTopicModal";
import CreateTicketModal from "./CreateTicketModal";
import { useTranslation } from "react-i18next";

function CallLogCategory() {
  const { t } = useTranslation();

  const [openTopicModal, setOpenTopicModal] = useState(false);

  const [openTicketModal, setOpenTicketModal] = useState(false);

  const [showTicket, setShowTicket] = useState(false);

  const [topic, setTopic] = useState(0);

  const [topicTitle, setTopicTitle] = useState("Title");
  const [topicPhase, setTopicPhase] = useState("Phase");

  const [chanName, setChanName] = useState(0);

  const handleTopicModalChange = () => {
    setOpenTopicModal(!openTopicModal);
  };

  const handleTicketModalChange = () => {
    setOpenTicketModal(!openTicketModal);
  };

  const addCallLog = () => {
    setChanName(localStorage.getItem("chanName"));
    console.log("Chan Name", localStorage.getItem("chanName"));
    handleTicketModalChange();
  };

  return (
    <Grid container spacing={1} marginTop={1} justifyContent={"center"}>
      <CreateTopicModal
        openModal={openTopicModal}
        handleModalChange={handleTopicModalChange}
      />
      <CreateTicketModal
        chanName={chanName}
        openModal={openTicketModal}
        handleModalChange={handleTicketModalChange}
      />
      <Grid item xs={2.5} md={2.5}>
        {showTicket ? (
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowTicket(false)}
          >
            {t("Go Back")}
          </Button>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={0} md={2.5} />
      <Grid item xs={2} md={2}>
        <h4>{showTicket ? topicTitle : `${t("Topic")}`}</h4>
      </Grid>
      <Grid item xs={2.5} md={2.5}>
        <h5>{showTicket ? topicPhase : ""}</h5>
      </Grid>
      <Grid item xs={4} md={2.5}>
        {showTicket ? (
          <>
            <Button
              variant="contained"
              fullWidth
              onClick={addCallLog}
              startIcon={<AddIcon />}
            >
              {t("Add Call Log")}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            fullWidth
            onClick={handleTopicModalChange}
            startIcon={<AddIcon />}
          >
            {t("New Topic")}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {showTicket ? (
          <TicketTable trigger={openTicketModal} topicId={topic} />
        ) : (
          <TopicTable
            trigger={openTopicModal}
            setShowTicket={setShowTicket}
            setTopic={setTopic}
            setTopicTitle={setTopicTitle}
            setTopicPhase={setTopicPhase}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default CallLogCategory;
