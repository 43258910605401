import { Button, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { useState } from "react";
import { Form, Formik } from "formik";
import CustomTextField from "../../formikInputs/CustomTextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GroupSelect from "../../formikInputs/GroupSelect";
import { AxiosFetch } from "../../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //p: 4,
};
export default function NewPhoneEntryModal({ refreshList }) {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues = {
    telNum: "",
    customerGroup: { groupId: 0 },
    memo: "",
  };
  const formValidation = Yup.object().shape({
    customerGroup: Yup.object().shape({
      groupId: Yup.string()
        .required("This field is required")
        .test(
          "zero not allowed",
          "This field is required",
          (val) => Number(val) !== 0
        ),
    }),
    telNum: Yup.string().required("This field is required"),
  });
  const handleSubmit = async (values, e) => {
    setLoading(true);
    console.log(values, "form submit values");
    try {
      const apiResponse = await axiosFetch.post(
        "/doNotCall",
        JSON.stringify(values)
      );
      console.log(apiResponse, "do not call API response");
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `New Area Saved`,
      });
      refreshList();
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        fullWidth
        startIcon={<AddIcon />}
        sx={{ borderRadius: "30px" }}
      >
        {t("Add Single Telephone")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <LinearProgress
            sx={{
              visibility: `${!loading ? "hidden" : ""}`,
            }}
          />
          <div style={{ padding: "2.5%" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
            >
              <Form>
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      {t(`Add Single Telephone Details`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <CustomTextField
                      data={{ name: "telNum", label: t("Telephone") }}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <GroupSelect name="customerGroup.groupId" />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      data={{ name: "memo", label: t("Memo") }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      fullWidth
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClose}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
