import axios from "axios";

//how to use convert commands from https://download.chatwork.com/ChatWork_API_Documentation.pdf to curl Command for terminal
// replace variables with values
// sys_chatwapi Chatwork API Token "7d045659dd6cc607ef9772debcf11ae0"

//function will return message id
export default async function ChatworkAPICall(curlCommand) {
  var body = new FormData();
  body.append("curlCommand", "/usr/bin/" + curlCommand);
  try {
    const response = await axios({
      method: "post",
      url: "https://topline-cti.com:9801/toplinecrm/api3/chatwork-api-call-forAll.php",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Handle success
    console.log(response, "response after Axios request");

    // Return the response data, which typically contains the message ID
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error making Axios request:", error);
    throw error; // Re-throw the error for the caller to handle
  }
}

// <?php
// // Get the data from the client-side request (e.g., API token, room ID, message content)
// // Allow requests from a specific origin (replace with your React app's domain)
// header('Access-Control-Allow-Origin: http://localhost:3000');
// header('Access-Control-Allow-Methods: POST'); // Specify the allowed HTTP methods
// header('Access-Control-Allow-Headers: Content-Type'); // Specify the allowed headers
// $allowedDomains = array(
//     'https://topline-cti.netlify.app',
//     'https://topline-cti.com:9043',
//     'https://topline-cti.com:9045',
//     'https://localhost:3000'
// );

// if (in_array($_SERVER['HTTP_ORIGIN'], $allowedDomains)) {
//     header('Access-Control-Allow-Origin: ' . $_SERVER['HTTP_ORIGIN']);
//     header('Access-Control-Allow-Methods: PUT, POST, GET, OPTIONS, DELETE');
//     header('Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept');
//     header('Access-Control-Allow-Credentials: true');
// }
// // Optional: Allow credentials (cookies) if needed
// // header('Access-Control-Allow-Credentials: true');

// $apiToken = $_POST['apiToken'];
// $roomId = $_POST['roomId'];
// $messageContent = $_POST['messageContent'];

// // Validate and sanitize the input here if needed

// // Construct the curl command
// $curlCommand = "/usr/bin/curl -X POST \
//     -H 'x-chatworktoken: $apiToken' \
//     -d \"body=$messageContent\" \
//     \"https://api.chatwork.com/v2/rooms/$roomId/messages\"";

// // Execute the curl command and capture the output
// $response = shell_exec($curlCommand);

// // Return the response (you can customize the response format)
// echo json_encode(['result' => $response]);
// ?>
