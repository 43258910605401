import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CustomTextField from "../../../formikInputs/CustomTextField";
import { Form, Formik, useFormikContext } from "formik";
import { AxiosFetch } from "../../../AxiosFetch";
import { useLocation } from "react-router";
import CallGroupSelect from "./helper/CallGroupSelect";
import CallFlagSelect from "./helper/CallFlagSelect";
import PhaseSelect from "./helper/PhaseSelect";
import CallerSelect from "./helper/CallerSelect";
import axios from "axios";
import { modalCustomStyle } from "../../../commonComponents/ModalCusotmStyle";
import LoginContext from "../../../../context/LoginContext";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import ShareIcon from "@mui/icons-material/Share";
import { ChatworkModal } from "../../../commonComponents/ChatworkModal";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function CreateTicketModal({
  chanName,
  openModal,
  handleModalChange,
}) {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const url = window.location.href;
  const urlObject = new URL(url);
  const port = urlObject.port;
  const [loading, setLoading] = useState(true);
  const { userId, ...context } = useContext(LoginContext);

  const [initialValues, setInitialValues] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [callGroup, setCallGroup] = useState();

  const [shareSaveFlag, setShareSaveFlag] = useState(false);
  const [openChatwork, setOpenChatwork] = useState(false);
  const [formValues, setFormValues] = useState({});

  const location = useLocation();
  const customerId = location.state.customerId;

  const formValidation = Yup.object().shape({
    phase: Yup.string().required(t("This field is required")),
    // callLogGroup: Yup.object().shape({
    //   id: Yup.string()
    //     .required(t("This field is required"))
    //     .test(
    //       "zero not allowed",
    //       t("This field is required"),
    //       (val) => Number(val) !== 0
    //     ),
    // }),
    // callFlag: Yup.object().shape({
    //   id: Yup.string()
    //     .required(t("This field is required"))
    //     .test(
    //       "zero not allowed",
    //       t("This field is required"),
    //       (val) => Number(val) !== 0
    //     ),
    // }),
    memo: Yup.string().max(3999, t("Text must be less than 4000 characters")),
  });

  useEffect(() => {
    const chanName1 = chanName;
    console.log("chanName1", chanName1);
    if (chanName1) {
      const apipath = process.env.REACT_APP_PBX_URL || "";
      var body = new FormData();
      body.append("command", "result");
      body.append("channame", `${chanName1}`);
      axios({
        method: "post",
        url: apipath,
        data: body,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          setInitialValues({
            chanName: chanName1,
            startTime: response.data.start,
            endTime: response.data.end,
            answerTime: response.data.answer,
            src: response.data.src,
            destination: response.data.destination,
            disposition: response.data.disposition,
            title: "",
            phase: "",
            caller: "",
            memo: '',
            callLogGroup: {
              id: 0,
            },
            // callFlag: "",
            callFlag: {
              id: 0,
            },
          });
          setLoading(false);
        })
        .catch(function (response) {});
    } else {
      setInitialValues({
        chanName: chanName1,
        startTime: "",
        endTime: "",
        answerTime: "",
        src: "",
        destination: "",
        disposition: "",
        phase: "",
        caller: "",
        memo: '',
        callLogGroup: {
          id: 0,
        },
        // callFlag: "",
        callFlag: {
          id: 0,
        },
      });
      setLoading(false);
    }
  }, []);

  const handleSubmitForChatwork = async (values) => {
    //return flag
    if (values) {
      values.topic = { id: localStorage.getItem("topic_id") };
      values.registerUserId = sessionStorage.getItem("userId");
      values.registerUserId = userId;

      axiosFetch.post(
        `https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/CustomerRelated/addEvent.php?port=${port}`,
        JSON.stringify({
          nextTimeHi: "10:00",
          nextTimeLow: "12:00",
          date: values.nextCallDate,
          cust_cd: customerId,
          userGroup: 2,
          subjectContent: "Registered from Call log screen ",
          user_cd: values.registerUserId,
          // ticketId:response.data.id,
          topicId: values.topic.id,
        })
      );

      try {
        const response = await axiosFetch.post("/ticket", values);
        console.log("ticket response", response);
        context.setGlobalAlert({
          open: true,
          severity: "success",
          title: "success",
          message: `${t("Ticket saved successfully")}`,
        });
        localStorage.removeItem("chanName");
        // setMessageValues(response.data);
        return response.data;
      } catch (error) {
        // window.alert("Error encountered");
        console.log(error, "ticket API error");
        context.setGlobalAlert({
          open: true,
          severity: "error",
          title: "error",
          message: `${error.response.data.errorMessage}`,
        });
        return false;
      }
      // console.log(response);
      // console.log(values);
    }
    //handleModalChange();
  };

  const handleSubmit = async (values) => {
    console.log("form submit values for ticket", values);

    if (shareSaveFlag) {
      setFormValues(values);
      setOpenChatwork(true);
    } else {
      setButtonLoading(true);
      const resFlag = await handleSubmitForChatwork(values);
      // if (values) {
      //   values.topic = { id: localStorage.getItem("topic_id") };
      //   values.registerUserId = sessionStorage.getItem("userId");
      //   values.registerUserId = userId;

      //   axiosFetch.post(
      //     `https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/CustomerRelated/addEvent.php?port=${port}`,
      //     JSON.stringify({
      //       nextTimeHi: "10:00",
      //       nextTimeLow: "12:00",
      //       date: values.nextCallDate,
      //       cust_cd: customerId,
      //       userGroup: 2,
      //       subjectContent: "Registered from Call log screen ",
      //       user_cd: values.registerUserId,
      //       // ticketId:response.data.id,
      //       topicId: values.topic.id,
      //     })
      //   );

      //   try {
      //     const response = await axiosFetch.post("/ticket", values);
      //     console.log("ticket response", response);
      //     context.setGlobalAlert({
      //       open: true,
      //       severity: "success",
      //       title: "success",
      //       message: `${t("Ticket saved successfully")}`,
      //     });
      //     localStorage.removeItem("chanName");
      //   } catch (error) {
      //     // window.alert("Error encountered");
      //     console.log(error, "ticket API error");
      //     context.setGlobalAlert({
      //       open: true,
      //       severity: "error",
      //       title: "error",
      //       message: `${error.response.data.errorMessage}`,
      //     });
      //   }
      //   // console.log(response);
      //   // console.log(values);
      // }
      if (resFlag !== false) {
        handleModalChange();
      }

      setButtonLoading(false);
    }
  };

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setCallGroup(values.callLogGroup.id || "");
    }, [values.callLogGroup.id]);
    return null;
  };

  return (
    <div>
      <Modal
        sx={modalCustomStyle}
        open={openModal}
        onClose={handleModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <div>Loading</div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formValidation}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            <Form id="formId">
              <FormObserver />
              <ChatworkModal
                open={openChatwork}
                handleOpen={(e) => setOpenChatwork(e)}
                values={formValues}
                handleSubmitForChatwork={handleSubmitForChatwork}
                handleModalChange={handleModalChange}
              />
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t("Create New Ticket")}
                </Typography>
                <Grid
                  container
                  spacing={1}
                  justifyContent={"center"}
                  id="modal=modal-description"
                >
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{ name: "chanName", label: `${t("Channel Name")}` }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "disposition",
                        label: `${t("Disposition")}`,
                      }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "destination",
                        label: `${t("Destination")}`,
                      }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{ name: "startTime", label: `${t("Start Time")}` }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{ name: "endTime", label: `${t("End Time")}` }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "answerTime",
                        label: `${t("Answer Time")}`,
                      }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <PhaseSelect name="phase" />
                  </Grid>
                  <Grid item xs={4}>
                    <CallerSelect name="caller" />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "nextCallDate",
                        label: `${t("Next Call Date")}`,
                      }}
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CallGroupSelect
                      // mode={readMode}
                      name="callLogGroup.id"
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CallFlagSelect name="callFlag.id" callGroup={callGroup} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LoadingButton
                      loading={buttonLoading}
                      variant="contained"
                      size={isMobile ? "small" : "large"}
                      type="submit"
                      onClick={() => {
                        setShareSaveFlag(false);
                      }}
                      fullWidth
                    >
                      {t("Save")}
                    </LoadingButton>
                    <Button
                      variant="contained"
                      fullWidth
                      size={isMobile ? "small" : "large"}
                      color="complementary"
                      type="submit"
                      onClick={() => {
                        setShareSaveFlag(true);
                      }}
                      startIcon={<ShareIcon />}
                    >
                      {t("Save And Share To SNS")}
                    </Button>
                    {/* <SNSModal /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      data={{ name: "memo", label: `${t("Memo")}` }}
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Formik>
        )}
      </Modal>
    </div>
  );
}
