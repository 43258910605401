import "../../../App.css";
import React, { useEffect, useState, useContext } from "react";
import { Button, Grid, Stack } from "@mui/material";
import CustForm from "../custForm/CustForm";
import { Formik, Form, useFormikContext, FormikContext } from "formik";
import CustomerDetailCategory from "./Call Log/CustomerDetailCategory";
import { useLocation, useNavigate } from "react-router";
import { AxiosFetch } from "../../AxiosFetch";
import LoginContext from "../../../context/LoginContext";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomerCategoryForm from "./CategoryFormDetail";
import CategoryFormDetail from "./CategoryFormDetail";

export default function CustomerDetails() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const location = useLocation();

  const [customerId, setCustomerId] = useState(location.state.customerId);
  const [telephoneList, setTelephoneList] = useState([]);
  const [dupTelephoneList, setDupTelephoneList] = useState([]);

  //const [allUserList, setAllUserList] = useState([]);
  const showModal = "true";
  const showTopicId = 4;
  useEffect(() => {
    setCustomerId(location.state.customerId);
    setIsLoading(true);
  }, [location.state.customerId]);

  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState();
  let categoryobj = {};
  useEffect(() => {
    context.setPageTitle("Customer Details");
    async function getData() {
      try {
        let userListAll = [];
        try {
          const userResponse = await axiosFetch.get("/user");
          console.log(userResponse, "user list response ");
          //setAllUserList(userResponse.data);
          userListAll = userResponse.data;
        } catch (error) {
          console.log(error, "error with all user list API");
        }
        const response = await axiosFetch.get(`/customers/${customerId}`);
        console.log(response, "customer detail response");
        context.setCustomerInfo({
          id: response.data.customerId,
          firstName: response.data.customerFirstName,
          lastName: response.data.customerLastName,
        });
        try {
          const dupResponse = await axiosFetch.get(
            `/duplicateTelephone/${customerId}`
          );
          console.log(dupResponse, "duplicate Telephone response ");
          setDupTelephoneList(dupResponse.data);
        } catch (error) {
          console.log(error, "error with duplicate Telephone API");
        }

        try {
          const groupItemsResponse = await axiosFetch.get(
            `categoryItemsByGroupId/${response.data.customerGroup.groupId}`
          );
          var itemIdvsItemType = {};
          groupItemsResponse.data.forEach((item, i) => {
            itemIdvsItemType[item.itemId] = item.itemType;
          });
          if (response.data.telephone.length !== 0) {
            //console.log(response.data.telephone[0].telnum, "tel number");
            localStorage.setItem(
              "telephone",
              response.data.telephone[0].telNum
            );
          }

          //convert categoryData to categoryDataNew for Formik.
          response.data.categoryData.forEach((data, index) => {
            categoryobj[data.itemId] = []; //initialise to array cuz of multiple tabs
            // if (itemIdvsItemType[data.itemId] === "multipleselect") {
            //   categoryobj[data.itemId] = [[]]; //initialise to array cuz of multiple tabs
            // } else if (itemIdvsItemType[data.itemId] === "filterselect") {
            //   categoryobj[data.itemId] = [{}]; //initialise to array cuz of multiple tabs
            // } else {
            //   categoryobj[data.itemId] = []; //initialise to array cuz of multiple tabs
            // }
          });

          response.data.categoryData.forEach((data, index) => {
            if (itemIdvsItemType[data.itemId] === "multipleselect") {
              if (
                typeof categoryobj[`${data.itemId}`][data.tabValue - 1] ===
                "undefined"
              ) {
                categoryobj[`${data.itemId}`][data.tabValue - 1] = [];
              }
              categoryobj[`${data.itemId}`][data.tabValue - 1].push(data.value);
            } else if (itemIdvsItemType[data.itemId] === "filterselect") {
              const parts = data.value.split(":");
              if (
                typeof categoryobj[`${data.itemId}`][data.tabValue - 1] ===
                "undefined"
              ) {
                categoryobj[`${data.itemId}`][data.tabValue - 1] = {};
              }
              if (
                !(parts[0] in categoryobj[`${data.itemId}`][data.tabValue - 1])
              ) {
                categoryobj[`${data.itemId}`][data.tabValue - 1] = {
                  [parts[0]]: [],
                };
              }
              categoryobj[`${data.itemId}`][data.tabValue - 1][parts[0]].push(
                parts[1]
              );
            } else {
              categoryobj[`${data.itemId}`][data.tabValue - 1] = data.value;
            }

            //loop over and replace any empty fields with ''
            Object.entries(categoryobj).forEach(([key, value]) => {
              //console.log(value,'vvvv');
              var length = value.length;
              for (let i = 0; i < length; i++) {
                if (typeof categoryobj[key][i] === "undefined") {
                  if (itemIdvsItemType[data.itemId] === "multipleselect") {
                    categoryobj[key][i] = [];
                  } else if (itemIdvsItemType[data.itemId] === "filterselect") {
                    categoryobj[key][i] = {};
                  } else {
                    categoryobj[key][i] = "";
                  }
                }
              }
            });
          });

          var telephoneDefault;
          telephoneDefault =
            response.data.telephone.length && response.data.telephone[0].telNum;
          var telephoneList = [];
          // var telephoneArr=[];
          response.data.telephone.map((data, index) => {
            telephoneList.push({ value: data.telNum, name: data.telNum });
          });
          setTelephoneList(telephoneList);
          console.log("here response", response.data.contentsOfConsultation);
          setInitialValues({
            ...response.data,
            categoryDataNew: categoryobj,
            telephoneNew: telephoneDefault,
            contentsOfConsultation: (response.data.contentsOfConsultation ? JSON.parse(response.data.contentsOfConsultation) : []),
            preferredContactTime: (response.data.preferredContactTime ? JSON.parse(response.data.preferredContactTime) : []),
            customerRegisterUserId: giveUserFullName(
              userListAll,
              response.data.customerRegisterUserId
            ),
            customerModifyUserId: giveUserFullName(
              userListAll,
              response.data.customerModifyUserId
            ),
            customerAge: getAge(response.data.customerBirthday),
          });
          setIsLoading(false);
        } catch (error) {
          //alert("grp errr");
          console.log(error, "errr");
        }
      } catch (error) {
        alert("cus errr");
      }
    }

    getData();
  }, [isLoading, customerId]);

  //for testing only
  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    localStorage.setItem("telephone", values.telephoneNew);
    //console.log(values, "form values are");
    return null;
  };

  // async function getDataUser(props) {
  //   const APIvalues = {
  //     customerGroups: [
  //       {
  //         groupId: props,
  //       },
  //     ],
  //   };
  //   const response = await axiosFetch.post(
  //     "/userList",
  //     JSON.stringify(APIvalues)
  //   );
  //   return await response.data;
  // }
  // const [userList, setUserList] = useState([]);
  // const FormObserver1 = () => {
  //   const { values, setFieldValue } = useFormikContext();
  //   useEffect(() => {
  //     var test = [];
  //     getDataUser(values.customerGroup).then((x) => {
  //       x.map((data, index) => {
  //         test[++index] = {
  //           value: data.userId,
  //           name: data.firstName + " " + data.lastName,
  //         };
  //       });
  //       test[0] = {
  //         value: "",
  //         name: "None",
  //       };
  //       if (JSON.stringify(userList) === JSON.stringify(test)) return;
  //       setUserList(test);
  //     });
  //   }, [values.customerGroup1]);
  //   return null;
  // };

  const handleExtension = (values) => {
    localStorage.setItem("extension", values.extension);
  };
  function giveUserFullName(allUserList, userId) {
    const objFound = allUserList.find((item) => item.userId === userId);
    if (objFound) {
      return `${objFound.lastName} ${objFound.firstName}`;
    }
    return userId;
  }
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          console.log(values, "submit values");
          //handleSubmit(values);
          alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form>
          <FormObserver />
          <Grid container spacing={1} margin={0.2} justifyContent={"center"}>
            <Grid item xs={12} md={4.5}>
              <CustForm
                mode="read"
                groupId={initialValues.customerGroup.groupId || 0}
                originalGroup={initialValues.customerGroup.groupId}
                type="detail"
                // telephoneList={telephoneList}
                customerDetails={initialValues}
                duplicateTelephoneCustArr={dupTelephoneList}
              />
            </Grid>
            <Grid item xs={12} md={7.3}>
              {/* <CustomerDetailCategory
                mode="read"
                groupId={initialValues.customerGroup.groupId || 0}
                showModal={showModal}
                showTopicId={showTopicId}
              /> */}
              <CategoryFormDetail
                itemsDataArray={initialValues.categoryData}
                mode="read"
                groupId={initialValues.customerGroup.groupId || 0}
                showModal={showModal}
                showTopicId={showTopicId}
              />
              <br />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "error.light" }}
                  color="error"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    navigate("/customerSearch");
                  }}
                  fullWidth
                >
                  {t("Cancel")}
                </Button>
                <FormikContext.Consumer>
                  {(context) => {
                    return (
                      <Button
                        onClick={() => {
                          navigate("/customerEdit", {
                            state: {
                              initialValues: context.values,
                              // searchArrayOfId: searchArrayOfId,
                              // indexId: indexId,
                            },
                          });
                        }}
                        variant="contained"
                        size="large"
                        fullWidth
                        startIcon={<EditIcon />}
                      >
                        {t("Edit")}
                      </Button>
                    );
                  }}
                </FormikContext.Consumer>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
}
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age || "";
}
// const response = await axiosFetch.get(`/customers/${customerId}`);
// if (response.status === 200) {
//   console.log(response, "cust detail response");
//   if (response.data.telephone.length !== 0) {
//     console.log(response.data.telephone[0].telnum, "tel number");
//     localStorage.setItem("telephone", response.data.telephone[0].telNum);
//   }

//   //convert categoryData to categoryDataNew for Formik.
//   response.data.categoryData.forEach((data, index) => {
//     categoryobj[data.itemId] = []; //initialise to array cuz of multiple tabs
//   });
//   response.data.categoryData.forEach((data, index) => {
//     const value = data.value;
//     var isJsonParsable = (string) => {
//       try {
//         JSON.parse(string);
//       } catch (e) {
//         return string;
//       }
//       return JSON.parse(string);
//     };
//     const temp = isJsonParsable(value); //for multiselect
//     categoryobj[`${data.itemId}`][data.tabValue - 1] = temp;
//     //loop over and replace any empty fields with ''
//     Object.entries(categoryobj).forEach(([key, value]) => {
//       //console.log(value,'vvvv');
//       var length = value.length;
//       for (let i = 0; i < length; i++) {
//         if (typeof categoryobj[key][i] === "undefined") {
//           categoryobj[key][i] = ""; //one conditon left for multiselect(initialse as[])
//         }
//       }
//     });
//   });

//   // response.data.categoryData.map((data) => {
//   //   const value = data.value;
//   //   var isJsonParsable = (string) => {
//   //     try {
//   //       JSON.parse(string);
//   //     } catch (e) {
//   //       return string;
//   //     }
//   //     return JSON.parse(string);
//   //   };
//   //   const temp = isJsonParsable(value);
//   //   categoryobj[data.itemId] = temp;
//   // });

//   //end

//   var telephoneDefault;
//   telephoneDefault =
//     response.data.telephone.length && response.data.telephone[0].telNum;
//   var telephoneList = [];
//   // var telephoneArr=[];
//   response.data.telephone.map((data, index) => {
//     telephoneList.push({ value: data.telNum, name: data.telNum });
//   });
//   setTelephoneList(telephoneList);
//   setInitialValues({
//     ...response.data,
//     categoryDataNew: categoryobj,
//     telephoneNew: telephoneDefault,
//   });
//   setIsLoading(false);
// }
