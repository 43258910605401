import { Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import CustomSelect from "./CustomSelect";
//Helper
{
  /* <ProjectSelectByGroup
  mode=""
  name="project"
  label={t("Project")}
  groupId={groupId}
  typeFlag="search"(to list all project when group is none, for others, pass any other string)
/>*/
}

export default function ProjectSelectByGroup(props) {
  const typeFlag = props.typeFlag;
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const originalGroup = props.originalGroup;

  async function getDataProject(groupId) {
    const response = await axiosFetch.get(`/project/customergroup/${groupId}`);
    return await response.data;
  }
  async function getDataProjectAll() {
    const response = await axiosFetch.get(`/project`);
    return await response.data;
  }
  useEffect(() => {
    //console.log(originalGroup, props.groupId, "lllllllll");
    setIsLoading(true);
    var test = [];
    test = [];
    if (props.groupId !== 0 && props.groupId !== "") {
      //console.log("yes inn");
      getDataProject(props.groupId).then((x) => {
        x.map((data, index) => {
          //console.log(data, "data");
          test[++index] = {
            value: data.id,
            name: data.name,
          };
        });
        test[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        setProjectList(test);
        setIsLoading(false);
      });
    } else {
      if (typeFlag === "search") {
        getDataProjectAll().then((x) => {
          x.map((data, index) => {
            //console.log(data, "data");
            test[++index] = {
              value: data.id,
              name: data.name,
            };
          });
          test[0] = {
            value: 0,
            name: t("Not Specified"),
          };
          setProjectList(test);
          setIsLoading(false);
        });
      } else {
        test[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        setProjectList(test);
        setIsLoading(false);
      }
    }
  }, [props.groupId]);

  return isLoading ? (
    <>
      {/* <LinearProgress /> */}
      <Skeleton variant="rounded" width={"100%"} height={56} />
    </>
  ) : (
    <>
      {/* {console.log(userList, "group select page")} */}
      <div>
        <CustomSelect
          data={{
            name: props.name,
            label: props.label,
            list: projectList,
          }}
          mode={props.mode}
          {...props}
        />
      </div>
    </>
  );
}
