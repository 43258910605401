import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  tabsClasses,
  useTheme,
} from "@mui/material";

import { AxiosFetch } from "../../AxiosFetch";
import CallLogCategory from "./Call Log/CallLogCategory";
import CategoryData from "../categoryForm/CategoryData";
import FunctionKey from "./Call Log/FunctionKey";
import TabPanelCategory from "../categoryFormRelated/TabPanelCategory";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

function CategoryFormDetail({ itemsDataArray, groupId, ...props }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const formikContext = useFormikContext();
  //   const calendarTopicId = props.calendarTopicId;//can not understand??
  const [tabValue, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [categoryObjectWithItems, setCategoryObjectWithItems] = useState({}); //object containing keys as categoryId and value as item array
  const [catNameArr, setCatNameArr] = useState([]);
  const [tabCountPerCategory, setTabCountPerCategory] = useState({});
  const catTabCount = catNameArr.length;
  useEffect(() => {
    if (
      Object.keys(tabCountPerCategory).length === 0 &&
      Object.keys(categoryObjectWithItems).length !== 0
    ) {
      const temp = giveMaxTabPerCategory(
        itemsDataArray,
        categoryObjectWithItems
      );
      //console.log(temp, "opopopop");
      var catObjAsEmpty = {};
      if (isLoading === false) {
        //to initialse all category items in categoryDataNew as per tabCount (as only customer Data items are initialised)
        Object.entries(categoryObjectWithItems).forEach(
          ([key, value], index) => {
            //console.log(key,value,'hehehehehehh');
            value.forEach((data, index) => {
              catObjAsEmpty[data.itemId] = [];
            });
            value.forEach((data, index) => {
              for (let i = 0; i < temp[key]; i++) {
                if (data.itemType === "multipleselect") {
                  catObjAsEmpty[data.itemId][i] = [];
                } else if (data.itemType === "filterselect") {
                  catObjAsEmpty[data.itemId][i] = {};
                } else {
                  catObjAsEmpty[data.itemId][i] = "";
                }
              }
            });
          }
        );

        const mergedObject = superImposeObjects(
          catObjAsEmpty,
          formikContext.values.categoryDataNew
        );
        formikContext.setFieldValue("categoryDataNew", mergedObject);
      }

      setTabCountPerCategory(temp);
    }
    setIsLoading2(false);
  }, [isLoading]);
  //This will work like componentDidMount
  useEffect(() => {
    setIsLoading(true);
    async function getData() {
      var categoryNameArray = [];
      var categoryMap = {};
      var itemIdArray = {};
      try {
        const response = await axiosFetch.get(
          `categoryItemsByGroupId/${groupId}`
        );
        console.log(response.data, "all items by group");
        response.data.map((data, index) => {
          let catId = data.category.categoryId;
          if (data.category.visible) {
            if (!(catId in categoryMap)) {
              //unique category Id
              categoryMap[catId] = []; //intialise with array
              categoryNameArray.push(data.category);
            }
            let obj = categoryMap[catId].find((o) => o.itemId === data.itemId); //if ItemId already exist
            if (!Boolean(obj)) {
              categoryMap[catId].push(data);
              data.itemType === "multipleselect"
                ? (itemIdArray[data.itemId] = [[]])
                : (itemIdArray[data.itemId] = [""]);
            }
          }
        });
        setCategoryObjectWithItems(categoryMap); //object containing keys as categoryId and value as item array
        setCatNameArr(categoryNameArray);
        setIsLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getData();
  }, [groupId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    //console.log(newValue);
  };

  return isLoading2 ? (
    <div> Loading </div>
  ) : (
    <Card sx={{ minWidth: 265 }} elevation={4}>
      <CardContent>
        <div>
          {/* <FunctionKey />
          <br></br> */}
          <AppBar
            position="static"
            sx={{ backgroundColor: "primary.light" }}
            color="primary"
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Tab
                label={t("Call Log")}
                wrapped
                index="0"
                key="0"
                sx={{
                  flexBasis: "20%",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                  },
                  "&.Mui-selected": {
                    color: "primary.main",
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "white",
                    },
                  },
                }}
              />
              {Array.from({ length: catTabCount }, (_, index) => (
                <Tab
                  label={catNameArr[index].categoryName}
                  id={index + 1}
                  key={index + 1}
                  value={index + 1}
                  sx={{
                    flexBasis: "20%",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "white",
                    },
                    "&.Mui-selected": {
                      color: "primary.main",
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                      "&:hover": {
                        backgroundColor: "primary.dark",
                        color: "white",
                      },
                    },
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <br />
          <div hidden={tabValue !== 0} key={0}>
            {/* <CallLogCategory calendarTopicId={calendarTopicId} /> */}
            <CallLogCategory />
          </div>
          {Array.from({ length: catTabCount }, (_, index) => (
            <div hidden={tabValue !== index + 1} key={index + 1}>
              <TabPanelCategory
                id={index + 1}
                categoryId={Object.keys(categoryObjectWithItems)[index]}
                itemArray={Object.values(categoryObjectWithItems)[index]} //passes array of items
                tabCountExisting={
                  tabCountPerCategory[catNameArr[index].categoryId]
                }
                mode="read"
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default CategoryFormDetail;
function giveMaxTabPerCategory(itemDataArray, categoryObjectWithItems) {
  var maxTabValuesPerItem = {};
  itemDataArray.forEach((data, index) => {
    const { itemId, tabValue } = data;
    if (
      !maxTabValuesPerItem[itemId] ||
      tabValue > maxTabValuesPerItem[itemId]
    ) {
      maxTabValuesPerItem[itemId] = tabValue;
    }
  });
  var maxTabValuesPerCategory = {};
  Object.keys(categoryObjectWithItems).forEach((key) => {
    const arr = categoryObjectWithItems[key];

    arr.forEach((data, index) => {
      if (
        !maxTabValuesPerCategory[key] ||
        maxTabValuesPerItem[data.itemId] > maxTabValuesPerCategory[key]
      ) {
        maxTabValuesPerCategory[key] = maxTabValuesPerItem[data.itemId] || 1;
      }
    });
  });
  return maxTabValuesPerCategory;
}
function superImposeObjects(obj1, obj2) {
  var finalObj = { ...obj1 };
  Object.entries(obj1).forEach(([key, value], index) => {
    value.forEach((data, i) => {
      if (obj2[key] && obj2[key][i]) {
        finalObj[key][i] = obj2[key][i];
      }
    });
  });
  return finalObj;
}

// const tabHead = categoryArray.map((category, index) => (
//     <Tab
//       label={category.categoryName}
//       wrapped
//       index={index + 1}
//       key={index + 1}
//       sx={{ minWidth: "fit-content", flex: 1 }}
//     />
//   ));
//   const tabContent = categoryArray.map((category, index) => (
//     <div key={index}>
//       <TabPanel value={tabValue} index={index + 1}>
//         {category.categoryName}
//       </TabPanel>
//     </div>
//   ));
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index &&
//           (value == 0 ? (
//             <CallLogCategory calendarTopicId={calendarTopicId} />
//           ) : (
//             <CategoryData
//               {...allProps(index - 1)}
//               mode={mode}
//               customerId={customerId}
//             />
//           ))}
//       </div>
//     );
//   }
// function allProps(index) {
//     return categoryArray[index];
//   }
