import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  tabsClasses,
} from "@mui/material";
import CategoryData from "../../categoryForm/CategoryData";
import CallLogCategory from "./CallLogCategory";
import { AxiosFetch } from "../../../AxiosFetch";
import FunctionKey from "./FunctionKey";

function CustomerDetailCategory(props) {
  const axiosFetch = AxiosFetch();

  const customerId = props.customerId;
  const mode = props.mode || "";
  let groupId = props.groupId;
  // groupId=2;
const calendarTopicId=props.calendarTopicId
  const [tabValue, setValue] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [categoryArray, setCategoryArray] = useState([]);

  //This will work like componentDidMount
  useEffect(() => {
    setisLoading(true);
    async function getData() {
      if (props.groupId && props.groupId > 0) {
        try {
          const response = await axiosFetch.get(`/category/${groupId}`);
          if (response.status === 200) {
            console.log(response, "rrrr category");
            setCategoryArray(response.data);
            setisLoading(false);
          }
        } catch (error) {
          alert("error");
          console.log(error, "errrr");
        }
      }
    }
    getData();
  }, [props.groupId]);

  function allProps(index) {
    return categoryArray[index];
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index &&
          (value == 0 ? (
            <CallLogCategory
            calendarTopicId={calendarTopicId}
            />
          ) : (
            <CategoryData
              {...allProps(index - 1)}
              mode={mode}
              customerId={customerId}
            />
          ))}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  const tabHead = categoryArray.map((category, index) => (
    <Tab
      label={category.categoryName}
      wrapped
      index={index + 1}
      key={index + 1}
      sx={{ minWidth: "fit-content", flex: 1 }}
    />
  ));
  const tabContent = categoryArray.map((category, index) => (
    <div key={index}>
      <TabPanel value={tabValue} index={index + 1}>
        {category.categoryName}
      </TabPanel>
    </div>
  ));
  return isLoading ? (
    <div> Loading </div>
  ) : (
    <Card sx={{ minWidth: 265 }} elevation={4}>
      <CardContent>
        <div>
          <FunctionKey />
          <br></br>
          <AppBar
            position="static"
            sx={{ backgroundColor: "primary.light" }}
            color="primary"
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.MuiTabScrollButton-root": { opacity: 1, color: "black" },
                  "&.Mui-disabled": { opacity: 0.5, color: "white" },
                },
              }}
              //centered
            >
              <Tab
                label="Call Log"
                wrapped
                index="0"
                key="0"
                sx={{ minWidth: "fit-content", flex: 1 }}
              />
              {tabHead}
            </Tabs>
          </AppBar>
          <br />
          <TabPanel value={tabValue} index={0}>
            Call Log Hello
          </TabPanel>
          {tabContent}
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerDetailCategory;
