import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Divider, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import UserSelectByGroup from "../formikInputs/UserSelectByGroup";
import GroupSelect from "../formikInputs/GroupSelect";
import CustomTextField from "../formikInputs/CustomTextField";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useCallback } from "react";
import { useRef } from "react";
import { drawerWidth } from "../layout/Layout";
import AccountMenu from "./AccountMenu";
import { isMobile } from "react-device-detect";
import axios from "axios";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isMobile && { maxHeight: "10%" }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar({ handletoggle, sidebaropen, drawerWidth }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [groupId, setGroupId] = useState(0);
  const context = useContext(LoginContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const formRef = useRef(null);
  const handleClick = (event) => {
    // console.log(event,'llllll',typeof(event.currentTarget));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // const {values,setFieldValue}=useFormikContext();
    // console.log(values,'oooo');
    setAnchorEl(null);
  };
  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    //console.log(formRef, "fffffff");
    var pp;
    {
      formRef.current && (pp = formRef.current.values.group || 0);
    }
    sessionStorage.setItem("Dextension", values.extension);
    localStorage.setItem("extension", values.extension);
    localStorage.setItem("extensionTo", values.extensionTo);
    sessionStorage.setItem("Dgroup", values.group);
    sessionStorage.setItem("Duser", values.user);
    useEffect(() => {
      setGroupId(values.group);
    }, [pp]);

    return null;
  };
  const initialValues = {
    extensionTo: localStorage.getItem("extensionTo") || "",
    extension: sessionStorage.getItem("Dextension") || "",
    group: sessionStorage.getItem("Dgroup") || 0,
    user: sessionStorage.getItem("Duser") || "",
  };
  const handleExtension = (values) => {
    localStorage.setItem("extension", values.extension);
  };
  const handleMonitorClick = () => {
    const apipath = process.env.REACT_APP_PBX_URL || "";
    let extensionNumber = localStorage.getItem("extension");
    let extensionNumberTo = localStorage.getItem("extensionTo");

    var body = new FormData();
    body.append("command", "monitor");
    body.append("exten", extensionNumber);
    body.append("to", extensionNumberTo);
    // body.append("timeout", 30);
    // body.append("phone_number", "0486405000");

    axios({
      method: "post",
      url: apipath,
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response, "response form monitor api call");
      })
      .catch(function (response) {
        //handle error
        console.log(response, response.error, "error form monitor api call");
      });
  };

  return (
    <>
      <Formik
        //enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        innerRef={formRef}
        onSubmit={(values) => {
          handleExtension(values);
          console.log("submitt");
        }}
      >
        <Form>
          <AppBar position="fixed" open={sidebaropen}>
            <FormObserver />
            <Grid
              container
              spacing={0.5}
              margin={0.5}
              paddingRight={isMobile ? 0 : 4}
            >
              <Grid item xs={context.pageTitle === "Customer Details" ? 6 : 9}>
                <Grid container spacing={isMobile ? 0.2 : 0} margin={0}>
                  <Grid item xs={1}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      // sx={{ mr: 2 }}
                      disabled={context.pageTitle === "Login" ? true : false}
                      sx={{ mr: 2, ...(sidebaropen && { display: "none" }) }}
                      onClick={handletoggle}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} display="flex" alignItems={"center"}>
                    <Tooltip title={t("Shortcut to customer search screen")}>
                      <Link
                        to="/customerSearch"
                        style={{
                          color: "white",
                          textDecoration: "none",
                          fontSize: isMobile ? "15px" : "35px",
                          width: "30%",
                          pointerEvents: `${
                            context.pageTitle === "Login" ? "none" : ""
                          }`,
                          display: "none",
                        }}
                      >
                        Topline
                      </Link>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={8} display="flex" alignItems={"center"}>
                    <Typography
                      variant={isMobile ? "h6" : "h3"}
                      component="div"
                      sx={{ flexGrow: 1 }}
                      align={"left"}
                      noWrap
                    >
                      {t(`${context.pageTitle}`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={context.pageTitle === "Customer Details" ? 6 : 3}
                justifyContent="flex-end"
                alignItems={"stretch"}
                display="flex"
              >
                <Tooltip
                  title={t(
                    `If you enter a number in the extension number, a call will be made using the phone number associated with that extension.Furthermore, if you select the group or user next to it and press "Monitor" or "Tutor", you will be able to monitor or tutor that user.`
                  )}
                >
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    size={isMobile ? "small" : "large"}
                    sx={{
                      bgcolor: "#e6ffe6",
                      color: "black",
                      "&:hover": {
                        color: "#e6ffe6",
                        backgroundColor: "darkGreen",
                      },
                      visibility: `${
                        context.pageTitle === "Login" ? "hidden" : ""
                      }`,
                      ...(isMobile && { fontSize: "10px" }),
                    }}
                  >
                    {t("Dashboard")}
                  </Button>
                </Tooltip>

                {/* <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    bgcolor: "#e6ffe6",
                    color: "black",
                    "&:hover": {
                      color: "#e6ffe6",
                      backgroundColor: "darkGreen",
                    },
                  }}
                  disabled={context.pageTitle === "Login" ? true : false}
                >
                  Dashboard
                </Button> */}
                {context.pageTitle === "Customer Details" ? (
                  <>
                    <Tooltip title={t("Previous Customer")}>
                      <div style={{ paddingBottom: 1 }}>
                        <Button
                          key="back"
                          variant="outlined"
                          sx={{
                            margin: 0.1,
                            bgcolor: "#e6ffe6",
                            color: "black",
                            "&:hover": {
                              color: "#e6ffe6",
                              backgroundColor: "darkGreen",
                            },
                            height: "100%",
                          }}
                          disabled={
                            Number(sessionStorage.getItem("indexId")) === 0
                              ? true
                              : false
                          }
                          onClick={() => {
                            var customerId = JSON.parse(
                              sessionStorage.getItem("searchArrayId")
                            )[Number(sessionStorage.getItem("indexId")) - 1];
                            sessionStorage.setItem(
                              "indexId",
                              Number(sessionStorage.getItem("indexId")) - 1
                            );

                            console.log(customerId, "cccccc");
                            navigate("/customerDetail", {
                              state: {
                                customerId: customerId,
                              },
                            });
                          }}
                        >
                          <ArrowBackIosNewIcon />
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title={t("Next Customer")}>
                      <div style={{ paddingBottom: 1 }}>
                        <Button
                          key="back2"
                          variant="outlined"
                          sx={{
                            margin: 0.1,
                            bgcolor: "#e6ffe6",
                            color: "black",
                            "&:hover": {
                              color: "#e6ffe6",
                              backgroundColor: "darkGreen",
                            },
                            height: "100%",
                          }}
                          disabled={
                            Number(sessionStorage.getItem("indexId")) ===
                            (
                              JSON.parse(
                                sessionStorage.getItem("searchArrayId")
                              ) || []
                            ).length -
                              1
                              ? true
                              : false
                          }
                          onClick={() => {
                            var customerId = JSON.parse(
                              sessionStorage.getItem("searchArrayId")
                            )[Number(sessionStorage.getItem("indexId")) + 1];
                            sessionStorage.setItem(
                              "indexId",
                              Number(sessionStorage.getItem("indexId")) + 1
                            );

                            console.log(customerId, "cccccc");
                            navigate("/customerDetail", {
                              state: {
                                customerId: customerId,
                              },
                            });
                          }}
                        >
                          <ArrowForwardIosIcon />
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
                {context.pageTitle === "Login" ? <></> : <AccountMenu />}
              </Grid>
              {/* <Grid item xs={1} alignItems="stretch" display="flex" justifyContent="flex-end">
               
              </Grid> */}
            </Grid>
          </AppBar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            //getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{
                width: "35vw",
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: "default",
                },
              }}
              disableTouchRipple
              disableRipple
              selected={false}
            >
              <Grid container spacing={2} alignItems="stretch" display="flex">
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    data={{ name: "extension", label: `${t("Extension")}` }}
                    size="medium"
                    variant="outlined"
                  />
                  {/* <Button variant="contained" type="Submit" size="small">
                          Submit
                        </Button> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    data={{
                      name: "extensionTo",
                      label: `${t("Extension Number To Monitor")}`,
                    }}
                    size="medium"
                    variant="outlined"
                  />
                  {/* <Button variant="contained" type="Submit" size="small">
                          Submit
                        </Button> */}
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      borderStyle: "dashed",
                      borderColor: "black",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <GroupSelect name="group" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <UserSelectByGroup
                    mode=""
                    name="user"
                    label={`${t("User Name")}`}
                    groupId={groupId}
                    originalGroup={groupId}
                  />
                </Grid>
                <Grid item xs={12} md={4} alignItems="stretch" display={"flex"}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      color: "black",
                      bgcolor: "orange",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#ff2403",
                      },
                    }}
                    onClick={handleMonitorClick}
                  >
                    {t("Monitor")}
                  </Button>
                </Grid>
                {/* <Grid item xs={12} md={3} alignItems="stretch" display={"flex"}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      color: "black",
                      bgcolor: "lightBlue",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "blue",
                      },
                    }}
                  >
                    {t("Tutor")}
                  </Button>
                </Grid> */}
              </Grid>
            </MenuItem>
          </Menu>
        </Form>
      </Formik>
    </>
  );
}
{
  /* <Grid container spacing={0.5} margin={0.5}>
                        <Grid item xs={2.4}>
                          <CustomTextField
                            data={{ name: "extension", label: "Extension" }}
                            size="small"
                            variant="outlined"
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                          />
                          <Button
                            variant="contained"
                            type="Submit"
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={2.4}>
                          <GroupSelect
                            name="group"
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                          />
                        </Grid>
                        <Grid item xs={2.4}>
                          <UserSelectByGroup
                            mode=""
                            name="user"
                            label={"User Name"}
                            groupId={groupId}
                            originalGroup={0}
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                          />
                        </Grid>
                        <Grid item xs={2.4}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                          >
                            Monitor
                          </Button>
                        </Grid>
                        <Grid item xs={2.4}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{ bgcolor: "#e6ffe6", color: "black" }}
                          >
                            Tutor
                          </Button>
                        </Grid>
                      </Grid> */
}
{
  /* <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={handletoggle}
                >
                  <MenuIcon />
                </IconButton>
                <Link
                  to="/home"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "35px",
                    width: "30%",
                  }}
                >
                  Topline
                </Link>
                <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
                  {t(`${context.pageTitle}`)}
                </Typography>
              </Toolbar> */
}
