import React, { useContext, useEffect, useState } from "react";
import LoginContext from "../../../context/LoginContext";
import { AxiosFetch } from "../../AxiosFetch";

const SearchContext = React.createContext();
export { SearchContext };
const SerachIndexWrapper = ({ children }) => {
  const { userRole, userId } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const axiosFetch = AxiosFetch();
  const [userList, setUserList] = useState();
  const [projectList, setProjectList] = useState();
  const [expiredNextCallRows, setExpiredNextCallRows] = useState([]);

  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 16);
  var APIvalues = {
    categoryData: [],
    customer: {
      topic: [
        {
          tickets: [
            {
              nextCallDateEnd: formattedDate,
            },
          ],
        },
      ],
    },
  };
  if (userRole === 1) {
    APIvalues.customer.customerFirstUserId = userId;
  } else if (userRole === 2) {
    APIvalues.customer.customerSecondUserId = userId;
  } else if (userRole === 3) {
    APIvalues.customer.topic[0].tickets[0].nextCallDate = "1800-01-07T11:39"; //change here as per condition
  }

  useEffect(() => {
    const verifyRefresh = async () => {
      try {
        const response = await axiosFetch.get(`/user`);
        const responseP = await axiosFetch.get(`/project`);
        const responseN = await axiosFetch.post(
          "/customerList",
          JSON.stringify(APIvalues)
        );
          console.log(responseN,'nnnnnn');
        setUserList(response.data);
        setProjectList(responseP.data);
        setExpiredNextCallRows(responseN.data);
      } catch (error) {
        console.log(error, "errror");
        alert("error");
      } finally {
        setIsLoading(false);
      }
    };
    verifyRefresh();
  }, []);

  return (
    <SearchContext.Provider value={{ userList, projectList,expiredNextCallRows }}>
      {isLoading ? <p> Search loading....</p> : <>{children}</>}
    </SearchContext.Provider>
  );
};

export default SerachIndexWrapper;
