import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  tabsClasses,
  useTheme,
} from "@mui/material";
import { AxiosFetch } from "../../AxiosFetch";
import TabPanelCategory from "../categoryFormRelated/TabPanelCategory";

function CategoryFormEdit({
  itemsDataArray,
  groupId,
  originalGroupId,
  originalCategoryDataNew,
  contextFormik,
  ...props
}) {
  const theme = useTheme();
  const axiosFetch = AxiosFetch();

  //   const calendarTopicId = props.calendarTopicId;//can not understand??
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [categoryObjectWithItems, setCategoryObjectWithItems] = useState({});
  const [catNameArr, setCatNameArr] = useState([]);
  const [tabCountPerCategory, setTabCountPerCategory] = useState({});
  const catTabCount = catNameArr.length;
  useEffect(() => {
    if (!isLoading) {
      const temp = giveMaxTabPerCategory(
        itemsDataArray,
        categoryObjectWithItems
      );
      setTabCountPerCategory(temp);
      setTabValue(0);
      setIsLoading2(false);
    }
  }, [isLoading]);
  //This will work like componentDidMount
  useEffect(() => {
    setIsLoading(true);
    setIsLoading2(true);
    async function getData() {
      var categoryNameArray = [];
      var categoryMap = {};
      var itemIdArray = {};
      try {
        const response = await axiosFetch.get(
          `categoryItemsByGroupId/${groupId}`
        );
        console.log(response.data, "all items by group");
        response.data.map((data, index) => {
          let catId = data.category.categoryId;
          if (data.category.visible) {
            if (!(catId in categoryMap)) {
              //unique category Id
              categoryMap[catId] = []; //intialise with array
              categoryNameArray.push(data.category);
            }
            let obj = categoryMap[catId].find((o) => o.itemId === data.itemId); //if ItemId already exist
            if (!Boolean(obj)) {
              categoryMap[catId].push(data);
              data.itemType === "multipleselect"
                ? (itemIdArray[data.itemId] = [[]])
                : data.itemType === "filterselect"
                ? (itemIdArray[data.itemId] = [{}])
                : (itemIdArray[data.itemId] = [""]);
            }
          }
        });
        setCategoryObjectWithItems(categoryMap);
        setCatNameArr(categoryNameArray);
        if (groupId !== originalGroupId) {
          contextFormik.setFieldValue("categoryDataNew", itemIdArray);
        } else {
          contextFormik.setFieldValue(
            "categoryDataNew",
            originalCategoryDataNew
          );
        }

        setIsLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getData();
  }, [groupId]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    //console.log(newValue);
  };

  return isLoading2 ? (
    <div> Loading </div>
  ) : (
    <Card sx={{ minWidth: 265 }} elevation={4}>
      <CardContent>
        <div>
          <AppBar
            position="static"
            sx={{ backgroundColor: "primary.light" }}
            color="primary"
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              {Array.from({ length: catTabCount }, (_, index) => (
                <Tab
                  label={catNameArr[index].categoryName}
                  id={index}
                  key={index}
                  value={index}
                  sx={{
                    flexBasis: '20%',
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "white",
                    },
                    "&.Mui-selected": {
                      color: "primary.main",
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                      "&:hover": {
                        backgroundColor: "primary.dark",
                        color: "white",
                      },
                    },
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <br />

          {Array.from({ length: catTabCount }, (_, index) => (
            <div hidden={tabValue !== index} key={index}>
              <TabPanelCategory
                id={index}
                categoryId={Object.keys(categoryObjectWithItems)[index]}
                itemArray={Object.values(categoryObjectWithItems)[index]} //passes array of items
                tabCountExisting={
                  tabCountPerCategory[catNameArr[index].categoryId]
                }
                multipleTabFlag={catNameArr[index].multipleValue}
                mode=""
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default CategoryFormEdit;
function giveMaxTabPerCategory(itemDataArray, categoryObjectWithItems) {
  var maxTabValuesPerItem = {};
  itemDataArray.forEach((data, index) => {
    const { itemId, tabValue } = data;
    if (
      !maxTabValuesPerItem[itemId] ||
      tabValue > maxTabValuesPerItem[itemId]
    ) {
      maxTabValuesPerItem[itemId] = tabValue;
    }
  });
  var maxTabValuesPerCategory = {};
  Object.keys(categoryObjectWithItems).forEach((key) => {
    const arr = categoryObjectWithItems[key];

    arr.forEach((data, index) => {
      if (
        !maxTabValuesPerCategory[key] ||
        maxTabValuesPerItem[data.itemId] > maxTabValuesPerCategory[key]
      ) {
        maxTabValuesPerCategory[key] = maxTabValuesPerItem[data.itemId];
      }
    });
  });
  return maxTabValuesPerCategory;
}

// const tabHead = categoryArray.map((category, index) => (
//     <Tab
//       label={category.categoryName}
//       wrapped
//       index={index + 1}
//       key={index + 1}
//       sx={{ minWidth: "fit-content", flex: 1 }}
//     />
//   ));
//   const tabContent = categoryArray.map((category, index) => (
//     <div key={index}>
//       <TabPanel value={tabValue} index={index + 1}>
//         {category.categoryName}
//       </TabPanel>
//     </div>
//   ));
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index &&
//           (value == 0 ? (
//             <CallLogCategory calendarTopicId={calendarTopicId} />
//           ) : (
//             <CategoryData
//               {...allProps(index - 1)}
//               mode={mode}
//               customerId={customerId}
//             />
//           ))}
//       </div>
//     );
//   }
// function allProps(index) {
//     return categoryArray[index];
//   }
