import React from "react";
import { FormControl, FormHelperText, Tooltip } from "@mui/material";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { Field, useField } from "formik";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

//HELPER TEXT FOR PROPS
// const variableList=[{value:10,name:"ten"},{value:20,name:"twenty"}]
// props=(mode="read", data={ {name:"namehere", label:"labelhere", list:variableList} } defaultValue={[value]}  )

export default function CustomSelect(props) {
  const data = props.data;
  const mode = props.mode;
  var readMode = mode ? true : false;
  // const defaultValue = props.defaultValue || "";
  //   console.log(defaultValue,);

  const CustomizedSelectForFormik = ({ children, form, field, ...props }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    const [, meta] = useField(name);
    var flag = meta.error && meta.touched ? true : false;
    return (
      <FormControl fullWidth error={flag}>
        <InputLabel id="demo-simple-select-disabled-label">
          {props.label}
          {props.tooltipProp && (
            <Tooltip title={props.tooltipProp} fontSize="small">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </InputLabel>
        <Select
          labelId="demo-simple-select-disabled-label"
          name={props.name}
          label={props.label}
          // value={value || defaultValue}
          value={value}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          {...props}
        >
          {children}
        </Select>
        {flag ? <FormHelperText>{meta.error}</FormHelperText> : <></>}
      </FormControl>
    );
  };

  return (
    <>
      <Field
        name={data.name}
        component={CustomizedSelectForFormik}
        label={data.label}
        inputProps={{
          readOnly: readMode,
        }}
        {...props}
      >
        {data.list.map((list, index) => (
          <MenuItem value={list.value} key={index}>
            <Tooltip title={list.name} placement="bottom" arrow>
              <div>
                {props.whitespaceflag === "true" ? (
                  <div style={{ whiteSpace: "normal", maxHeight: "50px" }}>
                    {list.name}
                  </div>
                ) : (
                  list.name
                )}
              </div>
            </Tooltip>
          </MenuItem>
        ))}
      </Field>
    </>
  );
}
// const CustomizedSelectForFormik = ({ children, form, field, ...props }) => {
//   const { name, value } = field;
//   const { setFieldValue } = form;
//   //console.log(field, form, "selecttt");
//   // useEffect(() => {
//   //   if (typeof value === "undefined") {
//   //     setFieldValue(name, defaultValue);
//   //   }
//   // }, []);
//   // if (typeof value === "undefined") {
//   //   return null;
//   // } else {
//   //   return (
//   //     <Select
//   //       name={name}
//   //       // value={value || defaultValue}
//   //       value={value}
//   //       onChange={(e) => {
//   //         setFieldValue(name, e.target.value);
//   //       }}
//   //       {...props}
//   //     >
//   //       {children}
//   //     </Select>
//   //   );
//   // }
//   return (
//     <Select
//       name={name}
//       // value={value || defaultValue}
//       value={value}
//       onChange={(e) => {
//         setFieldValue(name, e.target.value);
//       }}
//       {...props}
//     >
//       {children}
//     </Select>
//   );
// };
// return (
//   <div>
//     <FormControl fullWidth>
//       <InputLabel id="demo-simple-select-label">{data.label}</InputLabel>
//       <Field
//         name={data.name}
//         component={CustomizedSelectForFormik}
//         label={data.label}
//         inputProps={{
//           readOnly: readMode,
//         }}
//       >
//         {data.list.map((list, index) => (
//           <MenuItem value={list.value} key={index}>
//             {list.name}
//           </MenuItem>
//         ))}
//       </Field>
//     </FormControl>
//   </div>
// );
