import React from "react";
import { AxiosFetch } from "../AxiosFetch";
import CustomSelect from "./CustomSelect";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

/* <GroupSelect name="nameHere"/> */

export default function GroupSelect(props) {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  var group = [];

  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/group/user`);
      if (response.status === 200) {
        response.data.forEach((data, index) => {
          group[++index] = { name: data.groupDisplayName, value: data.groupId };
        });
        group[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        setGroupList(group);
        setIsLoading(false);
      }
    }
    getData();
  }, []);
  return isLoading ? (
    <div>
      <Skeleton variant="rounded" width={"100%"} height={56} />
    </div>
  ) : (
    <>
      <div>
        <CustomSelect
          data={{
            name: props.name,
            label: `${t("Select Group")}`,
            list: groupList,
          }}
          mode={props.mode}
          {...props}
          tooltipProp={props.tooltipProp}
        />
      </div>
    </>
  );
}
