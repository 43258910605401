import { Button, Grid, LinearProgress, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import CustomTextField from "../formikInputs/CustomTextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //p: 4,
};

export const NewBookmark = ({ open, setOpen, folderId, refreshList }) => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const initialValues = {
    name: "",
    url: "",
    bookmarkFolder: {
      id: folderId,
    },
  };
  //const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [urlArray, setUrlArray] = useState([]);
  // const [urlListCount, setUrlListCount] = useState(1);

  const handleSubmit = async (values) => {
    console.log(values, "vvvvvv");
    setLoading(true);
    try {
      const response = await axiosFetch.post(
        "/bookmark",
        JSON.stringify(values)
      );
      console.log(response, "URL Bookmark API response");
    } catch (error) {
      console.log(error, "URL Bookmark API Error");
    } finally {
      setLoading(false);
      setOpen(false);
      refreshList();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <LinearProgress
            sx={{
              visibility: `${!loading ? "hidden" : ""}`,
            }}
          />
          <div style={{ padding: "2.5%" }}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: `name`,
                        label: t("URL Name"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      data={{
                        name: `url`,
                        label: t("URL"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpen(false)}
                      variant="contained"
                      size="large"
                      fullWidth
                      color="error"
                      startIcon={<ArrowBackIcon />}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
