import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function DeleteDialog({
  handleDialogOpen,
  dialogOpen,
  handleDelete,
  delLoading,
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        handleDialogOpen(false);
      }}
      fullWidth
      maxWidth={"sm"}
    >
      <LinearProgress
        color="error"
        sx={{ visibility: `${!delLoading ? "hidden" : ""}` }}
      />
      <DialogTitle
        id="alert-dialog-title"
        align="center"
        sx={{
          paddingX: "5%",
        }}
      >
        {t("Are you sure you want to delete it?")}
      </DialogTitle>
      <DialogActions
        sx={{
          padding: "5%",
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          sx={{
            backgroundColor: "#e9ecef",
            color: "black",
            borderColor: "black",
          }}
          onClick={() => {
            handleDialogOpen(false);
          }}
        >
          {t("No")}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleDelete}
          autoFocus
          startIcon={<DeleteForeverIcon />}
        >
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
