import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomFilterSelect({ data, pageType, ...props }) {
  const readMode = props.mode === "read" ? true : false;
  const { name, label, list } = data;
  const formikContext = useFormikContext();

  //for intial value setup start
  const nameParts = name.split(".");
  const initialValue =
    pageType === "search"
      ? formikContext.values &&
        formikContext.values[nameParts[0]] &&
        formikContext.values[nameParts[0]][nameParts[1]]
      : formikContext.values &&
        formikContext.values[nameParts[0]] &&
        formikContext.values[nameParts[0]][nameParts[1]] &&
        formikContext.values[nameParts[0]][nameParts[1]][nameParts[2]];

  var val1i = "";
  var val2i = [];
  if (initialValue) {
    for (const [key, value] of Object.entries(initialValue)) {
      val1i = key;
      val2i = value;
    }
  } //end
  var list1 = [];
  const [val1, setVal1] = useState(val1i || "");
  const [val2, setVal2] = useState(val2i || []);
  const [list2, setList2] = useState([]);

  // const errorFlag =
  //   formikContext.errors &&
  //   formikContext.errors[nameParts[0]] &&
  //   formikContext.errors[nameParts[0]][nameParts[1]] &&
  //   formikContext.errors[nameParts[0]][nameParts[1]][nameParts[2]]
  //     ? true
  //     : false;

  list.forEach((e, index) => {
    for (const [key, value] of Object.entries(e)) {
      list1.push(key);
    }
  });
  useEffect(() => {
    if (val1 === val1i && val2.length !== 0);
    else setVal2([]);
    list.forEach((e, index) => {
      for (const [key, value] of Object.entries(e)) {
        if (key === val1) {
          setList2(value);
        }
      }
    });
  }, [val1]);
  useEffect(() => {
    if (val1 !== "" && val2.length !== 0) {
      const val = { [val1]: val2 };
      formikContext.setFieldValue(name, val);
    } else if (val1 !== "" && val2.length === 0) {
      formikContext.setFieldValue(name, {});
    }
    //console.log(rrr, "ref filter");
  }, [val1, val2]);

  const rrr = useRef(null);

  return (
    <Grid container columnSpacing={0.5}>
      <Grid item xs={4}>
        <FormControl
          fullWidth
          //error={errorFlag}
          error={rrr.current ? true : false}
        >
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={val1}
            label={label}
            onChange={(e) => setVal1(e.target.value)}
            inputProps={{ readOnly: readMode }}
          >
            {list1.map((e, index) => (
              <MenuItem value={e} key={index}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl
          fullWidth
          //error={errorFlag}
          error={rrr.current ? true : false}
        >
          <InputLabel id="demo-multiple-checkbox-label">
            {label} Filter
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={val2}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setVal2(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
              );
            }}
            inputProps={{ readOnly: readMode }}
            input={<OutlinedInput label={`${label} Filter`} />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {list2.map((name, ii) => (
              <MenuItem key={ii} value={name}>
                <Checkbox checked={val2.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <ErrorMessage name={name}>
          {(msg) => (
            <div style={{ color: "#d3302f" }} ref={rrr}>
              {msg}
            </div>
          )}
        </ErrorMessage>
        {/* {errorFlag ? (
          <div style={{ color: "red" }}>This field is required</div>
        ) : null} */}
      </Grid>
    </Grid>
  );
}
{
  /* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}Filter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={val2}
          label={label}
          onChange={(e) => setVal2(e.target.value)}
        >
          {list2.map((e, index) => (
            <MenuItem value={e} key={index}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */
}
