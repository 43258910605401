import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { memo } from "react";
import { renderItem } from "./renderItem";

const TabPanelMemo = ({ itemArray, index,mode }) => {
  //console.log("TabPanelMultiple Render");
  var arr = [];//converting itemArrayobj to Array
  Object.entries(itemArray).forEach(([key, value]) => {
    arr.push(value);
  });
  return (
    <div>
      <Grid container spacing={2}>
        {arr.map((data, i) =>
          data.visible ? (
            <Grid item xs={6} key={i}>
              {renderItem(data, index, mode)}
            </Grid>
          ) : (
            <div key={i}></div>
          )
        )}
      </Grid>
    </div>
  );
};
const TabPanelMultiple = memo(TabPanelMemo);
export default TabPanelMultiple;
