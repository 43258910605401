import React, { createContext, useState, useContext } from "react";

// Create a context
const AppContext = createContext();

// Define the initial state of the app for reusability
const initialState = {
  searchResults: [],
};

// Create a provider component
export const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState(initialState);

  // Function to update search results
  const updateSearchResults = (searchResults) => {
    setAppState({ ...appState, searchResults });
  };

  // Function to clear the app state
  const clearAppState = () => {
    setAppState(initialState);
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        updateSearchResults,
        clearAppState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the app context
export const useAppContext = () => useContext(AppContext);
