import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AxiosFetch } from "../../AxiosFetch";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import DeleteDialog from "../../commonComponents/DeleteDialog";

export default function CategoryItemTable(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  // const location = useLocation();
  // const data = location.state.data;
  //   console.log(location,"lllll");

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const data = props.data;
  const categoryId = data.categoryId;
  const categoryName = data.categoryName;

  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = useState({});

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  // const categoryName = location.state.data.categoryName;

  const columns = [
    { field: "itemId", headerName: `${t("Item ID")}`, flex: 0.4 },
    { field: "itemName", headerName: `${t("Item Name")}`, flex: 1 },
    // {
    //   field: "customerGroup",
    //   headerName: `${t("")}`"Group Name",
    // //   headerAlign: "center",
    //   flex:1,
    //   renderCell: (params) => {
    //     return <div>{params.row.customerGroup.groupDisplayName}</div>;
    //   },
    // //   valueFormatter: (params) => params.row,
    // },
    { field: "itemType", headerName: `${t("Item Type")}`, flex: 1 },
    { field: "mandatory", headerName: `${t("Mandatory")}`, flex: 0.8 },
    { field: "visible", headerName: `${t("Visible")}`, flex: 0.8 },
    {
      field: "registerUserId",
      headerName: `${t("Register User ID")}`,
      flex: 0.8,
    },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row, "ppppp");
          navigate("/categoryItemEdit", {
            state: {
              data:params.row,
            },
          });
          // navigate("/categoryItemDetail", {
          //   state: {
          //     itemId: params.row.itemId,
          //     categoryId: categoryId,
          //     categoryName: categoryName,
          //     groupId: params.row.category.customerGroup.groupId,
          //     groupDisplayName:
          //       params.row.category.customerGroup.groupDisplayName,
          //   },
          // });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={onClick}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: `${t("Delete")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation();
          setDialogOpen(true);
          setSelectRow(params.row); // don't select this row after clicking
          console.log(params.row, "ppppp");
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={onClick}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];
  const handleDelete = async () => {
    console.log(selectRow, "idd");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(
        `/categoryItems/${selectRow.itemId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  const handleRowDelete = (e) => {
    console.log(e, "eeee");
    var arr = [...rows];
    arr.splice(
      arr.findIndex((a) => a.itemId === e.itemId),
      1
    );
    //console.log(arr,'arrr');
    setRows(arr);
  };

  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/categoryItems/${categoryId}`);
      if (response.status === 200) {
        console.log(response.data, "ccc");
        setRows(response.data);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      {/* {console.log(rows,"rrrr")} */}
      {/* <ButtonAppBar title="Category Item Table" /> */}
      {/* <Box
        sx={{
          alignItems: "center",
          px: "5%",
          py: "2%",
          // background: "red",
        }}
      > */}
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom align="center">
            {typeof rows[0] !== "undefined"
              ? rows[0].category.categoryName
              : ""}{" "}
            {t("Item List")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => {
              navigate("/newCategoryItem", { state: { data: data } });
            }}
          >
            {t("Create New Category Item")}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.itemId}
              rows={rows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </Grid>
      </Grid>
      {/* </Box> */}
    </>
  );
}
