import React, { useContext } from "react";
import { Grid, Box, Button, Card, CardContent } from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import CustomSelect from "../formikInputs/CustomSelect";
import { useLocation, useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function CustomerGroupDetail(props) {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Customer Group Detail");
  }, []);
  const location = useLocation();
  const data = location.state.data;
  console.log(data, "ddddd");
  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];
  const statusList = [
    { name: `${t("active")}`, value: 1 },
    { name: `${t("inactive")}`, value: 2 },
  ];

  const initialValues = {
    groupDisplayName: data.groupDisplayName || "",
    groupId: data.groupId || "",
    groupName: data.groupName || "",
    groupType: data.groupType || "",
    modifyDateTime: data.modifyDateTime || "",
    modifyUserId: data.modifyUserId || "",
    parentGroup: data.parentGroup || "",
    registerDateTime: data.registerDateTime || "",
    registerUserId: data.registerUserId || "",
    status: Number(data.status) || "0",
  };
  console.log(initialValues.status, "ssss");
  //   const formValidation = Yup.object().shape({
  //     id: Yup.string()
  //       .required("Required!")
  //       .min(2, "Too Short!")
  //       .max(10, "Too Long!"),
  //     password: Yup.string().required("Required!"),
  //   });

  const handleSubmit = async (values) => {
    console.log(values);
    navigate("/editCustomerGroup", { state: { data: initialValues } });
  };
  return (
    <div>
      {/* <ButtonAppBar title="Customer Group Detail" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "25%",
              py: "5%",
              // background: "red",
            }}
          >
            <Card elevation={4}>
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{
                        name: "groupDisplayName",
                        label: `${t("Group Display Name")}`,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{ name: "groupId", label: `${t("Group ID")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{ name: "groupType", label: `${t("Group Type")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{ name: "groupName", label: `${t("Group Name")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{
                        name: "parentGroup",
                        label: `${t("Parent Group ID")}`,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{
                        name: "registerUserId",
                        label: `${t("Register User ID")}`,
                      }}
                      mode="read"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomSelect
                      data={{
                        name: "status",
                        label: `${t("Status")}`,
                        list: statusList,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      fullWidth
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<EditIcon />}
                      fullWidth
                    >
                      {t("Edit")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
