import React, { useContext } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { Formik, Form, FormikContext } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import CustomSelect from "../formikInputs/CustomSelect";
import { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import LoginContext from "../../context/LoginContext";
import { useEffect } from "react";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { GroupSelectWithType } from "../formikInputs/GroupSelectWithType";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function CreateNewCustomerGroup(props) {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const context = useContext(LoginContext);
  const axiosFetch = AxiosFetch();
  useEffect(() => {
    context.setPageTitle("New Customer Group");
  }, []);
  const [loading, setLoading] = useState(false);

  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 101010 },
  ];
  const statusList = [
    { name: `${t("active")}`, value: 1 },
    { name: `${t("inactive")}`, value: 2 },
  ];

  const initialValues = {
    groupType: "",
    parentGroup: "",
    groupName: "",
    status: 1,
    registerUserId: context.userId,
  };

  const formValidation = Yup.object().shape({
    groupName: Yup.string().required(`${t("This field is required")}`),
    groupType: Yup.string().required(`${t("This field is required")}`),
    parentGroup: Yup.string().when("groupType", {
      is: (value) => value === "child" || value === "grandchild",
      then: Yup.string().required(`${t("This field is required")}`),
      otherwise: Yup.string(),
    }),
  });

  const handleSubmit = async (values) => {
    // await new Promise((r) => setTimeout(r, 500));
    //console.log(values, "values");
    var APIvalues = { ...values };
    if (values.groupType == "parent") APIvalues.parentGroup = "";
    console.log(APIvalues, "API values");
    setLoading(true);
    try {
      const custResponse = await axiosFetch.post(
        "/group",
        JSON.stringify(APIvalues)
      );
      console.log(custResponse);
      navigate(-1);
      setLoading(false);
    } catch (error) {
      //alert("error");
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div>
      {/* <ButtonAppBar title="New Customer Group" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "25%",
              py: "5%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item xs={11.5}>
                    <CustomSelect
                      data={{
                        name: "groupType",
                        label: `${t("Group Type")}`,
                        list: [
                          { name: `${t("Parent")}`, value: "parent" },
                          { name: `${t("Child")}`, value: "child" },
                          { name: `${t("Grand Child")}`, value: "grandchild" },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip
                      fontSize="small"
                      title={t(
                        `Set the parent-child relationship of the group to be created. If there is no particular intention, "parent" is fine. See help for details`
                      )}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>

                  <FormikContext.Consumer>
                    {(context) => {
                      //console.log(context, "cccc");
                      var visible =
                        context.values.groupType === "child" ||
                        context.values.groupType === "grandchild"
                          ? "block"
                          : "none";
                      return (
                        <Grid item xs={12} style={{ display: visible }}>
                          <GroupSelectWithType
                            name="parentGroup"
                            type={convertUp(context.values.groupType)}
                            //mode='read'
                          />
                        </Grid>
                      );
                    }}
                  </FormikContext.Consumer>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{ name: "groupName", label: `${t("Group Name")}` }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomSelect
                      data={{
                        name: "status",
                        label: `${t("Status")}`,
                        list: statusList,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      fullWidth
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
function convertUp(name) {
  if (name === "child") return "parent";
  else if (name === "grandchild") return "child";
  else return "";
}
