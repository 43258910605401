import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Chip } from "@mui/material";
const ListChipItem = ({ text, index, moveListItem }) => {
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const hoverActualX = monitor.getClientOffset().x - hoverBoundingRect.left;

      // if dragging to the right, continue only when hover is smaller than middle X
      if (dragIndex < hoverIndex && hoverActualX < hoverMiddleX) return;
      // if dragging to the left, continue only when hover is bigger than middle X
      if (dragIndex > hoverIndex && hoverActualX > hoverMiddleX) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={dragDropRef}
      style={{
        opacity: opacity,
        margin: 2,
        padding: 2,
        backgroundColor: "#ebebeb",
        borderRadius: 5,
      }}
    >
      {text}
      {/* <Chip label={text} /> */}
    </div>
    // <Chip label={text} ref={dragDropRef} sx={{ opacity }} />
  );
};
export default ListChipItem;
