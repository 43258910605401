import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import CustomTextField from "../../formikInputs/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import GroupSelect from "../../formikInputs/GroupSelect";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSelect from "../../formikInputs/CustomSelect";

export const SearchBox = ({ updateRows, searchLoading, toggle }) => {
  const initialValues = {
    name: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    customerGroup: {
      groupId: 0,
    },
    memo: "",
    active: 0,
  };
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  //const [toggle, setToggle] = useState(false);
  const handleSubmit = async (values, e) => {
    searchLoading(true);
    //handleScroll();
    console.log(values, "vvv");
    try {
      const apiResponse = await axiosFetch.post(
        "/doNotCallAreaListSearch",
        JSON.stringify({
          ...values,
          isActive:
            values.active === 0 ? true : values.active === 1 ? false : null,
        })
      );
      console.log(apiResponse, "do not call Area list search API response");
      updateRows(apiResponse.data);
      //refreshList();
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
    } finally {
      searchLoading(false);
      window.scrollTo(0, window.scrollY + 200);
    }
  };
  return (
    <div style={{ margin: "1%", display: toggle ? "block" : "none" }}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={2.5}>
              <CustomTextField
                data={{ name: "address1", label: t("Address 1") }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CustomTextField
                data={{ name: "address2", label: t("Address 2") }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CustomTextField
                data={{ name: "address3", label: t("Address 3") }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <CustomTextField
                data={{ name: "address4", label: t("Address 4") }}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect
                data={{
                  name: "active",
                  label: t("Active Entries"),
                  list: [
                    {
                      name: t("True"),
                      value: 0,
                    },
                    {
                      name: t("False"),
                      value: 1,
                    },
                    {
                      name: t("Both"),
                      value: 2,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <GroupSelect name="customerGroup.groupId" />
            </Grid>
            <Grid item xs={3}>
              <CustomTextField data={{ name: "name", label: t("Name") }} />
            </Grid>

            <Grid item xs={3}>
              <CustomTextField data={{ name: "memo", label: t("Memo") }} />
            </Grid>
            <Grid item xs={2.5} justifyContent="center" display={"flex"}>
              <Button
                variant="outlined"
                color="complementary"
                startIcon={<SearchIcon />}
                type="submit"
                size="large"
                fullWidth
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item xs={0.5} justifyContent="center" display={"flex"}>
              <Tooltip title={t("Reset Form")}>
                <IconButton type="reset" color="error">
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};
