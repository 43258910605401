import {
  Card,
  CardContent,
  LinearProgress,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosFetch } from "../../AxiosFetch";
import TabPanelCategory from "./TabPanelCategory";
import { useTranslation } from "react-i18next";

export default function CategoryFormIndex({ groupId, contextFormik }) {
  //console.log("CategoryFormIndex render");
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [catArr, setCatArr] = useState({});
  const [catNameArr, setCatNameArr] = useState([]);
  const axiosFetch = AxiosFetch();
  var categoryNameArray = [];
  var categoryMap = {};
  var itemIdArray = {};
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await axiosFetch.get(
          `categoryItemsByGroupId/${groupId}`
        );
        console.log(response.data, "all items by group");
        response.data.map((data, index) => {
          let catId = data.category.categoryId;
          if (data.category.visible) {
            if (!(catId in categoryMap)) {
              //unique category Id
              categoryMap[catId] = []; //intialise with array
              categoryNameArray.push(data.category);
            }
            let obj = categoryMap[catId].find((o) => o.itemId === data.itemId); //if ItemId already exist
            if (!Boolean(obj)) {
              categoryMap[catId].push(data);
              data.itemType === "multipleselect"
                ? (itemIdArray[data.itemId] = [[]])
                : data.itemType === "filterselect"
                ? (itemIdArray[data.itemId] = [{}])
                : (itemIdArray[data.itemId] = [""]);
            }
          }
        });
        setCatArr(categoryMap);
        setCatNameArr(categoryNameArray);
        contextFormik.setFieldValue("categoryDataNew", itemIdArray);
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getData();
  }, [groupId]);

  const catTabCount = catNameArr.length;
  return loading ? (
    // <Box sx={{ width: "100%", height: "75vh" }}>
    <Card elevation={4} sx={{ height: "100%" }}>
      <LinearProgress />
    </Card>
  ) : // </Box>
  groupId === 0 ? (
    // <Box
    //   sx={{
    //     width: "100%",
    //     height: "75vh",
    //   }}
    // >
    <Card
      elevation={4}
      sx={{
        height: "100%",
        minWidth: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Typography variant="h5">{t("Select Group For Categories")} </Typography>
    </Card>
  ) : (
    // </Box>
    //<Box sx={{ width: "100%", height: "75vh" }}>
    <Card
      sx={{
        minWidth: "100%",
        height: "100%",

        overflowY: "auto",
      }}
      elevation={4}
    >
      <CardContent>
        <Tabs
          value={tabValue}
          onChange={(e, n) => setValue(n)}
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
            backgroundColor: "primary.light",
          }}
        >
          {Array.from({ length: catTabCount }, (_, index) => (
            <Tab
              label={catNameArr[index].categoryName}
              id={index}
              key={index}
              sx={{
                flexBasis: "20%",
                "&:hover": {
                  backgroundColor: "primary.dark",
                  color: "white",
                },
                "&.Mui-selected": {
                  color: "primary.main",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                  },
                },
              }}
            />
          ))}
        </Tabs>
        {Array.from({ length: catTabCount }, (_, index) => (
          <div hidden={tabValue !== index} key={index}>
            <TabPanelCategory
              id={index}
              categoryId={Object.keys(catArr)[index]}
              itemArray={Object.values(catArr)[index]}
              multipleTabFlag={catNameArr[index].multipleValue}
            />
          </div>
        ))}
      </CardContent>
    </Card>
    //</Box>
  );
}
