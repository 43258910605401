import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
  import { DndProvider } from "react-dnd";
  import { HTML5Backend } from "react-dnd-html5-backend";
  import LoginContext from "../../context/LoginContext";
  import { AxiosFetch } from "../AxiosFetch";
  import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
  import { useDrag, useDrop } from "react-dnd";
  import { useTranslation } from "react-i18next";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import SaveIcon from "@mui/icons-material/Save";
  
  export default function CategoryItemOrder({ setOrderItemEditFlag }) {
    const context = useContext(LoginContext);
    const axiosFetch = AxiosFetch();
    const { t } = useTranslation();
    const [categoryId, setCategoryId] = useState(0);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingBar, setLoadingBar] = useState(false);
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      context.setPageTitle("Customize Category Item Order Edit");
      async function getData() {
        try {
          const response = await axiosFetch.get(`/category`);
          setCategoryData(response.data);
          setLoading(false);
        } catch (error) {
        }
      }
      getData();
    }, []);
  
    async function getCategoryItemsByCategory(categoryId) {
      setLoadingBar(true);
      try {
        const response = await axiosFetch.get(`/categoryItems/${categoryId}`);
        console.log(response, "get category by parent groups api response");
        var arr = [];
        response.data.forEach((data) => {
          arr.push({ id: data.itemId, name: data.itemName });
        });
        setItems(arr);
      } catch (error) {
        console.log(error, "errr");
      } finally {
        setLoadingBar(false);
      }
    }
    useEffect(() => {
      if (categoryId !== 0) {
        getCategoryItemsByCategory(categoryId);
      }
    }, [categoryId]);
    const ListChipItem = ({ text, index, moveListItem }) => {
      // useDrag - the list item is draggable
      const [{ isDragging }, dragRef] = useDrag({
        type: "item",
        item: { index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });
  
      // useDrop - the list item is also a drop area
      const [spec, dropRef] = useDrop({
        accept: "item",
        hover: (item, monitor) => {
          const dragIndex = item.index;
          const hoverIndex = index;
          const hoverBoundingRect =
            ref.current && ref.current.getBoundingClientRect();
  
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const hoverActualY =
            monitor.getClientOffset().y - hoverBoundingRect.top;
  
          // if dragging downwards, continue only when hover is smaller than middle Y
          if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
          // if dragging upwards, continue only when hover is bigger than middle Y
          if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
  
          moveListItem(dragIndex, hoverIndex);
          item.index = hoverIndex;
        },
      });
  
      // Join the 2 refs together into one (both draggable and can be dropped on)
      const ref = useRef(null);
      const dragDropRef = dragRef(dropRef(ref));
  
      // Make items being dragged transparent, so it's easier to see where we drop them
      const opacity = isDragging ? 0 : 1;
      return (
        <div
          ref={dragDropRef}
          style={{
            opacity: opacity,
            margin: 2,
            padding: 2,
            backgroundColor: "#ebebeb",
            borderRadius: 5,
            height: "50px",
          }}
        >
          <DragIndicatorIcon /> {text}
        </div>
      );
    };
    const moveFinalOrderListItem = useCallback(
      (dragIndex, hoverIndex, orderList) => {
        const dragItem = orderList[dragIndex];
        const hoverItem = orderList[hoverIndex];
        const updatedFinalOrderList = [...orderList];
        updatedFinalOrderList[dragIndex] = hoverItem;
        updatedFinalOrderList[hoverIndex] = dragItem;
        setItems(updatedFinalOrderList);
      },
      []
    );
    const handleSubmit = async () => {
      setLoadingBar(true);
      var APIvalues = [];
      items.forEach((item, index) => {
        APIvalues.push({ itemId: item.id, categoryItemOrder: index + 1 });
      });
      console.log(APIvalues, "API values");
      try {
        await axiosFetch.put( `/categoryItemOrder`, JSON.stringify(APIvalues));
        context.setGlobalAlert({
          open: true,
          severity: "success",
          title: "Success",
          message: `Edit Successful`,
        });
        setOrderItemEditFlag(false);
      } catch (error) {
        console.log(error, "errr");
        context.setGlobalAlert({
          open: true,
          severity: "error",
          title: "error",
          message: `${
            error.response ||
            error.response.data ||
            error.response.data.errorMessage
          }`,
        });
      } finally {
        setLoadingBar(false);
      }
    };
  
    return loading ? (
      <>loading....</>
    ) : (
      <Box
        sx={{
          width: "90%",
          padding: "2%",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() => setOrderItemEditFlag(false)}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              disabled={categoryId === 0}
              onClick={handleSubmit}
              size="large"
              startIcon={<SaveIcon />}
            >
              {t("Save")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("Select Category")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryId}
                label={t("Select Category")}
                onChange={(event) => {
                  setCategoryId(event.target.value);
                }}
              >
                <MenuItem value={0}>{t("Not Specified")}</MenuItem>
                {categoryData.map((data) => (
                  <MenuItem value={data.categoryId} key={"abc" + data.categoryId}>
                    {data.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {categoryId !== 0 ? (
            <Grid item xs={12}>
              <DndProvider backend={HTML5Backend}>
                <LinearProgress
                  sx={{ visibility: loadingBar ? "visible" : "hidden" }}
                  color={"secondary"}
                />
                <Box sx={{ width: "100%", padding: "2%", border: "1px solid black" }}>
                  <Grid container spacing={1}>
                    {items.map((item, index) => (
                      <Grid item key={item.id} xs={12}>
                        <ListChipItem
                          text={`${index + 1}:     ${item.name}`}
                          index={index}
                          moveListItem={(dragIndex, hoverIndex) =>
                            moveFinalOrderListItem(dragIndex, hoverIndex, items)
                          }
                        />
                      </Grid>
                    ))}
                    {items.length === 0 ? <>{t("No Items In This Category")}</> : null}
                  </Grid>
                </Box>
              </DndProvider>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    );
  }
  