import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useNavigate } from "react-router";
import GroupSelect from "../formikInputs/GroupSelect";
import { AxiosFetch } from "../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { GroupSelectWithType } from "../formikInputs/GroupSelectWithType";

export default function CreateNewCustomerProject(props) {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("New Customer Project");
  }, []);
  const [loading, setLoading] = useState(false);

  var submitAction = "";

  const initialValues = {
    name: "",
    customerGroup: {
      groupId: "",
    },

    flag: "",
    registerUserId: context.userId,
  };

  const formValidation = Yup.object().shape({
    name: Yup.string().required(`${t("This field is required")}`),
    customerGroup: Yup.object().shape({
      groupId: Yup.string()
        .required(`${t("This field is required")}`)
        .test(
          "zero not allowed",
          `${t("This field is required")}`,
          (val) => Number(val) !== 0
        ),
    }),
  });

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      const Response = await axiosFetch.post(
        "/project",
        JSON.stringify(values)
      );
      console.log(Response);
      if (submitAction === "primary") navigate("/customerProjectTable");
      else window.location.reload();

      setLoading(false);
    } catch (error) {
      alert("error");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "15%",
              py: "5%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{ name: "name", label: `${t("Project Name")}` }}
                    />
                  </Grid>
                  {/* <Grid item xs={2}>
                    <CustomTextfield
                      data={{ name: "id", label:`${t()}` "Project ID" }}
                      mode="read"
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    {/* <GroupSelect
                      name="customerGroup.groupId"
                      label="Select Group"
                    /> */}
                    <GroupSelectWithType name="customerGroup.groupId" type='all'/>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{ name: "flag", label: `${t("Flag")}` }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      fullWidth
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      fullWidth
                      onClick={() => {
                        submitAction = "primary";
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="save"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        submitAction = "secondary";
                      }}
                      fullWidth
                    >
                      {t("Save and New")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
