import {
  Button,
  Grid,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  colors,
  CircularProgress,
  Checkbox,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../formikInputs/CustomTextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginContext from "../../../context/LoginContext";
import { AxiosFetch } from "../../AxiosFetch";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import ChatworkAPICall from "./ChatworkAPIcall";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //p: 4,
};

export const AddToMyList = ({ open, setOpen, refreshList }) => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const { userId, userRole, ...context } = useContext(LoginContext);
  const navigate = useNavigate();
  const [completeList, setCompleteList] = useState([]);
  const [openNewRoonModal, setOpenNewRoonModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [checked, setChecked] = useState([]);
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [roomEditId, setRoomEditId] = useState(0);

  const [initialValues, setInitialValues] = useState({
    roomName: "",
    roomId: "",
  });

  const formValidation = Yup.object().shape({
    roomId: Yup.string().required(`${t("This field is required")}`),
    roomName: Yup.string().required(`${t("This field is required")}`),
  });

  async function getCompleteList() {
    try {
      const response = await axiosFetch.get("/chatworkRoom");
      console.log(response, "get universal room list response");
      setCompleteList(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error, "get room list api error");
    }
  }
  useEffect(() => {
    getCompleteList();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddToMyList = async () => {
    setLoadingCircle(true);
    let temp = [];
    checked.forEach((data) => {
      temp.push({ id: completeList[data].id });
    });
    console.log(temp, "temppppp");
    try {
      const response = await axiosFetch.post(
        "/chatworkRoom/updateUserList",
        temp
      );
      console.log(response, "Update user list api response");
    } catch (error) {
      console.log(error, "update user list  API error");
    } finally {
      setLoadingCircle(false);
      setChecked([]);
      setOpen(false);
      refreshList();
      navigate(-1);
    }
  };
  const AddNewRoomModal = ({ open, setOpen }) => {
    const [loadingNewRoom, setLoadingNewRoom] = useState(false);
    const handleSubmit = async (values) => {
      console.log(values, "from submit values");
      setLoadingNewRoom(true);
      //validate if room id
      try {
        const response = await ChatworkAPICall(
          `curl -X GET  -H "x-chatworktoken: 7d045659dd6cc607ef9772debcf11ae0"  "https://api.chatwork.com/v2/rooms/${values.roomId}"`
        );
        console.log(response, "response validate room id");
        if (response && response.result && response.result.includes("errors")) {
          //alert("invalid room ID");
          setErrorFlag(true);
        } else {
          try {
            if (roomEditId !== 0) {
              const response = await axiosFetch.put(
                `/chatworkRoom/${roomEditId}`,
                values
              );
              console.log(response, "room Edit response ");
            } else {
              const response = await axiosFetch.post("/chatworkRoom", values);
              console.log(response, "room save response ");
              try {
                const response2 = await axiosFetch.post(
                  "/chatworkRoom/updateUserList",
                  [{ id: response.data.id }]
                );
                console.log(response2, "Update user list api response");
              } catch (error) {
                console.log(error, "update user list  API error");
              }
              navigate(-1);
            }
          } catch (error) {
            console.log(
              error,
              roomEditId !== 0 ? "edit room api error" : "room save API error"
            );
            alert("error");
          } finally {
            setLoadingNewRoom(false);
            setOpenNewRoonModal(false);
            getCompleteList();
          }
        }
      } catch (error) {
        console.log(error, "error at room id validate api call");
      } finally {
        setLoadingNewRoom(false);
      }
    };
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            ...style,
            width: "75%",
            p: 4,
          }}
        >
          <Formik
            //enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={formValidation}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center" gutterBottom>
                    {t("Add Chatwork Room")}
                  </Typography>
                </Grid>
                {errorFlag ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      color={"error"}
                      gutterBottom
                    >
                      <ErrorIcon color="error" />
                      {t("Error : Room is not common with sys_chatwapi")}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" gutterBottom>
                      <WarningIcon sx={{ color: colors.yellow[800] }} />
                      {t("Make sure room Id is common with sys_chatwapi")}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={4}>
                  <CustomTextField
                    data={{
                      name: `roomName`,
                      label: t("Room Name"),
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CustomTextField
                    data={{
                      name: `roomId`,
                      label: t("Room Id"),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => setOpen(false)}
                    variant="contained"
                    size="large"
                    fullWidth
                    color="error"
                    startIcon={<ArrowBackIcon />}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={loadingNewRoom}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    size="large"
                    type="submit"
                    fullWidth
                  >
                    {t("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    openNewRoonModal && setErrorFlag(!openNewRoonModal);
    if (!openNewRoonModal) {
      setInitialValues({
        roomName: "",
        roomId: "",
      });
      setRoomEditId(0);
    }
  }, [openNewRoonModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "75%",
            // overflowY: "auto",
            // maxHeight: "70vh",
            p: "2%",
          }}
        >
          {openNewRoonModal ? (
            <AddNewRoomModal
              open={openNewRoonModal}
              setOpen={(e) => setOpenNewRoonModal(e)}
            />
          ) : null}

          <CircularProgress
            sx={{
              position: "absolute",
              left: "50%",
              top: "40%",
              display: loadingCircle ? "block" : "none",
              zIndex: 10,
            }}
            color="secondary"
            size={50}
          />
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography align="center" variant="h3">
                {t("All Destinations")}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
              <Button
                size="large"
                variant="contained"
                color="error"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
          {loading ? (
            <Typography variant="h6"> Loading....</Typography>
          ) : (
            <Grid
              container
              spacing={1}
              sx={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <Grid item xs={12} md={6}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {completeList.map((data, index) => {
                    return index % 2 === 0 ? (
                      <ListItem
                        disablePadding
                        key={index + "g"}
                        secondaryAction={
                          userRole === 3 ? (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              sx={{ "&:hover": { color: "primary.main" } }}
                              onClick={() => {
                                console.log(data, index);
                                setInitialValues({
                                  roomName: data.roomName,
                                  roomId: data.roomId,
                                });
                                setOpenNewRoonModal(true);
                                setRoomEditId(data.id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : null
                        }
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(index)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(index) !== -1}
                              size="large"
                              tabIndex={-1}
                              disableRipple
                              //inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={index}
                            primary={
                              <Typography variant="h4">
                                {data.roomName}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : null;
                  })}
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {completeList.map((data, index) => {
                    return index % 2 !== 0 ? (
                      <ListItem
                        disablePadding
                        key={index + "f"}
                        secondaryAction={
                          userRole === 3 ? (
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              sx={{ "&:hover": { color: "primary.main" } }}
                              onClick={() => {
                                console.log(data, index);
                                setInitialValues({
                                  roomName: data.roomName,
                                  roomId: data.roomId,
                                });
                                setOpenNewRoonModal(true);
                                setRoomEditId(data.id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : null
                        }
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(index)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(index) !== -1}
                              size="large"
                              tabIndex={-1}
                              disableRipple
                              //inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={index}
                            primary={
                              <Typography variant="h4">
                                {data.roomName}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : null;
                  })}
                </List>
              </Grid>
            </Grid>
          )}

          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="outlined"
                fullWidth
                color="complementary"
                onClick={() => {
                  setOpenNewRoonModal(true);
                }}
                startIcon={<PersonAddIcon />}
              >
                {t("New Room Registration")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                fullWidth
                onClick={handleAddToMyList}
                startIcon={<CheckIcon />}
              >
                {t("Confirm")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
