import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomMultiSelectCheck from "../../../formikInputs/CustomMultiSelectCheck";
import CustomSelect from "../../../formikInputs/CustomSelect";
import { SearchContext } from "../SerachIndexWrapper";
//Helper
{
  /* <ProjectSelectFromGroup
    mode=""
    name="project"
    label={t("Project")}
    groupId={groupId}
    typeFlag="search"(to list all project when group is none)
  />*/
}

export default function ProjectSelectFromGroup({
  groupId,
  typeFlag,
  ...props
}) {
  const { projectList } = useContext(SearchContext);
  const [projectListNow, setProjectListNow] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (groupId === 0) {
      let temp = [];
      // temp.push({
      //   value: 0,
      //   name: t("Not Specified"),
      // });
      temp.push({
        value: -1,
        name: t("Unassigned"),
      });
      projectList.forEach((data) => {
        temp.push({
          value: data.id,
          name: data.name,
        });
      });
      setProjectListNow(temp);
    } else {
      let temp = [];
      // temp.push({
      //   value: 0,
      //   name: t("Not Specified"),
      // });
      temp.push({
        value: -1,
        name: t("Unassigned"),
      });
      projectList.forEach((data) => {
        const { customerGroup } = data;
        if (customerGroup.groupId === groupId) {
          temp.push({
            value: data.id,
            name: data.name,
          });
        }
        setProjectListNow(temp);
      });
    }
  }, [groupId]);

  return (
    <div>
      <CustomMultiSelectCheck
        data={{
          name: props.name,
          label: props.label,
          list: projectListNow,
        }}
        mode={props.mode}
      />
    </div>
  );
}
