import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CallGroup from "./callGroup/CallGroup";
import CallFlag from "./callFlag/CallFlag";
import Phase from "./Phase";
import Title from "./Title";
import Caller from "./Caller";
import FunctionKey from "./functionKey/FunctionKey";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CallLogIndex() {
  const { t } = useTranslation();
  const { userRole, ...context } = React.useContext(LoginContext);
  const defaultTabValue = userRole === 3 ? 0 : 5;
  console.log(defaultTabValue, "dddd", userRole);
  const [value, setValue] = React.useState(defaultTabValue);

  React.useEffect(() => {
    context.setPageTitle("Call Log Settings");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <ButtonAppBar title="Call Log Settings"/> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            disable
          >
            {userRole == 3 && (
              <Tab
                label={
                  <div>
                    {t("Call Group")}
                    <Tooltip
                      title={t(
                        "Create a new group. Add users to the group you created Assign access rights. Please note that this is necessary"
                      )}
                      fontSize="0.8rem"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                }
                {...a11yProps(0)}
              />
            )}
            {userRole == 3 && (
              <Tab
                label={
                  <div>
                    {t("Call Flag")}
                    <Tooltip
                      title={t(
                        `Create a flag inside "In Call Group". This is the main selected flag. (Example: "Answering machine", "Guidance", etc.)`
                      )}
                      fontSize="0.8rem"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                }
                {...a11yProps(1)}
              />
            )}
            {/* {userRole == 3 && <Tab label={t("Title")} {...a11yProps(2)} />} */}
            {userRole == 3 && (
              <Tab
                label={
                  <div>
                    {t("Phase")}
                    <Tooltip
                      title={t(
                        `Describe your progress toward that topic. (Example: "Approach", "Contract", "Rejection", etc.)`
                      )}
                      fontSize="0.8rem"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                }
                {...a11yProps(2)}
              />
            )}
            {userRole == 3 && (
              <Tab
                label={
                  <div>
                    {t("Caller")}
                    <Tooltip
                      title={t(
                        "Set the relationship, occupation, etc. of the person who answered the phone. (The person, spouse, receptionist, etc.)"
                      )}
                      fontSize="0.8rem"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                }
                {...a11yProps(3)}
              />
            )}

            <Tab
              label={
                <div>
                  {t("Function Keys")}
                  <Tooltip
                    title={t(
                      "Set the shortcut function for post-call processing. By linking call flags to some function keys, you can easily register frequently used flags."
                    )}
                    fontSize="0.8rem"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </div>
              }
              {...a11yProps(4)}
              disabled={userRole === 3 ? false : true}
            />
          </Tabs>
        </Box>
        {userRole == 3 && (
          <>
            <TabPanel value={value} index={0}>
              <CallGroup />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CallFlag />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <Title />
            </TabPanel> */}
            <TabPanel value={value} index={2}>
              <Phase />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Caller />
            </TabPanel>
          </>
        )}

        <TabPanel value={value} index={4}>
          <FunctionKey />
        </TabPanel>
      </Box>
    </div>
  );
}
