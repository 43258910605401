import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function CustomerTableForDistribution({setFinalList,...props}) {
  
  const { t } = useTranslation();

  const rows = props.rows;
//   console.log(rows,'rrr');
  const [selectionModel, setSelectionModel] = useState([]);
  useEffect(()=>{
    setFinalList(selectionModel);
  },[selectionModel])


  var selectAll = [];
  rows.map((data, index) => {
    selectAll[index] = data.customerId;
  });

  
  useEffect(() => {
    switch (props.settingType) {
      case "all":
        if (JSON.stringify(selectionModel) !== JSON.stringify(selectAll))
          setSelectionModel(selectAll);
        break;
      case "manual":
        if (selectionModel.length !== 0) {
          
          setSelectionModel([]);
        }
        break;
      default:
        
        setSelectionModel([]);
        break;
    }
  }, [props.settingType]);
  useEffect(() => {
    var temp=[...selectAll]
    setSelectionModel(temp.slice(0,props.number));
  }, [props.number]);

  const columns = [
    { field: "customerId", headerName: `${t("ID")}`, flex: 0.5 },
    { field: "customerLastName", headerName: `${t("Last Name")}`, flex: 1 },
    { field: "customerFirstName", headerName: `${t("First Name")}`, flex: 1 },
    {
      field: "customerAge",
     headerName: `${t("Age")}` ,
      // type: 'number',
      flex: 1,
    },
    {
      field: "customerNextCallDateTime",
     headerName: `${t("Next Call Date")}` ,
      type: "date",
      flex: 1,
    },
    {
      field: "customerModifyDateTime",
     headerName: `${t("Last Updated")}` ,
      type: "datetime",
      flex: 1,
    },
    { field: "customerAddress1",headerName: `${t("Address 1")}`, flex: 1 },
    { field: "customerAddress2",headerName: `${t("Address 2")}` , flex: 1 },
    {
      field: "customerGroup",
     headerName: `${t("Group Name")}` ,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.customerGroup !== null) {
          temp = params.row.customerGroup.groupDisplayName;
        }
        return temp;
      },
    },
    { field: "project",headerName: `${t("Project Name")}` , flex: 1 , valueGetter: (params) => {
        var temp = "";
        if (params.row.project !== null) {
          temp = params.row.project.name;
        }
        return temp;
      },},
    { field: "customerFirstUserId",headerName: `${t("User 1 ID")}` , flex: 1 },
    { field: "customerSecondUserId",headerName: `${t("User 2 ID")}` , flex: 1 },
  ];

//   console.log(selectionModel, "mmmmm");

  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          checkboxSelection
          getRowId={(row) => row.customerId}
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>
    </>
  );
}
