import React from 'react'
import { Button} from '@mantine/core'
import {  useDeleteEventData ,useBulkDeleteEventData} from '../useCalendarData';



const CalendarPopup = (
    { eventId, eventGroupId, eventRepeat,existAttendees,modalControl3 ,ticketId}
) => {

  const url = window.location.href;
  const urlObject = new URL(url);
  
  const port = urlObject.port;


  const { mutate: deleteEvent } = useDeleteEventData();
  const { mutate: bulkDeleteEvent } = useBulkDeleteEventData();

  const deleteEventData = (id,ticketId) => {
    const data = {
      id: id,
      ticketId:ticketId,
      port:port
    }
    
    console.log(data)
   deleteEvent(data,port);
   modalControl3(false)

  }


  const bulkDeleteEventData = (id,ticketId) => {
    const data = {
      id: id,
      ticketId:ticketId,
      port:port
    }
  
    console.log(data)
   bulkDeleteEvent(data,port);
   modalControl3(false)

  }
  const handleCancel=()=>{
    modalControl3(false)
  }




console.log(eventId)
console.log(eventGroupId)
console.log(modalControl3)
console.log(eventRepeat)

if(eventRepeat=='true'){
    return (
      <>
      <h1>この予定は繰り返し登録されています </h1>

      <Button onClick={()=>bulkDeleteEventData(eventGroupId,ticketId)} color='red'>全ての予定を削除する</Button>&emsp;
      <Button onClick={()=>deleteEventData(eventId,ticketId)} color='red'>この予定のみ削除する</Button> &emsp;
      <Button onClick={handleCancel} color='teal'>キャンセル</Button>


  </>  )
}

else if(existAttendees=='true'){
  return (
    <>
    <h1>この予定には、複数の出席者がいます </h1>

  <Button onClick={()=>bulkDeleteEventData(eventGroupId,ticketId)} color='red'>全ての予定を削除する</Button> &emsp;
  <Button onClick={handleCancel} color='teal'>キャンセル</Button>

  </>  )
}
else{
  return (
    <>
    <h1>削除しますか？ </h1>

    <Button onClick={()=>deleteEventData(eventId,ticketId)} color='red'>OK</Button> &emsp;
    <Button onClick={handleCancel} color='teal'>キャンセル</Button>

</>  )
}

}

export default CalendarPopup
