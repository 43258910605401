import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Chip } from "@mui/material";
import { TreeItem } from "@mui/lab";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FolderIcon from "@mui/icons-material/Folder";

const DragFolderItem = ({ id, text, index, moveItem }) => {
  const [, ref] = useDrag({
    type: "ITEM",
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: "ITEM",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      style={{
        opacity: 1,
        cursor: "move",
        padding: "8px",
        border: "1px solid gray",
        marginBottom: "4px",
        backgroundColor: "white",
      }}
    >
      <DragHandleIcon />
      <FolderIcon />
      {text}
    </div>
  );
};
export default DragFolderItem;
