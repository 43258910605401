import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Outlet } from "react-router";
import LoginContext from "../../context/LoginContext";
import { useRefresh } from "../hooks/useRefresh";

export const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefresh();
  const context = useContext(LoginContext);

  useEffect(() => {
    const verifyRefresh = async () => {
      try {
        await refresh();
      } catch (error) {
        console.log(error, "errror");
      } finally {
        setIsLoading(false);
      }
    };
    context.userId === "" ? verifyRefresh() : setIsLoading(false);
  }, []);

  return <>{isLoading ? <p>loading....</p> : <Outlet />}</>;
};
