import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useRef } from "react";
import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "10",
    fontSize: 11,
  },
}));
export default function TestFile({
  selected,
  handleSelected,

  ...props
}) {
  const options = props.list;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popAnchorEl, setPopAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState();
  const open = Boolean(anchorEl);
  const openPop = Boolean(popAnchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const reff = useRef(null);

  const handleMenuItemClick = (event, index) => {
    handleSelected(index);
    setAnchorEl(null);
  };
  var widd = (reff.current && reff.current.clientWidth) + "px" || "100%";

  const [age, setAge] = useState(0);

  const lisst = [
    {
      name: "ten",
      value: 0,
    },
    {
      name: "ttt",
      value: 1,
    },
    {
      name: "yyyy",
      value: 2,
    },
    {
      name: "uuuuuuuu",
      value: 3,
    },
  ];
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={(event) => {
            //console.log(event.target, "vvvvvvv");
            setAge(event.target.value);
          }}
          renderValue={(selected) => {
            //console.log(selected);
            return (
              <div>{lisst.find((obj) => obj.value === selected).name}</div>
            );
          }}
        >
          {lisst.map((data, index) => (
            <MenuItem value={data.value} key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="body">{data.name}</Typography>
                <LightTooltip
                  title={
                    <div
                      style={{
                        padding: "2%",
                        boxShadow: "0px 3px 10px #212121",
                        borderRadius: "10px",
                      }}
                      onMouseLeave={() => {
                        setPopAnchorEl(null);
                      }}
                    >
                      <Typography sx={{ p: 2 }}>{data.name}</Typography>
                      <Button
                        onClick={() => {
                          navigate("/customerDetail", {
                            state: {
                              customerId: 6,
                            },
                          });
                        }}
                      >
                        redirect
                      </Button>
                    </div>
                  }
                  placement="right"
                >
                  <span>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      sx={{
                        color: "#43a7ff",
                        "&:hover": {
                          backgroundColor: "#9ecdf6",
                          color: "#0069c6",
                        },
                      }}
                    >
                      <HelpIcon />
                    </IconButton>
                  </span>
                </LightTooltip>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List
        sx={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "4px",
          borderColor: "#000000de",
        }}
        ref={reff}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          {/* <ListItemText primary={selected} /> */}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listBox",
        }}
        sx={{ marginTop: "1%" }}
        PaperProps={{ sx: { width: `${widd}` } }}
      >
        <List>
          <ListItem
            key={0}
            disablePadding
            sx={{ background: `${selected === "None" ? "#e0f4e9" : ""}` }}
          >
            <ListItemButton
              role={undefined}
              onClick={(event) => handleMenuItemClick(event, "None")}
            >
              <ListItemText id={0} primary={"None"} />
            </ListItemButton>
          </ListItem>
          <div>
            {options.map((option, index) => (
              <div key={index}>
                <ListItem
                  key={index + 1}
                  secondaryAction={
                    <LightTooltip
                      title={
                        <div
                          style={{
                            padding: "2%",
                            boxShadow: "0px 3px 10px #212121",
                            borderRadius: "10px",
                          }}
                          onMouseLeave={() => {
                            setPopAnchorEl(null);
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            {option.name} The content of the Popover.
                            {option.value}
                          </Typography>
                          <Button
                            onClick={() => {
                              navigate("/customerDetail", {
                                state: {
                                  customerId: 6,
                                },
                              });
                            }}
                          >
                            {" "}
                            redirect
                          </Button>
                        </div>
                      }
                      placement="right"
                    >
                      <span>
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={(e) => {
                            setPopAnchorEl(e.currentTarget);
                            setSelectedIndex(option.name);
                          }}
                          onMouseEnter={() => {
                            setSelectedIndex(option.name);
                          }}
                          sx={{
                            color: "#43a7ff",
                            "&:hover": {
                              backgroundColor: "#9ecdf6",
                              color: "#0069c6",
                            },
                          }}
                        >
                          <HelpIcon />
                        </IconButton>
                      </span>
                    </LightTooltip>
                  }
                  disablePadding
                  sx={{
                    background: `${selected === option.name ? "#e0f4e9" : ""}`,
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={(event) => handleMenuItemClick(event, option.name)}
                  >
                    <ListItemText id={index + 1} primary={option.name} />
                  </ListItemButton>
                </ListItem>
              </div>
            ))}
          </div>
        </List>{" "}
      </Menu>
    </>
  );
}


var final = [
  ,
  {
    room_id: 218269784,
    name: "\u5c0f\u8c37\u91ce\u3000\u7d05\u5150",
  },
  {
    room_id: 218269773,
    name: "\u6c38\u5c71\u3000\u8fb0\u5b8f",
  },
  {
    room_id: 218269774,
    name: "\u6839\u672c\u3000\u5c06\u548c",
  },
  {
    room_id: 218269811,
    name: "\u672c\u6728\u3000\u5927\u53f2",
  },
  {
    room_id: 218269781,
    name: "\u5927\u77e2\u3000\u5e78\u5e73",
  },
  {
    room_id: 218269796,
    name: "\u7247\u5ca1\u3000\u6f84\u4eba",
  },
  {
    room_id: 242683612,
    name: "\u68ee\u5c71\u3000\u76f4\u7d00",
  },
  {
    room_id: 218269778,
    name: "\u68ee\u3000\u5178\u5b50",
  },
  {
    room_id: 218269806,
    name: "\u98ef\u68ee\u3000\u52c7\u8f1d",
  },
  {
    room_id: 218269777,
    name: "\u672c\u6728\u3000\u7fd4\u5e73",
  },
];
const fullFinalList = [
  {
    room_id: 218269784,
    name: "小谷野　紅子",
  },
  {
    room_id: 218269773,
    name: "永山　賀典",
  },
  {
    room_id: 218269774,
    name: "根本　将和",
  },
  {
    room_id: 218269811,
    name: "本木　大史",
  },
  {
    room_id: 218269781,
    name: "大磯　幸平",
  },
  {
    room_id: 218269796,
    name: "牧尾　潔人",
  },
  {
    room_id: 242683612,
    name: "松山　直紀",
  },
  {
    room_id: 218269778,
    name: "松　典子",
  },
  {
    room_id: 218269806,
    name: "飯松　勇賝",
  },
  {
    room_id: 218269777,
    name: "本木　寴平",
  },
];
