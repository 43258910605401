import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import CustomSelect from "../formikInputs/CustomSelect";
import CustomTextField from "../formikInputs/CustomTextField";
import GroupSelect from "../formikInputs/GroupSelect";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CategoryDataWithEdit from "./CategoryDataWithEdit";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "../commonComponents/DeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function CustomizeCategoryDetail(props) {
  const { t, i18n } = useTranslation();

  //   const formValidation = Yup.object().shape({
  //     id: Yup.string()
  //       .required("Required!")
  //       .min(2, "Too Short!")
  //       .max(10, "Too Long!"),
  //     password: Yup.string().required("Required!"),
  //   });

  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const location = useLocation();
  const context = useContext(LoginContext);

  const categoryId = location.state.categoryId;
  const [isLoading, setIsLoading] = useState(true);

  const [checkedHidden, setCheckedHidden] = React.useState(false);
  const [checkedMandatory, setCheckedMandatory] = React.useState(false); // for later user for mandatory

  const [initialValues, setInitialValues] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    context.setPageTitle("Customize Category Detail");
    async function getData() {
      const response = await axiosFetch.get(`/categoryById/${categoryId}`);
      console.log(response, "categoryById response");
      if (response.status === 200) {
        //  console.log(response.data,"rrrr");
        setInitialValues({ ...response.data, categoryDataNew: {} });
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const handleSubmit = async (values) => {
    console.log(values, "form values on submit");
    setEditLoading(true);
    var APIvalues = {
      ...values,
    };
    delete APIvalues.categoryId;
    delete APIvalues.registerUserId;
    delete APIvalues.registerDateTime;
    delete APIvalues.modifyDateTime;
    delete APIvalues.modifyUserId;
    delete APIvalues.categoryDataNew;
    console.log(APIvalues, "API values");
    try {
      const response = await axiosFetch.put(
        `/category/${values.categoryId}`,
        APIvalues
      );
      console.log("category edit response", response);
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `${t("Edit Done")}`,
      });
      // navigate("/customizeCategoryDetail", {
      //   state: { categoryId: values.categoryId },
      // });
      navigate(-1);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
    } finally {
      setEditDialogOpen(false);
      setEditLoading(false);
    }
  };
  const handleDelete = async (e) => {
    console.log(initialValues, "ppppp");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(
        `/category/${initialValues.categoryId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      navigate("/customizeCategoryTable");
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <div>
      {console.log(initialValues, "initial values here")}
      {/* <ButtonAppBar title="Customize Category Detail" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form id="formId">
          <Box
            sx={{
              alignItems: "center",
              px: "15%",
              py: "2%",
              // background: "red",
            }}
          >
            <DeleteDialog
              handleDialogOpen={(e) => {
                setDialogOpen(e);
              }}
              handleDelete={handleDelete}
              dialogOpen={dialogOpen}
              delLoading={delLoading}
            />
            <Dialog
              open={editDialogOpen}
              onClose={() => {
                setEditDialogOpen(false);
              }}
            >
              <LinearProgress
                color="success"
                sx={{ visibility: `${!editLoading ? "hidden" : ""}` }}
              />
              <DialogTitle id="alert-dialog-title">
                {t("Do you want to save changes?")}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setEditDialogOpen(false);
                  }}
                  fullWidth
                  startIcon={<CancelIcon />}
                >
                  {t("No")}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  autoFocus
                  fullWidth
                  form="formId"
                  startIcon={<CheckCircleIcon />}
                >
                  {t("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
            <Card elevation={4} sx={{ border: "10px solid #fff077" }}>
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={8}>
                    <CustomTextField
                      data={{
                        name: "categoryName",
                        label: `${t("Category Name")}`,
                      }}
                      //mode="read"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "categoryId",
                        label: `${t("Category ID")}`,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupSelect
                      name="customerGroup.groupId" //mode="read"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "visible",
                        label: `${t("Visibility")}`,
                        list: [
                          { name: `${t("visible")}`, value: true },
                          { name: `${t("hidden")}`, value: false },
                        ],
                      }}
                      //mode="read"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "multipleValue",
                        label: `${t("Multiple Value")}`,
                        list: [
                          { name: `${t("yes")}`, value: true },
                          { name: `${t("no")}`, value: false },
                        ],
                      }}
                      //mode="read"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{
                        name: "registerUserId",
                        label: `${t("Register User ID")}`,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      //type="submit"
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        setEditDialogOpen(true);
                      }}
                      fullWidth
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      variant="contained"
                      color="error"
                      //onClick={handleDelete}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      {t("Delete")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <Card elevation={4}>
              <CardContent>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom align="center">
                      {t("Category Preview")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        navigate("/newCategoryItem", {
                          state: { data: initialValues },
                        });
                      }}
                    >
                      {t("Create New Category Item")}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup
                      row
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedHidden}
                            onChange={(event) => {
                              setCheckedHidden(event.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        }
                        label={t("Show hidden")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedMandatory}
                            onChange={(event) => {
                              setCheckedMandatory(event.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        }
                        label={t("Show mandatory")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <CategoryDataWithEdit
                      categoryId={categoryId}
                      hiddenFlag={checkedHidden}
                      mandatoryFlag={checkedMandatory}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
