import React, { useEffect } from "react";
import { Button, Grid, Stack } from "@mui/material";
import { Formik, Form, useFormikContext, FormikContext } from "formik";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { AxiosFetch } from "../../AxiosFetch";
import LoginContext from "../../../context/LoginContext";
import CustForm from "../custForm/CustForm";
import DeleteDialog from "../../commonComponents/DeleteDialog";
import CategoryFormEdit from "./CategoryFormEdit";
import { LoadingButton } from "@mui/lab";

export default function CustomerEditNew() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Customer Edit");
  }, []);

  const location = useLocation();
  //const tempInitialValues = location.state.initialValues;
  console.log(location.state, "edit screen location values");
  const projectFlag = location.state.initialValues.project || { id: 0 };
  const initialValues = {
    ...location.state.initialValues,
    telephoneNew: (() => {
      var temp = [];
      location.state.initialValues.telephone.map((data) => {
        temp.push(data.telNum);
      });
      return temp;
    })(),
    project: projectFlag,
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  console.log(initialValues, "initial values");
  const formValidation = Yup.object().shape({
    project: Yup.object().shape({
      id: Yup.string()
        .required("This field is required")
        .test(
          "zero not allowed",
          "This field is required",
          (val) => Number(val) !== 0
        ),
    }),
    customerGroup: Yup.object().shape({
      groupId: Yup.string()
        .required("This field is required")
        .test(
          "zero not allowed",
          "This field is required",
          (val) => Number(val) !== 0
        ),
    }),
    // telephone: Yup.array().of(
    //   Yup.object().shape({
    //     telNum: Yup.string().matches(
    //       /^[1 2 3 4 5 6 7 8 9 0]+$/,
    //       "invalid Character"
    //     ),
    //   })
    // ),

    telephoneNew: Yup.array().of(
      Yup.string().matches(/^[1 2 3 4 5 6 7 8 9 0]+$/, "invalid Character")
    ),
    // customerFirstRuby: Yup.string()
    //   .matches(
    //     /^[ｱ	ｲ	ｳ	ｴ	ｵ ｧ	ｨ	ｩ	ｪ	ｫ ｶ	ｷ	ｸ	ｹ	ｺ ｶﾞ ｷﾞ	ｸﾞ	ｹﾞ	ｺﾞ ｻ	ｼ	ｽ	ｾ	ｿ ｻﾞ	ｼﾞ	ｽﾞ	ｾﾞ	ｿﾞ ﾀ	ﾁ	ﾂ	ﾃ	ﾄ ﾀﾞ	ﾁﾞ	ﾂﾞ	ﾃﾞ	ﾄﾞ ナ行	ﾅ	ﾆ	ﾇ	ﾈ	ﾉ ハ行	ﾊ	ﾋ	ﾌ	ﾍ	ﾎ ﾊﾞ	ﾋﾞ	ﾌﾞ	ﾍﾞ	ﾎﾞ ﾊﾟ	ﾋﾟ	ﾌﾟ	ﾍﾟ	ﾎﾟ ﾏ	ﾐ	ﾑ	ﾒ	ﾓ ﾔ	ﾕ	ﾖ	ﾗ	ﾘ	ﾙ	ﾚ	ﾛ ﾜ	ｦ	ﾝ	ﾞ	ﾟｯ	ｬ	ｭ	ｮ	ｰｳﾞ	｡	､	｢	｣]+$/,
    //     "Only Half width are allowed "
    //   )
    //   .nullable(),
    // customerLastRuby: Yup.string()
    //   .matches(
    //     /^[ｱ	ｲ	ｳ	ｴ	ｵ ｧ	ｨ	ｩ	ｪ	ｫ ｶ	ｷ	ｸ	ｹ	ｺ ｶﾞ ｷﾞ	ｸﾞ	ｹﾞ	ｺﾞ ｻ	ｼ	ｽ	ｾ	ｿ ｻﾞ	ｼﾞ	ｽﾞ	ｾﾞ	ｿﾞ ﾀ	ﾁ	ﾂ	ﾃ	ﾄ ﾀﾞ	ﾁﾞ	ﾂﾞ	ﾃﾞ	ﾄﾞ ナ行	ﾅ	ﾆ	ﾇ	ﾈ	ﾉ ハ行	ﾊ	ﾋ	ﾌ	ﾍ	ﾎ ﾊﾞ	ﾋﾞ	ﾌﾞ	ﾍﾞ	ﾎﾞ ﾊﾟ	ﾋﾟ	ﾌﾟ	ﾍﾟ	ﾎﾟ ﾏ	ﾐ	ﾑ	ﾒ	ﾓ ﾔ	ﾕ	ﾖ	ﾗ	ﾘ	ﾙ	ﾚ	ﾛ ﾜ	ｦ	ﾝ	ﾞ	ﾟｯ	ｬ	ｭ	ｮ	ｰｳﾞ	｡	､	｢	｣]+$/,
    //     "Only Half width are allowed "
    //   )
    //   .nullable(),
  });

  console.log(location.state, "edit screen state Values");
  const [groupId, setGroupId] = useState(initialValues.customerGroup.groupId);

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    //console.log(values, "live form values");
    useEffect(() => {
      setGroupId(values.customerGroup.groupId);
      if (groupId !== values.customerGroup.groupId) {
        setFieldValue("customerFirstUserId", "");
        setFieldValue("customerSecondUserId", "");
        setFieldValue("project.id", 0);
      }
    }, [values.customerGroup.groupId]);
    useEffect(() => {
      var temp = getAge(values.customerBirthday);
      //console.log(temp,'ageeeee');
      setFieldValue("customerAge", temp);
    }, [values.customerBirthday]);
    return null;
  };
  const handleDelete = async (e) => {
    console.log(initialValues, "ppppp");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(
        `/customers/soft/${initialValues.customerId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      navigate("/customerSearch");
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  const handleSubmit = async (values) => {
    console.log("submit");
    setLoading(true);
    var APIvalues = { ...values };
    var catObj = [];
    var itemIdvsId = {};
    const { categoryDataNew, categoryData, telephone, telephoneNew } =
      APIvalues;

    //map categoryDataNew to categoryData

    categoryData.forEach((data, index) => {
      itemIdvsId[`${data.itemId}`] = [];
    });
    categoryData.forEach((data, index) => {
      itemIdvsId[`${data.itemId}`].push(data.id);
    });
    //console.log(categoryData, "catttt", itemIdvsId);

    //repalce undefined values as ''
    Object.entries(categoryDataNew).forEach(([key, value]) => {
      //console.log(value,'vvvv');
      var length = value.length;
      for (let i = 0; i < length; i++) {
        if (typeof categoryDataNew[key][i] === "undefined") {
          categoryDataNew[key][i] = ""; //one conditon left for multiselect & filterselect (initialse as[] & {})
        }
      }
    });
    //console.log(categoryDataNew,'uiiuiuiuiui');
    Object.entries(categoryDataNew).forEach(([key, value], i) => {
      var itemIdvsIdCounter = 0;

      value.forEach((data, index) => {
        //if item is multiple selext => when value is array
        if (Array.isArray(data)) {
          if (data.length !== 0) {
            //below data is itemValue, if data empty then skip
            data.forEach((multiSelectItem, ii) => {
              catObj.push({
                id:
                  (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
                itemId: key,
                value: multiSelectItem, //save each multi select option, new format.
                tabValue: index + 1,
              });
              itemIdvsIdCounter++;
            });
          } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
            const foundObject = categoryData.find(
              (obj) => obj.itemId === Number(key) && obj.tabValue === index + 1
            );

            if (foundObject) {
              catObj.push({
                id: itemIdvsId[key][itemIdvsIdCounter],
                itemId: key,
                value: "", //convert multipleselect array to string
                tabValue: index + 1,
              });
            } else {
              itemIdvsIdCounter--;
            }
            itemIdvsIdCounter++;
          }
        }
        //check for filter select
        else if (typeof data === "object") {
          for (const [keyHere, valueHere] of Object.entries(data)) {
            //check for isEmpty
            if (keyHere && valueHere.length > 0) {
              valueHere.forEach((filterItem, ii) => {
                catObj.push({
                  id:
                    (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) ||
                    "",
                  itemId: key,
                  value: `${keyHere}:${filterItem}`, //save value using ":" new format.
                  tabValue: index + 1,
                });
                itemIdvsIdCounter++;
              });
              // catObj.push({
              //   id:
              //     (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
              //   itemId: key,
              //   value: JSON.stringify(data),
              //   tabValue: index + 1,
              // });
            } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
              const foundObject = categoryData.find(
                (obj) =>
                  obj.itemId === Number(key) && obj.tabValue === index + 1
              );
              if (foundObject) {
                catObj.push({
                  id: itemIdvsId[key][itemIdvsIdCounter],
                  itemId: key,
                  value: "", //convert filterselect object to string
                  tabValue: index + 1,
                });
              } else {
                itemIdvsIdCounter--;
              }
              itemIdvsIdCounter++;
            }
          }
        } else {
          if (data) {
            //below data is itemValue, if data empty then skip

            catObj.push({
              id: (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
              itemId: key,
              value: data || "",
              tabValue: index + 1,
            });
          } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
            const foundObject = categoryData.find(
              (obj) => obj.itemId === Number(key) && obj.tabValue === index + 1
            );

            if (foundObject) {
              catObj.push({
                id: itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter],
                itemId: key,
                value: "",
                tabValue: index + 1,
              });
            } else {
              itemIdvsIdCounter--;
            }
          }
          itemIdvsIdCounter++;
        }
      });
      //check for unused id
      if (itemIdvsId[key] && itemIdvsIdCounter < itemIdvsId[key].length) {
        for (
          let indexx = itemIdvsIdCounter;
          indexx < itemIdvsId[key].length;
          indexx++
        ) {
          catObj.push({
            id: itemIdvsId[key][indexx],
            itemId: key,
            value: "", //convert multipleselect array to string
            tabValue: 1,
          });
        }
      }
      //   var temp = categoryData.find((element) => element.itemId === Number(key));

      //   if (temp) {
      //     if (Array.isArray(value) && value.length !== 0)
      //       tempCatObj.push({ ...temp, value: JSON.stringify(value) });
      //     else if (value !== "" && typeof value !== "object")
      //       tempCatObj.push({ ...temp, value: value });
      //   } else {
      //     if (Array.isArray(value) && value.length !== 0)
      //       tempCatObj.push({
      //         id: "",
      //         itemId: Number(key),
      //         value: JSON.stringify(value),
      //       });
      //     else if (value !== "" && typeof value !== "object")
      //       tempCatObj.push({ id: "", itemId: Number(key), value: value });
      //   }
    });
    delete APIvalues.categoryData;
    delete APIvalues.telephoneNew;
    delete APIvalues.categoryDataNew;
    APIvalues["categoryData"] = [...catObj];
    APIvalues.project = { id: values.project.id };
    // APIvalues.telephone[0]={telNum:values.telephone[0].telNum};
    //console.log(itemIdvsId,catObj,'yyyyyyyy');
    let tempTelephone = [];
    telephoneNew.map((data, index) => {
      tempTelephone.push({ telNum: data });
    });
    APIvalues["telephone"] = tempTelephone;
    if (APIvalues.contentsOfConsultation) {
      APIvalues.contentsOfConsultation = JSON.stringify(
        APIvalues.contentsOfConsultation
      );
    }

    if (APIvalues.preferredContactTime) {
      APIvalues.preferredContactTime = JSON.stringify(
        APIvalues.preferredContactTime
      );
    }

    console.log(APIvalues, "API values");
    try {
      const custResponse = await axiosFetch.put(
        `/customers/${values.customerId}`,
        APIvalues
      );
      // if (custResponse.status == 200) {
      //   window.alert("Customer Edit done");
      // }
      {
        custResponse &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${t("Edit Done")}`,
          });
      }
      console.log(custResponse, "edit cust response");
      navigate("/customerDetail", {
        state: {
          customerId: values.customerId,
        },
      });
      setLoading(false);
    } catch (error) {
      //alert("error", error);
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#f0e68c",
        height: "100%",
        paddingBottom: "3%",
        pointerEvents: `${loading ? "none" : "auto"}`,
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <DeleteDialog
            handleDialogOpen={handleDialogOpen}
            handleDelete={handleDelete}
            dialogOpen={dialogOpen}
            delLoading={delLoading}
          />
          <FormObserver />
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} md={5.5}>
              <CustForm
                groupId={groupId}
                originalGroup={initialValues.customerGroup.groupId}
                type="edit"
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              {/* <CategoryForm groupId={groupId} /> */}
              <FormikContext.Consumer>
                {(contextFormik) => (
                  <CategoryFormEdit
                    itemsDataArray={initialValues.categoryData}
                    groupId={groupId}
                    originalGroupId={initialValues.customerGroup.groupId}
                    originalCategoryDataNew={initialValues.categoryDataNew}
                    contextFormik={contextFormik}
                  />
                )}
              </FormikContext.Consumer>

              <br />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "error.light" }}
                  color="error"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    navigate(-1);
                  }}
                  fullWidth
                >
                  {t("Cancel")}
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  size="large"
                  type="submit"
                  startIcon={<SaveIcon />}
                  fullWidth
                >
                  {t("Save")}
                </LoadingButton>
                <Button
                  size="large"
                  variant="contained"
                  color="error"
                  //onClick={handleDelete}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  startIcon={<DeleteIcon />}
                  fullWidth
                >
                  {t("Delete")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
}
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age || "";
}
// Object.entries(categoryDataNew).forEach(([key, value], i) => {
//   var itemIdvsIdCounter = 0;

//   value.forEach((data, index) => {
//     //if item is multiple selext => when value is array
//     if (Array.isArray(data)) {
//       if (data.length !== 0) {
//         //below data is itemValue, if data empty then skip
//         data.forEach((multiSelectItem,ii)=>{
//           catObj.push({
//             id: (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
//             itemId: key,
//             value: multiSelectItem,//save each multi select option, new format.
//             tabValue: index + 1,
//           });
//           itemIdvsIdCounter++;
//         })
//         // catObj.push({
//         //   id: (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
//         //   itemId: key,
//         //   value: JSON.stringify(data), //convert multipleselect array to string
//         //   tabValue: index + 1,
//         // });
//       } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
//         const foundObject = categoryData.find(
//           (obj) => obj.itemId === Number(key) && obj.tabValue === index + 1
//         );

//         if (foundObject) {
//           catObj.push({
//             id: itemIdvsId[key][itemIdvsIdCounter],
//             itemId: key,
//             value: JSON.stringify([]), //convert multipleselect array to string
//             tabValue: index + 1,
//           });
//         } else {
//           itemIdvsIdCounter--;
//         }
//       }
//     }
//     //check for filter select
//     else if (typeof data === "object") {
//       for (const [keyHere, valueHere] of Object.entries(data)) {
//         //check for isEmpty
//         if (keyHere && valueHere.length !== 0) {
//           catObj.push({
//             id:
//               (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
//             itemId: key,
//             value: JSON.stringify(data),
//             tabValue: index + 1,
//           });
//         } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
//           const foundObject = categoryData.find(
//             (obj) =>
//               obj.itemId === Number(key) && obj.tabValue === index + 1
//           );
//           if (foundObject) {
//             catObj.push({
//               id: itemIdvsId[key][itemIdvsIdCounter],
//               itemId: key,
//               value: JSON.stringify({}), //convert filterselect object to string
//               tabValue: index + 1,
//             });
//           } else {
//             itemIdvsIdCounter--;
//           }
//         }
//       }
//     } else {
//       if (data) {
//         //below data is itemValue, if data empty then skip

//         catObj.push({
//           id: (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) || "",
//           itemId: key,
//           value: data || "",
//           tabValue: index + 1,
//         });
//       } else if (itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter]) {
//         const foundObject = categoryData.find(
//           (obj) => obj.itemId === Number(key) && obj.tabValue === index + 1
//         );

//         if (foundObject) {
//           catObj.push({
//             id: itemIdvsId[key] && itemIdvsId[key][itemIdvsIdCounter],
//             itemId: key,
//             value: "",
//             tabValue: index + 1,
//           });
//         } else {
//           itemIdvsIdCounter--;
//         }
//       }
//     }
//     itemIdvsIdCounter++;
//   });

//   //   var temp = categoryData.find((element) => element.itemId === Number(key));

//   //   if (temp) {
//   //     if (Array.isArray(value) && value.length !== 0)
//   //       tempCatObj.push({ ...temp, value: JSON.stringify(value) });
//   //     else if (value !== "" && typeof value !== "object")
//   //       tempCatObj.push({ ...temp, value: value });
//   //   } else {
//   //     if (Array.isArray(value) && value.length !== 0)
//   //       tempCatObj.push({
//   //         id: "",
//   //         itemId: Number(key),
//   //         value: JSON.stringify(value),
//   //       });
//   //     else if (value !== "" && typeof value !== "object")
//   //       tempCatObj.push({ id: "", itemId: Number(key), value: value });
//   //   }
// });
