import {
  Checkbox,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { AxiosFetch } from "../../AxiosFetch";
import CustomSelect from "../../formikInputs/CustomSelect";
import CustomTextField from "../../formikInputs/CustomTextField";
import TransferList from "./TransferList";
import { useTranslation } from "react-i18next";
import PhaseSelect from "../customerDetail/Call Log/helper/PhaseSelect";
import CallerSelect from "../customerDetail/Call Log/helper/CallerSelect";
import TitleSelect from "../customerDetail/Call Log/helper/TitleSelect";
import CustomCheckbox from "../../formikInputs/CustomCheckbox";

export default function CallLog() {
  const { t, i18n } = useTranslation();

  const axiosFetch = AxiosFetch();
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [personName, setPersonName] = useState([]);
  const [groupName, setGroupName] = useState([]);
  const [userList, setUserList] = useState([]);
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const testList = [
    { value: 10, name: "ten" },
    { value: 20, name: "twenty" },
  ];
  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/callLogGroup`);
      if (response.status === 200) {
        //console.log(response, "response");
        setAllData(response.data);
        var temp = [];
        response.data.map((data, index) => {
          temp[index] = data.name;
        });
        setGroupName(temp);
      }
      setIsLoading(false);
      try {
        const response = await axiosFetch.get(`/user`);
        var arr = [];
        response.data.map((data, index) => {
          arr.push({
            value: data.userId,
            name: data.lastName + " " + data.firstName,
          });
        });
        setUserList(arr);
        //console.log(response,'rrr');
      } catch (error) {
        alert(error);
      }
    }
    getData();
  }, []);

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      // console.log(values.custData.customerGroupId);
      //console.log("yess");
    }, []);
    return null;
  };

  return isLoading ? (
    <>loading...</>
  ) : (
    <>
      <FormObserver />
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5" color="primary.dark">
            {t("Topic Search")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CustomCheckbox
            data={{
              name: "custData.topicEmpty",
              label: `${t("No Call Log")}`,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("Topic Creation Date")}</Typography>
        </Grid>

        <Grid item xs={3}>
          <CustomTextField
            type="date"
            data={{
              name: "custData.topic[0].registerDateTime",
              label: `${t("Start Date")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            type="date"
            data={{
              name: "custData.topic[0].registerDateTimeEnd",
              label: `${t("End Date")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            data={{ name: "custData.topic[0].title", label: `${t("Title")}` }}
            fullWidth
          />
          {/* <TitleSelect name="custData.topic[0].title" /> */}
        </Grid>
        <Grid item xs={3}>
          <CallerSelect name="custData.topic[0].caller" />
        </Grid>

        <Grid
          item
          xs={12}
          //sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="body1">{t("Topic Last Update Date")}</Typography>
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            type="date"
            data={{
              name: "custData.topic[0].modifyDateTime",
              label: `${t("Start Date")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            type="date"
            data={{
              name: "custData.topic[0].modifyDateTimeEnd",
              label: `${t("End Date")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            data={{
              name: "custData.topic[0].modifyUserId",
              label: `${t("Last Updated by")}`,
              list: userList,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {/* <CustomSelect
            data={{
              name: "custData.topic[0].phase",
              label: `${t("Ticket Phase")}`,
              list: testList,
            }}
          /> */}
          <PhaseSelect name="custData.topic[0].phase" />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            {t("Call Log Search")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t("Last Call Date")}</Typography>
        </Grid> */}
        {/* <Grid item xs={2.5}>
          <CustomTextField
            type="date"
            data={{ name: "cc", label: `${t("Start Date")}` }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}
        {/* <Grid item xs={2.5}>
          <CustomTextField
            type="date"
            data={{ name: "cc", label: `${t("End Date")}` }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}

        {/* <Grid item xs={2.5}>
          <CustomSelect
            data={{
              name: "namehere",
              label: `${t("Phone Type")}`,
              list: testList,
            }}
          />
        </Grid> */}

        {/* <Grid item xs={2}>
              <Typography variant="h5" color="primary.dark">
                Final Situation
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <CustomMultiSelectCheck
                data={{
                  name: "namehere2",
                  label: `${t()}`"Call Flag Group",
                  list: testList,
                }}
              />
            </Grid> */}
        <Grid item xs={12}>
          <Typography variant="body1">{t("Ticket Next Call Date")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="datetime-local"
            data={{
              name: "custData.topic[0].tickets[0].nextCallDate",
              label: `${t("Next Call Date Start")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="datetime-local"
            data={{
              name: "custData.topic[0].tickets[0].nextCallDateEnd",
              label: `${t("Next Call Date End")}`,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5" color="primary.dark">
            {t("Final Situation")}
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
              <CustomMultiSelectCheck
                data={{
                  name: "namehere2",
                  label: `${t()}`"Call Flag Group",
                  list: testList,
                }}
              />
            </Grid> */}
        <Grid item xs={12}>
          <InputLabel id="demo-label">{t("Call Flag Group")}</InputLabel>
          <Select
            labelId="demo-label"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            fullWidth
          >
            {groupName.map((data) => (
              <MenuItem key={data} value={data}>
                <Checkbox checked={personName.indexOf(data) > -1} />
                <ListItemText primary={data} />
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TransferList groupList={personName} allData={allData} />
        </Grid>
      </Grid>
    </>
  );
}
