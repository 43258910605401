import "./App.css";
import React, { useState, Suspense } from "react";
// import { useTranslation } from "react-i18next";
import { ThemeProvider, colors, Button } from "@mui/material";
import { createTheme } from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
// import ReactDOM from 'react-dom/client';
// import NewCustomerScreenIndex from "./components/newcustomer/NewCustomerIndex";
import CustomizeCategory from "./components/customizeCategory/CustomizeCategory";
import CreateNewCategory from "./components/customizeCategory/CreateNewCategory";
import CustomizeCategoryDetail from "./components/customizeCategory/CustomizeCategoryDetail";
import CategoryForm from "./components/customerRelated/categoryForm/CategoryForm";

import CustomerSearchScreenIndex from "./components/customerRelated/customerSearch/CustomerSearchIndex";
// import Home from "./components/home/Home";
import ButtonAppBar from "./components/customerRelated/Appbar";
import { Routes, Route, useNavigate, Link } from "react-router-dom";

import CustomerDetails from "./components/customerRelated/customerDetail/CustomerDetails";
import Test2 from "./components/testFolder/Test2";
import CustomerEdit from "./components/customerEdit/CustomerEdit";
import LoginIndex from "./components/login/LoginIndex";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import LoginState from "./context/LoginState";
import CreateNewUser from "./components/userRelated/CreateNewUser";
import UserSearch from "./components/userRelated/UserSearch";
import CreateNewCustomerGroup from "./components/cutomerGroupRelated/CreateNewCustomerGroup";
import CreateNewCustomerProject from "./components/customerProjectRelated/CreateNewCustomerProject";
import UserDetail from "./components/userRelated/UserDetail";
import UserEdit from "./components/userRelated/UserEdit";
import CustomerGroupTable from "./components/cutomerGroupRelated/CustomerGroupTable";
import CustomerDelete from "./components/customerRelated/customerDelete"
import Import from "./components/import/Import";
import CustomerGroupDetail from "./components/cutomerGroupRelated/CustomerGroupDetail";
import EditCustomerGroup from "./components/cutomerGroupRelated/EditCustomerGroup";
import NewCustomer from "./components/customerRelated/NewCustomer";
import CustomizeCategoryTable from "./components/customizeCategory/CustomizeCategoryTable";
import CustomizeCategoryEdit from "./components/customizeCategory/CustomizeCategoryEdit";
import CategoryItemTable from "./components/customizeCategory/customizeItemsRelated/CategoryItemTable";
import CreateNewItem from "./components/customizeCategory/customizeItemsRelated/CreateNewItem";
import CategoryItemDetail from "./components/customizeCategory/customizeItemsRelated/CategoryItemDetail";
import CustomerProjecTable from "./components/customerProjectRelated/CustomerProjectTable";
import CustomerProjectDetail from "./components/customerProjectRelated/CustomerProjectDetail";
import CallLogIndex from "./components/callLogSettings/CallLogIndex";
import Layout from "./components/layout/Layout";
// import SideBar from "./components/sideBar/SideBar";
import CustomerProjectDistribution from "./components/customerRelated/customerSearch/customerDistribution/CustomerProjectDistribution";
import CustomerUserDistribution from "./components/customerRelated/customerSearch/customerDistribution/CustomerUserDistribution";
import CallLog from "./components/customerRelated/customerSearch/CallLog";
import Export from "./components/export/Export";
import TestFile from "./components/testFolder/TestFile";
import TestForTranslation from "./components/testFolder/TestForTranslation";
import Calendar from "./components/Calendar/Calendar";
import { QueryClient, QueryClientProvider } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { enUS, jaJP } from "@mui/x-data-grid";
import { PersistLogin } from "./components/login/PersistLogin";
import TestWithFormik from "./components/testFolder/TestWithFormik";
import CallLogHistorySearch from "./components/callLogSettings/CallLogHistorySearch";
import UserChangePassword from "./components/userRelated/UserChangePassword";
import CustomerEditNew from "./components/customerRelated/customerEdit/CustomerEditNew";
import CategoryItemEdit from "./components/customizeCategory/customizeItemsRelated/CategoryItemEdit";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { DoNotCallIndex } from "./components/doNotCallList/DoNotCallIndex";
import { BookmarkURL } from "./components/bookmark/BookmarkURL";
import { SNSindex } from "./components/sns/chatwork/SNSindex";
import { AppProvider } from "./context/AppContext";
const themex = createTheme(
  {
    palette: {
      primary: {
        main: "#07a74a",
        light: "#07a74a42",
        dark: colors.green[800],
      },
      secondary: {
        //main: "#00b0ff",
        main: "#9c27b0",
        light: "#ba68c8",
        dark: "#7b1fa2",
      },
      complementary: {
        main: "#bd066a",
        dark: "#a70764",
        light: "#ed8dbc",
        contrastText: "#fff",
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#b0f097",
            },
          },
        },
      },
    },
  },
  jaJP
);
function App() {
  const queryClient = new QueryClient();
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            margin: "25%",
          }}
        >
          <h2>{t("Something went wrong:")}</h2>
          <p>{error.message}</p>
          <button onClick={resetErrorBoundary}>{t("Try again")}</button>
          <br />
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {t("Go to login page")}
          </button>
        </div>
      )}
      onReset={() => window.location.reload()}
    >
      <Suspense fallback="loading">
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={themex}>
            <AppProvider>
              <LoginState>
                <Layout>
                  <QueryClientProvider client={queryClient}>
                    <Routes>
                      <Route exact path="/" element={<LoginIndex />} />

                      {/* <Route exact path="/testPage" element={<Test2/>}/> */}
                      <Route element={<PersistLogin />}>
                        <Route
                          element={<PrivateRoutes allowedRoles={[1, 2, 3]} />}
                        >
                          <Route
                            exact
                            path="/customerSearch"
                            element={<CustomerSearchScreenIndex />}
                          />
                          <Route
                            exact
                            path="/newCustomer"
                            element={<NewCustomer />}
                          />
                          <Route
                            exact
                            path="/customerDetail"
                            element={<CustomerDetails />}
                          />
                          <Route
                            exact
                            path="/CustomerEdit"
                            //element={<CustomerEdit />}
                            element={<CustomerEditNew />}
                          />{" "}
                          <Route
                            exact
                            path="/userSearch"
                            element={<UserSearch />}
                          />
                          <Route
                            exact
                            path="/newUser"
                            element={<CreateNewUser />}
                          />
                          <Route
                            exact
                            path="/userDetail"
                            element={<UserDetail />}
                          />
                          <Route exact path="/userEdit" element={<UserEdit />} />
                          <Route
                            exact
                            path="/userChangePassword"
                            element={<UserChangePassword />}
                          />
                          <Route
                            exact
                            path="/callLogSettings"
                            element={<CallLogIndex />}
                          />
                          <Route
                            exact
                            path="/callLogHistorySearch"
                            element={<CallLogHistorySearch />}
                          />
                          <Route exact path="/calendar" element={<Calendar />} />
                          <Route
                            exact
                            path="/doNotCallList"
                            element={<DoNotCallIndex />}
                          />
                          <Route
                            exact
                            path="/bookmarkURL"
                            element={<BookmarkURL />}
                          />
                          <Route exact path="/SNS" element={<SNSindex />} />
                        </Route>

                        <Route element={<PrivateRoutes allowedRoles={[1, 3]} />}>
                          <Route
                            exact
                            path="/customerUserDistribution"
                            element={<CustomerUserDistribution />}
                          />
                        </Route>
                        <Route element={<PrivateRoutes allowedRoles={[3]} />}>
                          <Route exact path="/form" element={<CategoryForm />} />
                          {/* <Route
                        exact
                        path="/customizeCategory"
                        element={<CustomizeCategory />}
                      /> */}
                          <Route
                            exact
                            path="/customizeCategoryTable"
                            element={<CustomizeCategoryTable />}
                          />
                          <Route
                            exact
                            path="/newCustomizeCategory"
                            element={<CreateNewCategory />}
                          />
                          <Route
                            exact
                            path="/newCategoryItem"
                            element={<CreateNewItem />}
                          />
                          <Route
                            exact
                            path="/categoryItemTable"
                            element={<CategoryItemTable />}
                          />
                          {/* <Route
                        exact
                        path="/categoryItemDetail"
                        element={<CategoryItemDetail />}
                      /> */}
                          <Route
                            exact
                            path="/categoryItemEdit"
                            element={<CategoryItemEdit />}
                          />
                          <Route
                            exact
                            path="/customizeCategoryDetail"
                            element={<CustomizeCategoryDetail />}
                          />
                          {/* <Route
                          exact
                          path="/customizeCategoryEdit"
                          element={<CustomizeCategoryEdit />}
                        /> */}

                          <Route
                            exact
                            path="/customerUserDistribution"
                            element={<CustomerUserDistribution />}
                          />
                          <Route
                            exact
                            path="/customerProjectDistribution"
                            element={<CustomerProjectDistribution />}
                          />

                          <Route
                            exact
                            path="/newCustomerGroup"
                            element={<CreateNewCustomerGroup />}
                          />
                          <Route
                            exact
                            path="/customerDelete"
                            element={<CustomerDelete />}
                          />
                          <Route
                            exact
                            path="/customerGroupTable"
                            element={<CustomerGroupTable />}
                          />
                          <Route
                            exact
                            path="/customerGroupDetail"
                            element={<CustomerGroupDetail />}
                          />
                          <Route
                            exact
                            path="/editCustomerGroup"
                            element={<EditCustomerGroup />}
                          />
                          <Route
                            exact
                            path="/newCustomerProject"
                            element={<CreateNewCustomerProject />}
                          />
                          <Route
                            exact
                            path="/customerProjectTable"
                            element={<CustomerProjecTable />}
                          />
                          <Route
                            exact
                            path="/customerProjectDetail"
                            element={<CustomerProjectDetail />}
                          />
                          <Route
                            exact
                            path="/customerExport"
                            element={<Export />}
                          />

                          {/* <Route
                        exact
                        path="/callLogSettings"
                        element={<CallLogIndex />}
                      /> */}

                          <Route exact path="/testPage" element={<TestFile />} />
                          <Route exact path="/testPage2" element={<Test2 />} />
                          <Route
                            exact
                            path="/testFormik"
                            element={<TestWithFormik />}
                          />
                          <Route
                            exact
                            path="/testPage3"
                            element={<TestForTranslation />}
                          />

                          <Route exact path="/callLog" element={<CallLog />} />
                          <Route exact path="/import" element={<Import />} />
                        </Route>
                      </Route>
                      <Route
                        path="*"
                        element={<ButtonAppBar title="Error! Page not found" />}
                      />
                    </Routes>
                  </QueryClientProvider>
                </Layout>
              </LoginState>
            </AppProvider>
          </ThemeProvider>
        </DndProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
