import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { ListItemSecondaryAction, Switch, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useAppContext } from "../../context/AppContext";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function FlexSideBar({
  handleDrawerClose,
  open,
  drawerWidth,
  theme,
}) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { userRole, ...context } = useContext(LoginContext);
  const temp = sessionStorage.getItem("lang") === "en" ? false : true;
  const [checkedLang, setCheckedLang] = useState(temp);

  const { clearAppState } = useAppContext();

  const handleLink = (link) => {
    navigate(link);
    setListSelectFlag(link);
  };
  const [listSelectFlag, setListSelectFlag] = useState(
    window.location.pathname
  );
  useEffect(() => {
    if (context.pageTitle === "Customer Search") {
      setListSelectFlag("/customerSearch");
    }
  }, [context.pageTitle]);

  const list = () => (
    <>
      <Box role="presentation">
        <List>
          <ListItemButton
            onClick={(e) => {
              handleLink("/customerSearch");
            }}
            selected={listSelectFlag === "/customerSearch" ? true : false}
          >
            <ListItemText primary={t("Customer Search Screen")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t(
                  "It is mainly used for business purposes such as customer list search, list assignment, creation of new customers (one customer at a time), and CSV output of search results."
                )}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            onClick={() => handleLink("/userSearch")}
            selected={listSelectFlag === "/userSearch" ? true : false}
            //sx={{display:'none'}}
          >
            <ListItemText primary={t("User Search")} />

            <ListItemSecondaryAction>
              <Tooltip
                title={t("Add, edit, and delete user information.")}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleLink("/callLogSettings")}
            selected={listSelectFlag === "/callLogSettings" ? true : false}
          >
            <ListItemText primary={t("Call Log Settings")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t(
                  "Set call flags, phases, etc. used in customer response management."
                )}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>
          {userRole === 3 ? (
            <>
              <ListItemButton
                onClick={() => handleLink("/customerDelete")}
                selected={listSelectFlag === "/customerDelete" ? true : false}
              >
                <ListItemText primary={t("Customer Delete")} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      "Recover deleted customers or delete customers permanently."
                    )}
                    fontSize="small"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>

              <ListItemButton
                onClick={() => handleLink("/customerGroupTable")}
                selected={
                  listSelectFlag === "/customerGroupTable" ? true : false
                }
              >
                <ListItemText primary={t("Customer Group Table")} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      "Create, edit, and delete customer groups. Please note that when creating a new user, you will need to set access rights for the user again."
                    )}
                    fontSize="small"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>

              <ListItemButton
                onClick={() => handleLink("/customizeCategoryTable")}
                selected={
                  listSelectFlag === "/customizeCategoryTable" ? true : false
                }
              >
                <ListItemText primary={t("Category Settings")} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      "You can freely create and edit attributes to be assigned to customer data."
                    )}
                    fontSize="small"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>

              <ListItemButton
                onClick={() => handleLink("/customerProjectTable")}
                selected={
                  listSelectFlag === "/customerProjectTable" ? true : false
                }
              >
                <ListItemText primary={t("Customer Project Table")} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      `You can create "projects" for each group and differentiate the lists.`
                    )}
                    fontSize="small"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>

              <ListItemButton
                onClick={() => handleLink("/import")}
                selected={listSelectFlag === "/import" ? true : false}
              >
                <ListItemText primary={t("Import")} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={t(
                      "Import new customer data in bulk. You can import from CSV file."
                    )}
                    fontSize="small"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>
            </>
          ) : null}
          <ListItemButton
            onClick={() => handleLink("/callLogHistorySearch")}
            selected={listSelectFlag === "/callLogHistorySearch" ? true : false}
          >
            <ListItemText primary={t("Call Log History Search")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t(
                  "This is a search screen specialized for call history data."
                )}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            onClick={() => handleLink("/Calendar")}
            selected={listSelectFlag === "/Calendar" ? true : false}
          >
            <ListItemText primary={t("Calendar")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t(
                  "Customers whose next call date is entered will be displayed on the calendar."
                )}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleLink("/doNotCallList")}
            selected={listSelectFlag === "/doNotCallList" ? true : false}
          >
            <ListItemText primary={t("Do Not Call List")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t(
                  "Systematically controls calls to set phone numbers and areas."
                )}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleLink("/bookmarkURL")}
            selected={listSelectFlag === "/bookmarkURL" ? true : false}
          >
            <ListItemText primary={t("Bookmark")} />
            <ListItemSecondaryAction>
              <Tooltip
                title={t("You can register sites that you use frequently.")}
                fontSize="small"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton>

          {/* <ListItemButton
            onClick={() => handleLink("")}
            selected={listSelectFlag === "" ? true : false}
          >
            <ListItemText primary={t("Login Page")} />
          <ListItemSecondaryAction>
              <Tooltip title={t("Tooltip Text")} fontSize='small'>
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton> */}
          <br />
          <br />
          <ListItemButton
            onClick={() => {
              handleDrawerClose();
              sessionStorage.clear();
              clearAppState();
              // context.setToken(false);
              // context.setUserId("");
              context.resetToDefault();
              handleLink("");
            }}
            selected={listSelectFlag === "" ? true : false}
          >
            <ListItemText primary={t("Logout")} />
            <LogoutIcon />
          </ListItemButton>
          {/* <ListItemButton
            onClick={(e) => {
              handleLink("/testPage");
              console.log(e, "eeeee");
            }}
            selected={listSelectFlag === "/testPage" ? true : false}
          >
            <ListItemText primary={t("Test Page")} />
          <ListItemSecondaryAction>
              <Tooltip title={t("Tooltip Text")} fontSize='small'>
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemButton> */}
          <br />
          <br />
          <ListItemButton>
            <ListItemText primary="English" />
            <Switch
              checked={checkedLang}
              onChange={() => {
                checkedLang
                  ? i18n.changeLanguage("en")
                  : i18n.changeLanguage("jp");
                setCheckedLang(!checkedLang);
                sessionStorage.setItem("lang", `${checkedLang ? "en" : "jp"}`);
              }}
              // inputProps={{ "aria-label": "controlled" }}
            />
            <ListItemText primary="日本" />
          </ListItemButton>
        </List>
      </Box>
    </>
  );

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant={isMobile ? "temporary" : "persistent"}
      anchor="left"
      onClose={() => {
        handleDrawerClose();
      }}
      open={open}
    >
      <DrawerHeader>
        <div>{t("APP Name")}</div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>

      {list()}
    </Drawer>
  );
}
