import { Button, colors, Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "85vh",
  overflowY: "auto",
};
export const AlertModal = ({ open, handleOpen, data }) => {
  const { t } = useTranslation();
  const [showAllFlag, setShowAllFlag] = useState(false);

  return (
    <div>
      <Modal
        open={open}
        //onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={10}>
              <Typography
                id="modal-modal-title"
                variant="h3"
                align="center"
                color={"#f97f02"}
              >
                {t("Alert: Errors Found")}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => handleOpen(false)}
                color={"error"}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                {t("Close")}
              </Button>
            </Grid>
            {showAllFlag ? (
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setShowAllFlag(!showAllFlag);
                  }}
                  fullWidth
                  variant="contained"
                  color={"complementary"}
                >
                  {t("Reduce display")}
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {data.size !== 0 &&
              [...data].map(([key, value], j) => {
                if (showAllFlag) {
                  return (
                    <React.Fragment key={"key" + j}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          align="center"
                          bgcolor={colors.grey[400]}
                        >
                          {t("Row Number:")}
                          {key + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Column Name")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Value of the relevant cell")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Error details")}
                        </Typography>
                      </Grid>

                      {value.map((data, index) => {
                        return (
                          <React.Fragment key={index + "key" + j}>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                              >
                                {t(data.columnDisplayName)}
                              </Typography>
                            </Grid>

                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                                color={"error"}
                              >
                                {data.errorValue}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                              >
                                {t(data.reason)}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                } else if (j === 0) {
                  return (
                    <React.Fragment key={"yy" + j}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          align="center"
                          bgcolor={colors.grey[400]}
                        >
                          {t("Row Number:")}
                          {key + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Column Name")}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Value of the relevant cell")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          noWrap
                          align="center"
                          color={colors.blue[900]}
                        >
                          {t("Error details")}
                        </Typography>
                      </Grid>
                      {value.map((data, index) => {
                        return (
                          <React.Fragment key={"check" + index}>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                              >
                                {t(data.columnDisplayName)}
                              </Typography>
                            </Grid>

                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                                color={"error"}
                              >
                                {data.errorValue}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                align="center"
                              >
                                {t(data.reason)}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            setShowAllFlag(!showAllFlag);
                          }}
                          fullWidth
                          variant="outlined"
                          color={"complementary"}
                          disabled={data.size === 1}
                        >
                          {t("(... more errors) Show All")}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
