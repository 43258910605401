import { Box, Card, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneNumberIndex from "./phoneNumber/PhoneNumberIndex";
import PrefectureIndex from "./prefecture/PrefectureIndex";
export const DoNotCallIndex = () => {
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  return (
    <Card
      elevation={4}
      sx={{
        alignItems: "center",

        my: "1%",
        mx: "5%",
        paddingBottom: "0%",
      }}
    >
      {/* <Card elevation={0} sx={{ border: "10px solid #f6f6f6", padding: 2 }}> */}

      <Tabs
        value={tabValue}
        onChange={(e, n) => setTabValue(n)}
        variant="fullWidth"
        aria-label="scrollable auto tabs example"
        sx={{
          backgroundColor: "primary.light",
        }}
      >
        <Tab label={t("Area List")} />
        <Tab label={t("Telephone List")} />
      </Tabs>

      <div hidden={tabValue !== 0} style={{ padding: "1%", paddingTop: 15 }}>
        <PrefectureIndex />
      </div>
      <div hidden={tabValue !== 1} style={{ padding: "1%", paddingTop: 15 }}>
        <PhoneNumberIndex />
      </div>
      {/* </Card> */}
    </Card>
  );
};
