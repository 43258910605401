import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../../AxiosFetch";
import LoginContext from "../../../context/LoginContext";
import { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import DeleteDialog from "../../commonComponents/DeleteDialog";

export default function CustomerTable({ handleRowDelete, ...props }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const axiosFetch = AxiosFetch();
    const context = useContext(LoginContext);
    const [delLoading, setDelLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = (e) => {
        setDialogOpen(e);
    };
    const [selectRow, setSelectRow] = useState({});

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5 },
        { field: "isActive", headerName: "Active", flex: 0.5 },
        { field: "lastName", headerName: `${t("Last Name")}`, flex: 1 },
        { field: "firstName", headerName: `${t("First Name")}`, flex: 1 },
        { field: "groupName", headerName: `${t("Group Name")}`, flex: 1 },
        {
            field: "edit",
            headerName: `${t("Recover")}`,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const onClick = async(e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    console.log("Clicked Recover ", params.row);
                    try {
                        const response = await axiosFetch.put(`/customers/recover/${params.row.id}`);
                        {
                            response &&
                                context.setGlobalAlert({
                                    open: true,
                                    severity: "success",
                                    title: "success",
                                    message: `${response.data}`,
                                });
                        }

                    } catch (error) {
                        context.setGlobalAlert({
                            open: true,
                            severity: "error",
                            title: "error",
                            message: `${error.response.data.errorMessage}`,
                        });
                        console.log(error, "error");
                    }

                };
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={onClick}
                        startIcon={<EditIcon />}
                    >
                        {t("Recover")}
                    </Button>
                );
            },
        },
        {
            field: "delete",
            headerName: `${t("Delete")}`,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const onClick = async (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    setDialogOpen(true);
                    setSelectRow(params.row);
                };
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={onClick}
                        startIcon={<DeleteIcon />}
                    >
                        {t("Delete")}
                    </Button>
                );
            },
        },
    ];
    const handleDelete = async () => {
        setDelLoading(true);
        console.log(selectRow, "ppppp");
        try {
            const response = await axiosFetch.delete(`/customers/hardDelete/${selectRow.id}`);
            console.log(response, "rrrr");
            {
                response &&
                    context.setGlobalAlert({
                        open: true,
                        severity: "success",
                        title: "success",
                        message: `${response.data}`,
                    });
            }
            handleRowDelete(selectRow);
            setDelLoading(false);
        } catch (error) {
            context.setGlobalAlert({
                open: true,
                severity: "error",
                title: "error",
                message: `${error.response.data.errorMessage}`,
            });
            console.log(error, "error");
            setDelLoading(false);
        }
        setDialogOpen(false);
    };
    const rows = props.rows;
    return (
        <>
            <DeleteDialog
                handleDialogOpen={handleDialogOpen}
                handleDelete={handleDelete}
                dialogOpen={dialogOpen}
                delLoading={delLoading}
            />
            <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[10]}
                />
            </div>
        </>
    );
}
