import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import LoginContext from "../../../../context/LoginContext";
import { AxiosFetch } from "../../../AxiosFetch";
import CustomSelect from "../../../formikInputs/CustomSelect";
import GroupSelect from "../../../formikInputs/GroupSelect";
import UserSelectByGroup from "../../../formikInputs/UserSelectByGroup";
import CustomerTableForDistribution from "./CustomerTableForDistribution";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";
import { Stack } from "@mui/system";

export default function CustomerUserDistribution(props) {
  const CustomizedRadioGroup = ({ children, form, field, ...props }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    return (
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={(event) => {
          setFieldValue("settingType", event.currentTarget.value);
        }}
      >
        {children}
      </RadioGroup>
    );
  };
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Customer Assignment");
  }, []);
  // const custInformation = location.state.data;
  // console.log(custInformation,'iiii');
  const [custRows, setCustRows] = useState();
  if (typeof custRows === "undefined") {
    setCustRows(location.state.data);
  }

  const initialValues = {
    customerGroup: "",
    firstUserId: "",
    secondUserId: "",
    number: "",
    settingType: "manual",
  };
  const formValidation = Yup.object().shape({
    customerGroup: Yup.string().required(`${t("This field is required")}`),
  });

  const [user1List, setUser1List] = useState([]);
  const [marker, setMarker] = useState(false);
  const [settingType, setSettingType] = useState();
  const [finalList, setFinalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [number, setNumber] = useState("");
  const tt = useRef();

  async function getDataUser(props) {
    const APIvalues = {
      customerGroups: [
        {
          groupId: props,
        },
      ],
    };
    const response = await axiosFetch.post(
      "/userList",
      JSON.stringify(APIvalues)
    );
    return await response.data;
  }

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      var test = [];
      getDataUser(values.customerGroup).then((x) => {
        x.map((data, index) => {
          test[index + 2] = {
            value: data.userId,
            name: data.firstName + " " + data.lastName,
          };
        });
        test[0] = {
          value: "0",
          name: t("Not Specified"),
        };
        test[1] = {
          value: -1,
          name: t("Assign By Blank"),
        };
        if (JSON.stringify(user1List) === JSON.stringify(test)) return;
        setUser1List(test);
      });
    }, [values.customerGroup]);
    useEffect(() => {
      setSettingType(values.settingType);
      values.settingType === "numerical" ? setMarker(true) : setMarker(false);
      if (!marker) setNumber("");
    }, [values.settingType]);
    return null;
  };

  const handleSubmit = async (values) => {
    console.log(values, "vvvv", finalList);
    if (finalList.length === 0) {
      alert("No selection Made");
      return;
    }
    //check for single group
    // var errorFlag = false;
    // for (let i = 0; i < finalList.length; i++) {
    //   let found = custRows.find((e) => e.customerId === finalList[i]);
    //   console.log(found, "fffff");
    //   if (found.customerGroup.groupId !== values.customerGroup) {
    //     errorFlag = true;
    //     alert("group mismatch");
    //     break;
    //   }
    // }
    // if (errorFlag) return;

    setLoadingLogo(true);
    var APIvalues = {
      customerList: [...finalList],
      groupId: values.customerGroup,
      firstUserId: values.firstUserId,
      secondUserId: values.secondUserId,
    };
    console.log(APIvalues, "API values");
    if (values.settingType === "all") {
      try {
        const custResponse = await axiosFetch.post(
          `/customerUserId`,
          APIvalues
        );
        console.log(custResponse, "ressss");
        navigate(-1);
      } catch (err) {
        console.log(err.response.data, "err");
        alert(JSON.stringify(err.response.data, null, 4));
      }
    } else {
      try {
        const custResponse = await axiosFetch.post(
          `/customerUserId`,
          APIvalues
        );
        console.log(custResponse, "ressss");
        var newArr = [];
        Object.entries(custRows).map((data, index) => {
          var t = data[1].customerId;
          if (!finalList.includes(t)) newArr.push(data[1]);
        });
        setCustRows(newArr);
      } catch (err) {
        console.log(err.response.data, "err");
        alert(JSON.stringify(err.response.data, null, 4));
      }
    }
    setLoadingLogo(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingLogo}
            onClick={() => {
              setLoading(!loadingLogo);
            }}
          >
            <Typography variant="h2" gutterBottom>
              Loading...
            </Typography>
            <br />
            <CircularProgress color="inherit" />
          </Backdrop>
          <FormObserver loading={loading} />
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Card elevation={4}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <GroupSelect name="customerGroup" />
                    </Grid>
                    <Grid item xs={2}>
                      <CustomSelect
                        data={{
                          name: "firstUserId",
                          label: `${t("Select User One")}`,
                          list: user1List,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {loading ? (
                        <>loading</>
                      ) : (
                        <CustomSelect
                          data={{
                            name: "secondUserId",
                            label: `${t("Select User Two")}`,
                            list: user1List,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          {t("Settings")}
                        </FormLabel>
                        <Field
                          name="settingType"
                          component={CustomizedRadioGroup}
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label={t("All")}
                          />
                          <FormControlLabel
                            value="manual"
                            control={<Radio />}
                            label={t("Checked only")}
                          />
                          <FormControlLabel
                            value="numerical"
                            control={<Radio />}
                            label={t("Numerical input")}
                          />
                          {marker && (
                            <>
                              <TextField
                                type="number"
                                inputRef={tt}
                                defaultValue={number}
                                size="small"
                                required
                              />
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => {
                                  setNumber(tt.current.value);
                                }}
                              >
                                {t("select")}
                              </Button>
                            </>
                          )}
                        </Field>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack spacing={1}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          fullWidth
                        >
                          {t("Allocate")}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "error.light" }}
                          color="error"
                          size="medium"
                          onClick={() => {
                            navigate(-1);
                          }}
                          fullWidth
                        >
                          {t("Cancel")}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4} sx={{ minHeight: 500 }}>
                <CardContent>
                  <CustomerTableForDistribution
                    rows={custRows}
                    settingType={settingType}
                    setFinalList={(e) => setFinalList(e)}
                    number={number}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
}
