import {
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddToMyList } from "./AddToMyList";
import LoginContext from "../../../context/LoginContext";
import { AxiosFetch } from "../../AxiosFetch";
import DeleteDialog from "../../commonComponents/DeleteDialog";
import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarIcon from "@mui/icons-material/Star";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export const LinkChatworkAccount = () => {
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const context = useContext(LoginContext);

  const { userFirstName, userLastName, userId } = useContext(LoginContext);
  const [loading, setLoading] = useState(true);

  const [roomList, setRoomList] = useState([]);
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // const [cLoading, setCLoading] = useState(false);
  const [openAllListModal, setOpenAllListModal] = useState(false);

  async function refreshList() {
    try {
      const response = await axiosFetch.get("/chatworkRoomByUserId");
      //const response = await axiosFetch.get("/chatworkRoomByUas");
      console.log(response, "get room list response");
      setRoomList(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error, "get room list api error");
    }
  }
  useEffect(() => {
    refreshList();
  }, []);

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  const handleDelete = async (e) => {
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(`/chatworkRoom/${deleteId}`);
      console.log(response, "Room delete API response");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `Delete Successful`,
          });
      }
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          error.response ||
          error.response.data ||
          error.response.data.errorMessage
        }`,
      });
      console.log(error, "error");
    } finally {
      refreshList();
      setDelLoading(false);
      handleDialogOpen(false);
    }
  };

  const CustomRow = ({ data, index }) => {
    const [hover, setHover] = useState(false);

    return (
      <Grid
        container
        spacing={1}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => setHover(false)}
        sx={{ "&:hover": { backgroundColor: colors.grey[100] } }}
      >
        <Grid item xs={0.5}>
          <Typography align="center" variant="h6">
            {index + 1}
          </Typography>
        </Grid>

        <Grid item xs={8} md={9}>
          <Typography align="center" variant="h6">
            {data.roomName}
          </Typography>
        </Grid>
        <Grid item xs={2} md={2} display="flex" justifyContent={"end"}>
          <IconButton
            aria-label="delete"
            sx={{
              // visibility: !hover ? "hidden" : "visible",
              "&:hover": {
                color: "error.main",
              },
            }}
            onClick={(e) => {
              setDeleteId(data.id);
              setDialogOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };
  const column1 = useMemo(
    () =>
      roomList.map((data, index) =>
        index % 2 === 0 ? (
          <CustomRow data={data} index={index} key={index + "uni"} />
        ) : null
      ),
    [roomList]
  );
  const column2 = useMemo(
    () =>
      roomList.map((data, index) =>
        index % 2 !== 0 ? (
          <CustomRow data={data} index={index} key={index + "uni"} />
        ) : null
      ),
    [roomList]
  );
  return loading ? (
    <>loading....</>
  ) : (
    <>
      <Box
        sx={{
          px: "2.5%",
          py: "1%",
          // pointerEvents: `${cLoading ? "none" : "auto"}`,
          // filter: `${cLoading ? "brightness(80%)" : ""}`,
        }}
      >
        {/* <CircularProgress
          sx={{
            position: "absolute",
            left: "50%",
            top: "40%",
            display: cLoading ? "block" : "none",
            zIndex: 10,
          }}
          color="secondary"
          size={50}
        /> */}
        <DeleteDialog
          handleDialogOpen={handleDialogOpen}
          handleDelete={handleDelete}
          dialogOpen={dialogOpen}
          delLoading={delLoading}
        />
        <Typography variant="h4" align="center" gutterBottom>
          {t("Destination Edit Screen")}
        </Typography>
        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <Grid container spacing={0.5}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="h5" color={colors.blue[900]}>
                    S.No.
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    align="center"
                    variant="h5"
                    color={colors.blue[900]}
                  >
                    <AccountCircleIcon sx={{ mx: "1%" }} /> {t("Room Name")}
                  </Typography>
                </Grid>
              </Grid>
              {column1}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="h5" color={colors.blue[900]}>
                    S.No.
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    align="center"
                    variant="h5"
                    color={colors.blue[900]}
                  >
                    <AccountCircleIcon sx={{ mx: "1%" }} /> {t("Room Name")}
                  </Typography>
                </Grid>
              </Grid>
              {column2}
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                fullWidth
                onClick={() => {
                  setOpenAllListModal(true);
                }}
                startIcon={<PersonAddIcon />}
              >
                {t("Add Destinations")}
              </Button>
              {openAllListModal ? (
                <AddToMyList
                  open={openAllListModal}
                  setOpen={(val) => {
                    setOpenAllListModal(val);
                  }}
                  refreshList={refreshList}
                />
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};
