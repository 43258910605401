import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  colors,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AxiosFetch } from "../../../AxiosFetch";
import CheckIcon from "@mui/icons-material/Check";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isMobile ? 2 : 4,
};
export const ChatworkModalForTopic = ({
  open,
  handleOpen,
  formValuesForTopic,
  handleSubmitForChatwork,
}) => {
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ChildModal = () => {
    const [loading, setLoading] = useState(true);
    const [roomList, setRoomList] = useState([]);
    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };
    useEffect(() => {
      async function getData() {
        try {
          const response = await axiosFetch.get("/chatworkRoomByUserId");
          console.log(response, "chatwork room get response");
          setRoomList(response.data);
        } catch (error) {
          console.log(error, "error");
        } finally {
          setLoading(false);
        }
      }
      getData();
    }, []);
    const handleSubmit = async () => {
      handleOpen(false);
      var APIvalues = [];
      checked.forEach((index) => {
        APIvalues.push(roomList[index]);
      });
      const flag = await handleSubmitForChatwork(formValuesForTopic, APIvalues);
    };
    return (
      <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "75%",
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography
                align="center"
                variant={isMobile ? "h5" : "h3"}
                color={colors.blue[900]}
              >
                {t("Select Destination")}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
              <Button
                size={isMobile ? "small" : "large"}
                variant="contained"
                color="error"
                onClick={() => handleOpen(false)}
              >
                {t("Close")}
              </Button>
            </Grid>
            {loading ? (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="h4"
                  color={colors.yellow[800]}
                >
                  Loading....
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {roomList.map((data, index) => {
                      return index % 2 === 0 ? (
                        <ListItem disablePadding key={index + "f"}>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(index)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                size={isMobile ? "small" : "large"}
                                tabIndex={-1}
                                disableRipple
                                //inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={index}
                              primary={
                                <Typography variant={isMobile ? "h6" : "h4"}>
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: isMobile ? "1rem" : "2rem",
                                    }}
                                  />
                                  {` ${data.roomName}`}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {roomList.map((data, index) => {
                      return index % 2 !== 0 ? (
                        <ListItem disablePadding key={index + "g"}>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(index)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                size={isMobile ? "small" : "large"}
                                tabIndex={-1}
                                disableRipple
                                //inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={index}
                              primary={
                                <Typography variant={isMobile ? "h6" : "h4"}>
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: isMobile ? "1rem" : "2rem",
                                    }}
                                  />
                                  {` ${data.roomName}`}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                size={isMobile ? "small" : "large"}
                variant="outlined"
                fullWidth
                color="complementary"
                onClick={() => {
                  navigate("/SNS");
                }}
                startIcon={<EditIcon />}
              >
                {t("Edit Destination")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                size={isMobile ? "small" : "large"}
                variant="contained"
                fullWidth
                startIcon={<CheckIcon />}
                onClick={handleSubmit}
              >
                {t("Confirm Save And Share")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

  return <React.Fragment>{open ? <ChildModal /> : null}</React.Fragment>;
};
