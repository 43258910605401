import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AxiosFetch } from "../../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import { useState } from "react";
import DeleteDialog from "../../commonComponents/DeleteDialog";

export default function CustomerSearchTable({ handleRowDelete, ...props }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const rows = props.rows;
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = useState({});

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  const cw = 100;
  const columns = [
    { field: "customerId", headerName: "ID", flex: 0.8 },
    { field: "customerLastName", headerName: `${t("Last Name")}`, flex: 0.9 },
    { field: "customerFirstName", headerName: `${t("First Name")}`, flex: 0.9 },
    // { field: "customerRegisterDateTime", headerName: `${t("Created On")}`, flex: 1.1 },
    {
      field: "telephone",
      headerName: `${t("Telephone")}`,
      // type: 'number',
      valueGetter: (params) => {
        var temp = "";
        if (params.row.telephone.length !== 0) {
          temp = params.row.telephone[0].telNum;
        }

        return temp;
      },
      flex: 1,
    },
    {
      field: "customerNextCallDateTime",
      headerName: `${t("Next Call Date")}`,
      type: "date",
      flex: 1,
      valueGetter: (params) => calculateNextCallDate(params.row.topic),
    },
    {
      field: "customerModifyDateTime",
      headerName: `${t("Topic Last Updated")}`,
      type: "datetime",
      flex: 1,
      valueGetter: (params) => {
        // Extract the topic array from the row data
        const topics = params.row.topic;

        // Find the latest modifyDateTime
        const latestDate = topics.reduce((latest, current) => {
          return current.modifyDateTime > latest
            ? current.modifyDateTime
            : latest;
        }, "");
        return latestDate;
      },
    },
    { field: "customerAddress1", headerName: `${t("Address 1")}`, flex: 1 },
    { field: "customerAddress2", headerName: `${t("Address 2")}`, flex: 1 },
    {
      field: "customerGroup",
      headerName: `${t("Group Name")}`,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.customerGroup !== null) {
          temp = params.row.customerGroup.groupDisplayName;
        }
        return temp;
      },
    },
    {
      field: "project",
      headerName: `${t("Project Group")}`,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.project !== null) {
          temp = params.row.project.name;
        }
        return temp;
      },
    },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params, "ppppp");
          var arr = JSON.parse(sessionStorage.getItem("searchArrayId"));
          var indexId = arr.findIndex((e) => e === params.row.customerId);
          sessionStorage.setItem("indexId", indexId);

          navigate("/customerDetail", {
            state: {
              customerId: params.row.customerId,
            },
          });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={onClick}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    // {
    //   field: "delete",
    //   headerName: `${t("Delete")}`,
    //   sortable: false,
    //   flex: 0.8,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     const onClick = async (e) => {
    //       e.stopPropagation();
    //       setDialogOpen(true);
    //       setSelectRow(params.row);
    //     };
    //     return (
    //       <Button
    //         variant="contained"
    //         color="error"
    //         size="small"
    //         startIcon={<DeleteIcon />}
    //         onClick={onClick}
    //       >
    //         {t("Delete")}
    //       </Button>
    //     );
    //   },
    //   flex: 1,
    // },
  ];
  const handleDelete = async () => {
    setDelLoading(true);
    // don't select this row after clicking
    console.log(selectRow, "ppppp");
    try {
      const response = await axiosFetch.delete(
        `/customers/soft/${selectRow.customerId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };

  const [pageSize, setPageSize] = useState(
    sessionStorage.getItem("tablePageSize") || 100
  );

  return (
    <>
      {/* <LinearProgress
        color="error"
        sx={{ visibility: `${!delLoading ? "hidden" : ""}` }}
      /> */}
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <div
        style={{
          height: pageSize === 20 ? 500 : "85dvh",
          width: "100%",
        }}
      >
        <DataGrid
          getRowId={(row) => row.customerId}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          onPageSizeChange={(val) => {
            setPageSize(val);
            sessionStorage.setItem("tablePageSize", val);
          }}
          //checkboxSelection
        />
      </div>
    </>
  );
}
function calculateNextCallDate(topicArr) {
  let allDatesArr = [];
  const today = new Date();
  topicArr.forEach(({ tickets }, index) => {
    tickets.forEach(({ nextCallDate }) => {
      if (nextCallDate) {
        allDatesArr.push(new Date(nextCallDate));
      }
    });
  });
  allDatesArr.sort((a, b) => a - b);
  const futureDates = allDatesArr.filter((date) => date > today);
  //console.log(futureDates, "fffff", today, allDatesArr);
  if (futureDates.length > 0) {
    // If there are future dates, return the earliest one
    return futureDates[0];
  } else {
    // If there are no future dates, return the most recent past date
    const pastDates = allDatesArr.filter((date) => date < today);
    return pastDates.length > 0 ? pastDates[pastDates.length - 1] : null;
  }
}
