import {
 
  Card,
  colors,
 
  Grid,
  IconButton,
  InputBase,
  Link,
  TextField,
  Tooltip,
 
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem, TreeView } from "@mui/lab";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

import LinkIcon from "@mui/icons-material/Link";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { DragFolder } from "./DragFolder";
import { NewBookmarkFolder } from "./NewBookmarkFolder";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import LoginContext from "../../context/LoginContext";
import DeleteDialog from "../commonComponents/DeleteDialog";
import { NewBookmark } from "./NewBookmark";

//url=bookmark
export const BookmarkURL = () => {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [folderUrlArray, setFolderUrlArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [folderDragFlag, setFolderDragFlag] = useState(true);//drag and drop incomplete, low priority
  const [folderId, setFolderId] = useState(0);
  const [urlId, setUrlId] = useState(0);
  const [openAddUrlModal, setOpenAddUrlModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [folderDelFlag, setFolderDelFlag] = useState(true);
  useEffect(()=>{
    context.setPageTitle("Bookmark")
  },[])

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  const handleDelete = async (e) => {
    setDelLoading(true);
    var APIvalues = [];
    console.log(APIvalues, "APIvalues for delete");
    try {
      const response = await axiosFetch.delete(
        folderDelFlag ? `/bookmarkFolder/${folderId}` : `/bookmark/${urlId}`
      );
      console.log(response, "Folder delete API response");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `Delete Successful`,
          });
      }
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          error.response ||
          error.response.data ||
          error.response.data.errorMessage
        }`,
      });
      console.log(error, "error");
    } finally {
      getData();
      setDelLoading(false);
      handleDialogOpen(false);
    }
  };

  function getUrlDetails(nodeId) {
    const folderId = Math.floor(nodeId / 1000);
    const urlIndex = nodeId % 1000;
    const folderObj = folderUrlArray.find((item) => item.id === folderId);
    if (folderObj) {
      const urlObj = folderObj.bookmarkList[urlIndex];
      if (urlObj) return urlObj;
    }
    return { name: "", url: "" };
  }

  const CustomTreeLink = ({ nodeId, defaultLinkValue }) => {
    const urlDetails = getUrlDetails(+nodeId);
    const [hovered, setHovered] = useState(false);
    const [readMode, setReadMode] = useState(true);
    const [inputValue, setInputValue] = useState(defaultLinkValue);
    const [inputValueUrl, setInputValueUrl] = useState(urlDetails.url);

    return (
      <TreeItem
        nodeId={nodeId}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        label={
          <Grid container spacing={0.5}>
            <Grid
              item
              xs={0.5}
              display="flex"
              alignItems={"center"}
              justifyContent="end"
            >
              <LinkIcon
                sx={{
                  color: !hovered ? "black" : "white",
                  backgroundColor: hovered ? "black" : "transparent",
                  borderRadius: "9px",
                }}
              />
            </Grid>
            <Grid item xs={10.5} display="flex" alignItems={"center"}>
              {readMode ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={(e) => {
                    e.stopPropagation();
                    //window.location.href = urlDetails.url;
                    window.open(urlDetails.url, "_blank");
                  }}
                >
                  {inputValue}
                </Link>
              ) : (
                <>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label={t("URL Name")}
                    variant="outlined"
                    defaultValue={urlDetails.name}
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{
                      ml: 1,
                      flex: 0.4,
                      backgroundColor: "#fff077",
                    }}
                  />
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label={t("URL")}
                    variant="outlined"
                    defaultValue={urlDetails.url}
                    value={inputValueUrl}
                    onChange={(e) => {
                      setInputValueUrl(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{
                      ml: 0.5,
                      flex: 1.4,
                      backgroundColor: "#fff077",
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={1} display="flex" justifyContent={"end"}>
              {!readMode ? (
                <>
                  <IconButton
                    aria-label="Done"
                    sx={{
                      visibility: readMode ? "hidden" : "visible",
                      color:
                        inputValue !== defaultLinkValue ? "primary.main" : null,
                    }}
                    disabled={inputValue === defaultLinkValue}
                    onClick={(e) => {
                      handleUrlEdit(
                        e,
                        setReadMode,
                        inputValue,
                        inputValueUrl,
                        urlDetails.id
                      );
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Close"
                    sx={{
                      visibility: readMode ? "hidden" : "visible",
                      color: "error.main",
                    }}
                    onClick={(e) => {
                      setReadMode(true);
                      setInputValue(defaultLinkValue);
                      setInputValueUrl(urlDetails.url);
                      e.stopPropagation();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  
                  <Tooltip title={t("Edit Bookmark")}>
                    <IconButton
                      sx={{
                        "&:hover": {
                          color: "primary.main",
                        },
                        visibility: hovered ? "visible" : "hidden",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReadMode(false);
                        //textFieldRef.current.focus();
                      }}
                      disabled={!readMode}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Delete Bookmark")}>
                    <IconButton
                      sx={{
                        "&:hover": {
                          color: "error.main",
                        },
                        visibility: hovered ? "visible" : "hidden",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setUrlId(urlDetails.id);
                        setFolderDelFlag(false);
                        setDialogOpen(true);
                      }}
                      disabled={!readMode}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
            {/* <Grid item sx={1}>
              <IconButton
                aria-label="Done"
                sx={{
                  visibility: readMode ? "hidden" : "visible",
                  color:
                    inputValue !== defaultLinkValue ? "primary.main" : null,
                }}
                disabled={inputValue === defaultLinkValue}
              >
                <DoneIcon />
              </IconButton>
            </Grid>
            <Grid item sx={1}>
              <IconButton
                aria-label="Close"
                sx={{
                  visibility: readMode ? "hidden" : "visible",
                  color: "error.main",
                }}
                onClick={(e) => {
                  setReadMode(true);
                  setInputValue(defaultLinkValue);
                  e.stopPropagation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid> */}

            {/* <Grid
              item
              xs={3}
              sx={{ visibility: hovered ? "visible" : "hidden" }}
            >
              <Tooltip title={"Edit Link"}>
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Link"}>
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "error.main",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        }
      />
    );
  };

  const CustomTreeFolder = ({ children, nodeId, defaultFolderValue }) => {
    const [hovered, setHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [readMode, setReadMode] = useState(true);
    const textFieldRef = useRef(null);
    const [inputValue, setInputValue] = useState(defaultFolderValue);

    return (
      <TreeItem
        nodeId={nodeId}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          setOpen(!open);
        }}
        label={
          <Grid container spacing={1} width="50%">
            <Grid
              item
              xs={6}
              display="flex"
              alignItems={"center"}
              justifyContent="start"
            >
              {open ? <FolderOpenIcon /> : <FolderIcon />}
              &nbsp;
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  border: !readMode ? "2px solid black" : null,
                  pointerEvents: readMode ? "none" : null,
                  backgroundColor: readMode ? null : "#fff077",
                }}
                placeholder="folder name"
                inputProps={{ "aria-label": "folder name" }}
                defaultValue={defaultFolderValue}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                readOnly={readMode}
                inputRef={textFieldRef}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="Done"
                sx={{
                  visibility: readMode ? "hidden" : "visible",
                  color:
                    inputValue !== defaultFolderValue ? "primary.main" : null,
                }}
                disabled={inputValue === defaultFolderValue}
                onClick={(e) => {
                  handleFolderEdit(e, nodeId, inputValue, setReadMode);
                }}
              >
                <DoneIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="Close"
                sx={{
                  visibility: readMode ? "hidden" : "visible",
                  color: "error.main",
                }}
                onClick={(e) => {
                  setReadMode(true);
                  setInputValue(defaultFolderValue);
                  e.stopPropagation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent={"end"}
              sx={{ visibility: hovered ? "visible" : "hidden" }}
            >
              <Tooltip title={t("Add URL")}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setFolderId(nodeId);
                    setOpenAddUrlModal(true);
                    console.log(e, "eee", nodeId);
                  }}
                  sx={{
                    "&:hover": {
                      color: colors.blue[700],
                    },
                  }}
                  disabled={!readMode}
                >
                  <AddLinkIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Edit Folder Name")}>
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={(e) => {
                    setReadMode(false);
                    textFieldRef.current.focus();
                    e.stopPropagation();
                  }}
                  disabled={!readMode}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Delete Folder")}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setFolderDelFlag(true);
                    setFolderId(nodeId);
                    setDialogOpen(true);
                  }}
                  sx={{
                    "&:hover": {
                      color: "error.main",
                    },
                  }}
                  disabled={!readMode}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        }
      >
        {children}
      </TreeItem>
    );
  };

  const handleToggle = (event, nodeIds) => {
    //console.log("toggle", nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    //console.log("selected", nodeIds);
    setSelected(nodeIds);
  };
  const handleFolderEdit = async (e, nodeId, inputValue, setReadMode) => {
    e.stopPropagation();
    try {
      const response = axiosFetch.put(
        `/bookmarkFolder/${nodeId}`,
        JSON.stringify({ name: inputValue })
      );
      console.log(response, "Folder Edit response");
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `Edit Successful`,
      });
    } catch (error) {
      console.log(error, "Folder Edit Error response");
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          error.response ||
          error.response.data ||
          error.response.data.errorMessage
        }`,
      });
    } finally {
      setReadMode(true);
    }
  };

  const handleUrlEdit = async (
    e,
    setReadMode,
    inputValue,
    inputValueUrl,
    nodeId
  ) => {
    e.stopPropagation();
    try {
      const response = axiosFetch.put(
        `/bookmark/${nodeId}`,
        JSON.stringify({ name: inputValue, url: inputValueUrl })
      );
      console.log(response, "Url Edit response");
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `Edit Successful`,
      });
    } catch (error) {
      console.log(error, "Folder Edit Error response");
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          error.response ||
          error.response.data ||
          error.response.data.errorMessage
        }`,
      });
    } finally {
      setReadMode(true);
    }
  };
  const elements = useMemo(() => {
    //console.log("hello");
    var tt = [];
    folderUrlArray.forEach((folder, i) => {
      tt.push(
        <CustomTreeFolder
          nodeId={folder.id.toString()}
          key={folder.id}
          defaultFolderValue={folder.name}
        >
          {folder.bookmarkList.map((URL, index) => {
            return (
              <CustomTreeLink
                nodeId={(folder.id * 1000 + index).toString()}//each folder can have 1000 links
                key={folder.id * 1000 + index}
                defaultLinkValue={URL.name}
              />
            );
          })}
        </CustomTreeFolder>
      );
    });
    return tt;
  }, [folderUrlArray]);

  async function getData() {
    setLoading(true);
    try {
      const response = await axiosFetch.get("/bookmarkFolder");
      console.log(response, "URL Folder API Get response");
      setFolderUrlArray(response.data);
    } catch (error) {
      console.log(error, "URL Folder API Error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return loading ? (
    <>loading......</>
  ) : (
    <Box
      sx={{
        py: "2%",
        px: "5%",
      }}
    >
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <NewBookmark
        open={openAddUrlModal}
        setOpen={(val) => {
          setOpenAddUrlModal(val);
        }}
        folderId={folderId}
        refreshList={getData}
      />
      <Grid container spacing={2}>
        
        <Grid item xs={12}>
          <NewBookmarkFolder refreshList={getData}/>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => {
              setFolderDragFlag(!folderDragFlag);
            }}
            sx={{ borderRadius: "20px" }}
          >
            drag toggle
          </Button>
        </Grid> */}
        {folderDragFlag ? (
          <Grid item xs={12}>
            <Card elevation={4}>
              <TreeView
                aria-label="controlled"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultSelected={selected}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}

                //multiSelect
              >
                {elements}
              </TreeView>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <DragFolder folderUrlArray={folderUrlArray}/>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
