import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router";
import { Navigate } from "react-router";
import LoginContext from "../../context/LoginContext";

export default function PrivateRoutes({ allowedRoles }) {
  const { userRole } = useContext(LoginContext);
  // const location=useLocation();
  //   console.log( allowedRoles.includes(userRole),"aaaa",userRole,allowedRoles);

  return allowedRoles.includes(userRole) ? <Outlet /> : <Navigate to="/" />;
}

{
  /* <Navigate to="/" state={{from:location}} replace/> */
}
