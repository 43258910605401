import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useNavigate } from "react-router";
import UserSearchTable from "./UserSearchTable";
import { AxiosFetch } from "../AxiosFetch";
import GroupSelect from "../formikInputs/GroupSelect";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function UserSearch(props) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const [userRows, setUserRows] = useState([]);
  const context = useContext(LoginContext);
  const [tableLoading, setTableLoading] = useState(false);
  const handleRowDelete = (e) => {
    //console.log(e,'eeee');
    var arr = [...userRows];
    arr.splice(
      arr.findIndex((a) => a.id === e.id),
      1
    );
    //console.log(arr,'arrr');
    setUserRows(arr);
  };

  useEffect(() => {
    context.setPageTitle("User search");
  }, []);

  const initialValues = {
    userId: "",
    groupId: 0,
    firstName: "",
    lastName: "",
    extensionNumber: "",
  };

  const handleSubmit = async (values) => {
    setTableLoading(true);
    console.log(values);
    var apiValues = {
      ...values,
      customerGroups: [{ groupId: values.groupId }],
    };
    delete apiValues.groupId;
    console.log(apiValues, "aaaa");

    try {
      const Response = await axiosFetch.post(
        "/userList",
        JSON.stringify(apiValues)
      );
      console.log(Response, "response");
      var temp = [];
      Response.data.map((data, index) => {
        temp[index] = {
          id: index + 1,
          userId: data.userId,
          lastName: data.lastName,
          firstName: data.firstName,
          privilege: data.privilege,
          extensionNumber: data.extensionNumber,
        };
      });
      console.log(temp, "temppppp");
      setUserRows(temp);
      setTableLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          (error.response.data && error.response.data.errorMessage) || "error"
        }`,
      });
      console.log(error, "error");
      setTableLoading(false);
    }

    //navigate("/home");
  };
  return (
    <div>
      {/* <ButtonAppBar title="User Search" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "5%",
              py: "1%",
              // background: "red",
            }}
          >
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <CardContent>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={6}>
                        <CustomTextfield
                          data={{ name: "userId", label: `${t("User ID")}` }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <CustomTextfield
                          data={{
                            name: "extensionNumber",
                            label: `${t("extension number")}`,
                          }}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextfield
                          data={{
                            name: "lastName",
                            label: `${t("Last Name")}`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextfield
                          data={{
                            name: "firstName",
                            label: `${t("First Name")}`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <GroupSelect name="groupId" />
                      </Grid>

                      <Grid item xs={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          startIcon={<SearchIcon />}
                          fullWidth
                        >
                          {t("Search")}
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          size="large"
                          startIcon={<FileDownloadIcon />}
                          fullWidth
                        >
                          {t("Output")}
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          startIcon={<AddIcon />}
                          onClick={() => navigate("/newUser")}
                        >
                          {t("Create New User")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <LinearProgress
                    sx={{ visibility: `${!tableLoading ? "hidden" : ""}` }}
                  />
                  <CardContent>
                    <UserSearchTable
                      rows={userRows}
                      handleRowDelete={handleRowDelete}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
