import LoginContext from "./LoginContext";
import React, { useState } from "react";

export default function LoginState(props) {
  //const [token, setToken] = useState(sessionStorage.getItem("token") || false);
  const [token, setToken] = useState(false);

  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [pageTitle, setPageTitle] = useState("Login Page");
  const [jwtToken, setJwtToken] = useState("");
  const [searchPageDetails, setSearchPageDetails] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  //const[sideBarFlag,setSideBarFlag]=useState(true);
  //console.log(searchPageDetails, "searchPageDetails");
  const alertTemplate = {
    open: false,
    severity: "success",
    title: "",
    message: "",
  };
  const [globalAlert, setGlobalAlert] = useState(alertTemplate);

  const resetToDefault = () => {
    setToken(false);
    setUserId("");
    setUserFirstName("");
    setUserLastName("");
    setUserRole(null);
    setPageTitle("Login Page");
    setJwtToken("");
    setSearchPageDetails(null);
    setCustomerInfo({});
  };

  return (
    <LoginContext.Provider
      value={{
        token,
        setToken,
        userId,
        setUserId,
        userFirstName,
        setUserFirstName,
        userLastName,
        setUserLastName,
        pageTitle,
        setPageTitle,
        globalAlert,
        setGlobalAlert,
        userRole,
        setUserRole,
        jwtToken,
        setJwtToken,
        searchPageDetails,
        setSearchPageDetails,
        customerInfo,
        setCustomerInfo,
        resetToDefault,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
}
