import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Papa from "papaparse";
import { Form, Formik } from "formik";
import GroupSelect from "../../formikInputs/GroupSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CustomCheckbox from "../../formikInputs/CustomCheckbox";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import { AxiosFetch } from "../../AxiosFetch";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  //p: 4,
};

export const ImportPhoneNumbers = ({ refreshList }) => {
  const { t } = useTranslation();
  const context = useContext(LoginContext);
  const axiosFetch = AxiosFetch();
  const [fileData, setFileData] = useState(false);
  const allowedExtensions = ["csv"];
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const initialValues = {
    groupId: 0,
    skipLine: false,
  };
  const formValidation = Yup.object().shape({
    groupId: Yup.string()
      .required("This field is required")
      .test(
        "zero not allowed",
        "This field is required",
        (val) => Number(val) !== 0
      ),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = async (e) => {
    //console.log("helloo", e);

    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        alert('"Please input a csv file"');
        setFile("");
        return;
      }
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    if (!file) {
      window.alert("Please enter a supported file type (CSV)");
      return null;
    }

    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: false,
        skipEmptyLines: true,
      });
      // const columns = Object.keys(parsedData[0]);
      setFileData(csv.data);
    };
    reader.readAsText(file, "Shift-JIS");
  };
  const submitData = async (values) => {
    // const userId = sessionStorage.getItem("userId");
    setLoading(true);
    var APIvalues = {
      groupId: values.groupId,
      doNotCallList: [],
    };
    fileData.forEach((row, index) => {
      if (index !== 0 || (index === 0 && !values.skipLine)) {
        APIvalues.doNotCallList.push({
          telNum: row[0],
          memo: row[1],
        });
      }
    });
    console.log(APIvalues, "API values for import");
    try {
      const apiResponse = await axiosFetch.post(
        "/doNotCallList",
        JSON.stringify(APIvalues)
      );
      console.log(apiResponse, "do not call list import API response");
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `Import Successful`,
      });
      refreshList();
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response && error.response.data.errorMessage}`,
      });
      console.log(error, "error");
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        fullWidth
        startIcon={<QueueOutlinedIcon />}
        sx={{ borderRadius: "30px" }}
      >
        {t("Add Multiple Telephone")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            pointerEvents: `${loading ? "none" : "auto"}`,
            filter: `${loading ? "brightness(90%)" : ""}`,
          }}
        >
          <LinearProgress
            sx={{
              visibility: `${!loading ? "hidden" : ""}`,
            }}
          />
          <div style={{ padding: "2.5%" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={formValidation}
              onSubmit={submitData}
            >
              <Form>
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      {t(`Import Settings`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={file ? 6 : 12}>
                    <Button
                      //onClick={handleOpen}
                      variant={file ? "contained" : "outlined"}
                      size="large"
                      color="complementary"
                      fullWidth
                      startIcon={
                        file ? <InsertDriveFileIcon /> : <FileUploadIcon />
                      }
                      sx={{ borderRadius: "30px" }}
                      component="label"
                    >
                      {file ? file.name : t("Upload File")}

                      <input
                        type="file"
                        onChange={handleFileChange}
                        id="csvInput"
                        name="file"
                        hidden
                      />
                    </Button>
                  </Grid>
                  {file ? (
                    <Grid item xs={6}>
                      <Button
                        onClick={handleParse}
                        variant="outlined"
                        //color="secondary"
                        sx={{ borderRadius: "30px" }}
                        fullWidth
                        size="large"
                      >
                        {t("Import")}
                      </Button>
                    </Grid>
                  ) : null}

                  {fileData ? (
                    <>
                      <Grid item xs={3}>
                        <CustomCheckbox
                          data={{ name: "skipLine", label: t("Skip First Line") }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <GroupSelect name="groupId" />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "error.light" }}
                          color="error"
                          size="large"
                          fullWidth
                          startIcon={<ArrowBackIcon />}
                          onClick={handleClose}
                        >
                          {t("Cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          //   onClick={submitData}
                          fullWidth
                          startIcon={<SaveIcon />}
                        >
                          {t("Save")}
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
