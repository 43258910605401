import React, { useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Link,
  Card,
  CardContent,
  LinearProgress,
  colors,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import logo from "../testFolder/logo.jpg";
import * as Yup from "yup";
import CustomSelect from "../formikInputs/CustomSelect";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import LockResetIcon from "@mui/icons-material/LockReset";
import InfoIcon from "@mui/icons-material/Info";

export default function LoginIndex(props) {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const a = useContext(LoginContext);
  console.log(a);

  useEffect(() => {
    a.setPageTitle("Login");
    a.setUserId("");
    sessionStorage.clear(); //
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") < 0) {
        sessionStorage.setItem("safariFlag", true);
      } else {
        sessionStorage.setItem("safariFlag", false);
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const list = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];

  const initialValues = {
    userName: "",
    userPassword: "",
    reqPrivilege: 3,
  };

  const formValidation = Yup.object().shape({
    userName: Yup.string()
      .required("Required!")
      .min(2, "Too Short!")
      .max(10, "Too Long!"),
    userPassword: Yup.string().required("Required!"),
  });

  const handleClick = async (values, { resetForm }) => {
    console.log(values, "vvvv");
    setLoading(true);
    try {
      const userResponse = await axiosFetch.post(
        "/authenticate",
        JSON.stringify(values)
      );
      console.log(userResponse, "response");
      // a.setJwtToken(userResponse.data.jwtToken);
      a.setToken(true);
      // a.setUserId(userResponse.data.user.userId);
      // a.setUserRole(values.reqPrivilege);
      // sessionStorage.setItem("token", true);
      // sessionStorage.setItem("userId", userResponse.data.user.userId);
      sessionStorage.setItem("jwtToken", userResponse.data.jwtToken);
      sessionStorage.setItem("sideBarOpen", true);

      //localStorage.setItem("jwtToken", userResponse.data.jwtToken);
      // var userLang = navigator.language || navigator.userLanguage;
      // console.log(userLang,'auto language check');
      // if (userLang === "en-US" || "en-GB") {
      //   sessionStorage.setItem("lang", "en");
      //   i18n.changeLanguage("en");
      // }
      setLoading(false);
      //a.setSideBarFlag(true);
      navigate("/customerSearch");
    } catch (err) {
      console.log(err, "errr");
      //setErrorFlag(true);
      a.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${err.response.data.errorMessage}`,
      });
      setLoading(false);
      resetForm();
    }
  };
  return (
    <div>
      {/* <ButtonAppBar title="Login" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e, { resetForm }) => handleClick(e, { resetForm })}
      >
        <Form>
          {/* <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={() => {
              setLoading(!loading);
            }}
          >
            <Typography variant="h2" gutterBottom>
              Logging..
            </Typography>
            <br />
            <CircularProgress color="inherit" />
          </Backdrop> */}
          {/* <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={errorFlag}
            onClick={() => {
              setErrorFlag(!errorFlag);
            }}
          >
            <Alert severity="error">
              <Typography variant="h5" gutterBottom>
                Incorrect User ID or Password
              </Typography>
            </Alert>
          </Backdrop> */}
          <Box
            sx={{
              alignItems: "center",
              px: "25%",
              py: "2%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                    style={{ display: "none" }}
                  >
                    <img src={logo} alt="Topline"></img>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h4" align="center">
                      {t("CTI Log In")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{ name: "userName", label: `${t("User ID")}` }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextfield
                      data={{ name: "userPassword", label: `${t("Password")}` }}
                      type={togglePassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {" "}
                            {togglePassword ? (
                              <Visibility
                                onClick={(e) => {
                                  setTogglePassword(false);
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                onClick={(e) => {
                                  setTogglePassword(true);
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomSelect
                      data={{
                        name: "reqPrivilege",
                        label: `${t("Select role")}`,
                        list: list,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      // onClick={(e)=>{handleClick(e)}}
                    >
                      {t("Log In")}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color={colors.green[900]}
                      sx={{
                        underlinedText: {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <InfoIcon sx={{ color: colors.orange[700] }} />
                      {t("Forgot password? Ask Admin User to reset it")}
                      <LockResetIcon />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
