import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Button, IconButton, ListItemButton, ListSubheader, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useRef } from "react";
import DeleteDialog from "../commonComponents/DeleteDialog";
import { useState } from "react";

export default function SelectWithDelete({selected,handleSelected,handleSelectedDelete,...props}) {
  const options = props.list;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState('None');
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    //console.log(event, "eeeeee");
    setAnchorEl(event.currentTarget);
  };
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };
  const reff = useRef(null);
  const handleDelete = async (e) => {
    setDelLoading(true);
    await handleSelectedDelete();
    //const timer = await new Promise((r) => setTimeout(r, 3000));
    setDelLoading(false);
    setDialogOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    handleSelected(index);
    setAnchorEl(null);
  };
  var widd = (reff.current && reff.current.clientWidth) + "px" || "100%";
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <List
        sx={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "4px",
          borderColor: "#000000de",
        }}
        ref={reff}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            // primary="When device is locked"
            // secondary={options[selectedIndex]}
            primary={selected}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listBox",
        }}
        PaperProps={{ sx: { width: `${widd}` } }}
      >
        <List>
          <ListItem key={0}  disablePadding sx={{background:`${selected==='None' ? "#e0f4e9" : ""}`}}>
            <ListItemButton
              role={undefined}
              onClick={(event) => handleMenuItemClick(event,'None')}
            >
              <ListItemText id={0} primary={'None'} />
            </ListItemButton>
          </ListItem>
          {options.map((option, index) => (
            <ListItem
              key={index+1}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  color="error"
                  onClick={() => {
                    //console.log(option.name,'ooooo');
                    handleSelected(option.name);
                    setDialogOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
              sx={{background:`${selected===option.name ? "#e0f4e9" : ""}`}}
            >
              <ListItemButton
                role={undefined}
                onClick={(event) => handleMenuItemClick(event, option.name)}
              >
                <ListItemText id={index+1} primary={option.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>{" "}
      </Menu>
    </>
  );
}
