import { Button, Card, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { AxiosFetch } from "../../AxiosFetch";
import EditCallFlagModal from "./EditCallFlagModal";
import Alert from "../../Alert";
import { useTranslation } from "react-i18next";
import LoginContext from "../../../context/LoginContext";


export default function CallFlag() {
  const { t } = useTranslation();

  const { setGlobalAlert } = useContext(LoginContext);

  //Alert
  const [alert, setAlert] = useState({ open: false, type: "success", message: "Success" });
  const handleAlert = () => {
    setAlert(!alert);
  }

  async function refreshList() {
    const response = await axiosFetch.get(`/callLogGroup`);
    var data1 = [];
    if (response.status === 200) {
      response.data.map((element) => {
        if (element.callFlag) {
          element.callFlag.map((element1) => {
            if (element1.active) {
              data1.push({
                id: element1.id,
                name: element1.name,
                groupName: element.name,
              })
            }
          })
        }
      })
      setRows(data1);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/callLogGroup`);
      var data1 = [];
      if (response.status === 200) {
        response.data.map((element) => {
          if (element.callFlag) {
            element.callFlag.map((element1) => {
              //filter out soft deleted call flags
              if (element1.active) {
                data1.push({
                  id: element1.id,
                  name: element1.name,
                  groupName: element.name,
                })
              }
            })
          }
        })
        setRows(data1);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const deleteCallFlag = async (id) => {
    try {
      const response = await axiosFetch.delete(`/callFlag/${id}`);
      setGlobalAlert({
        open: true,
        severity: "success",
        title: "Success",
        message: response.data || "Deleted",
      });
      refreshList();
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error.response && error.response.data && error.response.data.errorMessage) {
        errorMessage = error.response.data.errorMessage;
      } else if (error.message) {
        errorMessage = error.message;
      }
      setGlobalAlert({
        open: true,
        severity: "error",
        title: "Error",
        message: errorMessage,
      });
    }
  }

  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState();

  const handleModalChange = () => {
    console.log(openModal);
    setOpenModal(!openModal);
  }

  const axiosFetch = AxiosFetch();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    { field: "id", headerName: `${t("ID")}`, flex: 1 },
    {
      field: "name",
      headerName: `${t("Call Flag Name")}`,
      flex: 1,
    },
    {
      field: "groupName",
      headerName: `${t("Call Group Name")}`,
      flex: 1,
      // valueGetter: (params) => {return params.row.callLogGroup ? params.row.callLogGroup.name : ""}
    },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setModalData({ id: params.row.id, name: params.row.name, groupname: params.row.groupname });
          handleModalChange();
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onClick}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: `${t("Delete")}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          deleteCallFlag(params.row.id);
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={onClick}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <Alert data={alert} handleAlert={handleAlert} />
      <EditCallFlagModal openModal={openModal} handleModalChange={handleModalChange} data={modalData} setAlert={setAlert} refreshList={refreshList} />
      <Box
        sx={{
          alignItems: "center",
          px: "5%",
          py: "2%",
          // background: "red",
        }}
      >
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                setModalData({});
                handleModalChange();
              }}
            >
              {t("Create New Call Flag")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    // getRowId={(row) => row.CallGroup}
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                  // checkboxSelection
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
