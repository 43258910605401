import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import LoginContext from "../../../../context/LoginContext";
import { AxiosFetch } from "../../../AxiosFetch";
import GroupSelect from "../../../formikInputs/GroupSelect";
import CustomerTableForDistribution from "./CustomerTableForDistribution";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ProjectSelectByGroup from "../../../formikInputs/ProjectSelectByGroup";
import { Stack } from "@mui/system";

export default function CustomerProjectDistribution(props) {
  const CustomizedRadioGroup = ({ children, form, field, ...props }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    return (
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={(event) => {
          setFieldValue("settingType", event.currentTarget.value);
        }}
      >
        {children}
      </RadioGroup>
    );
  };
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Customer Project Assignment");
  }, []);
  //const custInformation = location.state.data;
  const [custRows, setCustRows] = useState();
  if (typeof custRows === "undefined") {
    setCustRows(location.state.data);
  }

  const initialValues = {
    customerGroup: "",
    project: "",
    settingType: "manual",
  };
  const formValidation = Yup.object().shape({
    customerGroup: Yup.string().required(`${t("This field is required")}`),
    project: Yup.string()
      .required(`${t("This field is required")}`)
      .test(
        "zero not allowed",
        "This field is required cannot be none",
        (val) => Number(val) !== 0
      ),
  });

  const [finalList, setFinalList] = useState([]);
  const [settingType, setSettingType] = useState();
  const [marker, setMarker] = useState(false);
  const [number, setNumber] = useState("");
  const tt = useRef();
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [groupId, setGroupId] = useState("");

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      setGroupId(values.customerGroup);
      if (groupId !== values.customerGroup) setFieldValue("project", "");
      console.log(groupId, "groupId");
    }, [values.customerGroup]);
    useEffect(() => {
      setSettingType(values.settingType);
      values.settingType === "numerical" ? setMarker(true) : setMarker(false);
      if (!marker) setNumber("");
    }, [values.settingType]);

    return null;
  };

  const handleSubmit = async (values) => {
    console.log(values, "vvvv", finalList);
    if (finalList.length === 0) {
      alert("No selection Made");
      return;
    }
    //check for single group
    var errorFlag = false;
    for (let i = 0; i < finalList.length; i++) {
      let found = custRows.find((e) => e.customerId === finalList[i]);
      console.log(found, "fffff");
      if (found.customerGroup.groupId !== values.customerGroup) {
        errorFlag = true;
        alert("group mismatch");
        break;
      }
    }
    console.log(errorFlag, "flagg");
    if (errorFlag) return;

    setLoadingLogo(true);
    var APIvalues = {
      customerList: [...finalList],
      projectId: values.project,
    };
    console.log(APIvalues, "API values");

    if (values.settingType === "all") {
      try {
        const custResponse = await axiosFetch.post(
          `/customerProject`,
          APIvalues
        );
        console.log(custResponse, "ressss");
        navigate(-1);
      } catch (err) {
        console.log(err.response.data, "err");
        alert(JSON.stringify(err.response.data, null, 4));
      }
    } else {
      try {
        const custResponse = await axiosFetch.post(
          `/customerProject`,
          APIvalues
        );
        console.log(custResponse, "ressss");
        var newArr = [];
        Object.entries(custRows).map((data, index) => {
          var t = data[1].customerId;
          if (!finalList.includes(t)) newArr.push(data[1]);
        });
        setCustRows(newArr);
      } catch (err) {
        console.log(err.response.data, "err");
        alert(JSON.stringify(err.response.data, null, 4));
      }
    }
    setLoadingLogo(false);
    // const custResponse = await axiosFetch.post(`/customerProject`, APIvalues);
    // console.log(custResponse, "ressss");
    // navigate(-1);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingLogo}
            // onClick={() => {
            //   setLoading(!loadingLogo);
            // }}
          >
            <Typography variant="h2" gutterBottom>
              Loading...
            </Typography>
            <br />
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              alignItems: "center",
              px: "5%",
              py: "1%",
              // background: "red",
            }}
          >
            <FormObserver />
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <GroupSelect name="customerGroup" />
                      </Grid>
                      <Grid item xs={2}>
                        <ProjectSelectByGroup
                          mode=""
                          name="project"
                          label={t("Select Project")}
                          groupId={groupId}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            {t("Settings")}
                          </FormLabel>
                          <Field
                            name="settingType"
                            component={CustomizedRadioGroup}
                          >
                            <FormControlLabel
                              value="all"
                              control={<Radio />}
                              label={t("All")}
                            />
                            <FormControlLabel
                              value="manual"
                              control={<Radio />}
                              label={t("Checked only")}
                            />
                            <FormControlLabel
                              value="numerical"
                              control={<Radio />}
                              label={t("Numerical input")}
                            />
                            {marker && (
                              <>
                                <TextField
                                  type="number"
                                  inputRef={tt}
                                  defaultValue={number}
                                  size="small"
                                />
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => {
                                    setNumber(tt.current.value);
                                  }}
                                >
                                  {t("select")}
                                </Button>
                              </>
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack spacing={1}>
                          <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            fullWidth
                          >
                            {t("Allocate")}
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "error.light" }}
                            color="error"
                            size="medium"
                            onClick={() => {
                              navigate(-1);
                            }}
                            fullWidth
                          >
                            {t("Cancel")}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={4} sx={{ minHeight: 500 }}>
                  <CardContent>
                    <CustomerTableForDistribution
                      rows={custRows}
                      settingType={settingType}
                      setFinalList={(e) => setFinalList(e)}
                      number={number}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    </>
  );
}
