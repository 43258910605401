import { Autocomplete, Chip, Grid, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

export default function FilterSelectInput({ list }) {
  const context = useFormikContext();
  const [options1, setOptions1] = useState([]);
  const [inputValue1, setInputValue1] = useState("");
  const [value1, setValue1] = useState(
    (list && list.map((obj) => Object.keys(obj)[0])) || []
  );

  const [options2, setOptions2] = useState([[]]);
  const [inputValue2, setInputValue2] = useState([""]);
  const [value2, setValue2] = useState(
    (list && list.map((obj) => Object.values(obj)[0])) || [[]]
  );

  useEffect(() => {
    var arr = [];
    value1.forEach((val1, index) => {
      arr.push({ [val1]: value2[index] || [] });
    });
    // console.log(arr, "yessss");
    context.setFieldValue("filterSelectValue", arr);
  }, [value1, value2]);

  const handleInput1Delete = (i) => {
    const arr = [...value1];
    arr.splice(i, 1);
    setValue1(arr);
    const arr2 = [...value2];
    arr2.splice(i, 1);
    setValue2(arr2);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Add values for first drop down menu
        </Typography>
        <Autocomplete
          multiple
          id="tags-filled"
          value={value1}
          onChange={(event, newValue) => {
            setValue1(newValue);
          }}
          inputValue={inputValue1}
          onInputChange={(event, newInputValue) => {
            setInputValue1(newInputValue);
          }}
          options={options1}
          defaultValue={[]}
          fullWidth
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                onDelete={() => handleInput1Delete(index)} //create handle delete function here to reset value2
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Menu Input"
              placeholder="add options"
            />
          )}
        />
      </Grid>
      {value1.map((val, index) => (
        <React.Fragment key={`value1-${val}-${index}`}>
          <Grid
            item
            xs={2}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
            }}
            key={val + index + "0"}
          >
            <Typography variant="h5" noWrap>
              {val}
            </Typography>
          </Grid>
          <Grid item xs={10} key={val + index + "1"}>
            <Autocomplete
              multiple
              id="tags-filled"
              value={value2[index] || []}
              onChange={(event, newValue) => {
                //setValue2(newValue);
                setValue2((p) => {
                  const arr = [...p];
                  arr[index] = newValue;
                  return arr;
                });
              }}
              inputValue={inputValue2[index] || ""}
              onInputChange={(event, newInputValue) => {
                setInputValue2((p) => {
                  const arr = [...p];
                  arr[index] = newInputValue;
                  return arr;
                });
              }}
              options={options2[index] || []}
              defaultValue={[]}
              fullWidth
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`Menu Input ${val}`}
                  placeholder={`add options for ${val}`}
                />
              )}
            />
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}
