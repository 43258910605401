import React, { useContext, useEffect } from "react";
import { Grid, Box, Button, Card, CardContent, Tooltip } from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import GroupMultiSelect from "../formikInputs/GroupMultiSelect";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";
import LoginContext from "../../context/LoginContext";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTextField from "../formikInputs/CustomTextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function UserDetail(props) {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const { userRole, ...context } = useContext(LoginContext);
  //change title after render.
  // console.log(location,"lllll");

  const axiosFetch = AxiosFetch();

  const id = location.state.userId;
  const [isLoading, setIsLoading] = useState(true);

  let temp = [];
  let temp2 = [];

  const roleList = [
    { name: "Role 1", value: 1 },
    { name: "Role 2", value: 2 },
    { name: "Admin", value: 3 },
  ];
  const variableList = [
    { value: 100, name: "Oliver Hansen" },
    { value: 101, name: "Van Henry" },
    { value: 102, name: "Oliver Hansen" },
    { value: 103, name: "Van Henry" },
    { value: 104, name: "Oliver Hansen" },
    { value: 105, name: "Van Henry" },
    { value: 2, name: "gg" },
    { value: 3, name: "vv" },
  ];
  // const initialValues = {
  //   userId: "",
  //   extNumber: "",
  //   lastName: "",
  //   firstName: "",
  //   lastKana: "",
  //   firstKana: "",
  //   privilege: [],
  //   groupId: [],
  // };

  const [initialValues, setInitialValues] = useState();
  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/user/${id}`);
      if (response.status === 200) {
        //  console.log(response.data);
        response.data.customerGroups.map((data, index) => {
          temp[index] = data.groupId;
        });
        temp2 = Array.from(String(response.data.privilege), Number);
        setInitialValues({
          ...response.data,
          privilege: temp2,
          groupId: temp,
          userPassword: "####",
        });
        context.setPageTitle("User Detail");
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const handleSubmit = async (values) => {
    navigate("/userEdit", {
      state: { from: "detail screen", data: initialValues },
    });
  };
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <div>
      {/* <ButtonAppBar title="User Detail" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "10%",
              py: "5%",
              // background: "red",
            }}
          >
            <Card elevation={4}>
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item md={userRole === 3 ? 6 : 4} xs={6}>
                    <CustomTextfield
                      data={{ name: "userId", label: `${t("User ID")}` }}
                      mode="read"
                      // type="number"
                    />
                  </Grid>

                  {userRole === 3 ? (
                    <Grid item md={6} xs={6}>
                      <CustomTextfield
                        data={{ name: "userPassword", label: "Password" }}
                        mode="read"
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={6} md={userRole === 3 ? 6 : 4}>
                    <CustomTextfield
                      data={{
                        name: "extensionNumber",
                        label: `${t("extension number")}`,
                      }}
                      type="number"
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={userRole === 3 ? 5.5 : 3.5} xs={5.5}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "privilege",
                        label: `${t("User role")}`,
                        list: roleList,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={0.5} xs={0.5}>
                    <Tooltip
                      title={
                        <div>
                          {t(`Set the user's "role".`)}
                          <a
                            href="https://docs.google.com/spreadsheets/d/11M0EnWjh92KEZbivP10A7-DpToj_7ZyYxi3-e651q8g/edit#gid=0"
                            style={{ color: "lightblue" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("Click Here")}
                          </a>
                          {t("for details")}
                        </div>
                      }
                      fontSize="small"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{ name: "lastName", label: `${t("Last Name")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{ name: "firstName", label: `${t("First Name")}` }}
                      mode="read"
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "lastKana",
                        label: `${t("Last Kana Name")}`,
                      }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "firstKana",
                        label: `${t("First Kana Name")}`,
                      }}
                      mode="read"
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "registerDateTime",
                        label: `${t("Created Date")}`,
                      }}
                      type="datatime-local"
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <CustomTextfield
                      data={{
                        name: "modifyDateTime",
                        label: `${t("Last Updated Date")}`,
                      }}
                      type="datatime-local"
                      mode="read"
                    />
                  </Grid>

                  {/* <Grid item md={12}>
                    <CustomMultiSelectCheck
                      data={{
                        name: "groupId",
                        label: "Select Group",
                        list: variableList,
                      }}
                    />
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <GroupMultiSelect name="groupId" mode="read" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      multiline
                      minRows={3}
                      data={{ name: "memo", label: `${t("Memo")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      fullWidth
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate("/userSearch");
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<EditIcon />}
                    >
                      {t("Edit")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
