import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "../commonComponents/DeleteDialog";
import CategoryOrder from "./CategoryOrder";
import ReorderIcon from "@mui/icons-material/Reorder";
import CategoryItemOrder from "./CategoryItemOrder";

export default function CustomizeCategoryTable() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const axiosFetch = AxiosFetch();
    const context = useContext(LoginContext);
    const [delLoading, setDelLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectRow, setSelectRow] = useState({});
    const [orderEditFlag, setOrderEditFlag] = useState(false);
    const [orderItemEditFlag, setOrderItemEditFlag] = useState(false);
    const handleDialogOpen = (e) => {
        setDialogOpen(e);
    };
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        { field: "categoryId", headerName: "ID", flex: 0.4 },
        { field: "categoryName", headerName: `${t("Category Name")}`, flex: 1 },
        {
            field: "customerGroup",
            headerName: `${t("Group Name")}`,
            flex: 1,
            renderCell: (params) => {
                return <div>{params.row.customerGroup.groupDisplayName}</div>;
            },
        },
        {
            field: "registerUserId",
            headerName: `${t("Register User ID")}`,
            flex: 1,
        },
        {
            field: "visible",
            headerName: `${t("State")}`,
            flex: 0.5,
            renderCell: (params) => {
                return <div>{params.row.visible ? t("Display") : t("Hidden")}</div>;
            },
        },
        {
            field: "edit",
            headerName: `${t("Edit / Details")}`,
            flex: 0.8,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    navigate("/customizeCategoryDetail", {
                        state: { categoryId: params.row.categoryId },
                    });
                };
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={onClick}
                    >
                        {t("Edit")}
                    </Button>
                );
            },
        },

        {
            field: "delete",
            headerName: `${t("Delete")}`,
            flex: 0.8,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    setDialogOpen(true);
                    setSelectRow(params.row);
                };
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={onClick}
                    >
                        {t("Delete")}
                    </Button>
                );
            },
        },
        // {
        //   field: "softDelete",
        //   headerName: `${t("Soft Delete")}`,
        //   sortable: false,
        //   flex: 0.8,
        //   headerAlign: "center",
        //   align: "center",
        //   renderCell: (params) => {
        //     const onClick = async (e) => {
        //       e.stopPropagation(); // don't select this row after clicking
        //       setDialogOpen(true);
        //       setSelectRow(params.row);
        //      setDeleteType('soft');

        //     };
        //     return (
        //       <Button
        //         size="small"
        //         variant="outlined"
        //         color="error"
        //         onClick={onClick}
        //         startIcon={<HideSourceIcon />}
        //       >
        //         {t("Soft Delete")}
        //       </Button>
        //     );
        //   },
        // },
    ];
    const handleRowDelete = (e) => {
        var arr = [...rows];
        arr.splice(
            arr.findIndex((a) => a.categoryId === e.categoryId),
            1
        );
        setRows(arr);
    };
    const handleDelete = async () => {
        setDelLoading(true);
        try {
            const response = await axiosFetch.delete(`/category/${selectRow.categoryId}`);
            {
                response &&
                    context.setGlobalAlert({
                        open: true,
                        severity: "success",
                        title: "success",
                        message: `${response.data}`,
                    });
            }

            handleRowDelete(selectRow);
            setDelLoading(false);
        } catch (error) {
            context.setGlobalAlert({
                open: true,
                severity: "error",
                title: "error",
                message: `${error.response.data.errorMessage}`,
            });
            console.log(error, "error");

            setDelLoading(false);
        }
        setDialogOpen(false);
    };

    useEffect(() => {
        context.setPageTitle("Customize Category Table");
        async function getData() {
            const response = await axiosFetch.get(`/category`);
            if (response.status === 200) {
                var arr = [];
                response.data.map((data) => {
                    if (data.active) {
                        arr.push(data);
                    }
                });
                setRows(arr);
                setIsLoading(false);
            }
        }
        if (!orderEditFlag && !orderItemEditFlag) {
            getData();
        }
    }, [orderEditFlag, orderItemEditFlag]);

    return isLoading ? (
        <div>Loading</div>
    ) : (
        <>
            <Box sx={{ alignItems: "center", px: "5%", py: "2%" }} >
                <DeleteDialog
                    handleDialogOpen={handleDialogOpen}
                    handleDelete={handleDelete}
                    dialogOpen={dialogOpen}
                    delLoading={delLoading}
                />
                {(orderEditFlag || orderItemEditFlag)
                    ? (orderEditFlag
                        ? <CategoryOrder setOrderEditFlag={setOrderEditFlag} />
                        : <CategoryItemOrder setOrderItemEditFlag={setOrderItemEditFlag} />)
                    : (
                        <Grid container columnSpacing={2} rowSpacing={4}>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        navigate("/newCustomizeCategory");
                                    }}
                                >
                                    {t("Create New Category")}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<ReorderIcon />}
                                    color="secondary"
                                    onClick={() => {
                                        setOrderEditFlag(true);
                                    }}
                                >
                                    {t("Edit Category Order")}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    startIcon={<ReorderIcon />}
                                    color="secondary"
                                    onClick={() => {
                                        setOrderItemEditFlag(true);
                                    }}
                                >
                                    {t("Edit Category Item Order")}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Card elevation={4}>
                                    <CardContent>
                                        <div style={{ height: 700, width: "100%" }}>
                                            <DataGrid
                                                getRowId={(row) => row.categoryId}
                                                rows={rows}
                                                columns={columns}
                                                pageSize={20}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
            </Box>
        </>
    );
}
