import React from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import CustomTextfield from "../formikInputs/CustomTextField";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AxiosFetch } from "../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import DeleteDialog from "../commonComponents/DeleteDialog";
import { GroupSelectWithType } from "../formikInputs/GroupSelectWithType";

export default function CustomerProjectDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const location = useLocation();
  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Customer Project Edit");
  }, []);
  const data = location.state.data;
  //console.log(data, "dddd");
  const initialValues = data;

  const [loading, setLoading] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  //   const formValidation = Yup.object().shape({
  //     id: Yup.string()
  //       .required("Required!")
  //       .min(2, "Too Short!")
  //       .max(10, "Too Long!"),
  //     password: Yup.string().required("Required!"),
  //   });

  const handleSubmit = async (values) => {
    //console.log(values,'vvvvv');
    setLoading(true);
    var APIvalues = {
      name: values.name,
      flag: values.flag,
      customerGroup: {
        groupId: values.customerGroup.groupId,
      },
      modifyUserId: context.userId,
    };
    console.log(APIvalues, "API values");

    try {
      const Response = await axiosFetch.put(
        `/project/${data.id}`,
        JSON.stringify(APIvalues)
      );
      console.log(Response, "response");
      navigate("/customerProjectTable");
      setLoading(false);
    } catch (error) {
      alert("error");
      console.log(error);
      setLoading(false);
    }
  };
  const handleDelete = async (e) => {
    console.log(initialValues, "ppppp");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(`/project/${initialValues.id}`);
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      navigate("/customerProjectTable");
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  return (
    <div>
      {/* <ButtonAppBar title="Customer Project Edit" /> */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "15%",
              py: "5%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <DeleteDialog
              handleDialogOpen={handleDialogOpen}
              handleDelete={handleDelete}
              dialogOpen={dialogOpen}
              delLoading={delLoading}
            />
            <Card elevation={4} sx={{ border: "10px solid #fff077" }}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={4}>
                  <Grid item xs={10}>
                    <CustomTextfield
                      data={{ name: "name", label: `${t("Project Name")}` }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CustomTextfield
                      data={{ name: "id", label: `${t("Project ID")}` }}
                      mode="read"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GroupSelectWithType
                      name="customerGroup.groupId"
                      type="all"
                    />
                    {/* <GroupSelect
                      name="customerGroup.groupId"
                      label="Select Group"
                    /> */}
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextfield
                      data={{ name: "flag", label: `${t("Flag")}` }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container columnSpacing={1} rowSpacing={1}>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "error.light" }}
                          color="error"
                          size="large"
                          fullWidth
                          startIcon={<ArrowBackIcon />}
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          {t("Cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          fullWidth
                          startIcon={<SaveIcon />}
                        >
                          {t("Save")}
                        </Button>
                      </Grid>

                      <Grid item xs={4}>
                        <Button
                          size="large"
                          variant="contained"
                          color="error"
                          //onClick={handleDelete}
                          onClick={() => {
                            setDialogOpen(true);
                          }}
                          startIcon={<DeleteIcon />}
                          fullWidth
                        >
                          {t("Delete")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
