import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";
import { Field, useField } from "formik";
//use Case
{
  /* <CustomNumberField
  data={{ name: "namehere", label: "labelhere" }}
  mode="read"
/>; */
}

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CustomNumberField(props) {
  const data = props.data;
  const mode = props.mode;
  var readMode = mode === "read" ? true : false;

  const CustomizedSelectForFormik = ({ children, form, field, ...props }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    const [, meta] = useField(name);

    return (
      <TextField
        value={value === "" ? "" : +value}
        fullWidth
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        name={name}
        id="formatted-numberformat-input"
        variant="outlined"
        error={meta.error && meta.touched}
        helperText={meta.touched && meta.error}
        {...props}
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
      />
    );
  };

  return (
    <div>
      <Field
        name={data.name}
        component={CustomizedSelectForFormik}
        label={data.label}
        inputProps={{
          readOnly: readMode,
        }}
        {...props}
      />
    </div>
  );
}
