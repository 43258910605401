import React, { useState, useEffect, memo, useCallback } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
  useTheme,
} from "@mui/material";
import CategoryData from "../categoryForm/CategoryData";
import CustFormLite from "./CustFormLite";
import { AxiosFetch } from "../../AxiosFetch";
import CallLog from "./CallLog";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const flexB = "20%";

const CustCategoryFormPlusMemo = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const type = props.type;
  const [tabValue, setValue] = useState(1);
  const axiosFetch = AxiosFetch();

  // const [error, setError] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [categoryArray, setCategoryArray] = useState([]);
  // const groupId = props.groupId;

  function allProps(index) {
    return categoryArray[index];
  }

  const TabPanel = useCallback(
    (props) => {
      const { children, value, index, ...other } = props;
      if (props.check === "true") {
        return <div>{value === index && <>{children}</>}</div>;
        // return <div>{value === index && <h1>{children}</h1>}</div>;
      }

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <CategoryData {...allProps(index - 2)} type={type} />
          )}
        </div>
      );
    },
    [tabValue]
  );

  //This will work like componentDidMount
  useEffect(() => {
    async function getdata() {
      if (props.groupId && props.groupId > 0) {
        var tempArr = [];
        const response = await axiosFetch.get(`/category/${props.groupId}`);
        console.log(
          response.data,
          "category by groupId response",
          props.groupId
        );
        if (response.status === 200) {
          response.data.forEach((data) => {
            if (data.visible) {
              tempArr.push(data);
            }
          });
          setCategoryArray(tempArr);
        }
      } else {
        setCategoryArray([]);
      }
    }
    getdata();
    setValue(1);
  }, [props.groupId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabHead = categoryArray.map((category, index) => (
    <Tab
      label={category.categoryName}
      //wrapped
      //sx={{ minWidth: "fit-content", flex: 1 }}
      sx={{
        flexBasis: flexB,
        minWidth: "fit-content",
        "&:hover": { backgroundColor: "primary.dark", color: "white" },
        "&.Mui-selected": {
          color: "primary.main",
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          "&:hover": {
            backgroundColor: "primary.dark",
            color: "white",
          },
        },
      }}
      key={index + 2}
    />
  ));

  const tabContent = categoryArray.map((category, index) => (
    <div key={index + 2}>
      <TabPanel value={tabValue} index={2 + index}>
        {console.log(category.categoryName)}
        {category.categoryName}
      </TabPanel>
    </div>
  ));

  return (
    <Card
      sx={{
        minWidth: 275,
        height: 610,
        overflow: "auto",
        ...(isMobile && { overflow: "scroll", maxWidth: "100vw" }),
      }}
      elevation={4}
    >
      <CardContent>
        <div>
          <AppBar
            position="static"
            sx={{ backgroundColor: "primary.light" }}
            color="primary"
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              // variant="scrollable"
              // scrollButtons="auto"
              //aria-label="scrollable auto tabs example"
              // sx={{
              //   [`& .${tabsClasses.scrollButtons}`]: {
              //     "&.MuiTabScrollButton-root": { opacity: 1, color: "black" },
              //     "&.Mui-disabled": { opacity: 0.5, color: "white" },
              //   },
              // }}

              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Tab
                label={t("Call Log")}
                wrapped
                //sx={{ minWidth: "fit-content", flex: 1 }}
                key={0}
                sx={{
                  flexBasis: flexB,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                  },
                  "&.Mui-selected": {
                    color: "primary.main",
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "white",
                    },
                  },
                }}
              />
              <Tab
                label={t("Customer Information")}
                wrapped
                sx={{
                  flexBasis: flexB,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                  },
                  "&.Mui-selected": {
                    color: "primary.main",
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "white",
                    },
                  },
                }}
                //sx={{ minWidth: "fit-content", flex: 1 }}
                key={1}
              />
              {tabHead}
            </Tabs>
          </AppBar>
          <br />
          <TabPanel value={tabValue} index={0} check="true">
            <CallLog />
          </TabPanel>
          <TabPanel value={tabValue} index={1} check="true">
            <CustFormLite groupId={props.groupId} />
          </TabPanel>
          {tabContent}
        </div>
      </CardContent>
    </Card>
  );
};
const CustCategoryFormPlus = memo(CustCategoryFormPlusMemo);
export default CustCategoryFormPlus;
