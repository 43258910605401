import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import LoginContext from "../../context/LoginContext";
import { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import DeleteDialog from "../commonComponents/DeleteDialog";

export default function UserSearchTable({ handleRowDelete, ...props }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };
  const [selectRow, setSelectRow] = useState({});
  // const tt = new Date().toLocaleString().replace(",", "");
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "userId", headerName: `${t("User ID")}`, flex: 1 },
    { field: "lastName", headerName: `${t("Last Name")}`, flex: 1 },
    { field: "firstName", headerName: `${t("First Name")}`, flex: 1 },
    { field: "extensionNumber", headerName: `${t("Extension Number")}`, flex: 1 },
    { field: "privilege", headerName: `${t("Role")}`, flex: 0.5 },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row, "ppppp");
          navigate("/userDetail", { state: { userId: params.row.userId } });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={<EditIcon />}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: `${t("Delete")}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
           setDialogOpen(true);
          setSelectRow(params.row);
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={onClick}
            startIcon={<DeleteIcon />}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];
  const handleDelete = async () => {
    setDelLoading(true);
    console.log(selectRow, "ppppp");
    try {
      const response = await axiosFetch.delete(`/user/${selectRow.userId}`);
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
     setDialogOpen(false);
  };
  const rows = props.rows;
  return (
    <>
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
        />
      </div>
    </>
  );
}
