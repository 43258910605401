import React from "react";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";

export const useRefresh = () => {
  const context = useContext(LoginContext);
  const axiosFetch = AxiosFetch();
  const refresh = async () => {
    const response = await axiosFetch.get("/jwtDetails");
    console.log(response, "refresh response");
    context.setUserRole(+response.data.roles);
    context.setUserId(response.data.userId);
    context.setUserFirstName(response.data.firstName);
    context.setUserLastName(response.data.lastName);
    context.setToken(true);
    // context.setJwtToken()

    return response.data;
  };
  return refresh;
};
