import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../formikInputs/CustomSelect";
import { SearchContext } from "../SerachIndexWrapper";
//Helper
{
  /* <UserSelectFromGroup
mode={readMode}
name="nameHere"
label={t("First User Name")}
groupId={groupId}
originalGroup={originalGroup || 0}
/> */
}

export default function UserSelectFromGroup({ groupId, typeFlag, ...props }) {
  const { t } = useTranslation();
  const { userList } = useContext(SearchContext);
  const [userListNow, setUserListNow] = useState([]);

  useEffect(() => {
    if (groupId === 0) {
      let temp = [];
      temp.push({
        value: "",
        name: t("Not Specified"),
      });
      temp.push({
        value: -1,
        name: t("Unassigned"),
      });
      userList.forEach((data) => {
        temp.push({
          value: data.userId,
          name: data.lastName + " " + data.firstName,
        });
      });
      setUserListNow(temp);
    } else {
      let temp = [];
      temp.push({
        value: "",
        name: t("Not Specified"),
      });
      temp.push({
        value: -1,
        name: t("Unassigned"),
      });
      userList.forEach((data) => {
        const { customerGroups } = data;
        const foundObject = customerGroups.find(
          (item) => item.groupId === groupId
        );
        if (foundObject) {
          //temp.push(data);
          temp.push({
            value: data.userId,
            name: data.lastName + " " + data.firstName,
          });
        }
      });
      setUserListNow(temp);
    }
  }, [groupId]);

  return (
    <div>
      <CustomSelect
        data={{
          name: props.name,
          label: props.label,
          list: userListNow,
        }}
        mode={props.mode}
      />
    </div>
  );
}
