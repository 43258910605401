/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Button, Modal, Center, Text, Grid, Select } from "@mantine/core";
import { DatePicker } from '@mantine/dates';
import { useForm } from "@mantine/form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { formatDate } from './dateFormats';
import $ from "jquery"; //0707追記分
import { useDrop } from "react-dnd";

import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router";
import { images } from "./assets";
import events from "./events.json";
import resources from "./resources.json";
import "./styles.css";
import CalendarPopup from "./CalendarPopup/CalendarPopup";
import EditCalendarPopup from "./EditCalendarPopup/EditCalendarPopup";
import DeletePopup from "./DeletePopup/DeletePopup";

import { useUserEventData, useFetchResources } from "./useCalendarData";
import { AxiosFetch } from "../AxiosFetch";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { useDrag } from "react-dnd";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import {  parse } from 'date-fns'; // You can use date-fns for date formatting and parsing



const Calendar = () => {
  const { userId, ...context } = useContext(LoginContext);

var existingCalendarData ={}
  const axiosFetch2 = AxiosFetch();
  useEffect(() => {
    context.setPageTitle("Calendar");
  }, []);
  const style = {
    position: "absolute",
    zIndex: 10,
    border: "1px solid black",
    borderRadius: 5,
    backgroundColor: "white",
    padding: "1.5rem 2rem",
    cursor: "move",
  };
  const DraggableBox = ({ id, left, top, hideSourceOnDrag, children }) => {
    const [position, setPosition] = useState({ left, top });

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: "box",
        item: { id, left: position.left, top: position.top },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        drag: (item, monitor) => {
          const clientOffset = monitor.getClientOffset();
          if (clientOffset) {
            const left =
              clientOffset.x - monitor.getInitialSourceClientOffset().x;
            const top =
              clientOffset.y - monitor.getInitialSourceClientOffset().y;
            setPosition({ left, top });
          }
        },
      }),
      [id, position]
    );

    if (isDragging && hideSourceOnDrag) {
      return <div ref={drag} />;
    }
    return (
      <div
        className="box"
        ref={drag}
        style={{ ...style, left: position.left, top: position.top }}
        data-testid="box"
      >
        {children}
      </div>
    );
  };


  function clearCalendarLocalStorage() {
    localStorage.removeItem('calendar')
    window.location.reload();

  }

  const axiosFetch = AxiosFetch();
  const [userRows, setUserRows] = useState([]);
  const [group, setGroupRows] = useState([]);

  useEffect(() => {
    async function getDataUserAll() {
      const response = await axiosFetch.get(`/user`);
      setUserRows(response.data);
      const response2 = await axiosFetch.get(`/group`);
      setGroupRows(response2.data);
    }

    getDataUserAll();
    function convertTZ(date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: tzString }
        )
      );
    }
    const date = new Date();
    var date1 = convertTZ(date, "Asia/Tokyo");
    date1 = date1.toLocaleTimeString().slice(0, 5);

    var date2 = convertTZ(date, "Australia/Adelaide");
    date2 = date2.toLocaleTimeString().slice(0, 5);

    $(".fc-timeline-slot-frame").each(function () {
      var timeSlot = $(this).text();

      if (timeSlot.length == 4) {
        var zero = "0";
        timeSlot = zero.concat("", timeSlot);
      }
      var timeSlot2 = "";

      if (timeSlot == "00:00") {
        timeSlot2 = "00:30";
      }
      if (timeSlot == "00:30") {
        timeSlot2 = "01:00";
      }
      if (timeSlot == "01:00") {
        timeSlot2 = "01:30";
      }
      if (timeSlot == "01:30") {
        timeSlot2 = "02:00";
      }
      if (timeSlot == "02:00") {
        timeSlot2 = "02:30";
      }
      if (timeSlot == "02:30") {
        timeSlot2 = "03:00";
      }
      if (timeSlot == "03:00") {
        timeSlot2 = "03:30";
      }
      if (timeSlot == "03:30") {
        timeSlot2 = "04:00";
      }
      if (timeSlot == "04:00") {
        timeSlot2 = "04:30";
      }
      if (timeSlot == "04:30") {
        timeSlot2 = "05:00";
      }
      if (timeSlot == "05:00") {
        timeSlot2 = "05:30";
      }
      if (timeSlot == "05:30") {
        timeSlot2 = "06:00";
      }
      if (timeSlot == "06:00") {
        timeSlot2 = "06:30";
      }
      if (timeSlot == "06:30") {
        timeSlot2 = "07:00";
      }
      if (timeSlot == "07:00") {
        timeSlot2 = "07:30";
      }
      if (timeSlot == "07:30") {
        timeSlot2 = "08:00";
      }
      if (timeSlot == "08:00") {
        timeSlot2 = "08:30";
      }
      if (timeSlot == "08:30") {
        timeSlot2 = "09:00";
      }
      if (timeSlot == "09:00") {
        timeSlot2 = "09:30";
      }
      if (timeSlot == "09:30") {
        timeSlot2 = "10:00";
      }
      if (timeSlot == "10:00") {
        timeSlot2 = "10:30";
      }
      if (timeSlot == "10:30") {
        timeSlot2 = "11:00";
      }
      if (timeSlot == "11:00") {
        timeSlot2 = "11:30";
      }
      if (timeSlot == "11:30") {
        timeSlot2 = "12:00";
      }

      if (timeSlot == "12:00") {
        timeSlot2 = "12:30";
      }
      if (timeSlot == "12:30") {
        timeSlot2 = "13:00";
      }
      if (timeSlot == "13:00") {
        timeSlot2 = "13:30";
      }
      if (timeSlot == "13:30") {
        timeSlot2 = "14:00";
      }
      if (timeSlot == "14:00") {
        timeSlot2 = "14:30";
      }
      if (timeSlot == "14:30") {
        timeSlot2 = "15:00";
      }
      if (timeSlot == "15:00") {
        timeSlot2 = "15:30";
      }
      if (timeSlot == "15:30") {
        timeSlot2 = "16:00";
      }
      if (timeSlot == "16:00") {
        timeSlot2 = "16:30";
      }
      if (timeSlot == "16:30") {
        timeSlot2 = "17:00";
      }
      if (timeSlot == "17:00") {
        timeSlot2 = "17:30";
      }
      if (timeSlot == "17:30") {
        timeSlot2 = "18:00";
      }
      if (timeSlot == "18:00") {
        timeSlot2 = "18:30";
      }
      if (timeSlot == "18:30") {
        timeSlot2 = "19:00";
      }
      if (timeSlot == "19:00") {
        timeSlot2 = "19:30";
      }
      if (timeSlot == "19:30") {
        timeSlot2 = "20:00";
      }
      if (timeSlot == "20:00") {
        timeSlot2 = "20:30";
      }
      if (timeSlot == "20:30") {
        timeSlot2 = "21:00";
      }
      if (timeSlot == "21:00") {
        timeSlot2 = "21:30";
      }
      if (timeSlot == "21:30") {
        timeSlot2 = "22:00";
      }
      if (timeSlot == "22:00") {
        timeSlot2 = "22:30";
      }
      if (timeSlot == "22:30") {
        timeSlot2 = "23:00";
      }
      if (timeSlot == "23:00") {
        timeSlot2 = "23:30";
      }
      if (timeSlot == "23:30") {
        timeSlot2 = "24:00";
      }
      if (timeSlot == "24:00") {
        timeSlot2 = "00:30";
      }

      if (date1 > timeSlot && date1 < timeSlot2) {
        //Change 13 and 18 according to what you need

        console.log(timeSlot, date1, date2);
        console.log("true");
        $(this)
          .closest(".fc-timeline-slot-frame")
          .css("backgroundColor", "orange");
      }
    });
  }, []);
  const[ticketId,setTicketId]=useState(0)
  const [calendarDate, setCalendarDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const url = window.location.href;
  const urlObject = new URL(url);
  const port = urlObject.port;

  const form = useForm({ initialValues: { userName: "", department: "" } });
  const form2 = useForm({ initialValues: { date: "" } });
  
  
    useEffect(() => {
      // Retrieve values from localStorage
      const localStorageCalendar = JSON.parse(localStorage.getItem('calendar'));
      console.log(localStorageCalendar)
      const localStorageMyEvent = localStorage.getItem('myEvent');

      if (localStorageCalendar) {
        // Use values from localStorage
        if (localStorageCalendar.calendarDate) {
          setCalendarDate(localStorageCalendar.calendarDate);
        }
        if (localStorageCalendar.startDate && !isNaN(Date.parse(localStorageCalendar.startDate))) {
  
          setStartDate(localStorageCalendar.startDate);
          setCalendarDate(localStorageCalendar.startDate)
        
          setClearDateInput(true);
          
          form2.setFieldValue("date", localStorageCalendar.startDate);
          
        }
        if (localStorageCalendar.department) {
          // alert(localStorageCalendar.department)
          form.setFieldValue('department', localStorageCalendar.department);
        }
        if (localStorageCalendar.userName) {
          form.setFieldValue('userName', localStorageCalendar.userName);
        }
        if (localStorageCalendar.handleInterval > -1) {
          setDaysInterval(localStorageCalendar.handleInterval);
            form3.setFieldValue("timeInterval", localStorageCalendar.handleInterval);
            
        }
        if (localStorageCalendar.iteration === 'prev' || localStorageCalendar.iteration === 'next') {
          setIteration(localStorageCalendar.iteration);
        }
        if (localStorageCalendar.myEvent !== undefined) {
          setMyEvent(localStorageCalendar.myEvent);
        }
      }

    }, [daysInterval]);
    
  const form3 = useForm({ initialValues: { timeInterval: "" } });
  const navigate = useNavigate();
  const [daysInterval, setDaysInterval] = useState(7);

  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  const [iteration, setIteration] = useState("");
  const [myEvent, setMyEvent] = useState(false);
  const [clearDateInput, setClearDateInput] = useState(false);

  const [myUserId] = userId;

  const [resourcesState, setResourcesState] = useState([]);
  const [eventState, setEventState] = useState([]);

  const refMySchedule = useRef("false");

  const [filterUser, setFilterUser] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [arg, setEventArgs] = useState();

  const [boxes, setBoxes] = useState({
    a: { top: 300, left: 350, title: "Calendar popup" },
  });

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes({
        a: { top: top, left: left, title: "Calendar popup" },
      });
    },
    [boxes, setBoxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left + 10, top + 10);
        return undefined;
      },
    }),
    [moveBox]
  );

  const handleLink = (link) => {
    navigate(link);
  };
  
  let click = useRef(false).current;

  useEffect(() => {
    axiosFetch
      .post(
        `https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/userAndDep/fetchUserDepMst.php?port=${port}`
      )

      .then(function (response) {
        setFilterUser(response.data[0]);
        setFilterGroup(response.data[1]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [opened, setOpened] = useState(false);
  const [editPopupOpened, setEditPopupOpened] = useState(false);

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [eventGroupId, setEventGroupId] = useState(0);
  const [eventRepeat, setEventRepeat] = useState("false");
  const [existAttendees, setExistAttendees] = useState("false");
  const [selStartDate, setSelStartDate] = useState(new Date());
  const [resourcesData, setResourcesData] = useState();
  const [userEditEventData, setUserEditEventData] = useState();

  useEffect(() => {
    var theNewEndDate = new Date(startDate);
    theNewEndDate.setDate(theNewEndDate.getDate() + daysInterval - 1);
    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
    setEndDate(theNewEndDate);
  });

  let newDate = new Date();
  const currentdate = [];
  currentdate[2] = newDate.getDate();
  currentdate[1] = newDate.getMonth() + 1;
  currentdate[0] = newDate.getFullYear();

  const changeStartDate = (data) => {
    var theNewStartDate = new Date(startDate);
    var theNewEndDate = new Date(endDate);

    //setEndDate(theNewStartDate)

    var days = parseInt(daysInterval);

    if (data == "prev") {
      theNewStartDate.setDate(theNewStartDate.getDate() - days);

      theNewEndDate.setDate(theNewEndDate.getDate() - days);

      theNewStartDate = theNewStartDate.toISOString().slice(0, 10);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
      //alert(theNewEndDate)
    }

    if (data == "next") {
      theNewStartDate.setDate(theNewStartDate.getDate() + days);
      theNewEndDate.setDate(theNewStartDate.getDate() + (days - 1));

      theNewStartDate = theNewStartDate.toISOString().slice(0, 10);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
    }
    setCalendarDate(theNewStartDate);
    setStartDate(theNewStartDate);
    setEndDate(theNewEndDate);

    setIteration(data);
    
    
    // Retrieve the existing calendar data from localStorage
     existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};

    // Update the properties inside the 'calendar' object
    existingCalendarData.startDate = theNewStartDate;
    existingCalendarData.calendarDate = theNewStartDate;
    existingCalendarData.endDate = theNewEndDate;
    existingCalendarData.iteration = data;

    // Store the updated calendar data back in localStorage
    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));



  };
  const onSuccess = (data) => {
    setEventState(data.data);
    console.log(data);
  };

  const onError = (error) => {
    console.log({ error });
  };

  const onSuccess1 = (data) => {
    console.log("changed");

    setResourcesState(data.data);
  };

  const onError1 = (error) => {
    console.log({ error });
  };

  const fontSize = window.innerWidth < 1000 ? 20 : null;
  const resourceFontSize = window.innerWidth < 1000 ? 30 : null;

  const { data: resourceData } = useFetchResources(
    {
      daysInterval,
      startDate,
      iteration,
      myEvent,
      myUserId,
      userName: form.values.userName,
      department: form.values.department,
      port: port,
    },
    onSuccess1,
    onError1
  );

  const { data: eventData } = useUserEventData(
    {
      daysInterval,
      startDate,
      iteration,
      myEvent,
      myUserId,
      userName: form.values.userName,
      department: form.values.department,
      port: port,
    },
    onSuccess,
    onError
  );

  function eventDetails(eventInfo) {
    if (eventInfo.event.extendedProps.starttime) {
      if (eventInfo.event.extendedProps.registeredVia == "calendar") {
        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {eventInfo.event.extendedProps.eventType == 1 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          custCode: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["phoneForward"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 3 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          custCode: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img
                      src={images["transferStation"]}
                      style={{ height: 20 }}
                    />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 4 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          custCode: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["meetingRoom"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 5 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          custCode: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["localCafe"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 6 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          custCode: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["reportProblem"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                {"管理" == "管理" ? (
                  <Cross2Icon
                    style={{ float: "right" }}
                    onClick={() =>
                      handleDeleteEvent(
                        eventInfo.event.extendedProps.eventId,
                        eventInfo.event.extendedProps.eventGroupId,
                        eventInfo.event.extendedProps.eventRepeat,
                        eventInfo.event.extendedProps.existAttendees,
                        eventInfo.event.extendedProps.registeredVia,
                        eventInfo.event.extendedProps.ticketId
                      )
                    }
                  />
                ) : null}
                <br />
                {eventInfo.event.extendedProps.holiday == "true" ? null : (
                  <>
                    {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                    {eventInfo.event.extendedProps.endtime}
                    <br />
                  </>
                )}
                {eventInfo.event.extendedProps.inquiry}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.tempHolding == "true" ? (
                  <>
                    {" "}
                    {eventInfo.event.extendedProps.registered_by_name} <br />
                  </>
                ) : null}
                {eventInfo.event.extendedProps.location}
                <br />
              </h3>
            </div>
          </>
        );
      }

      if (eventInfo.event.extendedProps.registeredVia == "inquiry") {
        // registeredVia:Inquiry

        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {eventInfo.event.extendedProps.eventType == 1 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["phoneForward"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 3 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img
                      src={images["transferStation"]}
                      style={{ height: 20 }}
                    />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 4 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["meetingRoom"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 5 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["localCafe"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 6 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["reportProblem"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                 {  /* "管理"=="管理" ?
              
               <Cross2Icon style={{ float: 'right',pointerEvent:'auto' }}
                  onClick={() => {


                    handleDeleteEvent(eventInfo.event.extendedProps.eventId, eventInfo.event.extendedProps.eventGroupId, eventInfo.event.extendedProps.eventRepeat,eventInfo.event.extendedProps.existAttendees,eventInfo.event.extendedProps.registeredVia,eventInfo.event.extendedProps.ticketId)
                  }}
                /> :null  */} 
                
                
                <br />
                {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                {eventInfo.event.extendedProps.endtime}
                <br />
                {eventInfo.event.title}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.customerName} 様<br />
                {eventInfo.event.extendedProps.addr}
                <br />
                {eventInfo.event.extendedProps.code}
                <br />
              </h3>
            </div>
          </>
        );
      }

      //registeredVia after AfterFollow

      if (eventInfo.event.extendedProps.registeredVia == "afterFollow") {
        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <Button
                variant="none"
                size={5}
                onClick={() => {
                  navigate("/AfterFollow", {
                    state: {
                      customerCode: eventInfo.event.extendedProps.code,
                      recNum: eventInfo.event.extendedProps.afterFollowId,
                      
                    },
                  });
                }}
              >
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <img src={images["transferStation"]} style={{ height: 20 }} />
                </div>
              </Button>

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                {"管理" == "管理" ? (
                  <Cross2Icon
                    style={{ float: "right", pointerEvent: "auto" }}
                    onClick={() => {
                      handleDeleteEvent(
                        eventInfo.event.extendedProps.eventId,
                        eventInfo.event.extendedProps.eventGroupId,
                        eventInfo.event.extendedProps.eventRepeat,
                        eventInfo.event.extendedProps.existAttendees,
                        eventInfo.event.extendedProps.registeredVia,
                        eventInfo.event.extendedProps.ticketId
                      );
                    }}
                  />
                ) : null}
                <br />
                {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                {eventInfo.event.extendedProps.endtime}
                <br />
                {eventInfo.event.extendedProps.inquiry}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.customerName} 様<br />
                {eventInfo.event.extendedProps.addr}
                <br />
                {eventInfo.event.extendedProps.code}
                <br />
              </h3>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <h2>
            {" "}
            <Center>
              {" "}
              <span id={eventInfo.event.title}></span>
              <Text color="white" size="lg">
                {" "}
                {eventInfo.event.title}
              </Text>
            </Center>{" "}
          </h2>
        </>
      );
    }
  }

  function resourceDetails(resourceInfo) {
    if (resourceInfo.resource.title == "donotdisplay") {
      return <></>;
    } else {
      return (
        <>
          {resourceInfo.resource.title == " " ? (
            <Text color="black" size="xl" weight={800}>
              {" "}
              {resourceInfo.resource._resource.extendedProps.userId}
            </Text>
          ) : (
            <Text color="black" size="xl" weight={800}>
              {" "}
              {resourceInfo.resource.title}
            </Text>
          )}
        </>
      );
    }
  }

  function moreLinkClick(arg) {
    return <>その他のイベント = {arg.num}</>;
  }
  const cssB = { fontSize: "25px", width: "auto" };
  const handleDateClick = (arg) => {
    // bind with an arrow function

    setResourcesData(arg.resource._resource);
    var sDate = arg.resource._resource.id.slice(0, 10);

    var tpart = arg.dateStr.slice(11, 19);

    var result = sDate.concat("T");
    result = result.concat(tpart);

    setSelStartDate(new Date(result));
    setOpened(true);
  };

  const handleEditEvent = () => {
  
    console.log(arg.event._def);

    if (arg.event._def.extendedProps.registeredVia == "calendar" && !click) {
      setUserEditEventData(arg.event._def);
      setEditPopupOpened(true);
    } else {
      if (
        arg.event._def.extendedProps.registeredVia == "afterFollow" &&
        !click
      ) {
        setUserEditEventData(arg.event._def);

        navigate("/AfterFollow", {
          state: {
            customerCode: arg.event._def.extendedProps.code,
            recNum: arg.event._def.extendedProps.afterFollowId,
            eventId: arg.event._def.extendedProps.eventId,
          },
        });
      } else if (!click) {
  // Ask the user for confirmation
  const userConfirmed = window.confirm("この予定を対応しますか？\n「はい」を選ぶとカレンダーからチケットが削除されます");


  if (userConfirmed) {
  //    alert("You chose  to hide.");

      
    //  alert( arg.event._def.extendedProps.ticketId)
      
      axiosFetch2.post(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/hideEvents.php?port=${port}`, JSON.stringify({
        ticketId:arg.event._def.extendedProps.ticketId}))

        .then(function (response) {
                console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
        
                      
      
      navigate("/customerDetail", {
        state: {
          customerId: arg.event._def.extendedProps.code,
          showTopicId: arg.event._def.extendedProps.inquiryLogId,
          eventType: arg.event._def.extendedProps.eventType,
          eventId: arg.event._def.extendedProps.eventId,
          showModal: true,
          ticketId: arg.event._def.extendedProps.ticketId,
        },
      });
      
    
  } else {
    // User clicked "Cancel" (no) in the confirmation dialog
    // Show alert or handle as needed
    // alert("You chose not to hide.");
    navigate("/customerDetail", {
      state: {
        customerId: arg.event._def.extendedProps.code,
        showTopicId: arg.event._def.extendedProps.inquiryLogId,
        eventType: arg.event._def.extendedProps.eventType,
        eventId: arg.event._def.extendedProps.eventId,
        showModal: true,
        ticketId: 0,
      },
    });
  }
} else {
  // Your existing navigation logic

}

    }
  };
  const handleEdit = (arg) => {
    // bind with an arrow function

    setEventArgs(arg);
  };

  const handleDeleteEvent = (
    eventId,
    eventGroupId,
    eventRepeat,
    existAttendees,
    registeredVia,
    ticketId
  ) => {
    // setClick(true);
    click = true;
    if (registeredVia == "calendar") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setEditPopupOpened(false);
      setTicketId(0)
      setDeletePopupOpened(true);
      
    }
    if (registeredVia == "inquiry") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setTicketId(ticketId)
      setEditPopupOpened(false);
      setDeletePopupOpened(true);
      
    }

    if (registeredVia == "afterFollow") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setTicketId(0)
      setEditPopupOpened(false);
      setDeletePopupOpened(true);
      
    }
  };

  function resize() {
    //alert(info.event.end);
  }

  const handleMySchedule = () => {
    
    existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
    existingCalendarData.myEvent = refMySchedule.current.checked;

    // Store the updated calendar data back in localStorage
    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

    setMyEvent(refMySchedule.current.checked);
  };
  // FORM 1 SUBMIT
  

  const handleFilter = () => {
    //console.log(form.values)

    axiosFetch
      .get("formDataEvents.php", {
        params: {
          day: daysInterval,
          userName: form.values.userName,
          department: form.values.department,
          date: form2.values.date,
        },
      })
      .then(function (response) {
        setEventState(response.data);
        //  console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axiosFetch
      .get("formDataResources.php", {
        params: {
          day: daysInterval,
          userName: form.values.userName,
          department: form.values.department,
          date: form2.values.date,
        },
      })
      .then(function (response) {
        setResourcesState(response.data);

        //          console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //FORM2 SUBMIT

  const handleInterval = (value) => {
    var theNewEndDate = new Date(startDate);
    var days = parseInt(value);

    theNewEndDate.setDate(theNewEndDate.getDate() + days - 1);
    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);

    //  console.log(theNewEndDate)
    setEndDate(theNewEndDate);

    //console.log(form3.values.timeInterval)
    setDaysInterval(value);
  };

  const clearDate = () => {
    setClearDateInput(false);
    setStartDate(new Date().toISOString().slice(0, 10));
    setCalendarDate(new Date().toISOString().slice(0, 10));
    form2.setFieldValue("date", null);
  };
  const handleGotoDate = () => {
    if (form2.values.date != "") {
      setStartDate(form2.values.date);
      

      var theNewEndDate = new Date(form2.values.date);
      theNewEndDate.setDate(theNewEndDate.getDate() + 6);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
      setEndDate(theNewEndDate);
      
    }
  };

  const parseDate = (dateString) => {
    return parse(dateString, 'yyyy-MM-dd', new Date());
  };

  function isValidDate(dateString) {
    const pattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    return pattern.test(dateString);
  }


  return (
    <div ref={drop} className="App">
      <Grid columns={42} style={{ fontSize: 17 }}>
        <Grid.Col span={6} style={{ textAlign: "right" }}>
      
  【絞込み】
        </Grid.Col>
        <Grid.Col span={14}>
          <form onSubmit={form.onSubmit(handleFilter)}>
        
            <Select
              value={form.values.department && form.values.department}
              clearable
              style={{ width: "140px", display: "inline-block" }}
              placeholder="部門名"
              data={filterGroup}
              onChange={(value) => {
                    existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                    existingCalendarData.department = value;
                    existingCalendarData.userName = null;
                    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

                form.setFieldValue("department", value);
                form.setFieldValue("userName", null);
              }}
            />
            &nbsp;
            <Select
              clearable
              value={form.values.userName && form.values.userName}
              style={{ width: "140px", display: "inline-block" }}
              //  data={filterUser}
              data={
                form.values.department !== ""
                  ? filterUser.filter(
                      (user) => user.grp_cd == form.values.department
                    )
                  : []
              }
              onChange={(value) =>
                {
                  existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                  existingCalendarData.userName = value;
                  localStorage.setItem('calendar', JSON.stringify(existingCalendarData));
                 form.setFieldValue("userName", value)}
               }
            />
          </form>
        </Grid.Col>
        <Grid.Col span={8}>
          <form onSubmit={form2.onSubmit(handleGotoDate)}>
            {clearDateInput ? (
              <>
                {" "}
                <DatePicker
                
                  name="date"
                  // defaultValue={isValidDate(localStorageCalendar?.startDate) ? localStorageCalendar.startDate : ''}
                  value={isValidDate(form2.values.date) ? parseDate(form2.values.date) : null}
                  inputFormat="YYYY-MM-DD"
                  onChange={(value) => {
                if(value!=null){
                    const datePart = formatDate(value)
              
                    setCalendarDate(datePart)
                    form2.setFieldValue("date", datePart);
                    setClearDateInput(true);

                    var theNewEndDate = new Date(datePart);
                    theNewEndDate.setDate(theNewEndDate.getDate() + 6);
                    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
                    setEndDate(theNewEndDate);
                    setStartDate(datePart);
                    
              const existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
              existingCalendarData.clearDateInput = true;
              existingCalendarData.endDate = theNewEndDate;
              existingCalendarData.startDate = datePart;
              localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

                  }
                else{
                  clearDate();
                }}
                }
                />  {" "}
                
          
                
                {" "}
              </>
            ) : (
              <DatePicker
                inputFormat="YYYY-MM-DD"
              value={isValidDate(form2.values.date) ? parseDate(form2.values.date) : null}
                onChange={(value) => {
                    if(value!=null){
                const datePart =formatDate(value)
              
                  form2.setFieldValue("date", datePart);
                  setStartDate(datePart);
                  setClearDateInput(true);
                    setCalendarDate(datePart)
                existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                existingCalendarData.clearDateInput = true;
                existingCalendarData.startDate = datePart;
                localStorage.setItem('calendar', JSON.stringify(existingCalendarData));


                }
              
            else{
              clearDate();
            }}}
              />
            )}
          </form>
        </Grid.Col>
        <Grid.Col span={7}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginTop: -20,
            }}
          >
            <input
              type="checkbox"
              name="mySchedule"
              style={{
                ...(window.innerWidth < 1000 ? { width: 75, height: 70 } : {}),
              }}
              checked={myEvent}
              ref={refMySchedule}
              onChange={handleMySchedule}
            />
            <p
              style={{
                ...(window.innerWidth < 1000 && cssB),
                display: "inline",
                marginLeft: "5px",
              }}
            >
              マイスケジュール表示
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={7}>
          <form onSubmit={form3.onSubmit(handleInterval)}>
            <select
              name="timeInterval"
              value={form3.values.timeInterval && form3.values.timeInterval}
              style={{ width: "80px" }}
              clearable={true}
              onChange={(event) => {
                form3.setFieldValue("timeInterval", event.currentTarget.value);
                handleInterval(parseInt(event.currentTarget.value));
                existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                existingCalendarData.handleInterval = parseInt(event.currentTarget.value);
                localStorage.setItem('calendar', JSON.stringify(existingCalendarData));


              }}
            >
              <option value="7">週間</option>
              <option value="14">14 日</option>
              <option value="30">30 日</option>
            </select>
          </form>
        </Grid.Col>
      </Grid>

      <br />
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <Grid columns={30}>
          <Grid.Col span={12}>  <button onClick={clearCalendarLocalStorage}>絞込み条件クリア</button> </Grid.Col>
          <Grid.Col span={17}>
            {" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                size="sm"
                className="calendarIterateButton"
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  changeStartDate("prev");
                }}
              >
                &#60;
              </Button>
              <Text
                size="xl"
                underline
                style={{ marginLeft: "10px", fontSize: resourceFontSize }}
              >
                {startDate} -{endDate}
              </Text>
              <Button
                size="sm"
                className="calendarIterateButton"
                style={{ marginLeft: "10px", backgroundColor: "black" }}
                onClick={() => {
                  changeStartDate("next");
                }}
              >
                &#62;
              </Button>
            </div>
          </Grid.Col>
          <Grid.Col span={1}> </Grid.Col>
        </Grid>
      </div>

      <br />

      <Text
        style={{
          backgroundColor: "black",
          color: "white",
          height: "70px",
          width: "100%",
          textAlign: "center",
          textAlignVertical: "center",
          fontWeight: "800",
          fontSize: 20,
          marginRight: 0,
          paddingTop: "20px",
          paddingBottom: "20px",
          marginBottom: "-20px",
        }}
      >
        {calendarDate}
      </Text>

      <FullCalendar
        className="full-calendar"
        eventOverlap={false}
        headerToolbar="false"
        initialView="resourceTimeline"
        plugins={[
          resourceTimelinePlugin,
          interactionPlugin,
          momentTimezonePlugin,
        ]}
        height={window.innerWidth > 1000 ? "700px" : "1200px"}
        slotMinTime="08:00"
        slotMaxTime="22:00"
        slotDuration="00:30"
        slotLabelInterval="00:30"
        slotMinWidth={window.innerWidth > 1000 ? "67" : "100"}
        scrollTime="09:00"
        timeZone="Asia/Tokyo"
        timeZoneParam="Asia/Tokyo"
        eventTextColor="black"
        moreLinkContent={moreLinkClick}
        eventContent={eventDetails}
        events={eventState}
        resources={resourcesState}
        eventClick={handleEdit}
        dateClick={handleDateClick}
        eventMouseEnter={function (info) {
          // get a reference to the event DOM element
          var eventEl = info.el;
          // modify the appearance of the event element
          // eventEl.style.backgroundColor = 'yellow';
          // eventEl.style.borderColor = 'black';
          // display additional information about the event
          var eventObj = info.event;
          // setCalendarDate(event.title)
          if (eventObj.extendedProps.registeredVia == undefined) {
            setCalendarDate(eventObj.title);
          }
        }}
        resourceLabelContent={resourceDetails}
        resourceAreaHeaderContent="ユーザー"
        resourceAreaWidth="20%"
        locale="ja"
        eventResize={resize}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
      />

      <Modal
        opened={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        size="xl"
      >
        <DeletePopup
          ticketId={ticketId}
          eventId={eventId}
          eventGroupId={eventGroupId}
          eventRepeat={eventRepeat}
          existAttendees={existAttendees}
          modalControl3={setDeletePopupOpened}
        />
      </Modal>

      {editPopupOpened ? (
        <>
          ({" "}
          <Modal
            opened={editPopupOpened}
            onClose={() => setEditPopupOpened(false)}
            size="xl"
          >
            <EditCalendarPopup
              data={userEditEventData}
              modalControl2={setEditPopupOpened}
            />
          </Modal>
          )
        </>
      ) : null}

      {opened ? (
        <DraggableBox
          left={boxes.a.left}
          top={boxes.a.top}
          hideSourceOnDrag={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Cross1Icon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpened(false);
              }}
            />
          </div>
          <CalendarPopup
            selectedStartDate={selStartDate}
            resources={resourcesData}
            modalControl={setOpened}
          />
        </DraggableBox>
      ) : null}
    </div>
  );
};

export default Calendar;
