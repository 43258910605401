import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid } from "@mui/material";
import CustomTextField from "../../../formikInputs/CustomTextField";
import { Form, Formik, useFormikContext } from "formik";
import { AxiosFetch } from "../../../AxiosFetch";
import { useLocation } from "react-router";
import CallGroupSelect from "./helper/CallGroupSelect";
import CallFlagSelect from "./helper/CallFlagSelect";
import PhaseSelect from "./helper/PhaseSelect";
import CallerSelect from "./helper/CallerSelect";
import { modalCustomStyle } from "../../../commonComponents/ModalCusotmStyle";
import LoginContext from "../../../../context/LoginContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
import { ChatworkModalForTopic } from "./ChatworkModalForTopic";
import ChatworkAPICall from "../../../sns/chatwork/ChatworkAPIcall";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function CreateTopicModal({ openModal, handleModalChange }) {
  const { t } = useTranslation();

  const axiosFetch = AxiosFetch();
  const { userId, userFirstName, userLastName, customerInfo, ...context } =
    useContext(LoginContext);
  const [callGroup, setCallGroup] = useState();
  const [saveLoading, setSaveLoading] = useState(false);

  const location = useLocation();
  const customerId = location.state.customerId;
  // const userId = sessionStorage.getItem("userId");

  const [shareSaveFlag, setShareSaveFlag] = useState(false);
  const [openChatwork, setOpenChatwork] = useState(false);
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [formValuesForTopic, setFormValuesForTopic] = useState(null);

  const initialValues = {
    title: "",
    phase: "",
    caller: "",
    callLogGroup: { id: 0 },
    callFlag: {},
    nextCallDate: "",
    memo: "",
    user: { userId: userId },
    customer: { customerId: customerId },
  };
  const formValidation = Yup.object().shape({
    phase: Yup.string().required(t("This field is required")),
    // callLogGroup: Yup.object().shape({
    //   id: Yup.string()
    //     .required(t("This field is required"))
    //     .test(
    //       "zero not allowed",
    //       t("This field is required"),
    //       (val) => Number(val) !== 0
    //     ),
    // }),
    memo: Yup.string().max(3999, t("Text must be less than 4000 characters")),
  });

  const handleSubmitForChatwork = async (values, selectedRoomList) => {
    setLoadingCircle(true);
    try {
      const response = await axiosFetch.post("/topic", values);
      console.log(response, "topic resopnsesssss");
      const chanName1 = localStorage.getItem("chanName");
      let ticket = {
        callLogGroup: { id: response.data.callLogGroup.id },
        callFlag: { id: response.data.callFlag && response.data.callFlag.id },
        caller: response.data.caller,
        phase: response.data.phase,
        topic: { id: response.data.id },
        memo: response.data.memo,
        registerUserId: userId,
        chanName: "",
        startTime: "",
        endTime: "",
        answerTime: "",
        src: "",
        destination: "",
        disposition: "",
        nextCallDate: values.nextCallDate,
      };
      if (chanName1) {
        const apipath = process.env.REACT_APP_PBX_URL || "";
        var body = new FormData();
        body.append("command", "result");
        body.append("channame", `${chanName1}`);
        await axios({
          method: "post",
          url: apipath,
          data: body,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response1) {
            //handle success
            ticket.chanName = chanName1;
            ticket.startTime = response1.data.start;
            ticket.endTime = response1.data.end;
            ticket.answerTime = response1.data.answer;
            ticket.src = response1.data.src;
            ticket.destination = response1.data.destination;
            ticket.disposition = response1.data.disposition;
          })
          .catch(function (response1) {
            console.log(response1.error);
          });

        try {
          const responseTic = await axiosFetch.post("/ticket", ticket);
          console.log(
            responseTic,
            "ticket response after topic save when chanName1"
          );
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${t("Topic and Ticket saved successfully")}`,
          });
          localStorage.removeItem("chanName");
          await Promise.all(
            selectedRoomList.map(async (data, index) => {
              // const messageBody =
              //   `From: ${userLastName} ${userFirstName} (${userId})\n` +
              //   `Customer ID: ${customerInfo.id}\n` +
              //   `Customer Last Name: ${customerInfo.lastName}\n` +
              //   `Title: ${response.data.title}\n` +
              //   `Ticket Details:\n` +
              //   `  Phase: ${responseTic.data.phase}\n` +
              //   `  Caller: ${responseTic.data.caller}\n` +
              //   `  Call Group: ${responseTic.data.callLogGroup.name}\n` +
              //   `  Call Flag: ${responseTic.data.callFlag.callLogGroupName}\n` +
              //   `  Next Call Date: ${responseTic.data.nextCallDate}\n` +
              //   `  Memo: ${responseTic.data.memo}\n`;
              const messageBody =
                `-----------------------------------------------------\n\n` +
                `送信者: ${userLastName} ${userFirstName} (${userId})\n\n` +
                `顧客ID: ${customerInfo.id}\n` +
                `顧客姓: ${customerInfo.lastName}\n\n` +
                `タイトル: ${response.data.title}\n\n` +
                `チケット情報:\n` +
                `  └商談フェーズ: ${responseTic.data.phase}\n` +
                `  └応対相手: ${responseTic.data.caller}\n` +
                `  └応対フラググループ: ${responseTic.data.callLogGroup.name}\n` +
                `  └応対フラグ: ${responseTic.data.callFlag.callLogGroupName}\n` +
                `  └次回予定: ${responseTic.data.nextCallDate}\n` +
                `  └応対メモ:\n` +
                `${responseTic.data.memo
                  .split("\n")
                  .map((line) => `           ${line}`)
                  .join("\n")}\n\n` +
                `-----------------------------------------------------`;
              const responseC = await ChatworkAPICall(
                `curl -X POST -H "x-chatworktoken: 7d045659dd6cc607ef9772debcf11ae0" -d "body=${messageBody}" "https://api.chatwork.com/v2/rooms/${data.roomId}/messages"`
              );
              if (
                responseC &&
                responseC.result &&
                responseC.result.includes("message_id")
              ) {
                context.setGlobalAlert({
                  open: true,
                  severity: "success",
                  title: "success",
                  message: `message sent to ${data.roomName}`,
                });
              } else {
                context.setGlobalAlert({
                  open: true,
                  severity: "error",
                  title: "error",
                  message: `message failed to ${data.roomName}`,
                });
              }
            })
          );
        } catch (error) {
          console.log(error, "error with ticket API when chanName");
          context.setGlobalAlert({
            open: true,
            severity: "error",
            title: "error",
            message: `${
              error.response &&
              error.response.data &&
              error.response.data.errorMessage
            }`,
          });
        }
      } else {
        try {
          const responseTic = await axiosFetch.post("/ticket", ticket);
          //window.alert("Topic and Ticket saved successfully");
          console.log(responseTic, "ticket response after topic save");
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${t("Topic and Ticket saved successfully")}`,
          });
          localStorage.removeItem("chanName");
          await Promise.all(
            selectedRoomList.map(async (data, index) => {
              // const messageBody =
              //   `From: ${userLastName} ${userFirstName} (${userId})\n` +
              //   `Customer ID: ${customerInfo.id}\n` +
              //   `Customer Last Name: ${customerInfo.lastName}\n` +
              //   `Title: ${response.data.title}\n` +
              //   `Ticket Details:\n` +
              //   `  Phase: ${responseTic.data.phase}\n` +
              //   `  Caller: ${responseTic.data.caller}\n` +
              //   `  Call Group: ${responseTic.data.callLogGroup.name}\n` +
              //   `  Call Flag: ${responseTic.data.callFlag.callLogGroupName}\n` +
              //   `  Next Call Date: ${responseTic.data.nextCallDate}\n` +
              //   `  Memo: ${responseTic.data.memo}\n`;
              const messageBody =
                `-----------------------------------------------------\n\n` +
                `送信者: ${userLastName} ${userFirstName} (${userId})\n\n` +
                `顧客ID: ${customerInfo.id}\n` +
                `顧客姓: ${customerInfo.lastName}\n\n` +
                `タイトル: ${response.data.title}\n\n` +
                `チケット情報:\n` +
                `  └商談フェーズ: ${responseTic.data.phase}\n` +
                `  └応対相手: ${responseTic.data.caller}\n` +
                `  └応対フラググループ: ${responseTic.data.callLogGroup.name}\n` +
                `  └応対フラグ: ${responseTic.data.callFlag.callLogGroupName}\n` +
                `  └次回予定: ${responseTic.data.nextCallDate}\n` +
                `  └応対メモ:\n` +
                `${responseTic.data.memo
                  .split("\n")
                  .map((line) => `           ${line}`)
                  .join("\n")}\n\n` +
                `-----------------------------------------------------`;
              const responseC = await ChatworkAPICall(
                `curl -X POST -H "x-chatworktoken: 7d045659dd6cc607ef9772debcf11ae0" -d "body=${messageBody}" "https://api.chatwork.com/v2/rooms/${data.roomId}/messages"`
              );
              if (
                responseC &&
                responseC.result &&
                responseC.result.includes("message_id")
              ) {
                context.setGlobalAlert({
                  open: true,
                  severity: "success",
                  title: "success",
                  message: `message sent to ${data.roomName}`,
                });
              } else {
                context.setGlobalAlert({
                  open: true,
                  severity: "error",
                  title: "error",
                  message: `message failed to ${data.roomName}`,
                });
              }
            })
          );
        } catch (error) {
          console.log(error, "error with ticket API");
          context.setGlobalAlert({
            open: true,
            severity: "error",
            title: "error",
            message: `${error.response.data.errorMessage}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error with topic API");
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
    } finally {
      setLoadingCircle(false);
      handleModalChange();
    }
  };

  const handleSubmit = async (values) => {
    console.log(values, "on submit form values");

    if (shareSaveFlag) {
      setFormValuesForTopic(values);
      setOpenChatwork(true);
    } else {
      setSaveLoading(true);
      try {
        const response = await axiosFetch.post("/topic", values);
        console.log(response, "topic resopnsesssss");
        const chanName1 = localStorage.getItem("chanName");
        let ticket = {
          callLogGroup: { id: response.data.callLogGroup && response.data.callLogGroup.id || 0 },
          callFlag: { id: response.data.callFlag && response.data.callFlag.id || 0 },
          caller: response.data.caller,
          phase: response.data.phase,
          topic: { id: response.data.id },
          memo: response.data.memo,
          registerUserId: userId,
          chanName: "",
          startTime: "",
          endTime: "",
          answerTime: "",
          src: "",
          destination: "",
          disposition: "",
          nextCallDate: values.nextCallDate,
        };
        if (chanName1) {
          const apipath = process.env.REACT_APP_PBX_URL || "";
          var body = new FormData();
          body.append("command", "result");
          body.append("channame", `${chanName1}`);
          await axios({
            method: "post",
            url: apipath,
            data: body,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response1) {
              //handle success
              ticket.chanName = chanName1;
              ticket.startTime = response1.data.start;
              ticket.endTime = response1.data.end;
              ticket.answerTime = response1.data.answer;
              ticket.src = response1.data.src;
              ticket.destination = response1.data.destination;
              ticket.disposition = response1.data.disposition;
            })
            .catch(function (response1) {
              console.log(response1.error);
            });

          try {
            await axiosFetch.post("/ticket", ticket);

            context.setGlobalAlert({
              open: true,
              severity: "success",
              title: "success",
              message: `${t("Topic and Ticket saved successfully")}`,
            });
            localStorage.removeItem("chanName");
          } catch (error) {
            console.log(error, "error with ticket API when chanName");
            context.setGlobalAlert({
              open: true,
              severity: "error",
              title: "error",
              message: `${error.response.data.errorMessage}`,
            });
          }

          {
            /*
          axiosFetch.post(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/eventFromCustomer.php?port=${port}`,JSON.stringify({
  
          nextTimeHi:'10:00',
          nextTimeLow:"12:00",
          nextDate:'2023-02-20',
          cust_cd:customerId,
          userGroup:2,
          subjectContent:'Registered from Topic screen ',
          user_cd:'admin',
          ticketId:response.data.id
  
        }));
        
        */
          }
        } else {
          try {
            await axiosFetch.post("/ticket", ticket);
            //window.alert("Topic and Ticket saved successfully");
            context.setGlobalAlert({
              open: true,
              severity: "success",
              title: "success",
              message: `${t("Topic and Ticket saved successfully")}`,
            });
            localStorage.removeItem("chanName");
          } catch (error) {
            console.log(error, "error with ticket API");
            context.setGlobalAlert({
              open: true,
              severity: "error",
              title: "error",
              message: `${error.response.data.errorMessage}`,
            });
          }
        }
        handleModalChange();
      } catch (error) {
        console.log(error, "error with topic API");
        context.setGlobalAlert({
          open: true,
          severity: "error",
          title: "error",
          message: `${error.response.data.errorMessage}`,
        });
      }
      setSaveLoading(false);
    }
  };

  const FormObserver = () => {
    const { values, errors } = useFormikContext();
    //console.log(values, "live form values", errors);
    useEffect(() => {
      setCallGroup(values.callLogGroup.id || "");
    }, [values.callLogGroup.id]);
    return null;
  };

  return (
    <div>
      <Modal
        sx={modalCustomStyle}
        open={openModal}
        onClose={handleModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form>
            <FormObserver />
            <ChatworkModalForTopic
              open={openChatwork}
              handleOpen={(e) => setOpenChatwork(e)}
              formValuesForTopic={formValuesForTopic}
              setLoadingCircle={(e) => setLoadingCircle(e)}
              handleSubmitForChatwork={handleSubmitForChatwork}
            />{" "}
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "40%",
                display: loadingCircle ? "block" : "none",
                zIndex: 100,
              }}
              color="secondary"
              size={50}
            />
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("Create New Topic")}
              </Typography>
              <Grid
                container
                spacing={1}
                justifyContent={"center"}
                id="modal=modal-description"
              >
                <Grid item xs={4}>
                  {/* <TitleSelect name="title" /> */}
                  <CustomTextField
                    data={{ name: "title", label: `${t("Title")}` }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <PhaseSelect name="phase" />
                </Grid>
                <Grid item xs={4}>
                  <CallerSelect name="caller" />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CallGroupSelect
                    // mode={readMode}
                    name="callLogGroup.id"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CallFlagSelect name="callFlag.id" callGroup={callGroup} />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CustomTextField
                    type="datetime-local"
                    data={{ name: "nextCallDate", label: "Next Call Date" }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <LoadingButton
                    loading={saveLoading}
                    size={isMobile ? "small" : "large"}
                    type="submit"
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={() => {
                      setShareSaveFlag(false);
                    }}
                    fullWidth
                  >
                    {t("Save")}
                  </LoadingButton>
                  <Button
                    size={isMobile ? "small" : "large"}
                    type="submit"
                    startIcon={<ShareIcon />}
                    variant="contained"
                    color="complementary"
                    onClick={() => {
                      setShareSaveFlag(true);
                    }}
                    fullWidth
                  >
                    {t("Save And Share To SNS")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    data={{ name: "memo", label: `${t("Memo")}` }}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}
