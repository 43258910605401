import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosFetch } from "../AxiosFetch";

const axiosFetch = AxiosFetch();


const addEvent = (eventData,port) => {

  console.log(eventData);
     return axiosFetch.post(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/addEvent.php?port=${port}`, JSON.stringify(eventData))
}


export const useAddEvent = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (eventParam) => addEvent({...eventParam}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['userEvent']);
            },
        }
    )
}



const editEvent = (eventData,port) => {
  console.log(eventData);
    return axiosFetch.post(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/updateEvent.php?port=${port}`, JSON.stringify(eventData))
}



export const useEditEvent = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (eventParam) => editEvent({...eventParam}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['userEvent']);
            },
        }
    )
}


const fetchUserEventData = ({ queryKey }) => {

    const { daysInterval, startDate, iteration, myEvent, myUserId, userName, department , port } = queryKey[1];
    //  return axiosFetch.get(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/CustomerRelated/fetchEventsData.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}`)

    return axiosFetch.get(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/fetchEventsData.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}`)
}

export const useUserEventData = (dataFields, onSuccess, onError) => {
    return useQuery(
        ['userEvent', dataFields],
        fetchUserEventData,  {refetchOnWindowFocus: false ,
        onSuccess,
        onError
    })
}



const fetchResources = ({ queryKey }) => {


    const { daysInterval, startDate, iteration, myEvent, myUserId, userName, department, port  } = queryKey[1];

    return axiosFetch.get(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/fetchResources.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}`)
}

export const useFetchResources = (dataFields, onSuccess, onError) => {
    return useQuery(
        ['userResources', dataFields],
        fetchResources, {refetchOnWindowFocus: false ,
        onSuccess,
        onError
    })
}

const deleteEventData = (id,port) => {
    return axiosFetch.delete(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/deleteEvent.php?port=${id['port']}`, {
        data: JSON.stringify(id)
         
    })
}

export const useDeleteEventData = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteEventData, {
        onSuccess: () => {
            queryClient.invalidateQueries('userEvent');
        }

    })
}




const bulkDeleteEventData = (id,port) => {
    return axiosFetch.delete(`https://topline-cti.com:9801/toplinecrm/tl-ins/public/cti-calendar/Calendar/deleteBulkEvents.php?port=${id['port']}`, {
        data: JSON.stringify(id)
    })
}

export const useBulkDeleteEventData = () => {
    const queryClient = useQueryClient();

    return useMutation(bulkDeleteEventData, {
        onSuccess: () => {
            queryClient.invalidateQueries('userEvent');
        }

    })
}
