import { LoadingButton } from "@mui/lab";
import { Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "../formikInputs/CustomSelect";
import GroupSelect from "../formikInputs/GroupSelect";
import ProjectSelectByGroup from "../formikInputs/ProjectSelectByGroup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useFormikContext } from "formik";

function Options(props) {
  const [groupId, setGroupId] = useState(0);
  const { t } = useTranslation();

  const FormObserver = () => {
    const {values} = useFormikContext();
    useEffect(() => {
      setGroupId(values.groupId);
    }, [values.groupId])
    
    setGroupId(values.groupId);
  }
  return (
    <div>
      <FormObserver />
      <Grid container direction="row" columnSpacing={2} rowSpacing={4}>
        <Grid item xs={2.8}>
          <GroupSelect name="groupId" required></GroupSelect>
        </Grid>
        <Grid item xs={2.8}>
          <ProjectSelectByGroup
            mode=""
            name="projectId"
            label={t("Project")}
            groupId={groupId}
            typeFlag="no-search"
            required
          />
        </Grid>
        <Grid item xs={3}>
          <CustomSelect
            data={{
              name: "importType",
              label: `${t("Select Import type")}`,
              list: [
                { value: "noImport", name: `${t("Do not Import")}` },
                { value: "import", name: `${t("Import")}` },
                { value: "overWrite", name: `${t("Overwrite")}` },
              ],
            }}
            defaultValue={"noImport"}
          ></CustomSelect>
        </Grid>
        <Grid item xs={0.4}>
          <Tooltip
            fontSize="small"
            title={t(
              `Specifies import behavior when phone numbers in the file already exist in the system. "Import prohibited" → Will not be imported. "Import" → The list in the file will also be imported, and there will be two identical numbers. "Overwrite" → Replaces the list in the system with the list in the file.`
            )}
          >
            <HelpOutlineIcon />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            type="submit"
            loading={props.loading}
            loadingIndicator="Saving..."
            variant="contained"
            size="large"
            fullWidth
          >
            {t("Import")}
          </LoadingButton>
          {/* <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Submit
                    </Button> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default Options;
