import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  CircularProgress,
  colors,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import ChatworkAPICall from "../sns/chatwork/ChatworkAPIcall";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginContext from "../../context/LoginContext";
import CheckIcon from "@mui/icons-material/Check";
import { isMobile } from "react-device-detect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isMobile ? 2 : 4,
};
export const ChatworkModal = ({
  open,
  handleOpen,
  values,
  handleSubmitForChatwork,
  handleModalChange,
}) => {
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, userFirstName, userLastName, customerInfo, ...context } =
    useContext(LoginContext);

  const ChildModal = () => {
    const [loading, setLoading] = useState(true);
    const [roomList, setRoomList] = useState([]);
    const [checked, setChecked] = React.useState([]);
    const [loadingCircle, setLoadingCircle] = useState(false);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };
    useEffect(() => {
      async function getData() {
        try {
          const response = await axiosFetch.get("/chatworkRoomByUserId");
          console.log(response, "chatwork room get response");
          setRoomList(response.data);
        } catch (error) {
          console.log(error, "error");
        } finally {
          setLoading(false);
        }
      }
      getData();
    }, []);
    const handleSubmit = async () => {
      var APIvalues = [];
      setLoadingCircle(true);
      checked.forEach((index) => {
        APIvalues.push(roomList[index]);
      });
      const flag = await handleSubmitForChatwork(values);
      console.log(values, "values from ticket response to seend", flag);
      if (flag !== false) {
        await Promise.all(
          APIvalues.map(async (data, index) => {
            // const messageBody =
            //   `From: ${userLastName} ${userFirstName} (${userId})\n` +
            //   `Customer ID: ${customerInfo.id}\n` +
            //   `Customer Last Name: ${customerInfo.lastName}\n` +
            //   `Ticket Details:\n` +
            //   `  Phase: ${flag.phase}\n` +
            //   `  Caller: ${flag.caller}\n` +
            //   `  Call Group: ${flag.callLogGroup.name}\n` +
            //   `  Call Flag: ${flag.callFlag.callLogGroupName}\n` +
            //   `  Next Call Date: ${flag.nextCallDate}\n` +
            //   `  Memo: ${flag.memo}\n`;
            const messageBody =
              `-----------------------------------------------------\n\n` +
              `送信者: ${userLastName} ${userFirstName} (${userId})\n\n` +
              `顧客ID: ${customerInfo.id}\n` +
              `顧客姓: ${customerInfo.lastName}\n\n` +
              `チケット情報:\n` +
              `  └商談フェーズ: ${flag.phase}\n` +
              `  └応対相手: ${flag.caller}\n` +
              `  └応対フラググループ: ${flag.callLogGroup.name}\n` +
              `  └応対フラグ: ${flag.callFlag.callLogGroupName}\n` +
              `  └次回予定: ${flag.nextCallDate}\n` +
              `  └応対メモ:\n` +
              `${flag.memo
                .split("\n")
                .map((line) => `           ${line}`)
                .join("\n")}\n\n` +
              `-----------------------------------------------------`;
            const response = await ChatworkAPICall(
              `curl -X POST -H "x-chatworktoken: 7d045659dd6cc607ef9772debcf11ae0" -d "body=${messageBody}" "https://api.chatwork.com/v2/rooms/${data.roomId}/messages"`
            );
            if (
              response &&
              response.result &&
              response.result.includes("message_id")
            ) {
              context.setGlobalAlert({
                open: true,
                severity: "success",
                title: "success",
                message: `message sent to ${data.roomName}`,
              });
            } else {
              context.setGlobalAlert({
                open: true,
                severity: "error",
                title: "error",
                message: `message failed to ${data.roomName}`,
              });
            }
          })
        );
      }
      setLoadingCircle(false);
      handleOpen(false);
      handleModalChange();
    };
    return (
      <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "75%",
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <CircularProgress
            sx={{
              position: "absolute",
              left: "50%",
              top: "40%",
              display: loadingCircle ? "block" : "none",
              zIndex: 10,
            }}
            color="secondary"
            size={50}
          />
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography
                align="center"
                variant={isMobile ? "h5" : "h3"}
                color={colors.blue[900]}
              >
                {t("Select Destination")}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
              <Button
                size={isMobile ? "small" : "large"}
                variant="contained"
                color="error"
                onClick={() => handleOpen(false)}
              >
                {t("Close")}
              </Button>
            </Grid>
            {loading ? (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="h4"
                  color={colors.yellow[800]}
                >
                  Loading....
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {roomList.map((data, index) => {
                      return index % 2 === 0 ? (
                        <ListItem disablePadding key={index + "f"}>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(index)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                size={isMobile ? "small" : "large"}
                                tabIndex={-1}
                                disableRipple
                                //inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={index}
                              primary={
                                <Typography variant={isMobile ? "h6" : "h4"}>
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: isMobile ? "1rem" : "2rem",
                                    }}
                                  />
                                  {` ${data.roomName}`}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {roomList.map((data, index) => {
                      return index % 2 !== 0 ? (
                        <ListItem disablePadding key={index + "g"}>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(index)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                size={isMobile ? "small" : "large"}
                                tabIndex={-1}
                                disableRipple
                                //inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={index}
                              primary={
                                <Typography variant={isMobile ? "h6" : "h4"}>
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: isMobile ? "1rem" : "2rem",
                                    }}
                                  />
                                  {` ${data.roomName}`}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                size={isMobile ? "small" : "large"}
                variant="outlined"
                fullWidth
                color="complementary"
                onClick={() => {
                  navigate("/SNS");
                }}
                startIcon={<EditIcon />}
              >
                {t("Edit Destination")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                size={isMobile ? "small" : "large"}
                variant="contained"
                fullWidth
                startIcon={<CheckIcon />}
                onClick={handleSubmit}
              >
                {t("Confirm Save And Share")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

  return <React.Fragment>{open ? <ChildModal /> : null}</React.Fragment>;
};
