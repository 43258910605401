import { Box } from "@mui/system";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragFolderItem from "./DragFolderItem";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

export const DragFolder = ({folderUrlArray}) => {
  const [items, setItems] = useState([
    { id: 1, text: "Item 1" },
    { id: 2, text: "Item 2" },
    { id: 3, text: "Item 3" },
    { id: 4, text: "Item 4" },
    { id: 5, text: "Item 5" },
    // Add more items as needed
  ]);
  console.log(items,'iii',folderUrlArray);
  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        border={1}
        borderColor={"#c5c5c5"}
        //padding={2}
        display={"flex"}
        maxWidth={"100%"}
        flexDirection={"column"}
        // flexWrap={"wrap"}
      >
        {items.map((item, index) => (
          <DragFolderItem
            key={item.id}
            id={item.id}
            text={item.text}
            index={index}
            moveItem={moveItem}
          />
        ))}
      </Box>
    </DndProvider>
  );
};
