import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import LoginContext from "../../context/LoginContext";
import { AxiosFetch } from "../AxiosFetch";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "../commonComponents/DeleteDialog";

export default function CustomerProjecTable() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(LoginContext);
  // const cw = 200;
  const axiosFetch = AxiosFetch();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = useState({});

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  const columns = [
    // { field: 'id', headerName:`${t()}` 'ID', width: 70 },
    { field: "id", headerName: `${t("Project ID")}`, flex: 0.7 },
    {
      field: "name",
      headerName: `${t("Project Name")}`,
      flex: 1,
    },
    {
      field: "customerGroup",
      headerName: `${t("Group Name")}`,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.customerGroup !== null) {
          temp = params.row.customerGroup.groupDisplayName;
        }
        return temp;
      },
    },
    { field: "flag", headerName: `${t("Flag")}`, flex: 1 },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row, "ppppp");
          navigate("/customerProjectDetail", { state: { data: params.row } });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={<EditIcon />}
          >
            {t("Edit")}
          </Button>
        );
      },
    },

    {
      field: "delete",
      headerName: `${t("Delete")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDialogOpen(true);
          setSelectRow(params.row);
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={onClick}
            startIcon={<DeleteIcon />}
          >
            {t("Delete")}
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    async function getData() {
      context.setPageTitle("Project Table");
      const response = await axiosFetch.get(`/project`);
      if (response.status === 200) {
        console.log(response.data, "response");
        setRows(response.data);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const handleDelete = async () => {
    console.log(selectRow, "idd");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(`/project/${selectRow.id}`);
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };

  const handleRowDelete = (e) => {
    console.log(e, "eeee");
    var arr = [...rows];
    arr.splice(
      arr.findIndex((a) => a.id === e.id),
      1
    );
    //console.log(arr,'arrr');
    setRows(arr);
  };

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <Box
        sx={{
          alignItems: "center",
          px: "5%",
          py: "2%",
          // background: "red",
        }}
      >
        <DeleteDialog
          handleDialogOpen={handleDialogOpen}
          handleDelete={handleDelete}
          dialogOpen={dialogOpen}
          delLoading={delLoading}
        />
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/newCustomerProject");
              }}
            >
              {t("Create New Project")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
