import { IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function WrapperCategoryItemForEdit({
  children,
  itemDetails,
  mandatoryFlag,
  ...props
}) {
  //console.log(itemDetails, "item details");
  const [hoverFlag, setHoverFlag] = useState(false);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
      onMouseEnter={(e) => {
        setHoverFlag(true);
      }}
      onMouseLeave={() => {
        setHoverFlag(false);
      }}
    >
      {children}
      {itemDetails.mandatory && mandatoryFlag ? (
        <Typography
          variant="caption"
          align="center"
          color={hoverFlag ? "green" : "red"}
        >
          &nbsp;&nbsp;&nbsp;Required*
        </Typography>
      ) : (
        <></>
      )}

      <Tooltip title="Visibility">
        <IconButton
          aria-label="edit"
          size="small"
          sx={{
            color: `${itemDetails.visible ? "#a4c7fb" : "#a7a5a5"}`,
            position: "absolute",
            top: "1%",
            right: "5%",
          }}
        >
          {itemDetails.visible ? <></> : <VisibilityOffIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="EDIT">
        <IconButton
          aria-label="edit"
          size="medium"
          sx={{
            visibility: `${hoverFlag ? "visible" : "hidden"}`,
            color: "green",
            position: "absolute",
            top: "1%",
            right: "10%",
            zIndex: `${hoverFlag ? 5 : 0}`,
          }}
          onClick={() => {
            console.log(itemDetails, "iiiiii");
            navigate("/categoryItemEdit", {
              state: {
                data: itemDetails,
              },
            });
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
