import { Box, Card, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginContext from "../../../context/LoginContext";
import { LinkChatworkAccount } from "./LinkChatworkAccount";

export const SNSindex = () => {
  const { t } = useTranslation();
  const context = useContext(LoginContext);
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    context.setPageTitle("Social Networking Service");
  }, []);
  return (
    <Card
      elevation={4}
      sx={{
        alignItems: "center",

        my: "1%",
        mx: "5%",
        paddingBottom: "0%",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={(e, n) => setTabValue(n)}
        variant="fullWidth"
        aria-label="scrollable auto tabs example"
        sx={{
          backgroundColor: "primary.light",
        }}
      >
        <Tab label={t("Chatwork")} sx={{ fontSize: 30 }} />
        {/* <Tab label={t("Placeholder")}  sx={{ fontSize: 30 }}/> */}
      </Tabs>

      <div hidden={tabValue !== 0} style={{ padding: "1%", paddingTop: 15 }}>
        <LinkChatworkAccount />
      </div>
      {/* <div hidden={tabValue !== 1} style={{ padding: "1%", paddingTop: 15 }}>
        <> Place holder </>
      </div> */}
    </Card>
  );
};
