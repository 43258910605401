import { Autocomplete, Chip, TextField } from "@mui/material";
import { Field, useField } from "formik";
import React, { useState } from "react";

{
  /* <CustomAutoComplete
mode={readMode}
data={{
  name: "telephoneNew",
  label: `${t("Telephone")}`,
  placeholder:"kkkk"
}}
/> */
}

export default function CustomAutoComplete(props) {
  const data = props.data;
  const mode = props.mode;
  var readMode = mode ? true : false;

  const CustomizedAutoSelectForFormik = ({
    children,
    form,
    field,
    ...props
  }) => {
    const { name, value } = field;
    const { setFieldValue, setTouched } = form;
    const [inputValue, setInputValue] = useState("");
    return (
      <Autocomplete
        value={value}
        inputValue={inputValue}
        limitTags={props.tagLimit || 2}
        multiple
        id="tags-filled"
        options={[]}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              sx={{ fontSize: "1rem" }}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(e, newValue) => {
          setFieldValue(name, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onBlur={(e) => {
          if (e.target.value !== "") {
            const options = [e.target.value];
            //setFieldValue(name,[options]);
            setFieldValue(
              name,
              value
                .concat(options)
                .map((x) => x.trim())
                .filter((x) => x)
            );
            setInputValue("");
          }
          setTouched({ [name]: true });
        }}
        renderInput={(params) => {
          const [, meta] = useField(name);
          return (
            <TextField
              {...params}
              name={name}
              variant="outlined"
              label={props.label}
              placeholder={props.placeholder || "Add Number"}
              error={meta.error && meta.touched}
              helperText={meta.touched && meta.error}
            />
          );
        }}
      />
    );
  };
  return (
    <>
      <Field
        name={data.name}
        component={CustomizedAutoSelectForFormik}
        label={data.label}
        inputProps={{
          readOnly: readMode,
        }}
        placeholder={data.placeholder}
        {...props}
      />
    </>
  );
}
