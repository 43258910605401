import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CallLogHistorySearchTable(props) {
  const { t, i18n } = useTranslation();

  const columns = [
    { field: "id", headerName: `${t("ID")}`, flex: 0.5 },

    { field: "caller", headerName: `${t("Caller")}`, flex: 1 },
    {
      field: "callFlag",
      headerName: `${t("Call Flag")}`,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.callFlag !== null) {
          temp = params.row.callFlag.name;
        }
        return temp;
      },
    },
    {
      field: "callLogGroup",
      headerName: `${t("Call Log Group")}`,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.callLogGroup !== null) {
          temp = params.row.callLogGroup.name;
        }
        return temp;
      },
    },
    {
      field: "registerUserId",
      headerName: `${t("Register User ID")}`,
      flex: 1,
    },
    { field: "phase", headerName: `${t("Phase")}`, flex: 1 },
    { field: "startTime", headerName: `${t("Start Time")}`, flex: 1 },
    { field: "startTimeEnd", headerName: `${t("End Time")}`, flex: 1 },
    // { field: "privilege5", headerName: `${t("call flag")}`, flex: 1 },
  ];
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.id}
        rows={props.rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5]}
        //checkboxSelection
      />
    </div>
  );
}
