import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikContext } from "formik";
import CustomNumberField from "../formikInputs/CustomNumberField";
import CustomTextField from "../formikInputs/CustomTextField";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import CustomSelect from "../formikInputs/CustomSelect";
import GroupSelect from "../formikInputs/GroupSelect";
import { GroupSelectWithType } from "../formikInputs/GroupSelectWithType";
import CustomAutoComplete from "../formikInputs/CustomAutoComplete";
import { Box } from "@mui/system";
import CustomFilterSelect from "../formikInputs/CustomFilterSelect";
import CategoryData from "../customerRelated/categoryForm/CategoryData";
import CategoryDataWithEdit from "../customizeCategory/CategoryDataWithEdit";

export default function TestWithFormik() {
  const { t, i18n } = useTranslation();
  const initialValues = {
    categoryDataNew: {
      73: { Japan: ["tokyo"] },
    },
  };

  const item = {
    itemId: 73,
    itemName: "depDrop",
    visible: true,
    category: {
      categoryId: 8,
      categoryName: "pd",
      customerGroup: {
        groupId: 1,
        groupName: "admin",
        status: true,
        parentGroup: null,
        groupType: "parent",
        groupDisplayName: "admin",
        registerUserId: "admin",
        registerDateTime: "2023-02-27 19:22:01",
        modifyUserId: "admin",
        modifyDateTime: "2023-02-27 19:22:01",
        active: true,
      },
      visible: true,
      multipleValue: false,
      categoryOrder: null,
      registerUserId: "admin",
      registerDateTime: "2023-02-28 21:32:58",
      modifyUserId: "admin",
      modifyDateTime: "2023-02-28 21:32:58",
      active: true,
    },
    itemType: "filterselect",
    mandatory: false,
    registerUserId: "adminpd",
    registerDateTime: "2023-06-13 02:15:05",
    modifyUserId: "adminpd",
    modifyDateTime: "2023-06-13 02:15:05",
    itemOptions: [
      {
        itemOptionId: 60,
        itemOptionValue:
          '[{"India":["udr","del","bom"]},{"Japan":["tokyo","kyoto ","osaka"]},{"USA":["la","lv","nyc"]}]',
      },
    ],
    active: true,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        //validationSchema={formValidation}
        onSubmit={(values) => {
          console.log(values, "vvvvv");
        }}
      >
        <Form>
          <Box sx={{ px: "10%", py: "10%" }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CustomFilterSelect
                  data={{
                    name: `categoryDataNew.${item.itemId}`,
                    label: item.itemName,
                    list: JSON.parse(item.itemOptions[0].itemOptionValue),
                  }}
                />
              </Grid>
            </Grid>
            <CategoryDataWithEdit categoryId={26} />
            <Button type="submit">sub</Button>
          </Box>
        </Form>
      </Formik>
    </>
  );
}
