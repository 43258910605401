// import { Mic } from '@mui/icons-material';
import MicIcon from "@mui/icons-material/Mic";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../../../AxiosFetch";
import { modalCustomStyle } from "../../../commonComponents/ModalCusotmStyle";
import PhaseSelect from "./helper/PhaseSelect";
import CallerSelect from "./helper/CallerSelect";
import { Form, Formik } from "formik";
import CustomTextField from "../../../formikInputs/CustomTextField";
import LoginContext from "../../../../context/LoginContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function TicketTable(props) {
  const { t } = useTranslation();

  const { setGlobalAlert } = useContext(LoginContext);

  const [rows, setRows] = useState([]);
  const [isEditingTicket, setIsEditingTicket] = useState(false);

  const axiosFetch = AxiosFetch();

  const [openModal, setOpenModal] = useState(false);
  const [ticketDetailValues, setTicketDetailValues] = useState({
    id: "",
    chanName: "",
    startTime: "",
    endTime: "",
    answerTime: "",
    src: "",
    destination: "",
    disposition: "",
    phase: "",
    caller: "",
    memo: "",
    callLogGroup: "",
    callFlag: "",
  });

  //Hover
  const [anchorEl, setAnchorEl] = useState(null);
  const [popOverText, setPopOverText] = useState("");
  const handlePopoverOpen = (event, name) => {
    setPopOverText(name);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/tickets/${props.topicId}`);
      if (response.status === 200) {
        console.log(response, "ticket table response");
        setRows(response.data);
        // setLoading(false);
      } else {
        window.alert("Error! Bad request");
      }
    }
    getData();
  }, [props.trigger]);

  const columns = [
    { field: "id", headerName: `${t("ID")}`, flex: 0.5 },
    { field: "startTime", headerName: `${t("Start time")}`, flex: 1 },
    {
      field: "callLogGroup",
      headerName: `${t("Call Group")}`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.callLogGroup ? params.row.callLogGroup.name : "";
      },
    },
    {
      field: "callFlag",
      headerName: `${t("Call Flag")}`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.callFlag ? params.row.callFlag.name : "";
      },
    },
    {
      field: "memo",
      headerName: `${t("Memo")}`,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={(event) => {
              handlePopoverOpen(event, params.row.memo);
            }}
            onMouseLeave={handlePopoverClose}
          >
            {params.row.memo}
          </div>
        );
      },
    },
    { field: "modifyUserName", headerName: `${t("User")}`, flex: 0.8 },
    {
      field: "nextCallDate",
      headerName: `${t("Next Call Date")}`,
      type: "date",
      valueGetter: (params) => {
        if (params.row.nextCallDate) {
          const formttedDate = format(
            new Date(params.row.nextCallDate),
            "yyyy/MM/dd HH:mm"
          );
          return formttedDate;
        }
        return null;
      },
      flex: 2,
    },
    { field: "registerDateTime", headerName: `${t("Created at")}`, flex: 1 },
    // { field: "disposition", headerName: "Disposition", flex:1 0 },
    {
      field: "recording",
      headerName: (
        <IconButton aria-label="mic">
          <MicIcon />
        </IconButton>
      ),
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          // handleDelete(params.row.customerId);
        };
        return (
          <IconButton aria-label="mic" onClick={onClick}>
            <MicIcon />
          </IconButton>
        );
      },
      flex: 0.5,
    },
  ];

  const formValidation = Yup.object().shape({
    phase: Yup.string().required(t("This field is required")),
    // callLogGroup: Yup.object().shape({
    //   id: Yup.string()
    //     .required(t("This field is required"))
    //     .test(
    //       "zero not allowed",
    //       t("This field is required"),
    //       (val) => Number(val) !== 0
    //     ),
    // }),
    // callFlag: Yup.object().shape({
    //   id: Yup.string()
    //     .required(t("This field is required"))
    //     .test(
    //       "zero not allowed",
    //       t("This field is required"),
    //       (val) => Number(val) !== 0
    //     ),
    // }),
    memo: Yup.string().max(3999, t("Text must be less than 4000 characters")),
  });

  const handleSubmit = async (values) => {
    console.log("Handle submit", values);
    try {
      const submitValues = {
        id: values.id,
        phase: values.phase,
        caller: values.caller,
        memo: values.memo,
        nextCallDate: values.nextCallDate,
      }
      await axiosFetch.put(`/ticket/${values.id}`, submitValues);
      setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `${t("Ticket saved successfully")}`,
      });
    }
    catch (error) {
      console.log(error, "ticket API error");
      setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
    }
    finally {
      setIsEditingTicket(false);
    }
  }

  const open = Boolean(anchorEl);

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        // getRowId={(row) => row.customerId}
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5]}
        onRowClick={(e) => {
          setOpenModal(true);
          setTicketDetailValues({
            id: e.row.id,
            chanName: "",
            startTime: e.row.startTime,
            endTime: e.row.endTime,
            answerTime: e.row.answerTime,
            src: e.row.src,
            destination: e.row.destination,
            disposition: e.row.disposition,
            phase: e.row.phase,
            caller: e.row.caller,
            memo: e.row.memo || "",
            callLogGroup: e.row.callLogGroup,
            callFlag: e.row.callFlag,
            registerUserId: e.row.registerUserId,
            registerDateTime: e.row.registerDateTime,
            // nextCallDate: format(new Date(e.row.nextCallDate), "yyyy/MM/dd HH:mm")
            nextCallDate: e.row.nextCallDate
          });
        }}
      />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popOverText}</Typography>
      </Popover>

      {/* Modal for ticket Details */}
      <Modal
        sx={modalCustomStyle}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          enableReinitialize={true}
          initialValues={ticketDetailValues}
          validationSchema={formValidation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form id="formId">
            <Box sx={style}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={1}
                id="modal-description"
              >
                <Grid item xs={12} md={4}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t("Ticket Details")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {isEditingTicket &&
                    <Button
                      // onClick={() => setIsEditingTicket(false)}
                      variant="contained"
                      size="large"
                      fullWidth
                      type="submit"
                      startIcon={<SaveIcon />}
                    >
                      {t("Save")}
                    </Button>
                  }
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    onClick={() => setIsEditingTicket(!isEditingTicket)}
                    variant="contained"
                    size="large"
                    fullWidth
                    startIcon={isEditingTicket ? <CancelIcon /> : <EditIcon />}
                    style={{ backgroundColor: isEditingTicket ? 'red' : '' }}
                  >
                    {isEditingTicket ? t("Cancel") : t("Edit")}
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    label={t("User")}
                    defaultValue={ticketDetailValues.registerUserId}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    label={t("Created at")}
                    defaultValue={ticketDetailValues.registerDateTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    label={t("Disposition")}
                    defaultValue={ticketDetailValues.disposition}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    label={t("Destination")}
                    defaultValue={ticketDetailValues.destination}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    fullWidth
                    label={t("Start Time")}
                    defaultValue={ticketDetailValues.startTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    fullWidth
                    label={t("End Time")}
                    defaultValue={ticketDetailValues.endTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    fullWidth
                    label={t("Answer Time")}
                    defaultValue={ticketDetailValues.answerTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4}>
                  {isEditingTicket
                    ?
                    <PhaseSelect name="phase" />
                    :
                    <TextField
                      fullWidth
                      label={t("Phase")}
                      defaultValue={ticketDetailValues.phase}
                      InputProps={{
                        readOnly: true,
                      }}
                    ></TextField>
                  }
                </Grid>
                <Grid item xs={4} md={4}>
                  {isEditingTicket
                    ?
                    <CallerSelect name="caller" />
                    :
                    <TextField
                      fullWidth
                      label={t("Caller")}
                      defaultValue={ticketDetailValues.caller}
                      InputProps={{
                        readOnly: true,
                      }}
                    ></TextField>
                  }
                </Grid>
                <Grid item xs={4} md={4}>
                  {isEditingTicket
                    ?
                    <CustomTextField
                      data={{
                        name: "nextCallDate",
                        label: `${t("Next Call Date")}`,
                      }}
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                    :
                    <TextField
                      fullWidth
                      label={t("Next Call Date")}
                      defaultValue={ticketDetailValues.nextCallDate}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t("Call Log Group Name")}
                    defaultValue={ticketDetailValues.callLogGroup ? ticketDetailValues.callLogGroup.name : ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t("Call Flag Name")}
                    defaultValue={
                      ticketDetailValues.callFlag &&
                      ticketDetailValues.callFlag.name
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  {isEditingTicket
                    ?
                    <CustomTextField
                      data={{ name: "memo", label: `${t("Memo")}` }}
                      multiline
                      rows={3}
                      fullWidth
                      InputProps={{
                        maxLength: 3999,
                      }}
                    />
                    :
                    <TextField
                      fullWidth
                      label={t("Memo")}
                      defaultValue={ticketDetailValues.memo}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      minRows={3}
                    ></TextField>
                  }
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Formik>

      </Modal>
    </div>
  );
}

export default TicketTable;
