import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import CustomerDetailButton from "./customerDetail/CustomerDetailButton";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ButtonAppBar = (props) => {
  const context = useContext(LoginContext);
  const { t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Topline</Typography> */}

          <Link
            to="/home"
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "25px",
              width: "25%",
              display: "none",
            }}
          >
            Topline
          </Link>

          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
          {props.customerDetail ? <CustomerDetailButton /> : ""}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ButtonAppBar;
