import { Skeleton } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import CustomSelect from "./CustomSelect";
//Helper
{
  /* <UserSelectByGroup
mode={readMode}
name="nameHere"
label={t("First User Name")}
groupId={groupId}
originalGroup={originalGroup || 0}
/> */
}

export default function UserSelectByGroup(props) {
  const typeFlag = props.typeFlag;
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { values, setFieldValue } = useFormikContext();

  async function getDataUser(props) {
    const APIvalues = {
      customerGroups: [
        {
          groupId: props,
        },
      ],
    };
    const response = await axiosFetch.post(
      "/userList",
      JSON.stringify(APIvalues)
    );
    return await response.data;
  }
  async function getDataUserAll() {
    const response = await axiosFetch.get(`/user`);
    //console.log(response,'rrrrr');
    return await response.data;
  }

  useEffect(() => {
    //console.log(originalGroup, props.groupId, "lllllllll");
    //setFieldValue(props.name, "");
    setIsLoading(true);
    var test = [];
    test = [];
    // console.log(props.groupId, "gggggg");
    if (props.groupId !== 0) {
      getDataUser(props.groupId).then((x) => {
        x.map((data, index) => {
          test[++index] = {
            value: data.userId,
            name: data.lastName + " " + data.firstName,
          };
        });
        test[0] = {
          value: "",
          name: t("Not Specified"),
        };
        setUserList(test);
        setIsLoading(false);
      });
    } else {
      if (typeFlag === "search") {
        //console.log('in flag');
        getDataUserAll().then((x) => {
          x.map((data, index) => {
            test[++index] = {
              value: data.userId,
              name: data.lastName + " " + data.firstName,
            };
          });
          test[0] = {
            value: "",
            name: t("Not Specified"),
          };
          setUserList(test);
          setIsLoading(false);
        });
      } else {
        test[0] = {
          value: "",
          name: t("Not Specified"),
        };
        setUserList(test);
        setIsLoading(false);
      }
    }
  }, [props.groupId]);

  return isLoading ? (
    <div>
      <Skeleton variant="rounded" width={"100%"} height={56} />
    </div>
  ) : (
    <>
      {/* {console.log(userList, "group select page")} */}
      <div>
        <CustomSelect
          data={{
            name: props.name,
            label: props.label,
            list: userList,
          }}
          mode={props.mode}
        />
      </div>
    </>
  );
}
