import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AxiosFetch } from "../../AxiosFetch";
import CustomSelect from "../../formikInputs/CustomSelect";
import CustomTextField from "../../formikInputs/CustomTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginContext from "../../../context/LoginContext";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import FilterSelectInput from "./FilterSelectInput";
import CustomAutoComplete from "../../formikInputs/CustomAutoComplete";
import DeleteDialog from "../../commonComponents/DeleteDialog";

export default function CategoryItemEdit() {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const userId = context.userId;
  useEffect(() => {
    context.setPageTitle("Category Item Edit");
  }, []);

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const data = location.state.data;
  console.log(data, "location item page data");

  const [type, setType] = useState("");

  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = useState({});

  var list = [];
  if (data.itemType === "filterselect") {
    data.itemOptions.forEach((data, index) => {
      const parts = data.itemOptionValue.split(":");
      const keyExists = list.some((item) => item.hasOwnProperty(parts[0]));
      if (!keyExists) {
        list.push({ [parts[0]]: [parts[1]] });
      } else {
        const keyIndex = list.findIndex((item) =>
          Object.keys(item).includes(parts[0])
        );
        list[keyIndex][parts[0]].push(parts[1]);
      }
    });
  } else if (data.itemType === "multipleselect" || data.itemType === "select") {
    data.itemOptions.forEach((data, index) => {
      list.push(data.itemOptionValue);
    });
  }

  const initialValues = {
    itemName: data.itemName,
    visible: data.visible,
    category: {
      categoryId: data.category.categoryId,
    },
    itemType: data.itemType,
    registerUserId: userId,
    mandatory: data.mandatory,
    selectValue: list,
    filterSelectValue: [],
  };
  const formValidation = Yup.object().shape({
    itemName: Yup.string().required(`${t("This field is required")}`),
    itemType: Yup.string().required(`${t("This field is required")}`),
  });

  const FormObserver = () => {
    const { values } = useFormikContext();
    console.log(values, "live values");
    useEffect(() => {
      setType(values.itemType);
    }, [values.itemType]);
    return null;
  };

  //   switch (type) {
  //     case "text":
  //       return <TextField label="test sample for text" />;

  //     case "number":
  //       return <TextField label="test sample for number" type="number" />;

  //     case "date":
  //       return (
  //         <TextField
  //           label="test sample for date"
  //           type="date"
  //           InputLabelProps={{ shrink: true }}
  //         />
  //       );
  //     case "datetime":
  //       return (
  //         <TextField
  //           label="test sample for datetime"
  //           type="datetime-local"
  //           InputLabelProps={{ shrink: true }}
  //         />
  //       );
  //     case "fileUpload":
  //       return (
  //         <>
  //           <input type="file" />
  //         </>
  //       );
  //     case "checkbox":
  //       return (
  //         <>
  //           <FormControlLabel control={<Checkbox />} label="checkbox" />
  //         </>
  //       );

  //     case "select":
  //       return (
  //         <Select
  //           labelId="demo-simple-select-helper-label"
  //           id="demo-simple-select-helper"
  //           //value={20}
  //           defaultValue={20}
  //           label="Age"
  //         >
  //           <MenuItem value="">
  //             <em>None</em>
  //           </MenuItem>
  //           <MenuItem value={10}>Ten</MenuItem>
  //           <MenuItem value={20}>Twenty</MenuItem>
  //           <MenuItem value={30}>Thirty</MenuItem>
  //         </Select>
  //       );
  //     case "multipleselect":
  //       return (
  //         <Select
  //           labelId="demo-simple-select-helper-label"
  //           id="demo-simple-select-helper"
  //           //value={20}
  //           multiple
  //           defaultValue={[20]}
  //           renderValue={(selected) => selected.join(", ")}
  //           label="Age"
  //         >
  //           <MenuItem key={10} value={10}>
  //             <Checkbox />
  //             <ListItemText primary={10} />
  //           </MenuItem>
  //           <MenuItem key={20} value={20}>
  //             <Checkbox />
  //             <ListItemText primary={20} />
  //           </MenuItem>
  //           <MenuItem key={30} value={30}>
  //             <Checkbox />
  //             <ListItemText primary={30} />
  //           </MenuItem>
  //           <MenuItem key={40} value={40}>
  //             <Checkbox />
  //             <ListItemText primary={40} />
  //           </MenuItem>
  //         </Select>
  //       );
  //     case "filterselect":
  //       return (
  //         <>
  //           <FormControl>
  //             <InputLabel id="demo-simple-select-label">country</InputLabel>
  //             <Select
  //               labelId="demo-simple-select-helper-label"
  //               id="demo-simple-select-helper"
  //               //value={20}
  //               defaultValue={20}
  //               label="country"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Japan</MenuItem>
  //               <MenuItem value={20}>India</MenuItem>
  //               <MenuItem value={30}>USA</MenuItem>
  //             </Select>
  //           </FormControl>
  //           <FormControl>
  //             <InputLabel id="demo-simple-select-label">city</InputLabel>
  //             <Select
  //               labelId="demo-simple-select-helper-label"
  //               id="demo-simple-select-helper"
  //               //value={20}
  //               defaultValue={20}
  //               label="city"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Tokyo</MenuItem>
  //               <MenuItem value={20}>Delhi</MenuItem>
  //               <MenuItem value={30}>LA</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </>
  //       );

  //     default:
  //       return <></>;
  //   }

  //   return <TextField />;
  // };
  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values.selectValue, "vvvv", data.itemOptions);
    var finalArray = [];
    const originalItemOptions = [...data.itemOptions];
    var APIvalues = { ...values };
    delete APIvalues.selectValue;
    delete APIvalues.filterSelectValue;
    if (values.itemType === "filterselect") {
      values.filterSelectValue.forEach((data, index) => {
        Object.entries(data).map(([key, value]) => {
          value.forEach((menuItem, i) => {
            var foundObject = originalItemOptions.find(
              (obj) => obj.itemOptionValue === `${key}:${menuItem}`
            );
            if (foundObject) {
              finalArray.push(foundObject);
            } else {
              finalArray.push({
                itemOptionId: "",
                itemOptionValue: `${key}:${menuItem}`,
              });
            }
          });
        });
      });
      originalItemOptions.forEach((data, index) => {
        var foundObject = finalArray.find(
          (obj) => obj.itemOptionValue === data.itemOptionValue
        );
        if (!foundObject) {
          finalArray.push({
            itemOptionId: data.itemOptionId,
            itemOptionValue: "",
          });
        }
      });
    } else if (
      values.itemType === "multipleselect" ||
      values.itemType === "select"
    ) {
      values.selectValue.forEach((data, index) => {
        var foundObject = originalItemOptions.find(
          (obj) => obj.itemOptionValue === data
        );
        if (foundObject) {
          finalArray.push(foundObject);
        } else {
          finalArray.push({
            itemOptionId: "",
            itemOptionValue: data,
          });
        }
      });
      originalItemOptions.forEach((data, index) => {
        var foundObject = finalArray.find(
          (obj) => obj.itemOptionValue === data.itemOptionValue
        );
        if (!foundObject) {
          finalArray.push({
            itemOptionId: data.itemOptionId,
            itemOptionValue: "",
          });
        }
      });
    }
    APIvalues.itemOptions = finalArray;
    console.log(APIvalues, "API values");
    try {
      const Response = await axiosFetch.put(
        `/categoryItems/${data.itemId}`,
        APIvalues
      );
      console.log(Response, "edit response");
      setLoading(false);
      context.setGlobalAlert({
        open: true,
        severity: "success",
        title: "success",
        message: `Edit Done`,
      });
      navigate(-1);
    } catch (error) {
      alert("error");
      console.log(error);
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      setLoading(false);
    }
  };
  const handleDelete = async (e) => {
    console.log(initialValues, "ppppp");
    setDelLoading(true);
    try {
      const response = await axiosFetch.delete(`/categoryItems/${data.itemId}`);
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      navigate(-1);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };
  return (
    <div>
      <DeleteDialog
        handleDialogOpen={(e) => {
          setDialogOpen(e);
        }}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <FormObserver />
          <Box
            sx={{
              alignItems: "center",
              px: "16%",
              py: "2%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                      {data.category.categoryName}
                    </Typography>
                    <Typography variant="h6" gutterBottom align="center">
                      {t("Group ID")} {data.category.customerGroup.groupId} :{" "}
                      {data.category.customerGroup.groupDisplayName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{ name: "itemName", label: `${t("Item Name")}` }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "visible",
                        label: `${t("Display")}`,
                        list: [
                          { name: `${t("visible")}`, value: true },
                          { name: `${t("hidden")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "mandatory",
                        label: `${t("Mandatory")}`,
                        list: [
                          { name: `${t("yes")}`, value: true },
                          { name: `${t("no")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={11.5}>
                    <CustomSelect
                      data={{
                        name: "itemType",
                        label: `${t("Item Type")}`,
                        list: [
                          { name: `${t("text")}`, value: "text" },
                          { name: `${t("number")}`, value: "number" },
                          {
                            name: `${t("select-ForItemType")}`,
                            value: "select",
                          },
                          {
                            name: `${t("multipleselect")}`,
                            value: "multipleselect",
                          },
                          { name: `${t("date")}`, value: "date" },
                          { name: `${t("datetime")}`, value: "datetime" },
                          { name: `${t("checkbox")}`, value: "checkbox" },
                          {
                            name: `${t("filterselect")}`,
                            value: "filterselect",
                          },
                          { name: `${t("fileUpload")}`, value: "fileUpload" },
                        ],
                      }}
                      mode="read"
                    />
                  </Grid>

                  <Grid item xs={0.5}>
                    <Tooltip
                      fontSize="small"
                      title={t("This item cannot be edited.")}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Grid>

                  {(type === "select" || type === "multipleselect") && (
                    <Grid item xs={12}>
                      <CustomAutoComplete
                        mode={""}
                        data={{
                          name: "selectValue",
                          label: `${t("values for dropDown")}`,
                          placeholder: "Enter values",
                        }}
                        tagLimit={-1}
                      />
                    </Grid>
                  )}
                  {type === "filterselect" && <FilterSelectInput list={list} />}
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      variant="contained"
                      color="error"
                      //onClick={handleDelete}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      {t("Delete")}
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12}>
                      <div align="center">
                        {t("Preview")}
                        <br />
                        <PreviewItem type={type} />
                      </div>
                    </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
