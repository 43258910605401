// import { Mic } from '@mui/icons-material';
import MicIcon from "@mui/icons-material/Mic";
import { IconButton, Popover, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { AxiosFetch } from "../../../AxiosFetch";

function TopicTable(props) {
  const { t } = useTranslation();

  const location = useLocation();

  const customerId = location.state.customerId;

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(true);

  const axiosFetch = AxiosFetch();

  //Hover
  const [anchorEl, setAnchorEl] = useState(null);
  const [popOverText, setPopOverText] = useState("");
  const handlePopoverOpen = (event, name) => {
    setPopOverText(name);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(`/topics/${customerId}`);
      if (response.status === 200) {
        setRows(response.data);
        setLoading(false);
      } else {
        window.alert("Error! Bad request");
      }
    }
    getData();
  }, [props.trigger]);

  const handleRowClick = (params, event) => {
    event.stopPropagation();
    console.log("HandleRowClick");
    props.setShowTicket(true);
    props.setTopic(params.row.id);
    props.setTopicTitle(params.row.title);
    props.setTopicPhase(params.row.phase);
    localStorage.setItem("topic_id", params.row.id);
  };

  const columns = [
    { field: "id", headerName: `${t("ID")}`, flex: 0.5 },
    { field: "title", headerName: `${t("Title")}`, flex: 1 },
    { field: "phase", headerName: `${t("Phase")}`, flex: 1 },
    // { field: "caller", headerName: "Caller", flex:1  },
    {
      field: "callLogGroup",
      headerName: `${t("Call Group")}`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.callLogGroup ? params.row.callLogGroup.name : "";
      },
    },
    {
      field: "callFlag",
      headerName: `${t("Call Flag")}`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.callFlag ? params.row.callFlag.name : "";
      },
    },
    {
      field: "nextCallDate",
      headerName: `${t("Next Call Date")}`,
      type:'date',
      flex: 1,
      valueGetter: (params) => calculateNextCallDate(params.row.tickets),
    },
    { field: "registerDateTime", headerName: `${t("Created On")}`, flex: 1 },
    {
      field: "modifyDateTime",
      headerName: `${t("Last Updated On")}`,
      flex: 1,
    },
    {
      field: "modifyUserName",
      headerName: `${t("Last updated user")}`,
      flex: 1,
    },
    // {
    //   field: "memo",
    //   headerName: "Memo",
    //   flex:1 ,
    //   renderCell: (params) => {
    //     return <div
    //     aria-owns={open ? 'mouse-over-popover' : undefined}
    //     aria-haspopup="true"
    //     onMouseEnter={(event) => {handlePopoverOpen(event, params.row.memo)}}
    //     onMouseLeave={handlePopoverClose}
    //   >
    //     {params.row.memo}
    //   </div>
    //   }
    // },
  ];

  const open = Boolean(anchorEl);

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        // getRowId={(row) => row.customerId}
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5]}
        onRowClick={handleRowClick}
      />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popOverText}</Typography>
      </Popover>
    </div>
  );
}

export default TopicTable;
function calculateNextCallDate(ticketArr) {
  let allDatesArr = [];
  const today = new Date();

  ticketArr.forEach(({ nextCallDate }) => {
    if (nextCallDate) {
      allDatesArr.push(new Date(nextCallDate));
    }
  });

  allDatesArr.sort((a, b) => a - b);
  const futureDates = allDatesArr.filter((date) => date > today);
  //console.log(futureDates, "fffff", today, allDatesArr);
  if (futureDates.length > 0) {
    // If there are future dates, return the earliest one
    return futureDates[0];
  } else {
    // If there are no future dates, return the most recent past date
    const pastDates = allDatesArr.filter((date) => date < today);
    return pastDates.length > 0 ? pastDates[pastDates.length - 1] : null;
  }
}
