import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Formik, Form, useFormikContext } from "formik";
import CustomerTable from "./CustomerTable";
import { AxiosFetch } from "../../AxiosFetch";
import GroupSelect from "../../formikInputs/GroupSelect";
import ProjectSelectByGroup from "../../formikInputs/ProjectSelectByGroup";
import LoginContext from "../../../context/LoginContext";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

export default function CustomerDelete() {
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [groupId, setGroupId] = useState(0);
  const [userRows, setUserRows] = useState([]);
  const context = useContext(LoginContext);
  const [tableLoading, setTableLoading] = useState(false);
  const handleRowDelete = (e) => {
    var arr = [...userRows];
    arr.splice(
      arr.findIndex((a) => a.id === e.id),
      1
    );
    setUserRows(arr);
  };

  // const handleRowRecover = (rowId) => {
  //   const updatedData = userRows.map(row =>
  //     row.id === rowId ? { ...row, active: true } : row
  //   );
  //   setUserRows(updatedData);
  // }

  useEffect(() => {
    context.setPageTitle("Customer Delete");
  }, []);

  const initialValues = {
    projectId: 0,
    groupId: 0,
  };

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setGroupId(values.groupId);
    }, [values.groupId])

    setGroupId(values.groupId);
  }

  const handleSubmit = async (values) => {
    setTableLoading(true);
    console.log(values);
    //if projectId = 0
    let apiValues = {
      active: values.isActive,
      customerGroup: { groupId: values.groupId },
    };
    if (values.projectId) {
      apiValues.projectList = [{ id: values.projectId }]
    };
    console.log("API Values ", apiValues);
    try {
      const Response = await axiosFetch.post(
        "/customerListRecovery",
        JSON.stringify(apiValues)
      );
      console.log(Response, "response");
      var temp = [];
      Response.data.map((data, index) => {
        temp[index] = {
          id: data.customerId,
          isActive: data.active,
          lastName: data.customerLastName,
          firstName: data.customerFirstName,
          groupName: data.customerGroup.groupDisplayName,
        };
      });
      setUserRows(temp);
      setTableLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${(error.response.data && error.response.data.errorMessage) || "error"
          }`,
      });
      console.log(error, "error");
      setTableLoading(false);
    }
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <Box
            sx={{
              alignItems: "center",
              px: "5%",
              py: "1%",
            }}
          >
            <FormObserver />
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <CardContent>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={3} md={3}>
                        <GroupSelect name="groupId" />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <ProjectSelectByGroup
                          mode=""
                          name="projectId"
                          label={t("Project")}
                          groupId={groupId}
                          typeFlag="no-search"
                        />
                      </Grid>
                      {/* <Grid item xs={3} md={3}>
                        <CustomSelect
                          mode=""
                          data={
                            {
                              name: "isActive",
                              label: "Active Status",
                              list: [
                                { value: true, name: "Active" },
                                { value: false, name: "Inactive" }
                              ]
                            }
                          }
                          defaultValue={[true]}
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          startIcon={<SearchIcon />}
                          fullWidth
                        >
                          {t("Search")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={4}>
                  <LinearProgress
                    sx={{ visibility: `${!tableLoading ? "hidden" : ""}` }}
                  />
                  <CardContent>
                    <CustomerTable
                      rows={userRows}
                      handleRowDelete={handleRowDelete}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
