import { useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosFetch } from "../AxiosFetch";
import CustomSelect from "./CustomSelect";

//type ="child","grandchild",'parent','all'
export const GroupSelectWithType = ({ name, type, ...props }) => {
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const [groupList, setGroupList] = useState([]);
  const [completeGroupList, setCompleteGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const response = await axiosFetch.get(`/group/user`);
        //console.log(response.data, "yesss groupUser");

        setCompleteGroupList(response.data);
        setIsLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getData();
  }, []);
  useEffect(() => {
    //console.log("nnnnn");
    if (type === "child") {
      var temp = [];
      completeGroupList.map((data) => {
        if (data.groupType === "child")
          temp.push({ name: data.groupDisplayName, value: data.groupId });
      });
      setGroupList(temp);
    } else if (type === "grandchild") {
      var temp = [];
      completeGroupList.map((data) => {
        if (data.groupType === "grandchild")
          temp.push({ name: data.groupDisplayName, value: data.groupId });
      });
      setGroupList(temp);
    } else if (type === "parent") {
      var temp = [];
      completeGroupList.map((data) => {
        if (data.groupType === "parent")
          temp.push({ name: data.groupDisplayName, value: data.groupId });
      });
      setGroupList(temp);
    } else if (type === "all") {
      var temp = [];
      completeGroupList.map((data) => {
        temp.push({ name: data.groupDisplayName, value: data.groupId });
      });
      setGroupList(temp);
    }
  }, [type, isLoading]);

  return (
    <div>
      <CustomSelect
        data={{
          name: name,
          label: `${t("Select " + `${type}` + " Group")}`,
          list: groupList,
        }}
        mode={props.mode}
      />
    </div>
  );
};

