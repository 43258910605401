import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useState } from "react";
import DeleteDialog from "../../commonComponents/DeleteDialog";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import { AxiosFetch } from "../../AxiosFetch";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import NewPhoneEntryModal from "./NewPhoneEntryModal";
import EditPhoneEntryModal from "./EditPhoneEntryModal";
import { SearchBoxPhone } from "./SearchBoxPhone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ImportPhoneNumbers } from "./ImportPhoneNumbers";
import HideSourceIcon from "@mui/icons-material/HideSource";

export default function PhoneNumberIndex() {
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editInitialValues, setEditInitialValues] = useState({});
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "telNum",
      headerName: t("Telephone"),
      flex: 1,
    },
    {
      field: "customerGroup",
      headerName: t("Group"),
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.customerGroup !== null) {
          temp = params.row.customerGroup.groupDisplayName;
        }
        return temp;
      },
    },

    {
      field: "memo",
      headerName: t("Memo"),
      flex: 2,
    },
    // {
    //   field: "isActive",
    //   headerName: t("Active"),
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return t(params.row.isActive);
    //   },
    // },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row, "ppppp");
          setEditDialogOpen(true);
          setEditInitialValues(params.row);
          //navigate("/customerGroupDetail", { state: { data: params.row } });
        };
        return (
          <Tooltip title="Edit">
            <IconButton
              aria-label="Edit"
              size="large"
              onClick={onClick}
              color="primary"
              sx={{
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "disable",
      headerName: `${t("Disable")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDialogOpen(true);
          setSelectRow(params.row);
        };
        return (
          <Tooltip title="Disable">
            <span>
              {" "}
              <IconButton
                aria-label="disable"
                size="large"
                onClick={onClick}
                sx={{
                  color: "error.light",
                  "&:hover": {
                    color: "error.dark",
                  },
                }}
                disabled={!params.row.isActive}
              >
                <HideSourceIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const context = useContext(LoginContext);
  const axiosFetch = AxiosFetch();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectRow, setSelectRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [bulkDeleteFlag, setBulkDeleteFlag] = useState(false);
  useEffect(() => {
    context.setPageTitle("Do Not Call List");
  }, []);

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
    if (!e) {
      setBulkDeleteFlag(false);
    }
  };
  const handleRowDelete = (e) => {
    console.log(e, "eeee");
    var arr = [...rows];
    arr.splice(
      arr.findIndex((a) => a.groupId === e.groupId),
      1
    );
    //console.log(arr,'arrr');
    setRows(arr);
  };
  const handleDelete = async (e) => {
    setDelLoading(true);
    var APIvalues = [];

    if (bulkDeleteFlag) {
      selectedRows.forEach((iD, index) => {
        const foundItem = rows.find((item) => item.id === iD);
        if (foundItem) {
          APIvalues.push({
            id: foundItem.id,
            telNum: foundItem.telNum,
            customerGroup: { groupId: foundItem.customerGroup.groupId },
            memo: foundItem.memo,
            isActive: false,
          });
        }
      });
    } else {
      console.log(selectRow, "ppppp");
      APIvalues.push({
        id: selectRow.id,
        telNum: selectRow.telNum,
        customerGroup: { groupId: selectRow.customerGroup.groupId },
        memo: selectRow.memo,
        isActive: false,
      });
    }
    console.log(APIvalues, "APIvalues for delete");
    try {
      const response = await axiosFetch.put(
        `/doNotCallList`,
        JSON.stringify(APIvalues)
      );
      console.log(response, "do not call edit API response");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `Delete Successful`,
          });
      }
      //handleRowDelete(selectRow);
      //setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${
          error.response ||
          error.response.data ||
          error.response.data.errorMessage
        }`,
      });
      console.log(error, "error");
      //setDelLoading(false);
    } finally {
      getData();
      setDelLoading(false);
      handleDialogOpen(false);
    }
  };
  async function getData() {
    try {
      const apiResponse = await axiosFetch.get("/doNotCallList");
      console.log(apiResponse, "do not call List API response");
      setRows(apiResponse.data);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <EditPhoneEntryModal
        open={editDialogOpen}
        refreshList={getData}
        initialValues={editInitialValues}
        handleClose={() => {
          setEditDialogOpen(false);
        }}
      />
      <Grid
        container
        columnSpacing={0.5}
        rowSpacing={2}
        sx={{
          pointerEvents: `${searchLoading ? "none" : "auto"}`,
        }}
      >
        <Grid item xs={6}>
          <NewPhoneEntryModal refreshList={getData} />
        </Grid>
        <Grid item xs={6}>
          <ImportPhoneNumbers refreshList={getData} />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              //border: "2px solid rgb(224 224 224)",
              border: "2px solid #7f7f7f",
              borderRadius: "4px",
            }}
          >
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <Button
                  variant={filterToggle ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => {
                    setFilterToggle(!filterToggle);
                  }}
                  sx={{
                    width: "98%",
                    // borderRadius: "40px",
                    marginLeft: "1%",
                    marginTop: "2%",
                  }}
                  startIcon={
                    filterToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />
                  }
                  size="large"
                >
                  {filterToggle
                    ? `${t("Hide Search Filters")}`
                    : `${t("Show Search Filters")}`}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  sx={{
                    width: "98%",
                    // borderRadius: "40px",
                    marginRight: "1%",
                    marginTop: "2%",
                  }}
                  onClick={() => {
                    setBulkDeleteFlag(true);
                    setDialogOpen(true);
                  }}
                  startIcon={<HideSourceIcon />}
                  disabled={!(selectedRows.length > 1)}
                  size="large"
                >
                  {t("Disable All Selected")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                {/* <div style={{ margin: "1%" }}>
                  <Formik>
                    <Form>
                      <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid item xs={2.2}>
                          <CustomTextField
                            data={{ name: "name", label: "Name" }}
                          />
                        </Grid>
                        <Grid item xs={2.2}>
                          <CustomTextField
                            data={{ name: "name", label: "Name" }}
                          />
                        </Grid>
                        <Grid item xs={2.2}>
                          <CustomTextField
                            data={{ name: "name", label: "Name" }}
                          />
                        </Grid>

                        <Grid item xs={2.2}>
                          <CustomTextField
                            data={{ name: "name", label: "Name" }}
                          />
                        </Grid>
                        <Grid item xs={2.2}>
                          <CustomTextField
                            data={{ name: "name", label: "Name" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          justifyContent="center"
                          display={"flex"}
                        >
                          <Tooltip title="Search">
                            <IconButton
                              aria-label="search"
                              // onClick={onClick}
                              color="primary"
                              sx={{
                                "&:hover": {
                                  color: "primay.dark",
                                },
                                fontSize: "2.8rem",
                              }}
                            >
                              <SearchIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Form>
                  </Formik>
                </div> */}
                <SearchBoxPhone
                  updateRows={(data) => {
                    setRows(data);
                  }}
                  searchLoading={(data) => {
                    setSearchLoading(data);
                  }}
                  toggle={filterToggle}
                />
              </Grid>
              <Grid item xs={12}>
                <LinearProgress
                  sx={{ visibility: searchLoading ? "" : "hidden" }}
                />
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={12}
                  onSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                  }}
                  selectionModel={selectedRows}
                  checkboxSelection
                  disableRowSelectionOnClick
                  sx={{
                    border: "0", // Remove the outermost border (box shadow)
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  autoHeight
                  disableVirtualization
                  // initialState={{
                  //   pagination: { paginationModel: { pageSize: 10 } },
                  // }}
                  // pageSizeOptions={[5, 10, 20, 50]}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
