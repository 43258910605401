import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AxiosFetch } from "../../AxiosFetch";
import CustomSelect from "../../formikInputs/CustomSelect";
import CustomTextField from "../../formikInputs/CustomTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginContext from "../../../context/LoginContext";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import FilterSelectInput from "./FilterSelectInput";
import CustomAutoComplete from "../../formikInputs/CustomAutoComplete";

export default function CreateNewItem() {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const userId = context.userId;
  useEffect(() => {
    context.setPageTitle("New Category Item");
  }, []);

  const location = useLocation();
  var submitAction = "";
  const [loading, setLoading] = useState(false);

  const data = location.state.data;
  //console.log(location, "location item page");

  const [type, setType] = useState("");

  const initialValues = {
    itemName: "",
    visible: true,
    category: {
      categoryId: data.categoryId,
    },
    itemType: "",
    registerUserId: userId,
    mandatory: false,
    filterSelectValue: [],
    selectValue: [],
  };
  const formValidation = Yup.object().shape({
    itemName: Yup.string().required(`${t("This field is required")}`),
    itemType: Yup.string().required(`${t("This field is required")}`),
  });

  const FormObserver = () => {
    const { values } = useFormikContext();
    console.log(values, "live values");
    useEffect(() => {
      setType(values.itemType);
    }, [values.itemType]);
    return null;
  };
  const PreviewItem = ({ type }) => {
    switch (type) {
      case "text":
        return <TextField label="test sample for text" />;

      case "number":
        return <TextField label="test sample for number" type="number" />;

      case "date":
        return (
          <TextField
            label="test sample for date"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        );
      case "datetime":
        return (
          <TextField
            label="test sample for datetime"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
          />
        );
      case "fileUpload":
        return (
          <>
            <input type="file" />
          </>
        );
      case "checkbox":
        return (
          <>
            <FormControlLabel control={<Checkbox />} label="checkbox" />
          </>
        );

      case "select":
        return (
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            //value={20}
            defaultValue={20}
            label="Age"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        );
      case "multipleselect":
        return (
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            //value={20}
            multiple
            defaultValue={[20]}
            renderValue={(selected) => selected.join(", ")}
            label="Age"
          >
            <MenuItem key={10} value={10}>
              <Checkbox />
              <ListItemText primary={10} />
            </MenuItem>
            <MenuItem key={20} value={20}>
              <Checkbox />
              <ListItemText primary={20} />
            </MenuItem>
            <MenuItem key={30} value={30}>
              <Checkbox />
              <ListItemText primary={30} />
            </MenuItem>
            <MenuItem key={40} value={40}>
              <Checkbox />
              <ListItemText primary={40} />
            </MenuItem>
          </Select>
        );
      case "filterselect":
        return (
          <>
            <FormControl>
              <InputLabel id="demo-simple-select-label">country</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                //value={20}
                defaultValue={20}
                label="country"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Japan</MenuItem>
                <MenuItem value={20}>India</MenuItem>
                <MenuItem value={30}>USA</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="demo-simple-select-label">city</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                //value={20}
                defaultValue={20}
                label="city"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Tokyo</MenuItem>
                <MenuItem value={20}>Delhi</MenuItem>
                <MenuItem value={30}>LA</MenuItem>
              </Select>
            </FormControl>
          </>
        );

      default:
        return <></>;
    }

    return <TextField />;
  };
  const handleSubmit = async (values) => {
    // console.log(values, "vvvvv");
    // alert(JSON.stringify(values, null, 2));
    setLoading(true);
    var chips = values.selectValue;
    var APIvalues = { ...values };
    delete APIvalues.chips;
    delete APIvalues.filterSelectValue;
    if (
      APIvalues.itemType === "select" ||
      APIvalues.itemType === "multipleselect"
    ) {
      APIvalues.itemOptions = [];
      chips.map((data, index) => {
        APIvalues.itemOptions[index] = { itemOptionValue: data };
      });
    } else if (APIvalues.itemType === "filterselect") {
      APIvalues.itemOptions = [];
      values.filterSelectValue.forEach((data, index) => {
        Object.entries(data).map(([key, value]) => {
          value.forEach((menuItem, i) => {
            APIvalues.itemOptions.push({
              itemOptionValue: `${key}:${menuItem}`,
            });
          });
        });
      });

      // APIvalues.itemOptions[0] = {
      //   itemOptionValue: JSON.stringify(values.filterSelectValue),
      // };
    }
    console.log(APIvalues, "API values");
    try {
      const Response = await axiosFetch.post(
        "/categoryItem",
        JSON.stringify(APIvalues)
      );
      console.log(Response);
      submitAction === "primary" ? navigate(-1) : window.location.reload();
      setLoading(false);
    } catch (error) {
      alert("error");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Form>
          <FormObserver />
          <Box
            sx={{
              alignItems: "center",
              px: "16%",
              py: "2%",
              // background: "red",
              pointerEvents: `${loading ? "none" : "auto"}`,
              filter: `${loading ? "brightness(90%)" : ""}`,
            }}
          >
            <Card elevation={4}>
              <LinearProgress
                sx={{ visibility: `${!loading ? "hidden" : ""}` }}
              />
              <CardContent>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                      {data.categoryName}
                    </Typography>
                    <Typography variant="h6" gutterBottom align="center">
                      {t("Group ID")} {data.customerGroup.groupId} :{" "}
                      {data.customerGroup.groupDisplayName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      data={{ name: "itemName", label: `${t("Item Name")}` }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "visible",
                        label: `${t("Display")}`,
                        list: [
                          { name: `${t("visible")}`, value: true },
                          { name: `${t("hidden")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      data={{
                        name: "mandatory",
                        label: `${t("Mandatory")}`,
                        list: [
                          { name: `${t("yes")}`, value: true },
                          { name: `${t("no")}`, value: false },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomSelect
                      data={{
                        name: "itemType",
                        label: `${t("Item Type")}`,
                        list: [
                          { name: `${t("text")}`, value: "text" },
                          { name: `${t("number")}`, value: "number" },
                          {
                            name: `${t("select-ForItemType")}`,
                            value: "select",
                          },
                          {
                            name: `${t("multipleselect")}`,
                            value: "multipleselect",
                          },
                          { name: `${t("date")}`, value: "date" },
                          { name: `${t("datetime")}`, value: "datetime" },
                          { name: `${t("checkbox")}`, value: "checkbox" },
                          {
                            name: `${t("filterselect")}`,
                            value: "filterselect",
                          },
                          { name: `${t("fileUpload")}`, value: "fileUpload" },
                        ],
                      }}
                    />
                  </Grid>

                  

                  <Grid item xs={12}>
                    {(type === "select" || type === "multipleselect") && (
                      <CustomAutoComplete
                        mode={""}
                        data={{
                          name: "selectValue",
                          label: `${t("values for dropDown")}`,
                          placeholder: "Enter values",
                        }}
                        tagLimit={-1}
                      />
                    )}
                    {type === "filterselect" && <FilterSelectInput />}
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "error.light" }}
                      color="error"
                      size="large"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => {
                        navigate(-1);
                      }}
                      fullWidth
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        submitAction = "primary";
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        submitAction = "secondary";
                      }}
                    >
                      {t("Save and New")}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <div align="center">
                      {t("Preview")}
                      <br />
                      <PreviewItem type={type} />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      </Formik>
    </div>
  );
}
