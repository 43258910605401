import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Box, styled } from "@mui/system";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export default function Test2() {
  const [items, setItems] = React.useState([
    { id: 7, name: "george" },
    { id: 8, name: "rupert" },
    { id: 9, name: "alice" },
    { id: 10, name: "katherine" },
    { id: 11, name: "pam" },
    { id: 12, name: "katie" },
  ]);
  const ListChipItem = ({ text, index, moveListItem }) => {
    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
      type: "item",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // useDrop - the list item is also a drop area
    const [spec, dropRef] = useDrop({
      accept: "item",
      hover: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();
        // const hoverMiddleX =
        //   (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        // const hoverActualX =
        //   monitor.getClientOffset().x - hoverBoundingRect.left;

        // // if dragging to the right, continue only when hover is smaller than middle X
        // if (dragIndex < hoverIndex && hoverActualX < hoverMiddleX) return;
        // // if dragging to the left, continue only when hover is bigger than middle X
        // if (dragIndex > hoverIndex && hoverActualX > hoverMiddleX) return;

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverActualY =
          monitor.getClientOffset().y - hoverBoundingRect.top;

        // if dragging downwards, continue only when hover is smaller than middle Y
        if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
        // if dragging upwards, continue only when hover is bigger than middle Y
        if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

        moveListItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    // Join the 2 refs together into one (both draggable and can be dropped on)
    const ref = useRef(null);
    const dragDropRef = dragRef(dropRef(ref));

    // Make items being dragged transparent, so it's easier to see where we drop them
    const opacity = isDragging ? 0 : 1;
    return (
      <div
        ref={dragDropRef}
        style={{
          opacity: opacity,
          margin: 2,
          padding: 2,
          backgroundColor: "#ebebeb",
          borderRadius: 5,
          height: "30px",
        }}
      >
        <DragIndicatorIcon /> {text}
      </div>
    );
  };
  const moveFinalOrderListItem = useCallback(
    (dragIndex, hoverIndex, orderList) => {
      const dragItem = orderList[dragIndex];
      const hoverItem = orderList[hoverIndex];
      const updatedFinalOrderList = [...orderList];
      updatedFinalOrderList[dragIndex] = hoverItem;
      updatedFinalOrderList[hoverIndex] = dragItem;
      setItems(updatedFinalOrderList);
    },
    []
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          width: "90%",
          padding: "2%",
          margin: "auto",
          border: "5px solid black",
          //backgroundColor: "yellow",
        }}
      >
        <Grid container spacing={1}>
          {items.map((item, index) => (
            <Grid item key={item.id} xs={12}>
              <ListChipItem
                text={item.name}
                index={index}
                moveListItem={(dragIndex, hoverIndex) =>
                  moveFinalOrderListItem(dragIndex, hoverIndex, items)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </DndProvider>
  );
}
