import React, { useState, useCallback, useEffect } from "react";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useField } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import debounce from "lodash/debounce"; // Ensure lodash.debounce is installed

const CustomTextField = React.memo(({ mode, data, type, ...outerProps }) => {
  const [field, meta, helpers] = useField(data.name);
  const [dateFlag, setDateFlag] = useState(false);
  const readMode = mode === "read";

  // Initialize local state with Formik's field value for immediate feedback
  const [localValue, setLocalValue] = useState(field.value);

  // Update Formik's state with debounced value changes
  const debouncedSetValue = useCallback(debounce(helpers.setValue, 300), []);

  useEffect(() => {
    debouncedSetValue(localValue);
    // Cleanup on unmount
    return () => debouncedSetValue.cancel();
  }, [localValue, debouncedSetValue]);

  // Directly update local state for immediate feedback, debounce Formik updates
  const handleChange = useCallback((event) => {
    setLocalValue(event.target.value);
  }, []);

  const dateType =
    type == "date" ||
    type == "datetime-local" ||
    JSON.parse(sessionStorage.getItem("safariFlag"))
      ? true
      : false;

  return (
    <Grid container spacing={1}>
      <Grid item xs={dateType ? 12 : 12}>
        <Tooltip
          title={localValue || "Enter value here"}
          placement="bottom"
          arrow
        >
          <TextField
            {...field}
            {...outerProps}
            label={data.label} // Ensure label is passed here
            value={localValue}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            type={dateFlag ? type : "text"}
            disabled={readMode || (dateType && !dateFlag)}
            InputProps={{
              ...outerProps.InputProps,
              endAdornment: dateType ? (
                <IconButton
                  onClick={() => {
                    setDateFlag(!dateFlag);
                  }}
                >
                  {dateFlag ? <CancelIcon /> : <DateRangeIcon />}
                </IconButton>
              ) : null,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
});

export default CustomTextField;
