import "../../App.css";
import React, { useEffect, useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CustForm from "./custForm/CustForm";
import { Formik, Form, useFormikContext, FormikContext } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { AxiosFetch } from "../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CategoryFormIndex from "./categoryFormRelated/CategoryFormIndex";

export default function NewCustomer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();

  const context = useContext(LoginContext);
  useEffect(() => {
    context.setPageTitle("Create New Customer");
  }, []);

  const template = {
    customerGroup: { groupId: 0 },
    project: {
      id: 0,
    },
    telephone: [],
    customerBirthday: "",
    customerListId: "",
    customerLastName: "",
    customerFirstName: "",
    customerLastRuby: "",
    customerFirstRuby: "",
    customerZipCode: "",
    customerAddress1: "",
    customerAddress2: "",
    customerAddress3: "",
    customerAddress4: "",
    customerEmail: "",
    customerSex: "",
    customerFirstUserId: "",
    customerSecondUserId: "",
    customerModifiedUserId: "",
    customerMemo1: "",
    customerMemo2: "",
    customerLastCallLogFlag1: "",
    customerLastCallLogStartTime: "",
    customerCount: "",
    //customerRegisterUserId: context.userId || "",
    customerRegisterUserId:
      `${context.userLastName} ${context.userFirstName}` || "",
    customerRegisterDateTime: "",
    customerModifyUserId: "",
    customerModifyDateTime: "",
    customerBusinessType: "",
    customerNextCallDateTime: "",
    customerTableName: "",
    customerFields: "",
    customerAge: "",
    categoryDataNew: {},
    customerProject: "",
    date: "",
  };

  const formValidation = Yup.object().shape({
    project: Yup.object().shape({
      id: Yup.string()
        .required("This field is required")
        .test(
          "zero not allowed",
          "This field is required",
          (val) => Number(val) !== 0
        ),
    }),
    customerGroup: Yup.object().shape({
      groupId: Yup.string()
        .required("This field is required")
        .test(
          "zero not allowed",
          "This field is required",
          (val) => Number(val) !== 0
        ),
    }),
    telephone: Yup.array()
      .of(Yup.string().matches(/^[1 2 3 4 5 6 7 8 9 0]+$/, "invalid Character"))
      .min(1, "This field is required"),
    // customerFirstRuby: Yup.string().matches(
    //   /^[ｱ	ｲ	ｳ	ｴ	ｵ ｧ	ｨ	ｩ	ｪ	ｫ ｶ	ｷ	ｸ	ｹ	ｺ ｶﾞ ｷﾞ	ｸﾞ	ｹﾞ	ｺﾞ ｻ	ｼ	ｽ	ｾ	ｿ ｻﾞ	ｼﾞ	ｽﾞ	ｾﾞ	ｿﾞ ﾀ	ﾁ	ﾂ	ﾃ	ﾄ ﾀﾞ	ﾁﾞ	ﾂﾞ	ﾃﾞ	ﾄﾞ ナ行	ﾅ	ﾆ	ﾇ	ﾈ	ﾉ ハ行	ﾊ	ﾋ	ﾌ	ﾍ	ﾎ ﾊﾞ	ﾋﾞ	ﾌﾞ	ﾍﾞ	ﾎﾞ ﾊﾟ	ﾋﾟ	ﾌﾟ	ﾍﾟ	ﾎﾟ ﾏ	ﾐ	ﾑ	ﾒ	ﾓ ﾔ	ﾕ	ﾖ	ﾗ	ﾘ	ﾙ	ﾚ	ﾛ ﾜ	ｦ	ﾝ	ﾞ	ﾟｯ	ｬ	ｭ	ｮ	ｰｳﾞ	｡	､	｢	｣]+$/,
    //   "Only Half width are allowed "
    // ),
    // customerLastRuby: Yup.string().matches(
    //   /^[ｱ	ｲ	ｳ	ｴ	ｵ ｧ	ｨ	ｩ	ｪ	ｫ ｶ	ｷ	ｸ	ｹ	ｺ ｶﾞ ｷﾞ	ｸﾞ	ｹﾞ	ｺﾞ ｻ	ｼ	ｽ	ｾ	ｿ ｻﾞ	ｼﾞ	ｽﾞ	ｾﾞ	ｿﾞ ﾀ	ﾁ	ﾂ	ﾃ	ﾄ ﾀﾞ	ﾁﾞ	ﾂﾞ	ﾃﾞ	ﾄﾞ ナ行	ﾅ	ﾆ	ﾇ	ﾈ	ﾉ ハ行	ﾊ	ﾋ	ﾌ	ﾍ	ﾎ ﾊﾞ	ﾋﾞ	ﾌﾞ	ﾍﾞ	ﾎﾞ ﾊﾟ	ﾋﾟ	ﾌﾟ	ﾍﾟ	ﾎﾟ ﾏ	ﾐ	ﾑ	ﾒ	ﾓ ﾔ	ﾕ	ﾖ	ﾗ	ﾘ	ﾙ	ﾚ	ﾛ ﾜ	ｦ	ﾝ	ﾞ	ﾟｯ	ｬ	ｭ	ｮ	ｰｳﾞ	｡	､	｢	｣]+$/,
    //   "Only Half width are allowed "
    // ),
    customerLastName: Yup.string().required("This field is required"),
    categoryDataNew: Yup.lazy((value) => {
      //console.log(value, "valloooooo");
      var temp = {};
      Object.keys(value).forEach((data, index) => {
        const result = itemsData.find((item) => item.itemId === Number(data));
        //console.log(result, "rrrrrrrr");
        if (result && result.mandatory && result.visible) {
          if (result.itemType === "multipleselect") {
            temp[data] = Yup.array()
              .of(
                Yup.array()
                  .of(Yup.string().required("This field is required"))
                  .min(1, "This field is required")
              )
              .min(1, "Main array must have at least 1 nested array")
              .required("Main array is required");
          } else if (result.itemType === "filterselect") {
            //for filterselect
            temp[data] = Yup.array().of(
              Yup.object().test(
                "is-object-empty",
                "This field is required",
                (obj) => {
                  return Object.keys(obj).length > 0; // Check if the object has any properties
                }
              )
            );
          } else {
            temp[data] = Yup.array().of(
              Yup.string().required("This field is required")
            );
          }
        }
      });

      return Yup.object().shape(temp);
    }),
  });
  //console.log(formValidation, "ffffff");
  const [initialValues, setInitialvalues] = useState(template);
  const [isloading, setIsloading] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [categoryItemsValidation, setCategoryItemsValidation] = useState({});

  //console.log(categoryItemsValidation, "cccvvvv");
  let submitAction;

  const [groupId, setGroupId] = useState(0);
  let catObj = [];

  async function getDataCategory(props) {
    const response = await axiosFetch.get(`/categoryItemsByGroupId/${props}`);
    //console.log(response, "categoryItemsByGroupId");
    return await response.data;
  }

  const FormObserver = () => {
    const { values, setFieldValue, isSubmitting, errors, ...props } =
      useFormikContext();
    //console.log(values, "live form values");
    useEffect(() => {
      setGroupId(values.customerGroup.groupId);
      if (groupId !== values.customerGroup.groupId) {
        setFieldValue("customerFirstUserId", "");
        setFieldValue("customerSecondUserId", "");
        setFieldValue("project.id", 0);
        getDataCategory(values.customerGroup.groupId).then((x) => {
          setItemsData(x);
        });
      }
    }, [values.customerGroup.groupId]);
    useEffect(() => {
      var temp = getAge(values.customerBirthday);
      //console.log(temp,'ageeeee');
      setFieldValue("customerAge", temp);
    }, [values.customerBirthday]);

    useEffect(() => {
      {
        //console.log(errors, "errrrr");
        isSubmitting &&
          Object.keys(errors).length !== 0 &&
          alert("fill all required fields");
      }
    }, [isSubmitting]);

    return null;
  };

  const handleSubmit = async (values, e) => {
    setSaveButtonLoading(true);
    console.log(values, "vvvvvv");
    //itemId:["itemValue1","itemValue2","itemValue3"] => below key:value pair
    Object.entries(values.categoryDataNew).map(([key, value]) => {
      value.forEach((data, index) => {
        //if item is multiple selext => when value is array

        if (Array.isArray(data)) {
          if (data.length !== 0)
            //below data is itemValue, if data empty then skip
            data.forEach((multiSelectItem, ii) => {
              catObj.push({
                itemId: key,
                value: multiSelectItem, //save each multi select option, new format.
                tabValue: index + 1,
              });
            });
          // catObj.push({
          //   itemId: key,
          //   value: JSON.stringify(data), //convert multipleselect array to string
          //   tabValue: index + 1,
          // });
        }
        //check for object for filterselect
        else if (typeof data === "object") {
          for (const [keyHere, valueHere] of Object.entries(data)) {
            //check for isEmpty
            if (keyHere && valueHere.length > 0) {
              valueHere.forEach((filterItem, ii) => {
                catObj.push({
                  itemId: key,
                  value: `${keyHere}:${filterItem}`, //save value using ":" new format.
                  tabValue: index + 1,
                });
              });
            }
            // if (keyHere && valueHere.length !== 0) {
            //   catObj.push({
            //     itemId: key,
            //     value: JSON.stringify(data),//convert filterselect object to string
            //     tabValue: index + 1,
            //   });
            // }
          }
        } else {
          if (data)
            //below data is itemValue, if data empty then skip
            catObj.push({
              itemId: key,
              value: data,
              tabValue: index + 1,
            });
        }
      });
    });
    var tempTelephone = [];
    values.telephone.map((data) => {
      tempTelephone.push({ telNum: data });
    });
    let APIvalues = {
      ...values,
      categoryData: catObj,
      customerGroup: { groupId: values.customerGroup.groupId },
      telephone: tempTelephone,
    };
    delete APIvalues.categoryDataNew;

    if (APIvalues.contentsOfConsultation) {
      APIvalues.contentsOfConsultation = JSON.stringify(
        APIvalues.contentsOfConsultation
      );
    }

    if (APIvalues.preferredContactTime) {
      APIvalues.preferredContactTime = JSON.stringify(
        APIvalues.preferredContactTime
      );
    }

    console.log(APIvalues, "API values");
    try {
      const custResponse = await axiosFetch.post(`/customers`, APIvalues);
      console.log(custResponse, "response");
      if (submitAction === "primary") navigate("/customerSearch");
      else {
        e.resetForm();
      }
    } catch (err) {
      console.log(err.response.data, "err");
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${err.response.data.errorMessage}`,
      });
    } finally {
      setSaveButtonLoading(false);
    }
    //temp check for MO(remove code later)
    // var reqFlag = "";
    // if (values.customerGroup.groupId === 4) {
    //   values.categoryDataNew["20"] &&
    //     values.categoryDataNew["20"].forEach((data, index) => {
    //       if (data === "") {
    //         alert(
    //           `data rquired キクチ入力事項 -> タブ番号 ${index + 1} -> 対応日`
    //         );
    //         reqFlag = "yes";
    //       }
    //     });

    //   //   }
    // } else if (values.customerGroup.groupId === 2) {
    //   values.categoryDataNew["16"] &&
    //     values.categoryDataNew["16"].forEach((data, index) => {
    //       if (data === "") {
    //         alert(`data rquired 片倉出力用 -> タブ番号 ${index + 1} -> 対応日`);
    //         reqFlag = "yes";
    //       }
    //     });
    // }
    //MO end ---- remove below reqFlag if else

    // if (reqFlag === "yes") {
    //   setSaveButtonLoading(false);
    //   return;
    // } else {
    // try {
    //   const custResponse = await axiosFetch.post(`/customers`, APIvalues);
    //   console.log(custResponse, "response");
    //   if (submitAction === "primary") navigate("/customerSearch");
    //   else window.location.reload();
    // } catch (err) {
    //   console.log(err.response.data, "err");
    //   context.setGlobalAlert({
    //     open: true,
    //     severity: "error",
    //     title: "error",
    //     message: `${err.response.data.errorMessage}`,
    //   });
    // } finally {
    //   setSaveButtonLoading(false);
    // }
  };
  // function AlertForError() {
  //   const {formik} = useFormikContext();
  //   const submitting = formik.isSubmitting;
  //   useEffect(() => {
  //     {submitting && alert('fill all required fields')}
  //   }, [submitting]);
  //   return null;
  // }

  return isloading ? (
    <>Loading</>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidation}
        validateOnMount={true}
        onSubmit={(e, props) => handleSubmit(e, props)}
      >
        <Form id="form">
          {/* <AlertForError /> */}
          <FormObserver />
          <div>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{
                minWidth: 275,
                minHeight: "86vh",
                overflowY: "auto",
                paddingBottom: "20px",
              }} //main height defined here
            >
              <Grid item xs={12} md={5.5}>
                <CustForm groupId={groupId} type="new" />
              </Grid>
              <Grid
                item
                xs={12}
                md={5.5}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <FormikContext.Consumer>
                  {(contextFormik) => (
                    <CategoryFormIndex
                      groupId={groupId}
                      contextFormik={contextFormik}
                    />
                  )}
                </FormikContext.Consumer>

                <br />

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="stretch"
                  height={"10%"}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "error.light" }}
                    color="error"
                    size="large"
                    startIcon={<ArrowBackIcon />}
                    fullWidth
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <LoadingButton
                    loading={saveButtonLoading}
                    variant="contained"
                    size="large"
                    type="submit"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      submitAction = "primary";
                    }}
                    fullWidth
                  >
                    {t("Save")}
                  </LoadingButton>
                  <LoadingButton
                    loading={saveButtonLoading}
                    variant="contained"
                    size="large"
                    type="submit"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      submitAction = "secondary";
                    }}
                    fullWidth
                  >
                    {t("Save and New")}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </Form>
      </Formik>
    </>
  );
}
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age || "";
}
//       //console.log(validation, "validationn");
//       //setFieldValue("categoryDataNew", test); //////important remove comment
//       if (
//         Object.keys(categoryItemsValidation).length === 0 &&
//         Object.keys(validation).length !== 0
//       ) {
//         //console.log("h11111");
//         setCategoryItemsValidation(validation);
//       } else if (
//         JSON.stringify(categoryItemsValidation) !==
//         JSON.stringify(validation)
//       ) {
//         //console.log("h2222", categoryItemsValidation, validation);
//         setCategoryItemsValidation(validation);
//       }
//     });
//   }
// }

// telephone: Yup.array().of(
//   Yup.object().shape({
//     // telNum: Yup.string().test(
//     //   "character check",
//     //   "invalid character",
//     //   (value) => {
//     //     if (typeof value === "undefined") return true;
//     //     var temp = /[a-zA-Z ０１２３４５６７８９ -]/gmu.test(value);
//     //     return !temp;
//     //   }
//     // ),
//     telNum: Yup.string().matches(
//       /^[1 2 3 4 5 6 7 8 9 0]+$/,
//       "invalid Character"
//     ),
//   })
// ),
