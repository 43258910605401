import { Grid, Button, Typography, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import CustomCheckbox from "../../formikInputs/CustomCheckbox";
import CustomMultiSelectCheck from "../../formikInputs/CustomMultiSelectCheck";
import CustomNumberField from "../../formikInputs/CustomNumberField";
import CustomSelect from "../../formikInputs/CustomSelect";
import CustomTextField from "../../formikInputs/CustomTextField";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { ErrorMessage, FormikContext } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AxiosFetch } from "../../AxiosFetch";
import CustomFilterSelect from "../../formikInputs/CustomFilterSelect";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TaskIcon from "@mui/icons-material/Task";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";

//readMode="read" or ""
//pageType="search" or ""
export function renderItem(item, indexNumber, readMode, pageType) {
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const context = useContext(LoginContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  const getFileName = (str) => {
    const i = str.indexOf("_") + 1;
    return str.slice(i);
  };

  const handleFileSelect = async (event, name, setFieldValue) => {
    const fileSelected = event.target.files[0];
    if (fileSelected) {
      setFileLoading(true);
      const unique_id = uuid();
      // const ext = selectedFile.type.substr(
      //   selectedFile.type.lastIndexOf("/") + 1,
      //   selectedFile.type.length
      // );
      // const splitParts = name.split(".");
      // console.log(splitParts, "splitttt");
      const fileName = unique_id + "_" + fileSelected.name;
      //const fileName = unique_id + "." + ext;
      console.log(fileName, "filename");
      setFieldValue(name, fileName);
      try {
        var formData = new FormData();
        formData.append("file", fileSelected);
        formData.append("fileName", fileName);
        const response = await axiosFetch.post("/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response, "upload file API response");
        {
          response &&
            context.setGlobalAlert({
              open: true,
              severity: "success",
              title: "success",
              message: `File Upload Successful`,
            });
        }
        setSelectedFile(event.target.files[0]);
      } catch (error) {
        console.log(error, "errr");
        //alert("File upload error");
        context.setGlobalAlert({
          open: true,
          severity: "error",
          title: "error",
          message: `File Upload Error`,
        });
        setSelectedFile(null);
      } finally {
        setFileLoading(false);
        //setSelectedFile(event.target.files[0]);
      }
    } else {
      alert("select file");
    }
  };
  // console.log(selectedFile, "ffffff");

  const handleFileSubmit = async (name, setFieldValue) => {
    if (selectedFile) {
      // const unique_id = uuid();
      // const ext = selectedFile.type.substr(
      //   selectedFile.type.lastIndexOf("/") + 1,
      //   selectedFile.type.length
      // );
      const splitParts = name.split(".");
      console.log(splitParts, "splitttt");
      const fileName =
        splitParts[1] + "-" + (splitParts[2] + 1) + "_" + selectedFile.name;
      //const fileName = unique_id + "." + ext;
      console.log(fileName, "filename");
      setFieldValue(name, fileName);
      // try {
      //   var formData = new FormData();
      //   formData.append("file", selectedFile);
      //   formData.append("fileName", fileName);
      //   const response = await axiosFetch.post("/uploadFile", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   });
      //   console.log(response, "rrr");
      //   {
      //     response && alert("fileUploaded");
      //   }
      // } catch (error) {
      //   console.log(error, "errr");
      // }
    } else {
      alert("select file");
    }
  };

  const handleOpenFile = (obj) => {
    const fileName = obj.value; // replace with your file name
    const displayFileName = getFileName(obj.value);
    axiosFetch
      .get(`/downloadFile/${fileName}`, { responseType: "blob" })
      .then((response) => {
        // Create a new Blob object with the response data and the appropriate content type
        console.log(response, "download file response");
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a URL for the blob data
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;

        // Set the desired custom file name
        a.download = displayFileName; // Change "custom_file_name.ext" to your desired file name

        // Simulate a click on the anchor element to trigger the download
        a.click();

        // Clean up by revoking the object URL

        // Open the URL in a new tab
        //window.open(fileURL, "_blank");
        URL.revokeObjectURL(fileURL);
        // const previewWindow = window.open();
        // previewWindow.document.write(`<iframe src="${fileURL}" frameborder="0" style="border:0; width:100%; height:100vh;"></iframe>`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  switch (item.itemType) {
    case "text": {
      return (
        <CustomTextField
          mode={readMode}
          data={{
            name: `categoryDataNew.${item.itemId}.${indexNumber}`,
            label: item.itemName,
          }}
          multiline
          minRows={1}
        />
      );
    }
    case "number": {
      return (
        <CustomNumberField
          mode={readMode}
          data={{
            name: `categoryDataNew.${item.itemId}.${indexNumber}`,
            label: item.itemName,
          }}
        />
      );
    }
    case "date": {
      if (pageType === "search") {
        return (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: `categoryDataNew.${item.itemId}.${indexNumber}`,
                  label: `${item.itemName} Start`,
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: `categoryDataNew.${item.itemId}.${indexNumber}DateEnd`,
                  label: `${item.itemName} End`,
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <CustomTextField
            mode={readMode}
            data={{
              name: `categoryDataNew.${item.itemId}.${indexNumber}`,
              label: item.itemName,
            }}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        );
      }
    }

    case "datetime": {
      if (pageType === "search") {
        return (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: `categoryDataNew.${item.itemId}.${indexNumber}`,
                  label: `${item.itemName} Start`,
                }}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                // InputProps={{
                //   style: {
                //     fontSize: "small",
                //   },
                // }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: `categoryDataNew.${item.itemId}.${indexNumber}DateTimeEnd`,
                  label: `${item.itemName} End`,
                }}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <CustomTextField
            mode={readMode}
            data={{
              name: `categoryDataNew.${item.itemId}.${indexNumber}`,
              label: item.itemName,
            }}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
          />
        );
      }
    }
    case "select": {
      var list = [];
      // console.log(item,"iii");
      item.itemOptions.map((data, index) => {
        list[++index] = {
          value: data.itemOptionValue,
          name: data.itemOptionValue,
        };
      });
      list[0] = {
        value: "",
        name: t("Not Specified"),
      };
      //console.log(list, "lll");
      return (
        <CustomSelect
          data={{
            name: `categoryDataNew.${item.itemId}.${indexNumber}`,
            label: item.itemName,
            list: list,
          }}
          mode={readMode}
        />
      );
    }
    case "multipleselect": {
      // console.log(item,'iiiiiii');
      if (pageType === "search") {
        let list = [];
        // console.log(item,"iii");
        item.itemOptions.map((data, index) => {
          list[++index] = {
            value: data.itemOptionValue,
            name: data.itemOptionValue,
          };
        });
        list[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        //console.log(list, "lll");
        return (
          <CustomSelect
            data={{
              name: `categoryDataNew.${item.itemId}.${indexNumber}`,
              label: item.itemName,
              list: list,
            }}
            mode={readMode}
          />
        );
      } else {
        let list = [];
        item.itemOptions.map((data, index) => {
          list[index] = {
            value: data.itemOptionValue,
            name: data.itemOptionValue,
          };
        });
        // console.log(list, "multiselect list ");
        return (
          <CustomMultiSelectCheck
            data={{
              name: `categoryDataNew.${item.itemId}.${indexNumber}`,
              label: item.itemName,
              list: list,
            }}
            mode={readMode}
          />
          //<></>
        );
      }
    }
    case "filterselect": {
      var list = [];
      item.itemOptions.forEach((data, index) => {
        const parts = data.itemOptionValue.split(":");
        const keyExists = list.some((item) => item.hasOwnProperty(parts[0]));
        if (!keyExists) {
          list.push({ [parts[0]]: [parts[1]] });
        } else {
          const keyIndex = list.findIndex((item) =>
            Object.keys(item).includes(parts[0])
          );
          list[keyIndex][parts[0]].push(parts[1]);
        }
      });
      return (
        <CustomFilterSelect
          data={{
            name: `categoryDataNew.${item.itemId}.${indexNumber}`,
            label: item.itemName,
            // list: JSON.parse(item.itemOptions[0].itemOptionValue),
            list: list,
          }}
          mode={readMode}
        />
      );
    }
    case "checkbox": {
      return (
        <CustomCheckbox
          data={{
            name: `categoryDataNew.${item.itemId}.${indexNumber}`,
            label: item.itemName,
          }}
          mode={readMode}
        />
      );
    }
    case "fileUpload": {
      return readMode === "read" ? (
        <FormikContext.Consumer>
          {(context) => {
            let obj = context.values.categoryData.find(
              (arr) =>
                arr.itemId === item.itemId && arr.tabValue === indexNumber + 1
            );

            return (
              <>
                <Typography variant="body2" align="center">
                  {item.itemName}
                </Typography>
                <Button
                  variant="outlined"
                  size="medium"
                  disabled={!(obj && obj.value)}
                  onClick={() => handleOpenFile(obj)}
                  startIcon={<VisibilityIcon />}
                  fullWidth
                  //sx={{ my: "2%" }}
                >
                  {(obj && obj.value && getFileName(obj.value)) ||
                    t("Preview File")}
                </Button>
              </>
            );
          }}
        </FormikContext.Consumer>
      ) : (
        <div>
          <LinearProgress
            sx={{ display: !fileLoading ? "none" : "block", marginTop: "5%" }}
          />
          <FormikContext.Consumer>
            {(context) => {
              let obj =
                context.values.categoryData &&
                context.values.categoryData.find(
                  (arr) =>
                    arr.itemId === item.itemId &&
                    arr.tabValue === indexNumber + 1
                );
              return (
                <div style={{ display: fileLoading ? "none" : "block" }}>
                  <Typography variant="body2" align="center">
                    {item.itemName}
                  </Typography>
                  <Button
                    variant={selectedFile || obj ? "contained" : "outlined"}
                    size="medium"
                    component="label"
                    fullWidth
                    startIcon={
                      selectedFile || obj ? <TaskIcon /> : <FileUploadIcon />
                    }
                    //sx={{ my: "2%" }}
                  >
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileSelect(
                          e,
                          `categoryDataNew.${item.itemId}.${indexNumber}`,
                          context.setFieldValue
                        )
                      }
                      id="csvInput"
                      name="file"
                      hidden
                    />
                    {selectedFile || obj
                      ? (selectedFile && selectedFile.name) ||
                        (obj && obj.value && getFileName(obj.value))
                      : t("Upload File")}
                  </Button>
                  {/* <input type="file" onChange={handleFileSelect} />
                <button
                  type="button"
                  onClick={(e) => {
                    handleFileSubmit(
                      `categoryDataNew.${item.itemId}.${indexNumber}`,
                      context.setFieldValue
                    );
                  }}
                >
                  {t("Upload")}
                </button> */}
                </div>
              );
            }}
          </FormikContext.Consumer>

          <CustomTextField
            mode={readMode}
            data={{
              name: `categoryDataNew.${item.itemId}.${indexNumber}`,
              label: item.itemName,
            }}
            hidden
          />
          <span style={{ color: "#d3302f" }}>
            <ErrorMessage
              name={`categoryDataNew.${item.itemId}.${indexNumber}`}
            />
          </span>
        </div>
      );
    }
    //   // Add all cases here for the category options.
    default:
      return <div></div>;
  }
}
