import React, { useState, useEffect, useContext } from "react";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import CustomTextField from "../../formikInputs/CustomTextField";
import { AxiosFetch } from "../../AxiosFetch";
import CustomSelect from "../../formikInputs/CustomSelect";
import CustomCheckbox from "../../formikInputs/CustomCheckbox";
import CustomMultiSelectCheck from "../../formikInputs/CustomMultiSelectCheck";
import CustomNumberField from "../../formikInputs/CustomNumberField";
import { FormikContext, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import LoginContext from "../../../context/LoginContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomFilterSelect from "../../formikInputs/CustomFilterSelect";
import { useTranslation } from "react-i18next";

function CategoryData(props) {
  const type = props.type;
  const axiosFetch = AxiosFetch();
  const { t } = useTranslation();
  const context = useContext(LoginContext);
  const mode = props.mode;

  // const customerId = props.customerId;

  const [categoryItems, setCategoryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  //console.log(categoryItems);
  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(
        `/categoryItems/${props.categoryId}`
      );
      console.log(response, "category data items response");
      if (response.status === 200) {
        setCategoryItems(response.data);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleFileSubmit = async (name, setFieldValue) => {
    if (selectedFile) {
      const unique_id = uuid();
      const ext = selectedFile.type.substr(
        selectedFile.type.lastIndexOf("/") + 1,
        selectedFile.type.length
      );
      const fileName = unique_id + "." + ext;
      console.log(fileName, "filename");
      setFieldValue(name, fileName);
      try {
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileName", fileName);
        const response = await axiosFetch.post("/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response, "file submit response");
        {
          response && setFileUploadOpen(true);
        }
      } catch (error) {
        console.log(error, "errr");
      }
    } else {
      alert("select file");
    }
  };

  const handleOpenFile = (obj) => {
    const fileName = obj.value; // replace with your file name
    axiosFetch
      .get(`/downloadFile/${fileName}`, { responseType: "blob" })
      .then((response) => {
        // Create a new Blob object with the response data and the appropriate content type
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a URL for the blob data
        const fileURL = URL.createObjectURL(file);

        // Open the URL in a new tab
        window.open(fileURL, "_blank");
        // const previewWindow = window.open();
        // previewWindow.document.write(`<iframe src="${fileURL}" frameborder="0" style="border:0; width:100%; height:100vh;"></iframe>`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log("Selected file:", selectedFile);
  }, [selectedFile]);

  function renderItem(item) {
    switch (item.itemType) {
      case "text": {
        return (
          <CustomTextField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
            multiline
            minRows={1}
          />
        );
      }
      case "number": {
        return type === "search" ? (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <CustomNumberField
                mode={mode}
                data={{
                  name: `categoryDataNew.${item.itemId}`,
                  label: `${item.itemName} ~`,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomNumberField
                mode={mode}
                data={{
                  name: `categoryDataNew.${item.itemId}NumberEnd`,
                  label: `~ ${item.itemName}`,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <CustomNumberField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
          />
        );
      }
      case "date": {
        if (type === "search") {
          return (
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <CustomTextField
                  mode={mode}
                  data={{
                    name: `categoryDataNew.${item.itemId}`,
                    label: `${item.itemName} ~`,
                  }}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  mode={mode}
                  data={{
                    name: `categoryDataNew.${item.itemId}DateEnd`,
                    label: `~ ${item.itemName}`,
                  }}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <CustomTextField
              mode={mode}
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
              }}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          );
        }
      }
      case "datetime": {
        if (type === "search") {
          return (
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <CustomTextField
                  mode={mode}
                  data={{
                    name: `categoryDataNew.${item.itemId}`,
                    label: `${item.itemName} ~`,
                  }}
                  type="datetime-local"
                  InputLabelProps={{ shrink: true }}
                  // InputProps={{
                  //   style: {
                  //     fontSize: "small",
                  //   },
                  // }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  mode={mode}
                  data={{
                    name: `categoryDataNew.${item.itemId}DateTimeEnd`,
                    label: `~ ${item.itemName}`,
                  }}
                  type="datetime-local"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <CustomTextField
              mode={mode}
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
              }}
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
            />
          );
        }
      }
      case "select": {
        var list = [];
        // console.log(item,"iii");
        item.itemOptions.map((data, index) => {
          list[++index] = {
            value: data.itemOptionValue,
            name: data.itemOptionValue,
          };
        });
        list[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        //console.log(list, "lll");
        return (
          <CustomSelect
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
              list: list,
            }}
            mode={mode}
          />
        );
      }
      case "multipleselect": {
        // console.log(item,'iiiiiii');
        //type === search not being used now.
        if (type === "searchhh") {
          var list = [];
          // console.log(item,"iii");
          item.itemOptions.map((data, index) => {
            list[++index] = {
              value: data.itemOptionValue,
              name: data.itemOptionValue,
            };
          });
          list[0] = {
            value: 0,
            name: t("Not Specified"),
          };
          //console.log(list, "lll");
          return (
            <CustomSelect
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
                list: list,
              }}
              mode={mode}
            />
          );
        } else {
          var list = [];
          item.itemOptions.map((data, index) => {
            list[index] = {
              value: data.itemOptionValue,
              name: data.itemOptionValue,
            };
          });
          // console.log(list, "multiselect list ");
          return (
            <CustomMultiSelectCheck
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
                list: list,
              }}
              mode={mode}
            />
            //<></>
          );
        }
      }
      case "filterselect": {
        var list = [];
        item.itemOptions.forEach((data, index) => {
          const parts = data.itemOptionValue.split(":");
          const keyExists = list.some((item) => item.hasOwnProperty(parts[0]));
          if (!keyExists) {
            list.push({ [parts[0]]: [parts[1]] });
          } else {
            const keyIndex = list.findIndex((item) =>
              Object.keys(item).includes(parts[0])
            );
            list[keyIndex][parts[0]].push(parts[1]);
          }
        });
        return (
          <CustomFilterSelect
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
              // list: JSON.parse(item.itemOptions[0].itemOptionValue),
              list: list,
            }}
            pageType={"search"}
            mode={mode}
          />
        );
      }
      case "checkbox": {
        return (
          <CustomCheckbox
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
            mode={mode}
          />
        );
      }
      case "fileUpload": {
        return mode === "read" ? (
          <FormikContext.Consumer>
            {(context) => {
              let obj = context.values.categoryData.find(
                (arr) => arr.itemId === item.itemId
              );
              return (
                <Button
                  variant="outlined"
                  size="medium"
                  disabled={!Boolean(obj)}
                  onClick={() => handleOpenFile(obj)}
                  startIcon={<VisibilityIcon />}
                  fullWidth
                >
                  Preview File
                </Button>
              );
            }}
          </FormikContext.Consumer>
        ) : (
          <div>
            <FormikContext.Consumer>
              {(context) => (
                <>
                  <input type="file" onChange={handleFileSelect} />
                  <button
                    type="button"
                    onClick={(e) => {
                      handleFileSubmit(
                        `categoryDataNew.${item.itemId}`,
                        context.setFieldValue
                      );
                    }}
                  >
                    Upload
                  </button>
                </>
              )}
            </FormikContext.Consumer>

            <CustomTextField
              mode={mode}
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
              }}
              hidden
            />
          </div>
        );
      }
      // Add all cases here for the category options.
      default:
        return <div></div>;
    }
  }

  const items = categoryItems.map((item, index) => {
    return item.visible ? (
      <Grid item xs={6} key={index}>
        {renderItem(item)}
      </Grid>
    ) : (
      <div key={index}></div>
    );
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFileUploadOpen(false);
  };
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <Snackbar
        open={fileUploadOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          File uploaded successfully
        </Alert>
      </Snackbar>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {items}
      </Grid>
    </>
  );
}

export default CategoryData;
