import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import CustomTextField from "../formikInputs/CustomTextField";
import CustomSelect from "../formikInputs/CustomSelect";
import CustomCheckbox from "../formikInputs/CustomCheckbox";
import CustomMultiSelectCheck from "../formikInputs/CustomMultiSelectCheck";
import CustomNumberField from "../formikInputs/CustomNumberField";
import { FormikContext, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import LoginContext from "../../context/LoginContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomFilterSelect from "../formikInputs/CustomFilterSelect";
import { AxiosFetch } from "../AxiosFetch";
import WrapperCategoryItemForEdit from "./WrapperCategoryItemForEdit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";

function CategoryDataWithEdit({ hiddenFlag, mandatoryFlag, ...props }) {
  const type = props.type;
  const { t } = useTranslation();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const mode = props.mode;

  // const customerId = props.customerId;

  const [categoryItems, setCategoryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //console.log(categoryItems);
  useEffect(() => {
    async function getData() {
      const response = await axiosFetch.get(
        `/categoryItems/${props.categoryId}`
      );
      console.log(response, "category data items response");
      if (response.status === 200) {
        setCategoryItems(response.data);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  function renderItem(item) {
    switch (item.itemType) {
      case "text": {
        return (
          <CustomTextField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
            multiline
            minRows={1}
          />
        );
      }
      case "number": {
        return (
          <CustomNumberField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
          />
        );
      }
      case "date": {
        return (
          <CustomTextField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        );
      }

      case "datetime": {
        return (
          <CustomTextField
            mode={mode}
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
            }}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
          />
        );
      }
      case "select": {
        var list = [];
        // console.log(item,"iii");
        item.itemOptions.map((data, index) => {
          list[++index] = {
            value: data.itemOptionValue,
            name: data.itemOptionValue,
          };
        });
        list[0] = {
          value: 0,
          name: t("Not Specified"),
        };
        //console.log(list, "lll");
        return (
          <CustomSelect
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
              list: list,
            }}
            mode={mode}
          />
        );
      }
      case "multipleselect": {
        var list = [];
        item.itemOptions.map((data, index) => {
          list[index] = {
            value: data.itemOptionValue,
            name: data.itemOptionValue,
          };
        });
        // console.log(list, "multiselect list ");
        return (
          <CustomMultiSelectCheck
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
              list: list,
            }}
            mode={mode}
          />
          //<></>
        );
      }
      case "filterselect": {
        var list = [];
        item.itemOptions.forEach((data, index) => {
          const parts = data.itemOptionValue.split(":");
          const keyExists = list.some((item) => item.hasOwnProperty(parts[0]));
          if (!keyExists) {
            list.push({ [parts[0]]: [parts[1]] });
          } else {
            const keyIndex = list.findIndex((item) =>
              Object.keys(item).includes(parts[0])
            );
            list[keyIndex][parts[0]].push(parts[1]);
          }
        });
        return (
          <CustomFilterSelect
            data={{
              name: `categoryDataNew.${item.itemId}`,
              label: item.itemName,
              // list: JSON.parse(item.itemOptions[0].itemOptionValue),
              list: list,
            }}
            mode={mode}
          />
        );
      }
      case "checkbox": {
        return (
          <div
            style={{
              border: "1px solid #c5c5c5",
              alignItems: "center",
              paddingLeft: 10,
              display: "flex",
            }}
          >
            <CustomCheckbox
              data={{
                name: `categoryDataNew.${item.itemId}`,
                label: item.itemName,
              }}
              mode={mode}
            />
          </div>
        );
      }
      case "fileUpload": {
        return (
          <>
            <Typography variant="body2" align="center">
              {item.itemName}
            </Typography>
            <Button
              variant={"outlined"}
              size="medium"
              component="label"
              fullWidth
              startIcon={<FileUploadIcon />}
            >
              <input type="file" id="csvInput" name="file" hidden />
              {t("Upload File")}
            </Button>
          </>
          // <div
          //   style={{
          //     border: "1px solid #c5c5c5",
          //     height: "100%",
          //     alignItems: "center",
          //     paddingLeft: 10,
          //     justifyContent: "flex-start",
          //     display: "flex",
          //   }}
          // >

          //   <input type="file" style={{ width: "50%" }} />
          //   <button type="button">Upload</button>
          //   <CustomTextField
          //     mode={mode}
          //     data={{
          //       name: `categoryDataNew.${item.itemId}`,
          //       label: item.itemName,
          //     }}
          //     hidden
          //   />
          // </div>
        );
      }
      // Add all cases here for the category options.
      default:
        return <div></div>;
    }
  }

  const items = categoryItems.map((item, index) =>
    hiddenFlag ? (
      <Grid item xs={6} key={index}>
        <WrapperCategoryItemForEdit
          itemDetails={item}
          mandatoryFlag={mandatoryFlag}
        >
          {renderItem(item)}
        </WrapperCategoryItemForEdit>
      </Grid>
    ) : item.visible ? (
      <Grid item xs={6} key={index}>
        <WrapperCategoryItemForEdit
          itemDetails={item}
          mandatoryFlag={mandatoryFlag}
        >
          {renderItem(item)}
        </WrapperCategoryItemForEdit>
      </Grid>
    ) : (
      <div key={index}></div>
    )
  );

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {items}
      </Grid>
    </>
  );
}

export default CategoryDataWithEdit;
