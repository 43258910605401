import "../../../App.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Stack,
  Box,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomerSearchTable from "./CustomerSearchTable";
import { Formik, Form, useFormikContext } from "formik";
import CustCategoryFormPlus from "./CustCategoryFormPlus";
import { AxiosFetch } from "../../AxiosFetch";
import { useNavigate } from "react-router";
import LoginContext from "../../../context/LoginContext";
import GroupSelect from "../../formikInputs/GroupSelect";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../formikInputs/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CancelIcon from "@mui/icons-material/Cancel";
import SerachIndexWrapper, { SearchContext } from "./SerachIndexWrapper";
import ProjectSelectFromGroup from "./formikComponentsForSearch/ProjectSelectFromGroup";
import HelpIcon from "@mui/icons-material/Help";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useAppContext } from "../../../context/AppContext";

export default function CustomerSearchScreenIndex() {
  const { t, i18n } = useTranslation();
  const axiosFetch = AxiosFetch();
  const navigate = useNavigate();
  const { userRole, ...context } = useContext(LoginContext);
  const { appState, updateSearchResults } = useAppContext();

  useEffect(() => {
    context.setPageTitle("Customer Search");
  }, []);

  const initialValues = (context.searchPageDetails &&
    context.searchPageDetails.searchFields) || {
    custData: {
      customerGroup: { groupId: 0 },

      telephone: [
        {
          telNum: "",
        },
      ],
      customerBirthday: "",
      customerBirthdayEnd: "",
      customerLastName: "",
      customerFirstName: "",
      customerLastRuby: "",
      customerFirstRuby: "",
      customerZipCode: "",
      customerAddress1: "",
      customerAddress2: "",
      customerAddress3: "",
      customerAddress4: "",
      customerEmail: "",
      customerSex: "",
      customerFirstUserId: "",
      customerSecondUserId: "",
      customerMemo1: "",
      customerMemo2: "",
      customerCount: "",
      customerAge: "",
      storeUsed: "",
      householdIncome: "",
      contentsOfConsultation: "",
      preferredContactTime: "",
      customerMaritalStatus: "",
      customerChildren: "",
      topicEmpty: false,
      topic: [
        {
          registerDateTime: "",
          registerDateTimeEnd: "",
          modifyDateTime: "",
          modifyDateTimeEnd: "",
          modifyUserId: "",
          title: "",
          caller: "",
          phase: "",
          callFlagList: [],
          tickets: [
            {
              nextCallDate: "",
              nextCallDateEnd: "",
            },
          ],
        },
      ],
    },
    categoryDataNew: {},
    projectList: [],
  };
  const [searchObject, setSearchObject] = useState({});
  const [groupId, setgroupId] = useState(
    (context.searchPageDetails &&
      context.searchPageDetails.searchFields &&
      context.searchPageDetails.searchFields.custData.customerGroup.groupId) ||
      0
  );
  const [custInformation, setcustInformation] = useState(
    appState.searchResults
  );
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const populateSearchResults = () => {
      updateSearchResults(custInformation);
    };
    populateSearchResults();
  }, [custInformation]);

  const scrollRef = useRef(null);
  const formRef = useRef(null);

  async function handleSearch(values) {
    console.log(values, "vvv");
    setTableLoading(true);
    scrollRef.current.scrollIntoView();
    const { categoryDataNew, custData, projectList } = values;
    let categoryDataArray = [];
    if (categoryDataNew) {
      for (const [key, value] of Object.entries(categoryDataNew)) {
        if (key.search("DateEnd") > -1) {
          let i = key.slice(0, key.search("DateEnd"));
          let obj = categoryDataArray.find((o) => o.itemId === i);
          if (obj) {
            let newObj = { ...obj, valueEnd: value || "date" };
            let index = categoryDataArray.findIndex((e) => e.itemId === i);
            categoryDataArray[index] = newObj;
          } else if (value) {
            categoryDataArray.push({
              itemId: i,
              value: "",
              valueEnd: value,
            });
          }
          // }
        } else if (key.search("DateTimeEnd") > -1) {
          let i = key.slice(0, key.search("DateTimeEnd"));
          let obj = categoryDataArray.find((o) => o.itemId === i);
          if (obj) {
            let newObj = { ...obj, valueEnd: value || "date" };
            let index = categoryDataArray.findIndex((e) => e.itemId === i);
            categoryDataArray[index] = newObj;
          } else if (value) {
            categoryDataArray.push({
              itemId: i,
              value: "",
              valueEnd: value,
            });
          }
        } else if (key.search("NumberEnd") > -1) {
          let i = key.slice(0, key.search("NumberEnd"));
          let obj = categoryDataArray.find((o) => o.itemId === i);
          if (obj) {
            let newObj = { ...obj, valueEnd: value || "date" };
            let index = categoryDataArray.findIndex((e) => e.itemId === i);
            categoryDataArray[index] = newObj;
          } else if (value) {
            categoryDataArray.push({
              itemId: i,
              value: "",
              valueEnd: value,
            });
          }
        } else if (Array.isArray(value)) {
          if (value.length !== 0) {
            value.forEach((multiSelectItem, ii) => {
              categoryDataArray.push({
                itemId: key,
                value: multiSelectItem,
              });
            });
          }
        } else if (typeof value === "object") {
          for (const [keyHere, valueHere] of Object.entries(value)) {
            //check for isEmpty
            if (keyHere && valueHere.length > 0) {
              valueHere.forEach((filterItem, ii) => {
                categoryDataArray.push({
                  itemId: key,
                  value: `${keyHere}:${filterItem}`,
                });
              });
            }
          }
        } else if (value) {
          categoryDataArray.push({
            itemId: key,
            value: value,
          });
        }
      }
    }
    var tempProjectList = [];
    if (projectList.length !== 0) {
      projectList.forEach((data) => {
        tempProjectList.push({ id: data });
      });
    }

    const customerObject = {
      customer: { ...custData, projectList: tempProjectList },
      categoryData: categoryDataArray,
    };
    setSearchObject(customerObject);
    try {
      const custResponse = await axiosFetch.post(
        "/customerList",
        JSON.stringify(customerObject)
      );
      console.log(custResponse, "Search response");
      var tempArr = [];
      setcustInformation(custResponse.data);
      custResponse.data.map((data) => {
        tempArr.push(data.customerId);
      });
      //console.log(tempArr,'tempArr');
      sessionStorage.setItem("searchArrayId", JSON.stringify(tempArr));

      context.setSearchPageDetails({ searchFields: values, pageNumber: 2 });
      setTableLoading(false);
    } catch (error) {
      console.log(error, "errr");
      alert("error");
      setTableLoading(false);
    }
  }
  const handleRowDelete = (e) => {
    console.log(e, "eeee");
    var arr = [...custInformation];
    arr.splice(
      arr.findIndex((a) => a.customerId === e.customerId),
      1
    );
    //console.log(arr,'arrr');
    setcustInformation(arr);
  };
  const handleReset = () => {
    //console.log(e,"eeee");
    formRef.current.setValues(emptyInitialValues);
    context.setSearchPageDetails(null);
  };
  async function getDataCategory(props) {
    const response = await axiosFetch.get(`/categoryItemsByGroupId/${props}`);
    console.log(response.data, "categoryItemsByGroupId");
    return await response.data;
  }
  const ExButton = () => {
    const { expiredNextCallRows } = useContext(SearchContext);
    return (
      <Button
        variant="contained"
        fullWidth
        color="error"
        //startIcon={<FileDownloadIcon />}
        sx={{
          justifyContent: "space-between",
          paddingLeft: "15%",
          display: "inline-flex",
        }}
        onClick={() => {
          setcustInformation(expiredNextCallRows);
          var tempArr = [];
          expiredNextCallRows.map((data, index) => {
            tempArr.push(data.customerId);
          });
          //console.log(tempArr,'tempArr');
          sessionStorage.setItem("searchArrayId", JSON.stringify(tempArr));
        }}
      >
        <span>
          <PriorityHighIcon />
          {t("Next scheduled response date has passed")}
          {`(${expiredNextCallRows.length})`}
        </span>
        <Tooltip
          title={
            <div>
              {t(
                "Displaying the number of cases that have exceeded the next scheduled response date"
              )}
            </div>
          }
          fontSize="small"
        >
          <HelpIcon />
        </Tooltip>
      </Button>
    );
  };

  const FormObserver = () => {
    const { values, setFieldValue } = useFormikContext();
    //console.log(values, "live values");
    useEffect(() => {
      // console.log(values.custData.customerGroupId);
      setgroupId(values.custData.customerGroup.groupId || 0);
      if (groupId !== values.custData.customerGroup.groupId) {
        setFieldValue("custData.customerFirstUserId", "");
        setFieldValue("custData.customerSecondUserId", "");
        setFieldValue("custData.project.id", 0);
        setFieldValue("categoryDataNew", {});

        //new
        getDataCategory(values.custData.customerGroup.groupId).then((x) => {
          var test = {};
          //console.log(x, "xxxxx");
          x.map((data, index) => {
            test[data.itemId] = "";
            if (data.itemType === "date") {
              test[data.itemId + "DateEnd"] = "";
            } else if (data.itemType === "datetime") {
              test[data.itemId + "DateTimeEnd"] = "";
            } else if (data.itemType === "number") {
              test[data.itemId + "NumberEnd"] = "";
            }
          });
          //console.log(validation, "validationn");
          setFieldValue("categoryDataNew", test);
        });
      }
    }, [values.custData.customerGroup.groupId]);
    return null;
  };

  return (
    <>
      <SerachIndexWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSearch}
          innerRef={formRef}
        >
          <Form>
            <FormObserver />
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems="stretch"
              direction="row"
              display="flex"
            >
              <Grid item xs={12} md={3.5}>
                <Card elevation={4}>
                  <CardContent>
                    <Stack spacing={4}>
                      <Box>
                        <CustomTextField
                          mode=""
                          data={{
                            name: "custData.telephone[0].telNum",
                            label: `${t("Telephone")}`,
                          }}
                        />
                      </Box>
                      <Box>
                        <GroupSelect name="custData.customerGroup.groupId" />
                      </Box>
                      <Box>
                        <ProjectSelectFromGroup
                          mode=""
                          name="projectList"
                          label={t("Project")}
                          groupId={groupId}
                          typeFlag="search"
                        />
                      </Box>
                      <Box>
                        {/* <Stack spacing={1}> */}
                        <Grid container columnSpacing={0.5} rowSpacing={1}>
                          <Grid item xs={11}>
                            <Button
                              variant="contained"
                              //startIcon={<SearchIcon />}
                              fullWidth
                              type="submit"
                              sx={{
                                justifyContent: "space-between",
                                paddingLeft: "15%",
                                display: "inline-flex",
                              }}
                            >
                              <span>
                                <SearchIcon />
                                {t("Search")}
                              </span>
                              <Tooltip
                                title={t(
                                  "Your search criteria will be retained. Click the x on the right to clear."
                                )}
                                fontSize="small"
                              >
                                <HelpIcon />
                              </Tooltip>
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            display={"flex"}
                            justifyContent={"end"}
                          >
                            <Tooltip title={t("Reset Form")}>
                              <IconButton
                                color="error"
                                variant="outlined"
                                onClick={handleReset}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              startIcon={<AddIcon />}
                              sx={{
                                justifyContent: "left",
                                paddingLeft: "15%",
                              }}
                              onClick={() => navigate("/newCustomer")}
                            >
                              {t("Create New Customer")}
                            </Button>
                          </Grid>
                          {userRole === 1 || userRole === 3 ? (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                fullWidth
                                //startIcon={<AssignmentIndIcon />}
                                sx={{
                                  justifyContent: "space-between",
                                  paddingLeft: "15%",
                                  display: "inline-flex",
                                }}
                                onClick={() => {
                                  navigate("/customerUserDistribution", {
                                    state: { data: custInformation },
                                  });
                                }}
                              >
                                <span>
                                  <AssignmentIndIcon />
                                  {t("Customer Assignment")}
                                </span>
                                <Tooltip
                                  // title={t(
                                  //   "This function allows you to assign part or all of the list displayed in the search results to any user. Click here for feature details"
                                  // )}
                                  title={
                                    <div>
                                      {t(
                                        "This function allows you to assign part or all of the list displayed in the search results to any user"
                                      )}
                                      <a
                                        href="https://docs.google.com/spreadsheets/d/11M0EnWjh92KEZbivP10A7-DpToj_7ZyYxi3-e651q8g/edit#gid=0"
                                        style={{ color: "lightblue" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {t("Click Here")}
                                      </a>
                                      {t("for details")}
                                    </div>
                                  }
                                  fontSize="small"
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </Button>
                            </Grid>
                          ) : null}

                          {userRole === 3 ? (
                            <>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  sx={{
                                    justifyContent: "space-between",
                                    paddingLeft: "15%",
                                    display: "inline-flex",
                                  }}
                                  onClick={() => {
                                    navigate("/customerProjectDistribution", {
                                      state: { data: custInformation },
                                    });
                                  }}
                                >
                                  <span>
                                    <AssignmentIndIcon />
                                    {t("Customer Project Assignment")}
                                  </span>
                                  <Tooltip
                                    title={
                                      <div>
                                        {t(
                                          "This function allows you to assign part or all of the list displayed in the search results to any project"
                                        )}
                                        <a
                                          href="https://docs.google.com/spreadsheets/d/11M0EnWjh92KEZbivP10A7-DpToj_7ZyYxi3-e651q8g/edit#gid=0"
                                          style={{ color: "lightblue" }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {t("Click Here")}
                                        </a>
                                        {t("for details")}
                                      </div>
                                    }
                                    fontSize="small"
                                  >
                                    <HelpIcon />
                                  </Tooltip>
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  sx={{
                                    justifyContent: "space-between",
                                    paddingLeft: "15%",
                                    display: "inline-flex",
                                  }}
                                  onClick={() => {
                                    navigate("/customerExport", {
                                      state: { data: searchObject },
                                    });
                                  }}
                                >
                                  <span>
                                    <FileDownloadIcon />
                                    {t("Customer Output")}
                                  </span>
                                  <Tooltip
                                    title={
                                      <div>
                                        {t(
                                          "Outputs the specified information from the customer information displayed in the search results."
                                        )}
                                        <a
                                          href="https://docs.google.com/spreadsheets/d/11M0EnWjh92KEZbivP10A7-DpToj_7ZyYxi3-e651q8g/edit#gid=0"
                                          style={{ color: "lightblue" }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {t("Click Here")}
                                        </a>
                                        {t("for details")}
                                      </div>
                                    }
                                    fontSize="small"
                                  >
                                    <HelpIcon />
                                  </Tooltip>
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12}>
                            <ExButton />
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={7.5}>
                <CustCategoryFormPlus groupId={groupId} type="search" />
              </Grid>
              <Grid item xs={12} md={11}>
                <Card elevation={4} sx={{ minHeight: 500 }} ref={scrollRef}>
                  {tableLoading ? <LinearProgress /> : <></>}
                  <CardContent>
                    <CustomerSearchTable
                      rows={custInformation}
                      handleRowDelete={handleRowDelete}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </SerachIndexWrapper>
    </>
  );
}

const emptyInitialValues = {
  custData: {
    customerGroup: { groupId: 0 },
    project: {
      id: 0,
    },
    telephone: [
      {
        telNum: "",
      },
    ],
    customerBirthday: "",
    customerBirthdayEnd: "",
    customerLastName: "",
    customerFirstName: "",
    customerLastRuby: "",
    customerFirstRuby: "",
    customerZipCode: "",
    customerAddress1: "",
    customerAddress2: "",
    customerAddress3: "",
    customerAddress4: "",
    customerEmail: "",
    customerSex: "",
    customerFirstUserId: "",
    customerSecondUserId: "",
    customerMemo1: "",
    customerMemo2: "",
    customerCount: "",
    customerAge: "",
    storeUsed: "",
    householdIncome: "",
    contentsOfConsultation: "",
    preferredContactTime: "",
    customerMaritalStatus: "",
    customerChildren: "",
    topic: [
      {
        registerDateTime: "",
        registerDateTimeEnd: "",
        modifyDateTime: "",
        modifyDateTimeEnd: "",
        modifyUserId: "",
        title: "",
        caller: "",
        phase: "",
        callFlagList: [],
        // ticket: {
        //   nextCallDate: "",
        //   nextCallDateEnd: "",
        // },
        tickets: [
          {
            nextCallDate: "",
            nextCallDateEnd: "",
          },
        ],
      },
    ],
  },
  categoryDataNew: {},
};
